import Logo from '@guaranteed-rate/react-components/dist/Logo';
import { generateLink, normalizeSearchParams } from '../../../pages/Prefi/prefiCommon';

const getHomeUrl = () => {
  const urlParams = normalizeSearchParams();
  urlParams.delete('code');
  return generateLink(`/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
}

function PrefiHeader() {
  const homeUrl = getHomeUrl();
  const isGra = (window as any).tenantId === 'gra';
  return (
    <div className="flex justify-between w-full mb-12">
      {isGra ? (
        <Logo
          className="mt-1"
          url={homeUrl}
          src="/images/Guaranteed_Rate_Affinity_Logo.svg"
          alt="Guaranteed Rate Affinity"
          width={119}
          height={32}
        />
      ) : (
        <Logo
          className="mt-1"
          url={homeUrl}
          src="/images/rate_logo.svg"
          alt="Rate, Inc"
          width={70}
          height={40}
        />
      )}
      {/* <div className="text-md mt-1">
        <span className="mr-3 font-bold">Español</span>
      </div> */}
    </div>
  );
}

export default PrefiHeader;
