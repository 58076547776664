import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface IS3SingedURL {
  url: string;
  uuid: string;
}

export interface IUploadResponse {
  uuid: string;
  fileName: string;
  error?: any;
}

const uploadImage = (urlDetails: IS3SingedURL, file: File) => {
  const fileName = file.name;
  const extension = file.type;
  let headers = new Headers();
  headers.append('Content-Type', extension);
  return new Promise((resolve, reject) => {
    fetch(urlDetails.url, {
      method: 'PUT',
      body: file,
      headers,
      redirect: 'follow',
    })
      .then(async (respose) => {
        if (respose.status === 200) {
          return resolve({ fileName, uuid: urlDetails.uuid });
        }

        return reject({ fileName, uuid: urlDetails.uuid, error: respose });
      })
      .catch((error) => reject({ fileName, uuid: urlDetails.uuid, error }));
  });
};

const useS3Upload = (
  applicationId: string,
  type: string,
  numberOfFiles?: number
) => {
  const [urlList, setUrlList] = useState<Array<IS3SingedURL>>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<boolean>();

  useEffect(() => {
    if (numberOfFiles) {
      getPresignedS3Url(false);
    }
  }, []);

  const getPresignedS3Url = async (setError: boolean, fileCount: number = numberOfFiles || 0) => {
    setFetchLoading(true);

    const mutation = {
      query: `{
            getS3SignedUrl(
              id: "${applicationId}",
              data: {
                type: "${type}",
                count: ${fileCount}
              }
            ) {
                uuid
                url
              }
          }`,
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation),
    });
    try {
      const data = await resp.json();
      if (data?.data?.getS3SignedUrl?.length) {
        setUrlList(data.data.getS3SignedUrl as Array<IS3SingedURL>);
        return data?.data?.getS3SignedUrl;
      }
    } catch (e: any) {
      console.error(e);
      if (setError) {
        setFetchError(true);
      }
    } finally {
      setFetchLoading(false);
    }
  };

  const fetchUrlAndUploadTheFileToS3 = async (fileList: Array<File>) => {
    let responseList;
    let urls = [];
    try {
      urls = await getPresignedS3Url(true, fileList.length);
    } catch (error) {
      return responseList;
    }

    return uploadFileToS3(fileList, urls);
  };

  const uploadFileToS3 = async (
    fileList: Array<File>,
    urls: Array<IS3SingedURL> = urlList
  ) => {
    if (!urls.length) {
      await getPresignedS3Url(true);
    }
    const promises: Array<Promise<any>> = fileList.map(
      (file: File, index: number): Promise<any> =>
        uploadImage(urls[index], file)
    );
    let responseList;
    try {
      responseList = await Promise.all(promises);
      return responseList as Array<IUploadResponse>;
    } catch (error) {
      return responseList;
    }
  };

  return {
    urlList,
    uploadFileToS3,
    fetchUrlAndUploadTheFileToS3,
    fetchLoading,
    fetchError,
  };
};

export default useS3Upload;
