import Img from '@guaranteed-rate/react-components/dist/Img';
import clsx from 'clsx';
import { IRateAppDownloadCtaProps } from './types';
import {
  DEFAULT_RATE_APP_DOWNLOAD_CTA_HEADER,
  DEFAULT_RATE_APP_DOWNLOAD_CTA_DESCRIPTION,
  DEFAULT_RATE_APP_SCREENSHOT_URL,
  DEFAULT_RATE_APP_QR_CODE_URL,
  DEFAULT_RATE_APP_APPLE_APP_STORE_URL,
  DEFAULT_RATE_APP_PLAY_STORE_URL,
} from './constants';

export const RateAppDownloadCtaDesktopLayout = ({
  className,
  header = DEFAULT_RATE_APP_DOWNLOAD_CTA_HEADER,
  description = DEFAULT_RATE_APP_DOWNLOAD_CTA_DESCRIPTION,
  screenshotUrl = DEFAULT_RATE_APP_SCREENSHOT_URL,
  qrCodeUrl = DEFAULT_RATE_APP_QR_CODE_URL,
  appleAppStoreUrl = DEFAULT_RATE_APP_APPLE_APP_STORE_URL,
  googlePlayStoreUrl = DEFAULT_RATE_APP_PLAY_STORE_URL,
}: IRateAppDownloadCtaProps) => (
  <div
    className={clsx(className, 'app-banner-gradient-background pl-6 pr-6 rounded-xl')}
  >
    <div className="flex">
      <div className="pb-4 pt-6">
        <h3>{header}</h3>
        <p>{description}</p>
        <div className="flex items-center justify-center">
          <Img
            className="mr-4"
            src={qrCodeUrl}
            alt="Rate App QR code"
            width={86}
            height={86}
          />
          <a
            className="mr-4"
            href={appleAppStoreUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Img
              src="/images/app-badge-apple-app-store-en.svg"
              alt="Rate App Apple App Store"
              width={123}
              height={41}
            />
          </a>
          <a
            className="mr-4"
            href={googlePlayStoreUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Img
              src="/images/app-badge-google-play-en.png"
              alt="Rate App Play Store"
              width={136}
              height={41}
            />
          </a>
        </div>
      </div>
      <div className="flex justify-center items-center flex-none basis-[168px] relative">
        <img
          src={screenshotUrl}
          alt="Rate App screenshot"
          className="absolute bottom-0 left-0"
        />
      </div>
    </div>
  </div>
);
