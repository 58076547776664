/* eslint-disable max-len */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Banner from '../../components/Banner/Banner';
import { fetchFspPageContent, formatAsGraphQLPaylod, getCode, getTimeDiff, isNullEmptyKey, redirectToMyALogin, setCustomErrorBasedOnResponse } from '../../config/util/common';
import { IDeclarationInfo } from '../../config/util/interfaces';
import { MASKS } from '../../config/util/masks';
import { PreviousButton } from '../../components/PreviousButton/PreviousButton';
import RateAlertLogo from '../../components/RateAlertLogo/RateAlertLogo';
import { RateAlertWizardProgress } from '../../components/WizardProgress/RateAlertWizardProgress';
import { generateLink, getNextPanelBasedOnState } from './prefiCommon';
import { log } from '../../config/util/logger';
import { REDIRECT_TO_LOGIN_ERROR_CODE } from '../../config/content/constants';

const getDeclarationInfoData = async (applicationId: string, isCoBorrower: boolean) => {
  const mutation = {
    query:
      `query {
        getDeclarationInfoData(applicationId: "${applicationId}", isCoBorrower: ${isCoBorrower}) {
            primaryResidence
            otherOwnership
            priorPropertyUsage
            priorPropertyTitle
            borrowingMoney
            undisclosedBorrowedAmount
            otherMortgage
            newCredit
            newLien
            otherDebt
            outstandingJudgment
            delinquentDefault
            lawsuit
            conveyedTitle
            shortSale
            foreclosure
            bankruptcy
            bankruptcyChapterSeven
            bankruptcyChapterEleven
            bankruptcyChapterTwelve
            bankruptcyChapterThirteen
            citizenshipStatus
            otherLiabilities {
              liabilityOrExpenseType
              monthlyPayment
           }
        }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "getDeclarationInfoData" ${JSON.stringify({ applicationId, isCoBorrower })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getDeclarationInfoData) {
      log({ message: `Query "getDeclarationInfoData" was successful ${JSON.stringify({ applicationId, duration, isCoBorrower })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.getDeclarationInfoData };
    }
    log({ message: `Query "getDeclarationInfoData" failed with errors ${JSON.stringify({ applicationId, duration, isCoBorrower, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "getDeclarationInfoData" failed due to exception ${JSON.stringify({ applicationId, isCoBorrower })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const updateDeclarationInfoData = async (applicationId: string, isCoBorrower: boolean, declarationInfoRequest: any) => {
  const mutation = {
    query:
      `mutation {
        updateDeclarationInfoData(applicationId: "${applicationId}", isCoBorrower: ${isCoBorrower}, declarationInfoRequest: ${declarationInfoRequest}) {
          applicationType
            application {
                id
                tenant
                applicationType
                createdAt
                updatedAt
                state
                currentState
                currentDynamicState
            }
    }
  }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Mutation "updateDeclarationInfoData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.updateDeclarationInfoData) {
      log({ message: `Mutation "updateDeclarationInfoData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.updateDeclarationInfoData };
    }
    log({ message: `Mutation "updateDeclarationInfoData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Mutation "updateDeclarationInfoData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

interface IPrefiDeclarationForm extends Omit<IDeclarationInfo | any, 'childSupport' | 'alimony' | 'undisclosedBorrowedAmount'> {
  childSupport?: string;
  alimony?: string;
  undisclosedBorrowedAmount?: string;
}

const newForm = (): IPrefiDeclarationForm => ({
  primaryResidence: false,
  otherOwnership: false,
  priorPropertyUsage: '',
  priorPropertyTitle: '',
  borrowingMoney: false,
  otherMortgage: false,
  newCredit: false,
  newLien: false,
  otherDebt: false,
  outstandingJudgment: false,
  delinquentDefault: false,
  lawsuit: false,
  conveyedTitle: false,
  shortSale: false,
  foreclosure: false,
  bankruptcy: false,
  citizenshipStatus: '',
  bankruptcyType: '',
  undisclosedBorrowedAmount: '',
  payAlimony: false,
  alimony: '',
  payChildSupport: false,
  childSupport: '',
});

function PrefiDeclarations(props: any) {
  const navigate = useNavigate();
  const [declarationInfo, setDeclarationInfo] = useState<IDeclarationInfo | any>();
  const [declarationInfoFetched, setDeclarationInfoFetched] = useState(false);
  const [content, setContent] = useState(null as any);
  const [contentFetched, setContentFetched] = useState(false);
  const [declarationForm, setDeclarationForm] = useState<IPrefiDeclarationForm>(newForm());
  const [declarationQuest, updateDeclarationQuest] = useState(null as any);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [trySubmit, setTrySubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  if (props.applicationId && !declarationInfo && !declarationInfoFetched && !error) {
    getDeclarationInfoData(props.applicationId, props.isCoborrowerPage).then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiDeclarations: getDeclarationInfoData(): isCoborrowerPage ${props.isCoborrowerPage}: Setting custom error based on response ${JSON.stringify({ data })}`, context: { applicationId: props.applicationId }, level: 'error' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          setDeclarationInfoFetched(false);
          if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
            redirectToMyALogin();
          }
        } else {
          setError(false);
          setDeclarationInfoFetched(true);
          setDeclarationInfo(data.content);
        }
      }
    );
  }

  const keyValue = (updatedDeclarationInfo: any, key: string) => updatedDeclarationInfo?.otherLiabilities.indexOf(updatedDeclarationInfo.otherLiabilities.find((o: any) => o.liabilityOrExpenseType === key));

  const setLiabilities = (declarationInfo: any, updatedDeclarationInfo: any, textKey: string, liabilityType: string) => {
    declarationInfo[textKey] = updatedDeclarationInfo.otherLiabilities[keyValue(updatedDeclarationInfo, liabilityType)].monthlyPayment;
    declarationForm[textKey] = updatedDeclarationInfo.otherLiabilities[keyValue(updatedDeclarationInfo, liabilityType)].monthlyPayment;
  };

  const updateFormValue = (declarationInfo: any, questionKey: any, value: boolean) => {
    declarationInfo[questionKey] = value;
    declarationForm[questionKey] = value;
  };

  if (declarationInfo && !content && !contentFetched && !error) {
    fetchFspPageContent(props.isCoborrowerPage ? '"prefi_coborrower_declarations"' : '"prefi_declarations"').then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiDeclarations: fetchFspPageContent(): isCoborrowerPage ${props.isCoborrowerPage}: Error fetching page content. ${JSON.stringify({ page: 'prefi_coborrower_declarations' })}`, level: 'error' });
          setError(true);
          setErrorMessage('Error fetching page content. Please Try again...');
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
          let declarationsQuestions = data?.content?.questions ? data?.content?.questions?.map((item: any) => ({
            name: 'declaration',
            label: item.option.label,
            value: item.option.value,
            relatedQuest: item.option.related_question,
            isToggle: item.option.is_toggle,
            isTextBox: item.option.is_textbox,
            isDropDown: item.option.is_dropdown,
            isCheckBox: item.option.is_checkbox,
            active: item.option.value === 'payAlimony'
              ? (!isNullEmptyKey(declarationInfo.otherLiabilities, keyValue(declarationInfo, 'Alimony'), 'monthlyPayment'))
              : (
                item.option.value === 'payChildSupport'
                  ? (!isNullEmptyKey(declarationInfo.otherLiabilities, keyValue(declarationInfo, 'ChildSupport'), 'monthlyPayment'))
                  : (declarationInfo[item.option.value] === null ? false : declarationInfo[item.option.value])
              ),
            radios: data?.content?.declaration ? data?.content?.declaration?.map((radio: any) => ({
              name: item.option.value,
              label: radio.option.label,
              value: radio.option.label === null ? false : (radio.option.label === 'Yes'),
              active: false
            })) : []
          })) : [];

          if (!isNullEmptyKey(declarationInfo.otherLiabilities, keyValue(declarationInfo, 'Alimony'), 'monthlyPayment')) {
            setLiabilities(declarationInfo, declarationInfo, 'alimony', 'Alimony');
            updateFormValue(declarationInfo, 'payAlimony', true);
          } else {
            updateFormValue(declarationInfo, 'payAlimony', false);
          }
          if (!isNullEmptyKey(declarationInfo.otherLiabilities, keyValue(declarationInfo, 'ChildSupport'), 'monthlyPayment')) {
            setLiabilities(declarationInfo, declarationInfo, 'childSupport', 'ChildSupport');
            updateFormValue(declarationInfo, 'payChildSupport', true);
          } else {
            updateFormValue(declarationInfo, 'payChildSupport', false);
          }

          updateDeclarationQuest(declarationsQuestions);

          // Change null values to empty string for below 4 string attributes alone befor updating the form
          if (declarationInfo?.undisclosedBorrowedAmount === null) {
            declarationInfo.undisclosedBorrowedAmount = '';
          }
          if (declarationInfo?.priorPropertyUsage === null) {
            declarationInfo.priorPropertyUsage = '';
          }
          if (declarationInfo?.priorPropertyTitle === null) {
            declarationInfo.priorPropertyTitle = '';
          }
          if (declarationInfo?.citizenshipStatus === null) {
            declarationInfo.citizenshipStatus = '';
          }

          // Update all null values to false for only the remaining boolean attributes and then set the form
          setDeclarationForm(() => {
            let newObj = { ...declarationInfo };
            Object.keys(newObj).forEach((key) => {
              if (newObj[key] === null) newObj[key] = false;
            });
            return newObj;
          });
        }
      }
    );
  }

  const citizenshipsData = content?.citizenships ? content?.citizenships?.map((item: any) => ({
    displayName: item.option.label,
    value: item.option.value,
  })) : [];

  const bankruptcyType = content?.bankruptcy_type ? content?.bankruptcy_type?.map((item: any) => ({
    displayName: item.option.label,
    value: item.option.value,
    key: item.option.key,
  })) : [];

  const propertyType = content?.property_type ? content?.property_type?.map((item: any) => ({
    displayName: item.option.label,
    value: item.option.value,
  })) : [];

  const propertyTitle = content?.property_title ? content?.property_title?.map((item: any) => ({
    displayName: item.option.label,
    value: item.option.value,
  })) : [];

  const handleDeclarationQuestions = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    if (name === 'primaryResidence' && event.target.ariaLabel === 'No') {
      setDeclarationForm((currentDeclarationForm: any) => {
        let newObj = { ...currentDeclarationForm };
        newObj.otherOwnership = false;
        newObj.primaryResidence = false;
        return newObj;
      });
    } else {
      setDeclarationForm({ ...declarationForm, [name !== '' ? name : event.target.name]: (event.target.ariaLabel === 'Yes') });
    }
  };

  const handleCitizenshipStatus = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setDeclarationForm({ ...declarationForm, [name]: (event.target.value) });
  };

  const handleDeclarationTextbox = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setDeclarationForm({ ...declarationForm, [name !== '' ? name : event.target.name]: (event.target.value) });
  };

  const handleDropdown = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setDeclarationForm({ ...declarationForm, [name]: (event.target.value) });
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setDeclarationForm({ ...declarationForm, [name]: (event.target.checked) });
  };

  const updateDeclaration = (selectedQuest: any, isActive: any) => {
    let boolIsActive = (isActive === 'true');
    if (selectedQuest === 'primaryResidence' && isActive === 'false') {
      updateDeclarationQuest((currentQuest: any) => {
        let newObj = [...currentQuest];
        newObj.forEach((quest: any) => {
          if (quest.value === selectedQuest) {
            quest.active = boolIsActive;
            quest.radios[0].active = boolIsActive;
            quest.radios[1].active = !boolIsActive;
          }
          if (quest.value === 'otherOwnership') {
            quest.active = boolIsActive;
            quest.radios[0].active = boolIsActive;
            quest.radios[1].active = !boolIsActive;
          }
        });
        return newObj;
      });
    } else {
      updateDeclarationQuest((currentQuest: any) => {
        let newObj = [...currentQuest];
        newObj.forEach((quest: any) => {
          if (quest.value === selectedQuest) {
            quest.active = boolIsActive;
            quest.radios[0].active = boolIsActive;
            quest.radios[1].active = !boolIsActive;
          }
        });
        return newObj;
      });
    }
  };

  const validateKey = (key: string) => (declarationForm as any)[key as keyof IPrefiDeclarationForm]?.toString().length > 0;

  const hasError = (name: string) => {
    const isError: any = trySubmit && !validateKey(name);
    return isError;
  };

  const liabilityObject = (liabilityOrExpenseType: string, monthlyPayment: null | number | string | undefined): any => ({ liabilityOrExpenseType, monthlyPayment });

  const updateLiabilityValue = (updatedDeclarationForm: IPrefiDeclarationForm, liabilityType: string, liabilityValue: null | number | string | undefined) => {
    if (updatedDeclarationForm.otherLiabilities && updatedDeclarationForm.otherLiabilities.length > 0 && updatedDeclarationForm.otherLiabilities?.find((o: any) => (o.liabilityOrExpenseType === liabilityType))) {
      updatedDeclarationForm.otherLiabilities.find((o: any, i: number) => {
        if (updatedDeclarationForm.otherLiabilities && o.liabilityOrExpenseType === liabilityType) {
          updatedDeclarationForm.otherLiabilities[i] = liabilityObject(liabilityType, liabilityValue);
        }
      });
    } else if (liabilityValue) {
      const liabilityObj = liabilityObject(liabilityType, liabilityValue);

      if (!updatedDeclarationForm.otherLiabilities) {
        updatedDeclarationForm.otherLiabilities = [];
      }

      updatedDeclarationForm.otherLiabilities.push(liabilityObj);
    }
  };

  const handleContinue = async () => {
    log({ message: `PrefiDeclarations: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: Handling continue`, level: 'info' });
    setError(false);
    const updatedDeclarationForm = { ...declarationForm };

    // delete fields from form so they do not get validated
    if (!updatedDeclarationForm.borrowingMoney) {
      delete updatedDeclarationForm.undisclosedBorrowedAmount;
    }
    if (!updatedDeclarationForm.otherOwnership) {
      updatedDeclarationForm.otherOwnership = false;
      delete updatedDeclarationForm.priorPropertyUsage;
      delete updatedDeclarationForm.priorPropertyTitle;
    }
    if (!updatedDeclarationForm.payAlimony) {
      delete updatedDeclarationForm.alimony;
    }
    if (!updatedDeclarationForm.payChildSupport) {
      delete updatedDeclarationForm.childSupport;
    }

    updateLiabilityValue(updatedDeclarationForm, 'Alimony', updatedDeclarationForm.payAlimony ? updatedDeclarationForm.alimony : null);
    updateLiabilityValue(updatedDeclarationForm, 'ChildSupport', updatedDeclarationForm.payChildSupport ? updatedDeclarationForm.childSupport : null);

    // remove $0 liabilities
    if (updatedDeclarationForm.otherLiabilities) {
      updatedDeclarationForm.otherLiabilities = updatedDeclarationForm.otherLiabilities?.filter((it: any) => !!it.monthlyPayment);
    }
    if (updatedDeclarationForm.otherLiabilities?.length === 0) {
      delete updatedDeclarationForm.otherLiabilities;
    }

    setTrySubmit(true);
    const keys = Object.keys(updatedDeclarationForm);
    const isFormValid = keys.every((key) => {
      const valid = validateKey(key);
      return valid;
    });
    if (isFormValid) {
      // these are for frontend state only to generate the OtherLiabilities, and should not be sent with the declarations info
      delete updatedDeclarationForm.alimony;
      delete updatedDeclarationForm.childSupport;
      delete updatedDeclarationForm.payAlimony;
      delete updatedDeclarationForm.payChildSupport;

      log({ message: `PrefiDeclarations: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: Client validation passed`, level: 'info' });

      setLoading(true);
      const declarationInfoRequest = formatAsGraphQLPaylod(updatedDeclarationForm);
      updateDeclarationInfoData(props.applicationId, props.isCoborrowerPage, declarationInfoRequest).then(
        (data: any) => {
          setLoading(false);
          if (data.content === 'error') {
            log({ message: `PrefiDeclarations: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: updateDeclarationInfoData(): Setting custom error based on response ${JSON.stringify({ data })}`, level: 'info' });
            setError(true);
            setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
            window.scrollTo(0, 0);
            if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
              redirectToMyALogin();
            }
          } else {
            setError(false);
            if (data.content?.application) {
              const nextPanel = getNextPanelBasedOnState(data);
              log({ message: `PrefiDeclarations: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: updateDeclarationInfoData(): Success ${JSON.stringify({ nextPanel })}`, level: 'info' });
              navigate(generateLink(`/${nextPanel}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
              window.scrollTo(0, 0);
            }
          }
        }
      );
    } else {
      log({ message: `PrefiDeclarations: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: Invalid fields`, level: 'info' });
      setError(true);
      setErrorMessage('Fill all required fields');
      window.scrollTo(0, 0);
    }
  };

  return (
    <div>
      {(content && declarationForm)
        ? (
          <div className="flex items-center justify-center">
            <div className="items-center justify-center w-full max-w-3xl px-1">
              <RateAlertWizardProgress className="lg:hidden px-4 mb-4" />
              <div className="items-center justify-center lg:mb-8">
                <div className="px-4 w-full">
                  {
                    error
                      ? (
                        <Banner className="border-2 mt-4 mb-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
                      )
                      : ''
                  }
                  <RateAlertLogo alt={content?.header} />
                </div>
              </div>
              <div className="w-full px-1 lg:border border-solid border-slate-300 rounded-2xl bg-white">
                <RateAlertWizardProgress className="hidden lg:block mt-6 px-4" />
                <div className="flex items-center justify-center mb-4">
                  <div className="px-4 w-full">
                    <div className="mt-4">{content?.description}</div>
                  </div>
                </div>
                <div className="items-center justify-center">
                  <div className="px-4 w-full">
                    <div className="text-xl font-black mt-6">{content?.header}</div>
                    <div className="w-full mt-4">
                      {
                        declarationQuest?.map((declaration: any, index: number) => (
                          <div key={`declarationWrapper${index}`}>
                            {(declaration.isToggle === 'no' || declaration.isToggle === '')
                              ? (
                                <div key={`declaration${index}`} className="grid grid-flow-row-dense grid-cols-3 mb-2">
                                  <div className="col-span-2">
                                    {declaration?.label}
                                  </div>
                                  <div className="flex justify-end">
                                    <RadioButtons
                                      className="radioButton inline-block place-self-auto w-1/2 p-2 font-bold"
                                      isChecked={declaration.active}
                                      radios={declaration.radios}
                                      updateCheckedValue={(event) => { updateDeclaration(event.target.name, event.target.value); }}
                                      onChange={(event) => handleDeclarationQuestions(event, event.target.name)}
                                    />
                                  </div>
                                </div>
                              ) : ((declaration.isToggle === 'yes' && (declarationQuest[declarationQuest.map((temp: any) => temp.value).indexOf(declaration.relatedQuest)].active))
                              && (
                                (declaration.isTextBox === 'yes')
                                  ? (
                                    <div key={`declarationText${index}`} className="grid grid-flow-row-dense grid-cols-3 mb-2">
                                      <div className="col-span-3">
                                        <TextInput
                                          name="declarationText"
                                          label={declaration?.label}
                                          className="w-auto lg:mr-4"
                                          value={(declarationForm as any)[declaration.value]?.toString()}
                                          onChange={(event) => handleDeclarationTextbox(event, declaration.value)}
                                          mask={MASKS.CURRENCY_WITHOUT_DECIMAL}
                                          hasError={hasError(declaration.value)}
                                          helperText={hasError(declaration.value) ? 'required' : undefined}
                                        />
                                      </div>
                                    </div>
                                  )
                                  : (
                                    (declaration.isDropDown === 'yes')
                                      ? (
                                        <div key={`declarationDropdown${index}`} className="grid grid-flow-row-dense grid-cols-3 mb-2">
                                          <div className="col-span-3">
                                            <Dropdown
                                              name={declaration.value}
                                              value={(declarationForm as any)[declaration.value]}
                                              label={declaration?.label}
                                              onChange={(event) => handleDropdown(event, declaration.value)}
                                              className="w-auto dropdown-text lg:mr-4"
                                              options={(declaration.value === 'priorPropertyUsage') ? propertyType : propertyTitle}
                                              hasError={hasError(declaration.value)}
                                              helperText={hasError(declaration.value) ? 'required' : undefined}
                                            />
                                          </div>
                                        </div>
                                      )
                                      : (
                                        (declaration.isCheckBox === 'yes')
                                          ? (
                                            <div className="mb-12 ml-12">
                                              {bankruptcyType.map(({ checkbox }: any, index: number) => (
                                                <div key={`declarationCheckbox${index}`} className="w-full mb-4">
                                                  <input
                                                    type="checkbox"
                                                    className="checkbox"
                                                    checked={(declarationForm as any)[bankruptcyType[index].key]}
                                                    onChange={(event) => handleCheckbox(event, bankruptcyType[index].key)}
                                                  /><span className="font-bold inline-block absolute">{bankruptcyType[index].displayName}</span>
                                                </div>
                                              ))}
                                            </div>
                                          )
                                          : (
                                            <div key={`declarationRadio${index}`} className="grid grid-flow-row-dense grid-cols-3 mb-2">
                                              <div className="col-span-2">
                                                {declaration?.label}
                                              </div>
                                              <div className="flex justify-end">
                                                <RadioButtons
                                                  className="radioButton inline-block place-self-auto w-1/2 p-2 font-bold"
                                                  isChecked={declaration.active}
                                                  radios={declaration.radios}
                                                  updateCheckedValue={(event) => { updateDeclaration(event.target.name, event.target.value); }}
                                                  onChange={(event) => handleDeclarationQuestions(event, event.target.name)}
                                                />
                                              </div>
                                            </div>
                                          )
                                      )
                                  )
                              ))}
                          </div>
                        ))
                      }
                      <Dropdown
                        name="citizenshipStatus"
                        value={declarationForm.citizenshipStatus}
                        label={content?.form?.citizenship_label}
                        onChange={(event) => handleCitizenshipStatus(event, 'citizenshipStatus')}
                        className="w-full"
                        options={citizenshipsData}
                        hasError={hasError('citizenshipStatus')}
                        helperText={hasError('citizenshipStatus') ? 'required' : undefined}
                      />
                    </div>
                    <div className="px-4 mt-2 mb-8 w-full flex justify-between">
                      <div className="flex justify-center mt-12">
                        <PreviousButton isDynamic={false} applicationId={props.applicationId} />
                      </div>
                      <div className="items-center justify-center">
                        <div className="px-4 mt-2 mb-8 w-full">
                          <div className="flex justify-center mt-12">
                            <Button
                              buttonStyle="primary"
                              className="!w-full md:!w-48 confirmButton"
                              onClick={handleContinue}
                              buttonAttrs={{ disabled: loading }}
                              loading={loading}
                            >{content?.form?.button_label}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : (
          <div className="px-4 w-full">
            {
              error
                ? (
                  <Banner className="border-2 mt-4 mb-4" text={errorMessage} title="Error" type="error" icon="warning-triangle" isMultiline={true} errorContent={props.errors.errorContent} />
                )
                : ''
            }
          </div>
        )}
    </div>
  );
}
export default PrefiDeclarations;
