import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import { formatPhoneNumber, getTimeDiff } from '../../config/util/common';
import Avatar from '../ui/Avatar/Avatar';
import CompanyAvatar from '../ui/Avatar/CompanyAvatar';
import { log } from '../../config/util/logger';

const fallbackImage = '/images/mobile-logo.png';

interface PrefiLoanOfficer {
  employeeId: number;
  status: string;
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  nmlsId: string;
  title: string;
  officePhone?: string;
  cellPhone?: string;
  photoUrl?: string;
  url?: string;
}

const preloadImage = (src: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = function () {
      reject(src);
    };
    img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });

const getLoanOfficer = async (applicationId: string): Promise<PrefiLoanOfficer | null> => {
  const query = {
    query:
      `query {
        applicationLoanOfficer(applicationId: "${applicationId}") {
          employeeId
          status
          email
          firstName
          lastName
          displayName
          nmlsId
          title
          officePhone
          cellPhone
          photoUrl
          url
      }
    }`
  };
  const uuid = uuidv4();
  log({
    message: `Query "applicationLoanOfficer" ${JSON.stringify({ applicationId })}`,
    context: { applicationId },
    level: 'info',
    requestId: uuid
  });
  const startTime = performance.now();
  const resp = await fetch('/gateway/graphql', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(query)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.applicationLoanOfficer) {
      log({
        message: `Query "applicationLoanOfficer" was successful ${JSON.stringify({ applicationId, duration })}`,
        context: { applicationId },
        level: 'info',
        requestId: uuid
      });
      return data?.data?.applicationLoanOfficer;
    }
    log({ message: `Query "applicationLoanOfficer" failed ${JSON.stringify({ applicationId, duration, errors: data?.errors })}`,
      context: { applicationId },
      level: 'error',
      requestId: uuid
    });
    return null;
  } catch (e) {
    log({ message: `Query "applicationLoanOfficer" failed due to exception ${JSON.stringify({ applicationId })}`,
      context: { applicationId },
      level: 'error',
      requestId: uuid
    });
    console.error(e);
    return null;
  }
};

interface LoanOfficerData {
  photoUrl?: string;
  email: string;
  phone?: string;
  phone2?: string;
  name?: string;
  paragraph: string;
  text1: string;
  text2?: string;
  text3?: string;
  url?: string;
}

const fallbackLoanOfficerData = {
  email: 'direct.quote@rate.com',
  phone: '833-539-1346',
  text1: 'Need to connect?',
  text2: 'Contact a mortgage advisor',
  paragraph: "Let's find the perfect loan for your home purchase or refinance.",
} satisfies LoanOfficerData;

const useLoanOfficerData = (applicationId: string): LoanOfficerData | undefined => {
  const [data, setData] = useState<LoanOfficerData>();

  useEffect(() => {
    const fetchData = async () => {
      const loanOfficer = await getLoanOfficer(applicationId);
      if (loanOfficer?.status === 'active') {
        setData({
          photoUrl: loanOfficer.photoUrl,
          email: loanOfficer.email,
          phone: loanOfficer.officePhone,
          phone2: loanOfficer.cellPhone,
          name: loanOfficer.displayName,
          text1: loanOfficer.displayName,
          text2: loanOfficer.title,
          text3: `NMLS ${loanOfficer.nmlsId}`,
          paragraph: fallbackLoanOfficerData.paragraph,
        });
      } else {
        setData(fallbackLoanOfficerData);
      }
    };

    fetchData();
  }, []);

  return data;
};

const Loading = () => (
  <div className="rounded-xl border bg-white p-6">
    Loading...
  </div>
);

const PrefiLoanOfficer = ({ applicationId }: { applicationId: string }) => {
  const [photoUrl, setPhotoUrl] = useState<string>();
  const data = useLoanOfficerData(applicationId);

  useEffect(() => {
    if (!data) return;

    if (data.photoUrl) {
      preloadImage(data.photoUrl)
        .then(() => {
          setPhotoUrl(data.photoUrl);
        })
        .catch(() => {
          setPhotoUrl(fallbackImage);
        });
    } else {
      setPhotoUrl(fallbackImage);
    }
  }, [data]);

  if (!data || !photoUrl) {
    return <Loading />;
  }

  const { email, phone, phone2, name, url, paragraph, text1, text2, text3 } = data;
  const photo = photoUrl.startsWith('https://') ? <Avatar src={photoUrl} alt={name} /> : <CompanyAvatar src={photoUrl} />;
  const showApply = false;
  return (
    <div className="rounded-xl border bg-white p-6">
      {paragraph && (
        <p className="mb-4">
          <span>{paragraph}</span>
          {showApply && url && (
            <>
              <span className="mx-0.5"> </span>
              <a
                href={url}
                className="whitespace-nowrap font-bold text-action hover:underline"
              >
                Apply now
              </a>
            </>
          )}
        </p>
      )}
      <div className="flex flex-col leading-xs md:flex-row">
        <div className="flex mb-4 md:w-[50%] md:mb-0">
          <div className="mr-5 flex flex-col justify-center">{photo}</div>
          <div className="flex flex-grow flex-col justify-center md:mr-6">
            <h3 className="text-base leading-8 h-7 m-0">{text1}</h3>
            {text2 && <p className="text-base leading-8 h-7 m-0">{text2}</p>}
            {text3 && <p className="text-base leading-8 h-7 m-0">{text3}</p>}
          </div>
        </div>
        {(email || phone || phone2) && (
          <div className="leading-none md:flex md:flex-grow md:flex-col md:justify-center">
            {text2 && text3 && !phone2 && <div className="hidden h-7 md:block" />}
            {email && (
              <div className="mb-2 md:mb-0">
                <a href={`mailto:${email}`} className="inline-flex items-center h-7">
                  <FontIcon iconName="mail" className="mr-2 leading-6 text-[1.3rem] w-[20px]" />
                  <h4 className="text-base leading-7 m-0 font-bold">{email}</h4>
                </a>
              </div>
            )}
            {phone && (
              <div className={clsx({ 'mb-2 md:mb-0': !!phone2, 'm-0': !phone2 })}>
                <a href={`tel:${phone}`} className="inline-flex items-center h-7">
                  <FontIcon iconName="phone" className="mr-2 leading-6 text-[1.3rem] w-[20px]" />
                  <h4 className="text-base leading-7 m-0 font-bold">{formatPhoneNumber(phone)}</h4>
                </a>
              </div>
            )}
            {phone2 && (
              <div className="m-0">
                <a href={`tel:${phone2}`} className="inline-flex items-center h-7">
                  <FontIcon iconName="phone-mobile" className="mr-2 leading-6 text-[1.5rem] w-[20px]" />
                  <h4 className="text-base leading-7 m-0 font-bold">{formatPhoneNumber(phone2)}</h4>
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PrefiLoanOfficer;
