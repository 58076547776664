/* eslint-disable max-len */

import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { footerContent } from '../../config/content/footer';
import { getSupportNavigationContent } from '../../config/content/supportNavigation';
import { affordabilityProgressList } from '../../config/content/progressList';
import { ProgressProps } from '../../components/ProgressList/IProgressList';
import { AffordabilityErrorPage } from './AffordabilityErrorPage/AffordabilityErrorPage';
import { AffordabilityIncomePage } from './AffordabilityIncomePage/AffordabilityIncomePage';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { AffordabilityDebtPage } from './AffordabilityDebtPage/AffordabilityDebtPage';
import { AffordabilityDownPaymentPage } from './AffordabilityDownPaymentPage/AffordabilityDownPaymentPage';
import { AffordabilityCreditScorePage } from './AffordabilityCreditScorePage/AffordabilityCreditScorePage';
import { AffordabilityMilitaryPage } from './AffordabilityMilitaryPage/AffordabilityMilitaryPage';
import { AffordabilityOfficerPage } from './AffordabilityOfficerPage/AffordabilityOfficerPage';
import { AffordabilityLocationPage } from './AffordabilityLocationPage/AffordabilityLocationPage';
import { AffordabilityReviewPage, IReviewData } from './AffordabilityReviewPage/AffordabilityReviewPage';
import { IAffordabilityData, ILeadInput, ILoData, ILoanOfficer, ISupportNavLoData } from '../../config/util/interfaces';
import { fetchContent, formatLoanOfficers, getLoCookie, setLoCookie } from '../../config/util/common';
import { AffordabilitySaveProgressPage } from './AffordabilitySaveProgressPage/AffordabilitySaveProgressPage';
import PageLoader from '../../components/PageLoader/PageLoader';
import LoanOfficerBanner from '../../components/SupportNavigation/LoanOfficerBanner';

const pages = ['income', 'debt', 'down_payment', 'credit_score', 'military', 'officer', 'location', 'review', 'save_progress'];

export const affordabilityPathMap = {
  dev: '/home-affordability/',
  qa: '/home-affordability/',
  stage: '/',
  prod: '/',
};
interface IAffordabilityPages {
  income: any;
  debt: any;
  downPayment: any;
  creditScore: any;
  military: any;
  officer: any;
  location: any;
  review: any;
  saveProgress: any;
}

function getPageData(data?: IAffordabilityData) {
  return `{
    creditScore: "${data?.creditScore || 0}"
    isMilitary: ${data?.isMilitary || false}
    cash: "${data?.cash || 0}"
    equity: "${data?.equity || 0}"
    city: "${data?.city || 0}"
    zip: "${data?.zip || 0}"
    isHomeOwner: ${data?.isHomeOwner || false}
    occupancyType: "${data?.occupancyType || 'PRIMARY'}"
    propertyType: "${data?.propertyType || 'Attached'}"
    affordableDebtData: {
      autoLoan: "${data?.affordableDebtData?.autoLoan || 0}"
      personalLoan: "${data?.affordableDebtData?.personalLoan || 0}"
      creditCardLoan: "${data?.affordableDebtData?.creditCardLoan || 0}"
      studentLoan: "${data?.affordableDebtData?.studentLoan || 0}"
      otherLoan: "${data?.affordableDebtData?.otherLoan || 0}"
      isMonthly: ${data?.affordableDebtData?.isMonthly || false}
    }
    affordableIncomeData: {
      base: "${data?.affordableIncomeData?.base || 0}"
      bonus: "${data?.affordableIncomeData?.bonus || 0}"
      overTime: "${data?.affordableIncomeData?.overTime || 0}"
      otherIncome: "${data?.affordableIncomeData?.otherIncome || 0}"
      isMonthly: ${data?.affordableIncomeData?.isMonthly || false}
    }
  }`;
}

const getProgressContent = (isLoPresent: boolean, progressContent: any) => (
  isLoPresent ? { ...progressContent, steps: progressContent.steps.filter((item: any) => item.title !== 'Officer') }
    : progressContent
);

export const AffordabilityPage = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState('');
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [applicationId, setApplicationId] = useState(urlParams.get('id') || '');
  const [affordabilityData, setAffordabilityData] = useState({} as IAffordabilityData);
  const [supportNavContent, setSupportNavContent] = useState<ISupportNavLoData>();
  const [loData, setLoData] = useState(null as unknown as ILoData);
  const [fetchLoData, setFetchLoData] = useState(false);
  const [progressContent, setProgressContent] = useState(affordabilityProgressList);
  const [resultData, setResultData] = useState({} as IReviewData);
  const [contents, setContents] = useState({} as IAffordabilityPages);
  const [errorMessage, setErrorMessage] = useState('');
  const [isRecalculate, setIsRecalculate] = useState(false);
  const [isPreApproval, setIsPreApproval] = useState(false);
  const [leadInput, setLeadInput] = useState({} as ILeadInput);
  const [isApplyLoading, setApplyLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  const [loanOfficers, setLoanOfficers] = useState([] as Array<ILoanOfficer>);
  const [affordabilityPath] = useState(affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap] ?? '/home-affordability/');

  const updateProgress = (step: number, progress: ProgressProps) => {
    progress.currentStep = step;
    progress.steps.map((value, index) => {
      if (index < step) {
        value.active = false;
        value.complete = true;
      } else if (index === step) {
        value.active = true;
        value.complete = false;
      } else {
        value.active = false;
        value.complete = false;
      }
    });
    setProgressContent(progress);
  };

  const toggleMenu = (menuItem: string) => (showMenu === '' || showMenu !== menuItem ? setShowMenu(menuItem) : setShowMenu(''));

  const handlePageSubmit = async (isReload = false, page: string, data?: IAffordabilityData) => {
    if (isPreApproval) {
      setIsPreApproval(false);
    }
    const mutation = {
      query: `query {
        ${isReload ? `getUserAffordabilityDataById(id: "${applicationId}")` : `getUserAffordabilityData(data: ${getPageData(data)})`} {
          id
          applicationType
          loanAmount
          totalAmount
          downPayment
          downPaymentPercent
          estimatedMonthlyPayment
          principalAndInterest
          rate
          apr
          loanTermsInMonths
          totalClosingCosts
          discountPoints {
            closest
          }
          pmi
          taxes
          insurance
          createdAt
          updatedAt
          isScoreExpired
          affordabilityInputResponse {
            creditScore
            isMilitary
            cash
            equity
            city
            zip
            isHomeOwner
            propertyType
            occupancyType
            affordableDebtInfo {
              autoLoan
              personalLoan
              creditCardLoan
              studentLoan
              otherLoan
              isMonthly
            }
            affordableIncomeInfo {
              base
              bonus
              overTime
              otherIncome
              isMonthly
            }
          }
        }
      }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      const responseData = isReload ? data?.data?.getUserAffordabilityDataById : data?.data?.getUserAffordabilityData;
      if (responseData) {
        if (applicationId !== responseData?.id) {
          urlParams.set('id', responseData?.id);
          setApplicationId(responseData?.id);
        }
        if (errorMessage) {
          setErrorMessage('');
        }
        setResultData(responseData);
        updateProgress(7, progressContent);
        navigate(`${affordabilityPath}review/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
      } else {
        console.error(data?.errors[0]?.message);
        if (isReload) {
          navigate(`${affordabilityPath}income/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
        } else {
          setErrorMessage(data?.errors[0]?.message);
          if (page === 'review') {
            setIsRecalculate(true);
          }
          navigate(`${affordabilityPath}error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
        }
      }
    } catch (err) {
      navigate(`${affordabilityPath}error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    }
  };

  const fetchLoanOfficers = async () => {
    const mutation = {
      query:
        `{
            fetchYextLOs(tenant: "${(window as any).tenantId}") {
              name
              id
            }
          }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data) {
        setLoanOfficers(formatLoanOfficers(data?.data?.fetchYextLOs));
      } else {
        console.error('Error retrieving data. No loan officers found.');
      }
    } catch (e) {
      console.error('Error retrieving loan officers');
    }
  };

  const setLo = async (loId: string) => {
    setFetchLoData(true);
    const mutation = {
      query:
        `{
          fetchLoanOfficer(employeeId: "${loId}") {
            name
            id
            title
            subTitle
            email
            phone
            avatarUrl
          }
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.fetchLoanOfficer) {
        setLoData(data.data.fetchLoanOfficer);
        if (getLoCookie() !== loId) {
          setLoCookie(loId);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFetchLoData(false);
    }
  };

  const handleDMXSubmit = async (leadInput: ILeadInput) => {
    setApplyLoading(true);
    setIsPreApproval(true);
    if (isRecalculate) {
      setIsRecalculate(false);
    }
    const uuid = uuidv4();
    const adTrk = Cookies.get('AdTrk');
    const loId = getLoCookie();
    const query = {
      query: `{
        getAffordabilityPreApprovalData(leadInput: {
          submittedBy: "ALP"
          loanPurpose: "Purchase"
          estimatedHousePrice: ${leadInput?.estimatedHousePrice}
          downpayment: ${leadInput?.downPayment}
          loanAmount: ${leadInput?.loanAmount}
          cashOutAmount: 0
          creditProfile: "${leadInput.creditProfile}"
          adTrack: "${adTrk}"
          tcpaAuditId: "tcpaAuditId"
          firstName: "${leadInput.firstName || ''}"
          lastName: "${leadInput.lastName || ''}"
          phone: "${leadInput.phone}"
          email: "${leadInput.email || ''}"
          propertyType: "${leadInput.propertyType || 'single-family'}"
          isMilitary: ${leadInput.isMilitary}
          postalCode: "${leadInput.postalCode}"
          city: "${leadInput.city}"
          state: "${leadInput.state}"
          county: "${leadInput.county}"
          ${loData ? `loanOfficerId: "${loId}"` : ''}
        }, isLoPresent: ${!!loData}) {
          dmxUrl
          dmxGuid
        }
      }`
    };
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(query)
    });
    try {
      const data = await resp.json();
      window.scrollTo(0, window.scrollY);
      if (data?.data?.getAffordabilityPreApprovalData) {
        window.open(data?.data?.getAffordabilityPreApprovalData.dmxUrl, '_blank', 'noopener,noreferrer');
      } else {
        console.error(data?.errors[0]?.message);
        if (data?.errors[0]?.message) {
          setErrorMessage("I'm sorry, we had some trouble submitting your information. Please try again");
        }
        const pageName = location.pathname.includes('saveProgress') ? 'saveProgress' : 'review';
        setCurrentPage(pageName);
        navigate(`${affordabilityPath}error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
        console.log('error retrieving data');
      }
      setApplyLoading(false);
    } catch (err) {
      setApplyLoading(false);
      navigate(`${affordabilityPath}error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
      console.error(err);
      console.log('error retrieving data');
    }
  };

  useEffect(() => {
    async function getContents() {
      try {
        setLoading(true);
        const [income, debt, downPayment, creditScore, military, officer, location, review, saveProgress] = await Promise.all(pages.map((page) => fetchContent(`"graffordable_${page}_page"`)));
        setLoading(false);
        if (income === 'error') {
          navigate(`${affordabilityPath}error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
        } else {
          setContents({ income, debt, downPayment, creditScore, military, officer, location, review, saveProgress });
        }
      } catch (e) {
        navigate(`${affordabilityPath}error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
      }
    }
    getContents();
  }, []);

  useEffect(() => {
    if (loData === null && !fetchLoData) {
      const loId = urlParams.get('loId') || urlParams.get('emp-id') || getLoCookie();
      if (loId) {
        setLo(loId);
      } else {
        fetchLoanOfficers();
      }
    }
  }, []);

  useEffect(() => {
    setProgressContent(getProgressContent(!!getLoCookie(), affordabilityProgressList));
  }, [getLoCookie()]);

  useEffect(() => {
    async function fetchData() {
      if (urlParams.has('id')) {
        setDataLoading(true);
        await handlePageSubmit(true, 'review');
        setDataLoading(false);
      } else if (progressContent.currentStep === 0) {
        navigate(`${affordabilityPath}income/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
      }
    }
    fetchData();
  }, []);

  async function setSupportNavigationContent() {
    const supportNavResp = await getSupportNavigationContent('affordability_support_navigation');
    setSupportNavContent(supportNavResp);
  }

  if (!supportNavContent) {
    setSupportNavigationContent();
  }

  return (loading || dataLoading) ? <PageLoader /> : (
    <div className="p-4 md:p-8 md:flex md:justify-center">
      <div className="md:max-w-7xl md:px-4">
        <div className="w-full">
          <Header
            logoUrl="https://www.rate.com"
            authData={null}
            fetchUserData={true}
            isLite={false}
          />
        </div>
        <div className="w-full">
          {progressContent.currentStep < progressContent.steps.length && (
            <div className="mb-0">
              <ProgressBar progress={progressContent} />
            </div>
          )}
        </div>
        <div className="md:flex justify-center w-full mb-8">
          <div className="md:grow max-w-2xl">
            <Routes>
              <Route path="/income/*" element={(<AffordabilityIncomePage progress={progressContent} content={contents?.income} updateProgress={updateProgress} affordabilityData={affordabilityData} setAffordabilityData={setAffordabilityData} />)} />
              <Route path="/debt/*" element={(<AffordabilityDebtPage progress={progressContent} content={contents?.debt} updateProgress={updateProgress} affordabilityData={affordabilityData} setAffordabilityData={setAffordabilityData} />)} />
              <Route path="/downPayment/*" element={(<AffordabilityDownPaymentPage progress={progressContent} content={contents?.downPayment} updateProgress={updateProgress} affordabilityData={affordabilityData} setAffordabilityData={setAffordabilityData} />)} />
              <Route path="/creditScore/*" element={(<AffordabilityCreditScorePage progress={progressContent} content={contents?.creditScore} updateProgress={updateProgress} affordabilityData={affordabilityData} setAffordabilityData={setAffordabilityData} />)} />
              <Route path="/military/*" element={(<AffordabilityMilitaryPage progress={progressContent} content={contents?.military} updateProgress={updateProgress} loData={loData} affordabilityData={affordabilityData} setAffordabilityData={setAffordabilityData} />)} />
              <Route path="/officer/*" element={(<AffordabilityOfficerPage setLo={setLo} progress={progressContent} content={contents?.officer} updateProgress={updateProgress} loanOfficers={loanOfficers} setLoData={setLoData} />)} />
              <Route path="/location/*" element={(<AffordabilityLocationPage progress={progressContent} content={contents?.location} affordabilityData={affordabilityData} loData={loData} updateProgress={updateProgress} setAffordabilityData={setAffordabilityData} handleSubmit={handlePageSubmit} />)} />
              <Route path="/review/*" element={<AffordabilityReviewPage content={contents?.review} reviewData={resultData} affordabilityData={affordabilityData} handleSubmit={handlePageSubmit} setAffordabilityData={setAffordabilityData} handleDMXSubmit={handleDMXSubmit} leadInput={leadInput} setLeadInput={setLeadInput} isApplyLoading={isApplyLoading} />} />
              <Route path="/saveProgress" element={(<AffordabilitySaveProgressPage content={contents?.saveProgress} setLeadInput={setLeadInput} leadInput={leadInput} handleDMXSubmit={handleDMXSubmit} isApplyLoading={isApplyLoading} />)} />
              <Route path="/error/*" element={(<AffordabilityErrorPage currentPage={currentPage} isPreApproval={isPreApproval} contentAvailable={Object.keys(contents).length > 0} progress={progressContent} isRecalculate={isRecalculate} updateProgress={updateProgress} errorMessage={errorMessage} disclaimer={contents?.review?.disclaimer} />)} />
            </Routes>
          </div>
        </div>
        {supportNavContent && <LoanOfficerBanner supportNavLoData={loData ? { ...loData, heading: supportNavContent.heading, description: supportNavContent.description } : supportNavContent} />}
        <Footer data={footerContent} />
      </div>
    </div>
  );
};
