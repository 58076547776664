import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import DropDownSearch from '@guaranteed-rate/react-components/dist/DropDownSearch';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import Form from '../../../components/Form/Form';
import { ILoanOfficer, IPropertyResponse } from '../../../config/util/interfaces';
import { MASKS } from '../../../config/util/masks';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { getLoCookie } from '../../../config/util/common';
import { HelocOfficerPage } from '../HelocOfficerPage/HelocOfficerPage';
import { helocLiteStates } from '../../../config/content/dropdown';

interface IPropertyProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  propertyData: IPropertyResponse;
  progress?: ProgressProps;
  loanOfficers: any;
  setLo: (loId: string) => void;
}

// This component is used to render the property, annual, occupancy and purpose pages
export const HelocLoanInfo = (props: IPropertyProps) => {
  const [region, setRegion] = useState(props.propertyData?.address?.region || '');
  const [estimatedValue, setEstimatedValue] = useState(props.propertyData?.estimatedValue
    ? `${props.propertyData.requestedLoanAmount}` : '');
  const [loanAmount, setLoanAmount] = useState(props.propertyData?.requestedLoanAmount ? `${props.propertyData.requestedLoanAmount}` : ''); // requested loan amount

  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);

  // when the user clicks next they submit their data to go to the next page
  const handleSubmit = async (form: any) => {
    setTrySubmit(true);

    // validates the input based on the current page (annual or property)
    if (!loanAmountError() && region !== '' && estimatedValue !== '') {
      setLoading(true);
      const cookiedId = getLoCookie();
      const pageData = `{ 
        page: "${props.pageName}"
        lite: true
        propertyData: { 
          state: "${region}"
          estimatedValue: "${estimatedValue}"
          loanAmount: "${loanAmount}"
          loanOfficerId: "${cookiedId || ''}"
        }
      }`;
      await props.handleSubmit(pageData, false);
      setLoading(false);
      setTrySubmit(false);
    }
  };

  // This is used to go backwards
  const handleBack = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      lite: true
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };

  // validates the loan amount is within the appropriate bounds
  const loanAmountError = () => {
    if (loanAmount.length <= 0) {
      return true;
    }
    const amountNumber = parseInt(loanAmount);
    if (amountNumber < 50000 || amountNumber > 500000) {
      return true;
    }
    return false;
  };
  const loanToValueError = () => {
    if (loanAmount.length <= 0 || estimatedValue.length <= 0) {
      return true;
    }
    const amountNumber = parseInt(loanAmount);
    const valueNumber = parseInt(estimatedValue);
    if (amountNumber > valueNumber) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (props.loanOfficers && props.loanOfficers.length > 0 && props.pageName === 'officer') {
      setLoading(false);
    }
  }, [props.loanOfficers]);

  // pre-select dropdown search
  useEffect(() => {
    const input = document.getElementsByClassName('dropDownSearchBox')[0]?.querySelector('input');
    if (input) {
      const name = helocLiteStates.find((option) => option.value === region)?.name;
      if (name) {
        input.value = name;
        const label = document.getElementById('dropDownSearch-label');
        if (label) {
          label.className = 'filled';
        }
      }
    }
  }, [region, props.pageName]);

  return (
    <Form title={props.content.header} progress={props.progress}>
      {props.pageName === 'loanInfo'
      && (
        <div>
          <h4 className="mb-4">{props.content.prompt}</h4>
          <div className="min-h-[300px]">
            <DropDownSearch
              items={helocLiteStates}
              optionLabel="name"
              label={props.content.state_label}
              onChange={(option) => option && setRegion(option.value)}
              hasError={trySubmit && region === ''}
              helperText={trySubmit && region === '' ? 'required' : ''}
              required={true}
            />
            <TextInput
              name="estimatedValue"
              value={estimatedValue}
              label={props.content.value_label}
              onChange={(input) => setEstimatedValue(input.target.value)}
              mask={MASKS.CURRENCY}
              hasError={trySubmit && (estimatedValue === '')}
              helperText={trySubmit && estimatedValue === '' ? 'required' : ''}
              required={true}
            />
            <TextInput
              name="loanAmount"
              value={loanAmount}
              label={props.content.loan_amount_label}
              onChange={(input) => setLoanAmount(input.target.value)}
              mask={MASKS.CURRENCY}
              hasError={trySubmit && (loanAmountError() || loanToValueError())}
              helperText={trySubmit
                ? (loanAmountError()
                  ? 'Please enter a value between $50,000 and $500,000.'
                  : loanToValueError()
                    ? 'Requested loan amount must be below the estimated value.' : '')
                : ''}
              required={true}
            />
          </div>
          <div className="flex justify-center mt-4">
            <Button
              buttonStyle="primary"
              className="!w-full md:!w-48 nextButton"
              onClick={() => handleSubmit({ state: region })}
              loading={loading}
              buttonAttrs={{ disabled: loading }}
            >Next
            </Button>
          </div>
        </div>
      )}

      {props.pageName === 'officer'
      && (
        <HelocOfficerPage
          content={props.content}
          setLo={props.setLo}
          helocForm={{ state: region } as any}
          handleSubmit={handleSubmit}
          handleBack={handleBack}
          setLoading={setLoading}
          isLite={true}
          loanOfficers={props.loanOfficers}
          loading={loading}
        />
      )}
    </Form>
  );
};
