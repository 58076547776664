import { useState } from 'react';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Form from '../../../components/Form/Form';
import { MASKS } from '../../../config/util/masks';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { formatRichText } from '../../../config/util/common';

interface ISSNProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  progress?: ProgressProps;
  isLite?: boolean;
}

export const HelocIdentifierPage = (props: ISSNProps) => {
  const [helocForm, setHelocForm] = useState({ identifier: '' });
  const [loading, setLoading] = useState(false);
  const [showSSN, setShowSSN] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHelocForm({ ...helocForm, identifier: event.target.value });
  };

  const handleBack = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      ${props.isLite ? 'lite: true' : ''}
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      identifierInput: { 
        ssn: "${helocForm.identifier}"
        ssnConsent: true
      }
      ${props.isLite ? 'lite: true' : ''}
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };

  const canSubmit = () => helocForm.identifier.length === 9;

  return (
    <Form title={props.content.header} progress={props.progress}>
      <div className="min-h-[300px]">
        <h4 className="mb-4">{props.content.description}</h4>
        <div className="md:grid md:space-x-4">
          <div
            tabIndex={0}
            role="link"
            onClick={(event: any) => event.target.className.indexOf('eye') !== -1 && setShowSSN(!showSSN)}
          >
            <TextInput
              name="identifier"
              class="identifier-input"
              value={helocForm.identifier}
              label={props.content.ssn_field_label}
              mask={showSSN ? MASKS.SSN : MASKS.SSN_PW}
              type="password"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex flex-row mb-2">
          <h4>
            <div className="text-sm text-left">
              {formatRichText('declainmer', props.content.checkbox_content, props.content)}
            </div>
          </h4>
        </div>
      </div>
      <div className="flex justify-center">
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-60 confirmButton"
          buttonAttrs={{ disabled: !canSubmit() || loading }}
          loading={loading}
          onClick={handleSubmit}
        >
          I agree and continue
        </Button>
      </div>
      {props.isLite && (
        <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
          <Button
            buttonStyle="quaternary"
            iconPos="left"
            iconName="chevron-left-large"
            onClick={handleBack}
            buttonAttrs={{ disabled: loading }}
          >
            Back
          </Button>
        </div>
      )}
    </Form>
  );
};
