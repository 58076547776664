import Button from '@guaranteed-rate/react-components/dist/Button';
import { useState } from 'react';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';

interface IVerifiedProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  progress?: ProgressProps;
  name: string;
  incomeVerified: boolean;
  identityVerified: boolean;
  employmentVerified: boolean;
}

export const HelocVerifiedPage = (props: IVerifiedProps) => {
  const [loading, setLoading] = useState(false);
  const header = props.content.header.replaceAll('%NAME', props.name);

  const handleSubmit = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      identityVerified: ${props.identityVerified}
      incomeVerified: ${props.incomeVerified}
      employmentVerified: ${props.employmentVerified}
    }`;
    (window as any).digitalData = {
      pageInfo: {
        verifiedData: {
          identityVerified: props.identityVerified,
          incomeVerified: props.incomeVerified,
          employmentVerified: props.employmentVerified
        }
      }
    };
    await props.handleSubmit(pageData, false);
    setLoading(false);
  };

  return (
    <Form title={header} progress={props.progress}>
      <div>
        <div className="min-h-[300px]">
          <h4>{props.content.description}</h4>
        </div>
        <div className="flex justify-center mt-12">
          <Button
            buttonStyle="primary"
            className="!w-full md:!w-48 nextButton"
            onClick={handleSubmit}
            loading={loading}
            buttonAttrs={{ disabled: loading }}
          >Next
          </Button>
        </div>
      </div>
    </Form>
  );
};
