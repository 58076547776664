const Avatar = ({ src, alt }: { src: string; alt?: string }) => (
  <div className="relative overflow-hidden bg-inactive-lightest shadow rounded-full w-[4.5rem] h-[4.5rem]">
    <img
      className="absolute top-1/2 h-auto max-w-full -translate-y-1/2 border-none transition-opacity duration-300 mt-1"
      alt={alt}
      src={src}
      width="72"
      height="72"
    />
  </div>
);

export default Avatar;
