/* eslint-disable max-len */
import { useState } from 'react';
import Tabs from '@guaranteed-rate/react-components/dist/Tabs';
import Button from '@guaranteed-rate/react-components/dist/Button';
import { useNavigate } from 'react-router-dom';
import { fetchPageContent } from '../../config/util/common';

const tabs = [
  {
    button: 'Rate',
    content: '',
    isDefault: true,
  },
  {
    button: 'APR',
    content: '',
  },
];

function OwningCongratulations(props: any) {
  props.updateHeaderType('one');
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };
  const [content, setContent] = useState(null) as any;
  const [contentFetched, setContentFetched] = useState(false);
  const [error, setError] = useState(false);

  if (!content && !contentFetched && !error) {
    fetchPageContent('"owning_congratulations"').then(
      (data: any) => {
        if (data.content === 'error') {
          setError(true);
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
        }
      }
    );
  }

  const handlePreApprovalLetter = () => {
    navigate('/pre-screen');
  };

  return (
    <div>
      <div className="flex justify-center w-full">
        <div className="max-w-2xl w-full px-4">
          <div className="flex items-center justify-center">
            <div className="px-4 w-full">
              <h2 className="font-bold mt-8">{content?.header}</h2>
              <div className="mt-4">{content?.description}</div>
            </div>
          </div>
          <div className="items-center justify-center w-full">
            <div className="max-w-2xl mx-auto h-fit rounded-lg shadow-[-5px_-5px_30px_-13px_rgba(0,0,0,0.3)] pb-6 mt-6">
              <div className="pt-2 pl-4 xl:block">
                <Tabs tabs={tabs} tabsAriaLabel="Rate or APR Input" onChange={handleTabChange} isCentered={true} />
              </div>
              <div className="pt-2 pl-4 xl:block">
                {tabIndex === 0
                  ? (
                    <div>
                      <div>
                        <h5 className="mt-1">{content?.rate_data?.today_s_rate_label}</h5>
                        <h2 className="font-bold">5.500%</h2>
                      </div>
                      <div>
                        <h5 className="mt-1">{content?.rate_data?.competitor_s_rate_label}</h5>
                        <h2 className="font-bold">6.475%</h2>
                      </div>
                      <div>
                        <h5 className="mt-1">{content?.rate_data?.savings_label}: <span className="font-bold">$454</span></h5>
                      </div>
                    </div>
                  )
                  : (
                    <div>
                      APR
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="px-4 w-full">
              <div className="font-bold mt-8">{content?.info?.header}</div>
              <div className="mt-4">{content?.info?.description}</div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-full justify-center">
              <div className="flex items-center justify-center mt-4 px-4">
                <Button buttonStyle="primary" className="!w-full mt-4" onClick={handlePreApprovalLetter}>
                  {content?.button_label}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OwningCongratulations;
