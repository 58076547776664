/* eslint-disable max-len */
import { RefObject, useEffect, useRef, useState } from 'react';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import { config } from '../../config/content/config';
import { loadScriptFunction, removeDuplicates } from '../../config/util/common';

interface IGoogleMapAddressProps {
  /** This is called when the location is selected in the ui */
  onChange: (value: any, type: string) => void;
  /** This is a starting value that can be assigned to the text field */
  value: string;
  /** This is a starting value that can be assigned to the Unit text field */
  addressObj: any;
  /** This is a label for the text field */
  label?: string;
  /** Type of results to show */
  type: string;
  /** Whether or not the field is required */
  required?: boolean;
  /** Whether or not to show error */
  hasError?: boolean;
  /** Whether or not to show error */
  hasUnitError?: boolean;
  /** These are additional classes that can be applied to the text field */
  className?: string;
  /** use this to disable the text field */
  disabled?: boolean;
  /** Check if the input field needs to show full address */
  isAddress?: boolean;
  /** The name of input element */
  name?: string;
  /** This is a label prop for the helperText */
  helperText?: string;
  /** Setting this to true will preserve the value when the field is cleared. */
  resetOnBlur?: boolean;
}

/** This is a more friendly object type for the object we receive from the google maps library */
interface IGoogleAddress {
  formattedAddress: string;
  address: string;
  unitNumber: string;
  city: string;
  county: string;
  state: string;
  zip: string;
}

/** This is used to map the field names from the google library to a more user friendly set of field names */
const fieldMapping = {
  street_number: 'address',
  route: 'address',
  subpremise: 'unitNumber',
  administrative_area_level_2: 'county',
  locality: 'city',
  administrative_area_level_1: 'state',
  postal_code: 'zip'
};

/** Runs when a place has been selected on the auto complete dropdown */
const handlePlaceSelect = async (updateLocation: any, autoComplete: any): Promise<void> => {
  const addressObject = autoComplete.getPlace();
  const location = addressObject?.formatted_address;
  if (location) updateLocation(addressObject);
};

const getValueByType = (obj: IGoogleAddress, isAddress = false, type: string) => {
  switch (true) {
  case isAddress:
    return obj.formattedAddress;
  case type === 'postal_code':
    return obj.zip;
  case type === 'locality':
    return obj.city;
  default:
    return obj.formattedAddress;
  }
};

const onFocus = (event: any) => {
  event.preventDefault();
};

/** Runs when the google maps script has finished loading */
const handleScriptLoad = (setLocation: any, autoCompleteRef: RefObject<HTMLInputElement>, type: string, setApiLoaded: any): void => {
  if (autoCompleteRef) {
    setApiLoaded(true);
  }
  const autoComplete = new (window as any).google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: [type], componentRestrictions: { country: 'us' } }
  );
  autoComplete.setFields(['address_components', 'formatted_address']);
  autoComplete.addListener('place_changed', () => handlePlaceSelect(setLocation, autoComplete));
  const list = document.querySelectorAll('div.pac-container[style*="display: none;"');
  list.forEach((node: any, index: number) => index !== 0 && document.body.removeChild(node));
  setTimeout(() => {
    removeDuplicates();
  }, 1000);
};

/** This is a function to display a custom helper text if there is an error */
function getHelperText(hasError?: boolean, helperText?: string) {
  switch (true) {
  case hasError && !!helperText:
    return helperText;
  case hasError:
    return 'required';
  default:
    return undefined;
  }
}

const GoogleMapAddress = (props: IGoogleMapAddressProps) => {
  const [apiLoaded, setApiLoaded] = useState(false);
  const [formattedAddress, setFormattedAddress] = useState(props.value || '');
  const [addressObj, setAddressObj] = useState(props.addressObj);
  const [unitNumber, setUnitNumber] = useState(props.addressObj.unitNumber);
  const [notComplete, setNotComplete] = useState(false);
  const [autoCompleteLoaded, setAutoCompleteLoaded] = useState(false);
  const autoCompleteRef = useRef<HTMLInputElement>(null);
  const hideUnit = (['postal_code', 'locality'].indexOf(props.type) > -1);

  useEffect(() => {
    const isScriptLoaded = document.querySelector('script[src*="https://maps.googleapis.com/maps/api/js"]');
    if (!isScriptLoaded && !apiLoaded && !(window as any)?.google?.maps) {
      loadScriptFunction(config.googleMapsApiURL, () => setApiLoaded(true));
    } else if ((window as any)?.google?.maps && (window as any)?.google?.maps?.places && !autoCompleteLoaded) {
      handleScriptLoad(updateLocation, autoCompleteRef, props.type, setApiLoaded);
      setAutoCompleteLoaded(true);
    }
  });

  const address: IGoogleAddress = {
    formattedAddress: '',
    address: '',
    unitNumber: '',
    city: '',
    county: '',
    state: '',
    zip: ''
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!props.resetOnBlur) {
      setFormattedAddress(event.target.value);
      if (!event.target.value) {
        props.onChange(address, props.type);
      }
      return;
    }
    if (getValueByType(addressObj, props.isAddress, props.type)) {
      setFormattedAddress(getValueByType(addressObj, props.isAddress, props.type));
    } else {
      setFormattedAddress('');
      props.onChange('', props.type);
    }
  };

  /** formats the google address object into an easy to use format and returns it to the onChange prop */
  const updateLocation = (value: any) => {
    const googleAddress: IGoogleAddress = {
      formattedAddress: value?.formatted_address,
      address: '',
      unitNumber: '',
      city: '',
      county: '',
      state: '',
      zip: ''
    };
    value?.address_components.map((component: any) => {
      if (component.types.includes('street_number')) {
        googleAddress.address = `${component.short_name} ${googleAddress.address}`;
      } else {
        component.types.map((type: string) => {
          const field = fieldMapping[type as keyof typeof fieldMapping] as keyof IGoogleAddress;
          if (field) {
            googleAddress[field] += component.short_name;
          }
        });
      }
    });
    setFormattedAddress(getValueByType(googleAddress, props.isAddress, props.type));
    if ((props.type === 'postal_code' && googleAddress.zip) || (props.type === 'locality' && googleAddress.state && googleAddress.city)
      || (googleAddress.address && googleAddress.city && googleAddress.state && googleAddress.zip)) {
      setAddressObj(googleAddress);
      props.onChange(googleAddress, props.type);
      setNotComplete(false);
    } else {
      setNotComplete(true);
    }
  };

  const updateUnitNumber = (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement, Element>, name: string) => {
    props.onChange({ ...addressObj, formattedAddress, [name]: event.target.value }, 'unitNumber');
    setUnitNumber(event.target.value);
    setAddressObj({ ...addressObj, formattedAddress, [name]: event.target.value });
  };

  const handleClear = () => {
    if (notComplete && formattedAddress) {
      setFormattedAddress('');
      setNotComplete(false);
    }
  };

  useEffect(() => {
    if (props.value) setFormattedAddress(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.addressObj.unitNumber) {
      setUnitNumber(props.addressObj.unitNumber);
    }
  }, [props.addressObj.unitNumber]);

  // Callback will happend whenever address will get changed.
  // States are not accessible inside the updateLocation function
  // Whenever address changes, will uppdate object with unit number
  useEffect(() => {
    if (formattedAddress) {
      props.onChange({ ...addressObj, formattedAddress, unitNumber }, props.type);
      setAddressObj({ ...addressObj, formattedAddress, unitNumber });
    }
  }, [formattedAddress]);

  return (
    <div className="flex flex-row mb-5">
      <div className={hideUnit ? 'w-full' : 'w-5/6'}>
        <TextInput
          name={props.name || 'address'}
          label={props.label || 'Address'}
          onFocus={onFocus}
          value={formattedAddress}
          className={props.className ?? undefined}
          onClick={handleClear}
          onChange={(event: any) => (setFormattedAddress(event.target.value))}
          onBlur={handleOnBlur}
          onPaste={(event: any) => { event.preventDefault(); }}
          ref={autoCompleteRef}
          required={props.required}
          hasError={notComplete || props.hasError}
          helperText={notComplete ? 'Must select a valid address.' : getHelperText(props.hasError, props.helperText)}
          disabled={props.disabled}
        />
      </div>
      {!hideUnit && (
        <div className="text-end w-1/6 ml-3 overflow-hidden">
          <TextInput
            name="unit"
            label="Unit"
            minWidth="50px"
            value={unitNumber}
            className={props.className ?? undefined}
            onChange={((input) => updateUnitNumber(input, 'unitNumber'))}
            hasError={!unitNumber && (notComplete || props.hasUnitError)}
            helperText={props.hasUnitError && !unitNumber ? getHelperText(props.hasUnitError, props.helperText) : ''}
            disabled={props.disabled}
            maxLength={5}
          />
        </div>
      )}
    </div>
  );
};

export default GoogleMapAddress;
