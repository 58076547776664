/* eslint-disable max-len */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Banner from '../../components/Banner/Banner';
import { cleanObject, dateDiffInDays, fetchFspPageContent, formatAsGraphQLPaylod, formatDate, formatDateSlashes, getCode, getTimeDiff, loadDate, redirectToMyALogin, setCustomErrorBasedOnResponse } from '../../config/util/common';
import { MASKS } from '../../config/util/masks';
import { IEmploymentForm, IEmploymentInfo } from '../../config/util/interfaces';
import { PreviousButton } from '../../components/PreviousButton/PreviousButton';
import DateInput from '../../components/DateInput/DateInput';
import RateAlertLogo from '../../components/RateAlertLogo/RateAlertLogo';
import { RateAlertWizardProgress } from '../../components/WizardProgress/RateAlertWizardProgress';
import GoogleMapAddress from '../../components/GoogleMapAddress/GoogleMapAddress';
import { generateLink, getNextPanelBasedOnState } from './prefiCommon';
import { log } from '../../config/util/logger';
import { REDIRECT_TO_LOGIN_ERROR_CODE } from '../../config/content/constants';

const getEmploymentDetailsData = async (applicationId: string) => {
  const mutation = {
    query:
      `query {
        getEmploymentDetailsData(applicationId: "${applicationId}") {
          addressCity
          addressPostalCode
          addressState
          addressStreetLine1
          altId
          attention
          badgeOrEmployeeID
          basePayAmount
          bonusAmount
          businessName
          businessOwnedPercent
          businessPhone
          clothingAllowance
          commissionsAmount
          country
          countryCode
          currentEmploymentIndicator
          doesNotApply
          email
          employerComments
          employerName
          employmentMonthlyIncomeAmount
          employmentStartDate
          endDate
          fax
          foreignAddressIndicator
          foreignIncome
          id
          individualEmployer
          jobTermMonths
          militaryCombatPay
          militaryEmployer
          militaryEntitlement
          militaryFlightPay
          militaryHazardPay
          militaryOverseasPay
          militaryPropPay
          monthlyIncomeAmount
          noLinkToDocTrackIndicator
          otherAllowanceDescription
          otherAmount
          overtimeAmount
          owner
          ownershipInterestType
          phoneNumber
          positionDescription
          printAttachmentIndicator
          printUserJobTitleIndicator
          printUserNameIndicator
          quartersAllowance
          rationsAllowance
          seasonalIncome
          selfEmployedIndicator
          specialEmployerRelationshipIndicator
          startDate
          timeInLineOfWorkMonths
          timeInLineOfWorkYears
          timeOnJobTermMonths
          timeOnJobTermYears
          title
          titleFax
          titlePhone
          uRLA2020StreetAddress
          unitNumber
          unitType
          variableHousingAllowance
          employmentCopiedFromOldLoan
      }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "getEmploymentDetailsData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getEmploymentDetailsData) {
      log({ message: `Query "getEmploymentDetailsData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.getEmploymentDetailsData };
    }
    log({ message: `Query "getEmploymentDetailsData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "getEmploymentDetailsData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const updateEmploymentDetailsData = async (applicationId: string, isCoBorrower: boolean, updateEmploymentDetailsRequest: any) => {
  const mutation = {
    query:
      `mutation {
        updateEmploymentDetailsData(applicationId: "${applicationId}", isCoBorrower: ${isCoBorrower}, updateEmploymentDetailsRequest: ${updateEmploymentDetailsRequest}) {
          applicationType
            application {
                id
                tenant
                applicationType
                createdAt
                updatedAt
                state
                currentState
                currentDynamicState
                invitationNumber
                newLoanId
                oldLoanNumber
                oldLoanId
            }
        }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Mutation "updateEmploymentDetailsData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.updateEmploymentDetailsData) {
      log({ message: `Mutation "updateEmploymentDetailsData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.updateEmploymentDetailsData };
    }
    log({ message: `Mutation "updateEmploymentDetailsData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Mutation "updateEmploymentDetailsData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const newBorrowerForm = (): IEmploymentForm => ({
  employmentType: '',
  employerName: '',
  jobTitle: '',
  city: '',
  cityInput: '',
  state: '',
  isCurrentJob: false,
  employmentStartDate: '',
  endDate: '',
  annualBasePay: '',
  annualCommission: '',
  annualOverTime: '',
  annualBonus: '',
  monthlyBasePay: '',
  monthlyCommission: '',
  monthlyOverTime: '',
  monthlyBonus: '',
  isFormOpen: false,
  employmentCopiedFromOldLoan: false,
  owner: 'Borrower',
  originalEmploymentData: {},
});

const newCoBorrowerForm = (): IEmploymentForm => ({
  employmentType: '',
  employerName: '',
  jobTitle: '',
  city: '',
  cityInput: '',
  state: '',
  isCurrentJob: false,
  employmentStartDate: '',
  endDate: '',
  annualBasePay: '',
  annualCommission: '',
  annualOverTime: '',
  annualBonus: '',
  monthlyBasePay: '',
  monthlyCommission: '',
  monthlyOverTime: '',
  monthlyBonus: '',
  isFormOpen: false,
  employmentCopiedFromOldLoan: false,
  owner: 'CoBorrower',
  originalEmploymentData: {},
});

function PrefiEmployment(props: any) {
  const navigate = useNavigate();
  const [employmentInfo, setEmploymentInfo] = useState<IEmploymentInfo>();
  const [employmentInfoFetched, setEmploymentInfoFetched] = useState(false);
  const [content, setContent] = useState(null as any);
  const [contentFetched, setContentFetched] = useState(false);
  const [employmentForm, setEmploymentForm] = useState([props.isCoborrowerPage ? newCoBorrowerForm() : newBorrowerForm()]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [trySubmit, setTrySubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  if (!content && !contentFetched && !error) {
    fetchFspPageContent('"prefi_employment"').then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiEmployment: fetchFspPageContent(): isCoborrowerPage ${props.isCoborrowerPage}: Error fetching page content. ${JSON.stringify({ page: 'prefi_employment' })}`, level: 'error' });
          setError(true);
          setErrorMessage('Error fetching page content. Please Try again...');
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
        }
      }
    );
  }

  if (content && props.applicationId && !employmentInfo && !employmentInfoFetched && !error) {
    getEmploymentDetailsData(props.applicationId).then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiEmployment: getEmploymentDetailsData(): isCoborrowerPage ${props.isCoborrowerPage}: Setting custom error based on response ${JSON.stringify({ data })}`, context: { applicationId: props.applicationId }, level: 'error' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          setEmploymentInfoFetched(false);
          if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
            redirectToMyALogin();
          }
        } else {
          setError(false);
          setEmploymentInfoFetched(true);
          setEmploymentInfo(data.content);
          let currenEmploymentData: any = [];
          data.content.forEach((employment: any) => {
            const eachEmployment: IEmploymentForm = {
              employmentType: employment.selfEmployedIndicator ? 'self' : 'w2',
              employerName: employment?.employerName,
              jobTitle: employment?.positionDescription,
              city: employment?.addressCity,
              cityInput: employment?.addressCity,
              state: employment?.addressState,
              isCurrentJob: employment?.currentEmploymentIndicator,
              employmentStartDate: (employment?.employmentStartDate ? formatDateSlashes(loadDate(employment?.employmentStartDate)) : ''),
              endDate: employment?.currentEmploymentIndicator ? null : (employment?.endDate ? formatDateSlashes(loadDate(employment?.endDate)) : ''),
              annualBasePay: employment?.basePayAmount !== null ? Math.ceil(parseFloat(employment?.basePayAmount) * 12).toString() : '',
              annualCommission: employment?.commissionsAmount !== null ? Math.ceil(parseFloat(employment?.commissionsAmount) * 12).toString() : '',
              annualOverTime: employment?.overtimeAmount !== null ? Math.ceil(parseFloat(employment?.overtimeAmount) * 12).toString() : '',
              annualBonus: employment?.bonusAmount !== null ? Math.ceil(parseFloat(employment?.bonusAmount) * 12).toString() : '',
              monthlyBasePay: employment?.basePayAmount !== null ? Math.ceil(parseFloat(employment?.basePayAmount)).toString() : '',
              monthlyCommission: employment?.commissionsAmount !== null ? Math.ceil(parseFloat(employment?.commissionsAmount)).toString() : '',
              monthlyOverTime: employment?.overtimeAmount !== null ? Math.ceil(parseFloat(employment?.overtimeAmount)).toString() : '',
              monthlyBonus: employment?.bonusAmount !== null ? Math.ceil(parseFloat(employment?.bonusAmount)).toString() : '',

              owner: employment?.owner || 'Borrower',
              isFormOpen: false,
              employmentCopiedFromOldLoan: employment.employmentCopiedFromOldLoan,
              originalEmploymentData: { ...employment,
                id: undefined,
                employmentCopiedFromOldLoan: undefined, },
            };
            if (eachEmployment.owner === (props.isCoborrowerPage ? 'CoBorrower' : 'Borrower')) {
              currenEmploymentData.push(eachEmployment);
            }
          });
          currenEmploymentData.forEach((item: any) => {
            cleanObject(item);
          });
          setEmploymentForm(currenEmploymentData);
        }
      }
    );
  }

  const employmentTypes = content?.employment_types ? content?.employment_types?.map((item: any) => ({
    displayName: item.option.label,
    value: item.option.value,
  })) : [];

  const handleAnnualVsMonthly = (event: any, index: number, relativeField: string, isAnnual: boolean) => {
    setEmploymentForm((currentEmployment: any) => {
      let newObj = [...currentEmployment];
      newObj[index][relativeField] = isAnnual
        ? (parseFloat(event.target.value.replace(/\$/g, '').split(',').join('')) / 12).toString()
        : (parseFloat(event.target.value.replace(/\$/g, '').split(',').join('')) * 12).toString();
      return newObj;
    });
  };

  const updateEmploymentForm = (currentForm: any, formIndex: number) => {
    setEmploymentForm((currentEmployment: any) => {
      let newObj = [...currentEmployment];
      newObj[formIndex] = currentForm;
      return newObj;
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name: string, index: number) => {
    setError(false);
    setEmploymentForm((currentEmployment: any) => {
      let newObj = [...currentEmployment];
      newObj[index][name] = name === 'isCurrentJob' ? event.target.checked : event.target.value;
      if (name === 'isCurrentJob') {
        if (newObj[index].isCurrentJob) {
          delete newObj[index].endDate;
        } else {
          newObj[index].endDate = '';
        }
      }
      return newObj;
    });
  };

  const onAddressChange = (address: any, name: string, index: number) => {
    setEmploymentForm((currentEmployment: any) => {
      let newObj = [...currentEmployment];
      if (name === 'city') {
        newObj[index].city = address.city;
        newObj[index].state = address.state;
      }
      return newObj;
    });
  };

  const handleAddEmployement = () => {
    setEmploymentForm((currentEmployment: any) => {
      let newObj = [...currentEmployment];
      newObj.push(props.isCoborrowerPage ? newCoBorrowerForm() : newBorrowerForm());
      return newObj;
    });
    setError(false);
    handleToggle(employmentForm.length);
  };

  const handleDeleteEmployement = (index: number) => {
    setEmploymentForm((currentEmployment: any) => {
      let newObj = [...currentEmployment];
      newObj.splice(index, 1);
      if (employmentForm.length === 0) {
        setEmploymentForm([]);
      }
      return newObj;
    });
  };

  const handleToggle = (index: number) => {
    setEmploymentForm((currentEmployment: any) => {
      let newObj = [...currentEmployment];
      newObj.forEach((formItem: any, formIndex: number) => {
        if (formIndex === index) {
          formItem.isFormOpen = !formItem.isFormOpen;
        } else {
          formItem.isFormOpen = false;
        }
      });
      return newObj;
    });
    window.scrollTo(0, 0);
  };

  const hasError = (name: string, value: string, relativeValue?: any) => {
    if (value?.length > 0) {
      if (name.indexOf('employmentStartDate') > -1) {
        return (value.length < 10 || ((new Date(value).getFullYear()) < 1900) || (parseInt(value.substring(value.length - 4)) < 1900) || (dateDiffInDays(new Date(), new Date(value)) >= -1));
      }
      if (name.indexOf('endDate') > -1) {
        if (value.length === 10) {
          const diffInStartEndDate = dateDiffInDays(new Date(relativeValue), new Date(value));
          return diffInStartEndDate < 1;
        }
        return (value.length < 10 || ((new Date(value).getFullYear()) < 1900) || (dateDiffInDays(new Date(), new Date(value)) >= -1));
      }
    }
    const isError: any = trySubmit && !(value?.length > 0);
    return isError;
  };

  const getErrorText = (dateVal: string): string => (dateVal.length < 10 ? (dateVal.length === 0 ? 'required field' : 'Please enter a valid date') : ((((new Date(dateVal).getFullYear()) < 1900) || parseInt(dateVal.substring(dateVal.length - 4)) < 1900) ? 'Date is too far in the past' : 'Date must be before today'));

  const isValidForm = () => {
    let isValid = true;
    if (employmentForm.length > 0) {
      let reconstructedForm: any = [];
      employmentForm.forEach((eachItemInform: any) => {
        let formWithRequiredFields = {};
        if (eachItemInform.isCurrentJob) {
          formWithRequiredFields = {
            employmentType: eachItemInform.employmentType,
            employerName: eachItemInform.employerName,
            jobTitle: eachItemInform.jobTitle,
            city: eachItemInform.city,
            state: eachItemInform.state,
            employmentStartDate: eachItemInform.employmentStartDate,
            annualBasePay: eachItemInform.annualBasePay,
            monthlyBasePay: eachItemInform.monthlyBasePay,
          };
        } else {
          formWithRequiredFields = {
            employmentType: eachItemInform.employmentType,
            employerName: eachItemInform.employerName,
            jobTitle: eachItemInform.jobTitle,
            city: eachItemInform.city,
            state: eachItemInform.state,
            employmentStartDate: eachItemInform.employmentStartDate,
            endDate: eachItemInform.endDate,
            annualBasePay: eachItemInform.annualBasePay,
            monthlyBasePay: eachItemInform.monthlyBasePay,
          };
        }
        reconstructedForm.push(formWithRequiredFields);
      });
      reconstructedForm.forEach((eachItemInform: any) => {
        for (let propName in eachItemInform) {
          if (eachItemInform[propName] === null || eachItemInform[propName] === undefined || eachItemInform[propName] === '') {
            isValid = false;
          }
          if (eachItemInform?.employmentStartDate?.length < 10 || eachItemInform?.endDate?.length < 10) {
            isValid = false;
          }
          const diffInStartEndDate = dateDiffInDays(new Date(eachItemInform.employmentStartDate), new Date(eachItemInform.endDate));
          if (diffInStartEndDate < 0) {
            isValid = false;
          }
        }
      });
    }
    return isValid;
  };

  const handleContinue = async () => {
    log({ message: `PrefiEmployment: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: Handling continue`, level: 'info' });
    setTrySubmit(true);
    setError(false);
    if (employmentForm && isValidForm()) {
      log({ message: `PrefiEmployment: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: Client validation passed`, level: 'info' });
      setLoading(true);
      let requestBody: Array<IEmploymentInfo> = [];
      employmentForm.forEach((employment: IEmploymentForm) => {
        const eachObject: IEmploymentInfo = {
          ...employment.originalEmploymentData, // if employment is editing an existing employment, place all the original's data here
          owner: employment.owner,
          employerName: employment.employerName,
          positionDescription: employment.jobTitle,
          addressCity: employment.city,
          addressState: employment.state,
          currentEmploymentIndicator: employment.isCurrentJob,
          employmentStartDate: formatDate(new Date(employment.employmentStartDate)),
          endDate: employment.endDate && employment.endDate !== '' ? formatDate(new Date(employment.endDate)) : '',
          basePayAmount: employment.monthlyBasePay ? parseFloat(employment.monthlyBasePay) : undefined,
          commissionsAmount: employment.monthlyCommission ? parseFloat(employment.monthlyCommission) : undefined,
          overtimeAmount: employment.monthlyOverTime ? parseFloat(employment.monthlyOverTime) : undefined,
          bonusAmount: employment.monthlyBonus ? parseFloat(employment?.monthlyBonus) : undefined,
          selfEmployedIndicator: employment.employmentType === 'self',
        };
        requestBody.push(eachObject);
      });
      const updateEmploymentDetailsRequest = formatAsGraphQLPaylod(requestBody);
      updateEmploymentDetailsData(props.applicationId, props.isCoborrowerPage, updateEmploymentDetailsRequest).then(
        (data: any) => {
          setLoading(false);
          if (data.content === 'error') {
            log({ message: `PrefiEmployment: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: updateEmploymentDetailsData(): Setting custom error based on response ${JSON.stringify({ data })}`, level: 'info' });
            setError(true);
            setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
            window.scrollTo(0, 0);
            if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
              redirectToMyALogin();
            }
          } else {
            setError(false);
            if (data.content?.application) {
              const nextPanel = getNextPanelBasedOnState(data);
              log({ message: `PrefiEmployment: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: updateEmploymentDetailsData(): Success ${JSON.stringify({ nextPanel })}`, level: 'info' });
              navigate(generateLink(`/${nextPanel}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
              window.scrollTo(0, 0);
            }
          }
        }
      );
    } else {
      setError(true);
      setErrorMessage('Fill all required fields');
      window.scrollTo(0, 0);
    }
  };

  return (
    <div>
      {(content && employmentForm)
        ? (
          <div className="flex items-center justify-center">
            <div className="items-center justify-center w-full max-w-3xl px-1">
              <RateAlertWizardProgress className="lg:hidden px-4 mb-4" />
              <div className="items-center justify-center lg:mb-8">
                <div className="px-4 w-full">
                  {
                    error
                      ? (
                        <Banner className="border-2 mt-4 mb-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
                      )
                      : ''
                  }
                  <RateAlertLogo alt={content?.header} />
                </div>
              </div>
              <div className="w-full px-1 lg:border border-solid border-slate-300 rounded-2xl bg-white">
                <RateAlertWizardProgress className="hidden lg:block px-4 mt-6" />
                <div className="text-xl font-black mt-6 px-4">{props.isCoborrowerPage ? content?.coborrower_header : content?.header}</div>
                <div className="flex items-center justify-center mb-8">
                  <div className="px-4 w-full">
                    <div className="mt-4">{content?.description}</div>
                  </div>
                </div>
                {
                  (employmentForm?.length > 0 && employmentForm.filter((emp: any) => emp.owner === (props.isCoborrowerPage ? 'CoBorrower' : 'Borrower'))?.length > 0)
                  && (employmentForm.filter((emp: any) => emp.owner === (props.isCoborrowerPage ? 'CoBorrower' : 'Borrower'))?.map((eachFormItem: any, index: number) => (
                    <div id={`employment${index}`} key={`employment${index}`} className="items-center justify-center border-b pb-2 mb-8">
                      <div className="px-4 w-full">
                        <div className="flex justify-between w-full p-2 my-2">
                          <span className="text-xl font-black">
                            {
                              eachFormItem.employerName ? eachFormItem.employerName : 'Enter Employment Details'
                            }
                          </span>
                          <div className="w-1/4">
                            <div className="grid grid-flow-col items-end justify-end">
                              <Button
                                buttonStyle="icon"
                                iconName="trash"
                                className={`${eachFormItem.employmentCopiedFromOldLoan ? 'grey-icon' : 'red-icon'}`}
                                onClick={() => { handleDeleteEmployement(index); }}
                                buttonAttrs={{ disabled: eachFormItem.employmentCopiedFromOldLoan }}
                              />
                              <Button
                                buttonStyle="icon"
                                iconName={`${eachFormItem.isFormOpen ? 'chevron-up-large' : 'chevron-down-large'}`}
                                onClick={() => { handleToggle(index); }}
                              />
                            </div>
                          </div>
                        </div>
                        {
                          eachFormItem.isFormOpen
                            ? (
                              <div className="w-full mt-4">
                                <div className="lg:grid grid-cols-2 gap-4">
                                  <div
                                    role="tab"
                                    tabIndex={0}
                                    className="w-full mb-6"
                                  >
                                    <Dropdown
                                      name="employmentType"
                                      value={eachFormItem.employmentType}
                                      label={content?.form?.employment_type_label}
                                      onChange={(event) => handleChange(event, 'employmentType', index)}
                                      className="w-full prefi-dropdown"
                                      options={employmentTypes}
                                      hasError={hasError(`employmentType${index}`, eachFormItem.employmentType)}
                                      helperText={hasError(`employmentType${index}`, eachFormItem.employmentType) ? 'required' : undefined}
                                      required={true}
                                    />
                                  </div>

                                  <TextInput
                                    name="employerName"
                                    label={content?.form?.employer_name_label}
                                    className="w-full"
                                    value={eachFormItem.employerName}
                                    hasError={hasError(`employerName${index}`, eachFormItem.employerName)}
                                    helperText={hasError(`employerName${index}`, eachFormItem.employerName) ? 'required field' : ''}
                                    onChange={(input) => handleChange(input, 'employerName', index)}
                                    required={true}
                                    disabled={eachFormItem.employmentCopiedFromOldLoan}
                                  />
                                </div>
                                <div className="lg:grid grid-cols-2 gap-4">
                                  <TextInput
                                    name="jobTitle"
                                    label={content?.form?.job_title_label}
                                    className="w-full"
                                    value={eachFormItem.jobTitle}
                                    hasError={hasError(`jobTitle${index}`, eachFormItem.jobTitle)}
                                    helperText={hasError(`jobTitle${index}`, eachFormItem.jobTitle) ? 'required field' : ''}
                                    onChange={(input) => handleChange(input, 'jobTitle', index)}
                                    required={true}
                                  />
                                  <GoogleMapAddress
                                    onChange={(event) => onAddressChange(event, 'city', index)}
                                    value={eachFormItem.cityInput}
                                    addressObj={eachFormItem}
                                    label={content?.form?.city_label}
                                    type="locality"
                                    hasError={hasError(`city${index}`, eachFormItem.city)}
                                    helperText={hasError(`city${index}`, eachFormItem.city) ? 'required field' : ''}
                                    required={true}
                                    resetOnBlur={true}
                                  />
                                </div>
                                <div className="lg:grid grid-cols-2 gap-4 -mt-3.5">
                                  <GoogleMapAddress
                                    onChange={(event) => onAddressChange(event, 'state', index)}
                                    value={eachFormItem.state}
                                    addressObj={eachFormItem}
                                    label={content?.form?.state_label}
                                    type="locality"
                                    hasError={hasError(`state${index}`, eachFormItem.state)}
                                    helperText={hasError(`state${index}`, eachFormItem.state) ? 'required' : undefined}
                                    required={true}
                                    disabled={true}
                                    resetOnBlur={true}
                                  />
                                </div>

                                <div className="flex flex-row mb-4 -mt-5">
                                  <input
                                    name="isCurrentJob"
                                    type="checkbox"
                                    className="checkbox"
                                    checked={eachFormItem.isCurrentJob}
                                    onChange={(event) => handleChange(event, 'isCurrentJob', index)}
                                  />
                                  <h4>{content?.form?.current_job_checkbox_label}</h4>
                                </div>
                                <div className="lg:grid grid-cols-2">
                                  <div className="w-full">
                                    <DateInput
                                      label={content?.form?.start_date_label}
                                      className="lg: mr-2"
                                      name="employmentStartDate"
                                      form={employmentForm[index]}
                                      setForm={(currentForm: any) => updateEmploymentForm(currentForm, index)}
                                      hasError={() => hasError(`employmentStartDate${index}`, eachFormItem.employmentStartDate)}
                                      errorText={hasError(`employmentStartDate${index}`, eachFormItem.employmentStartDate) ? getErrorText(eachFormItem.employmentStartDate) : ''}
                                      id="employmentStartDate"
                                      required={true}
                                      disabled={eachFormItem.employmentCopiedFromOldLoan}
                                    />
                                  </div>
                                  {
                                    eachFormItem.isCurrentJob
                                      ? ''
                                      : (
                                        <DateInput
                                          label={content?.form?.end_date_label}
                                          className="lg: mr-2"
                                          name="endDate"
                                          form={employmentForm[index]}
                                          setForm={(currentForm: any) => updateEmploymentForm(currentForm, index)}
                                          hasError={() => hasError(`endDate${index}`, eachFormItem.endDate, eachFormItem.employmentStartDate)}
                                          errorText={eachFormItem.endDate.length < 10 ? getErrorText(eachFormItem.endDate) : 'End Date must be greater than Start Date'}
                                          id="endDate"
                                          required={true}
                                        />
                                      )
                                  }
                                </div>
                                <div className="lg:grid grid-cols-2 gap-4">
                                  <TextInput
                                    name="annualBasePay"
                                    label={content?.form?.annual_base_pay_label}
                                    className="w-full"
                                    value={eachFormItem.annualBasePay}
                                    hasError={hasError(`annualBasePay${index}`, eachFormItem.annualBasePay)}
                                    helperText={hasError(`annualBasePay${index}`, eachFormItem.annualBasePay) ? 'required field' : ''}
                                    onChange={(input) => { handleChange(input, 'annualBasePay', index); }}
                                    onKeyUp={(input) => { handleAnnualVsMonthly(input, index, 'monthlyBasePay', true); }}
                                    mask={MASKS.CURRENCY}
                                    required={true}
                                  />
                                  <TextInput
                                    name="annualCommission"
                                    label={content?.form?.annual_commission_label}
                                    className="w-full"
                                    value={eachFormItem.annualCommission}
                                    onChange={(input) => { handleChange(input, 'annualCommission', index); }}
                                    onKeyUp={(input) => { handleAnnualVsMonthly(input, index, 'monthlyCommission', true); }}
                                    mask={MASKS.CURRENCY}
                                  />
                                </div>
                                <div className="lg:grid grid-cols-2 gap-4">
                                  <TextInput
                                    name="annualOverTime"
                                    label={content?.form?.annual_overtime_label}
                                    className="w-full"
                                    value={eachFormItem.annualOverTime}
                                    onChange={(input) => { handleChange(input, 'annualOverTime', index); }}
                                    onKeyUp={(input) => { handleAnnualVsMonthly(input, index, 'monthlyOverTime', true); }}
                                    mask={MASKS.CURRENCY}
                                  />
                                  <TextInput
                                    name="annualBonus"
                                    label={content?.form?.annual_bonus_label}
                                    className="w-full"
                                    value={eachFormItem.annualBonus}
                                    onChange={(input) => { handleChange(input, 'annualBonus', index); }}
                                    onKeyUp={(input) => { handleAnnualVsMonthly(input, index, 'monthlyBonus', true); }}
                                    mask={MASKS.CURRENCY}
                                  />
                                </div>
                                <div className="lg:grid grid-cols-2 gap-4">
                                  <TextInput
                                    name="monthlyBasePay"
                                    label={content?.form?.monthly_base_pay_label}
                                    className="w-full"
                                    value={eachFormItem.monthlyBasePay}
                                    hasError={hasError(`monthlyBasePay${index}`, eachFormItem.monthlyBasePay)}
                                    helperText={hasError(`monthlyBasePay${index}`, eachFormItem.monthlyBasePay) ? 'required field' : ''}
                                    onChange={(input) => { handleChange(input, 'monthlyBasePay', index); }}
                                    onKeyUp={(input) => { handleAnnualVsMonthly(input, index, 'annualBasePay', false); }}
                                    mask={MASKS.CURRENCY}
                                    required={true}
                                  />
                                  <TextInput
                                    name="monthlyCommission"
                                    label={content?.form?.monthly_commission_label}
                                    className="w-full"
                                    value={eachFormItem.monthlyCommission}
                                    onChange={(input) => { handleChange(input, 'monthlyCommission', index); }}
                                    onKeyUp={(input) => { handleAnnualVsMonthly(input, index, 'annualCommission', false); }}
                                    mask={MASKS.CURRENCY}
                                  />
                                </div>
                                <div className="lg:grid grid-cols-2 gap-4">
                                  <TextInput
                                    name="monthlyOverTime"
                                    label={content?.form?.monthly_overtime_label}
                                    className="w-full"
                                    value={eachFormItem.monthlyOverTime}
                                    onChange={(input) => { handleChange(input, 'monthlyOverTime', index); }}
                                    onKeyUp={(input) => { handleAnnualVsMonthly(input, index, 'annualOverTime', false); }}
                                    mask={MASKS.CURRENCY}
                                  />
                                  <TextInput
                                    name="monthlyBonus"
                                    label={content?.form?.monthly_bonus_label}
                                    className="w-full"
                                    value={eachFormItem.monthlyBonus}
                                    onChange={(input) => { handleChange(input, 'monthlyBonus', index); }}
                                    onKeyUp={(input) => { handleAnnualVsMonthly(input, index, 'annualBonus', false); }}
                                    mask={MASKS.CURRENCY}
                                  />
                                </div>
                              </div>
                            )
                            : ''
                        }
                      </div>
                    </div>
                  )))
                }
                <div className="flex items-start justify-start">
                  <div className="flex justify-start px-4">
                    <Button
                      buttonSize="large"
                      buttonStyle="tertiary"
                      iconName="plus-add"
                      iconPos="left"
                      className="!w-full md:!w-48"
                      onClick={handleAddEmployement}
                    >
                      {content?.form?.add_employment_button_label}
                    </Button>
                  </div>
                </div>
                <div className="px-4 mt-2 mb-8 w-full flex justify-between">
                  <div className="flex justify-center mt-12">
                    <PreviousButton isDynamic={true} applicationId={props.applicationId} />
                  </div>
                  <div className="items-center justify-center">
                    <div className="px-4 mt-2 mb-8 w-full">
                      <div className="flex justify-center mt-12">
                        <Button
                          buttonStyle="primary"
                          className="!w-full md:!w-48 confirmButton"
                          onClick={handleContinue}
                          buttonAttrs={{ disabled: loading }}
                          loading={loading}
                        >{content?.form?.button_label}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : (
          <div className="px-4 w-full">
            {
              error
                ? (
                  <Banner className="border-2 mt-4 mb-4" text={errorMessage} title="Error" type="error" icon="warning-triangle" isMultiline={true} errorContent={props.errors.errorContent} />
                )
                : ''
            }
          </div>
        )}
    </div>
  );
}
export default PrefiEmployment;
