/* eslint-disable max-len */
import Img from '@guaranteed-rate/react-components/dist/Img';
import { useEffect } from 'react';
import Header from '../../components/Header/Header';
import OwningHeaderMain from '../../components/Header/OwningHeader/OwningHeaderMain';
import OwningFooter from '../../components/Footer/OwningFooter';
import Footer from '../../components/Footer/Footer';
import { log } from '../../config/util/logger';

export const Error404Page = (props: any) => {
  const tenant = (window as any).tenantId;
  const isGri = tenant === 'gri';
  const isOwning = tenant === 'owning';

  useEffect(() => {
    log({ message: 'Error404Page: 404 error page shown', level: 'info' });
  }, []);

  return (
    <div className="flex justify-center">
      <div className="max-w-screen-2xl w-full flex justify-center">
        <div className="w-full">
          {
            props.isHeader
              ? (isGri ? (
                <div className="mt-8">
                  <Header />
                </div>
              ) : (
                <div>
                  <OwningHeaderMain />
                </div>
              )) : ''
          }
          <div className="px-4">
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center w-full min-h-[50vh] m-8">
                <div className="text-center">
                  {!isGri && (
                    <div className="m-8 flex justify-center">
                      <Img
                        src="/images/magnifying-glass.svg"
                        alt="404"
                        width={227}
                        height={144}
                      />
                    </div>
                  )}
                  <h2 className="font-bold">Page not Found</h2>
                  <p className="text-xl">
                    Apologies, but the page you requested could not be found.
                  </p>
                </div>
              </div>
            </div>
            {
              props.isFooter
                ? (isOwning ? <OwningFooter /> : <Footer />)
                : ''
            }
          </div>
        </div>
      </div>
    </div>
  );
};
