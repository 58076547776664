import { config } from '../../config/content/config';
import { stateKeyToRoute } from '../../config/util/prefiNavigation';

export const isPrefiUrl = () => config.prefiUrls.includes(window.location.hostname);

export const getPrefix = () =>
  (['prod', 'stage'].includes((window as any).env)
  && isPrefiUrl() ? '' : '/prefi');

export const generateLink = (link: string) => `${getPrefix()}${link}`;

export const getNextPanelBasedOnState = (data: any): string => {
  const nextPanelKey = (data?.content?.application?.currentState === 'PRE_FI_DYNAMIC_STATE_IN_PROGRESS'
    || data?.content?.application?.currentState === 'CO_BORROWER_PRE_FI_DYNAMIC_STATE_IN_PROGRESS')
    ? data.content.application.currentDynamicState as keyof typeof stateKeyToRoute
    : data.content.application.currentState as keyof typeof stateKeyToRoute;
  const nextPanel = nextPanelKey?.length > 0 ? stateKeyToRoute[nextPanelKey] : 'loandetail';
  return nextPanel;
};

export const normalizeSearchParam = (param: string | null): string | null =>
  (param && param !== 'null' ? param : null);

export const normalizeSearchParams = (search?: string): URLSearchParams => {
  const urlParams = new URLSearchParams(search || window.location.search);
  urlParams.forEach((value, key) => {
    if (!value || value === 'null') {
      urlParams.delete(key);
    }
  });
  return urlParams;
};

export const normalizeSearchParamsString = (search?: string): string => {
  const urlParams = normalizeSearchParams(search);
  if (!urlParams.size) return '';
  return `?${urlParams.toString()}`;
};

export const getDemographicCategoryList = (categoryData: any, categoryIndex: number): Array<string> => {
  let categoryList: Array<string> = [];
  for (let j = 0; j < categoryData.content.checkbox_module[categoryIndex].category.checkbox_group.length; j++) {
    categoryList.push(categoryData.content.checkbox_module[categoryIndex].category.checkbox_group[j].option.value);
    if (categoryData.content.checkbox_module[categoryIndex].category.checkbox_group[j].option.input.length > 0) {
      for (let a = 0; a < categoryData.content.checkbox_module[categoryIndex].category.checkbox_group[j].option.input.length; a++) {
        categoryList.push(categoryData.content.checkbox_module[categoryIndex].category.checkbox_group[j].option.input[a].option.value);
      }
    }
  }
  return categoryList;
};
