/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
import Cookies from 'js-cookie';
import './App.css';
import 'react-range-slider-input/dist/style.css';
import '@guaranteed-rate/react-components/dist/styles.css';
import '@guaranteed-rate/react-components/dist/typography-tags.css';
import './styles/Typography.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { HelocPage } from './pages/Heloc/HelocPage';
import { AffordabilityPage } from './pages/Affordability/AffordabilityPage';
import { Error404Page } from './pages/Error/Error404Page';
import { config } from './config/content/config';
import { OwningPreScreen as OwningPhase1 } from './pages/Owning/OwningPreScreen';
import { OwningPreScreen as OwningPhase0 } from './pages/Owning/Phase0/OwningPreScreen';
import { PrefiPage } from './pages/Prefi/PrefiPage';
import { HelocLite } from './pages/Heloc/HelocLite/HelocLite';
import { TestPage } from './pages/Test/TestPage';
import { log } from './config/util/logger';
import { isPrefiUrl, getPrefix } from './pages/Prefi/prefiCommon';
import { getTimeDiff } from './config/util/common';

const adTrkMap = {
  gri: 'guaranteedrate',
  owning: 'owning',
  op: 'originpoint',
  citywide: 'citywidehomeloans',
  prate: 'properrate',
  gra: 'grarate'
};

const cssMap = {
  gri: 'gri',
  owning: 'own',
  op: 'op',
  citywide: 'city',
  prate: 'prate',
  gra: 'gra'
};

export const scriptsLoaded = [] as Array<string>;

const isValidReferrer = (referrer: string) => {
  const url = new URL(referrer);
  return (url.host.indexOf('google') !== -1 || url.host.indexOf('yahoo') !== -1
   || url.host.indexOf('bing') !== -1 || url.host.indexOf('duckduckgo') !== -1 || url.host.indexOf('android') !== -1);
};

const handleAdtrk = () => {
  const queryParams = new URLSearchParams(window.location.search);
  let adTrk = Cookies.get('AdTrk');
  queryParams.forEach((value, key) => {
    if (key.toLowerCase() === 'adtrk') {
      adTrk = value;
    }
  });
  const referrer = document.referrer;
  if (referrer && referrer !== '') {
    if ((isValidReferrer(referrer)) && (!adTrk || adTrk.indexOf('direct') !== -1)) {
      const url = new URL(referrer);
      const parts = url.host.split('.');
      const source = parts.length > 2 ? parts[parts.length - 2] : '';
      const sub_id = queryParams.get('sub_id');
      const subId = queryParams.get('sub-id');
      const searchString = new URLSearchParams({
        ...(sub_id ? { sub_id } : {}),
        ...(subId ? { 'sub-id': subId } : {})
      }).toString();
      adTrk = `|seo|${source}|||||${window.location.host}${window.location.pathname}${searchString ? `?${searchString}` : ''}||||||||`;
    }
  }
  if (!adTrk || adTrk === '') {
    adTrk = `|gnr|${adTrkMap[(window as any).tenantId as keyof typeof adTrkMap]}||||||||direct|||||`;
  }
  setAdtrk(adTrk);
};

const setAdtrk = (adtrk: string) => Cookies.set('AdTrk', adtrk, { expires: 365, domain: '.rate.com', path: '/' });

function App() {
  const [env, setEnv] = useState('');
  const [fetchedMetaData, setFetchedMetaData] = useState(false);
  const [metaDataLoaded, setMetaDataLoaded] = useState(false);
  const tenant = (window as any).tenantId;

  const loadScript = (url: string) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.onload = () => log({ message: `script loaded ${JSON.stringify({ url })}`, level: 'info' });
    document.body.appendChild(script);
  };

  const fetchMetaData = async () => {
    const adobeLaunchConfig = config.adobeLaunch[tenant as keyof typeof config.adobeLaunch];
    if (process.env.REACT_APP_ENVIRONMENT) {
      const env = process.env.REACT_APP_ENVIRONMENT;
      (window as any).env = env;
      log({ message: 'Metadata skipped.', level: 'debug' });
      setEnv(env);
      setMetaDataLoaded(true);
    } else {
      log({ message: 'Fetching metadata', level: 'debug' });
      const startTime = performance.now();
      const resp = await fetch('/metadata', {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        }
      });
      try {
        const data = await resp.json();
        const duration = getTimeDiff(startTime, performance.now());
        if (data) {
          (window as any).env = data.env;
          log({ message: `Metadata was successful ${JSON.stringify({ duration })}`, level: 'debug' });
          setEnv(data.env);
          loadScript(adobeLaunchConfig[data.env as keyof typeof adobeLaunchConfig]);
          setMetaDataLoaded(true);
        } else {
          (window as any).env = 'dev';
          log({ message: `Metadata failed. Defaulting to dev ${JSON.stringify({ duration })}`, level: 'debug' });
          setEnv('dev');
          loadScript(adobeLaunchConfig.dev);
          setMetaDataLoaded(true);
        }
      } catch (e) {
        (window as any).env = 'dev';
        log({ message: 'Metadata failed due to exception. Defaulting to dev.', level: 'debug' });
        setEnv('dev');
        setMetaDataLoaded(true);
      }
    }
  };

  handleAdtrk();

  if (!fetchedMetaData) {
    setFetchedMetaData(true);
    fetchMetaData();
  }

  useEffect(() => {
    if (window.location.pathname === '/' || window.location.pathname === '') {
      if ((tenant === 'gri' || tenant === 'gra') && (env === 'dev' || env === 'qa')) {
        window.location.href = '/heloc';
      } else if (tenant === 'owning') {
        window.location.href = '/pre-screen';
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {
    log({ message: 'App loaded', level: 'info' });
  }, []);

  return (
    <div className="App">
      <link rel="stylesheet" href={`/static/css/${tenant}.css`} type="text/css" />
      <link rel="stylesheet" href="https://use.typekit.net/svp7poq.css" type="text/css" />
      <link rel="stylesheet" href={`/static/css-shared-modules/${cssMap[tenant as keyof typeof cssMap]}.css`} type="text/css" />
      {
        metaDataLoaded
        && (
          <Router>
            <Routes>
              {tenant === 'owning' && <Route path="/pre-screen/*" element={<OwningPhase0 />} />}
              {env !== 'prod' && tenant === 'owning' && <Route path="/mortgage/*" element={<OwningPhase1 />} />}
              {(['dev', 'qa'].includes((window as any).env) || isPrefiUrl()) && (<Route path={`${getPrefix()}/*`} element={<PrefiPage />} />)}
              {tenant === 'gri' && config.homeAffordabilityUrls.includes(window.location.hostname) && <Route path="/*" element={<AffordabilityPage />} /> }
              {env !== 'prod' && env !== 'stage' && tenant === 'gri' && <Route path="/home-affordability/*" element={<AffordabilityPage />} /> }
              {tenant === 'gri' && config.helocLiteUrls.includes(window.location.hostname) && <Route path="/*" element={<HelocLite />} /> }
              {env !== 'prod' && env !== 'stage' && tenant === 'gri' && <Route path="/heloc/lite/*" element={<HelocLite />} /> }
              {env !== 'prod' && tenant === 'gra' && <Route path="/heloc/*" element={<HelocPage />} /> }
              {env !== 'prod' && tenant === 'gri' && <Route path="/heloc/*" element={<HelocPage />} /> }
              {env === 'dev' && <Route path="/test/*" element={<TestPage />} /> }
              <Route path="*" element={<Error404Page isHeader={true} isFooter={true} />} />
            </Routes>
          </Router>
        )
      }
    </div>
  );
}

export default App;
