/* eslint-disable max-len */
import Cookies from 'js-cookie';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { footerContent } from '../../config/content/footer';
import { log } from '../../config/util/logger';
import { getTimeDiff } from '../../config/util/common';

function OwningFooter(props: any) {
  const tenantId = (window as any).tenantId;
  const [adTrkURIVal, setAdTrkURIVal] = useState(encodeURIComponent(JSON.stringify(Cookies.get('AdTrk'))?.replace(/\"/g, '')));
  const [disclaimer, setDisclaimer] = useState('');
  const [copyright, setCopyright] = useState('');
  const [complaintUrl, setComplaintUrl] = useState('');
  const [links, setLinks] = useState([]);
  const [content, setContent] = useState(null) as any;
  const [contentFetched, setContentFetched] = useState(false);
  const [error, setError] = useState(false);
  const page = footerContent[tenantId as keyof typeof footerContent];
  const fetchContent = async () => {
    if (!contentFetched && !content && !error) {
      setContentFetched(true);
      const mutation = {
        query:
          `query {
            fetchFspContent(page: "${page}") {
              pageContentJson
            }
          }`
      };
      const uuid = uuidv4();
      const url = '/gateway/graphql';
      log({ message: `Query "fetchFspContent" ${JSON.stringify({ page })}`, level: 'info', requestId: uuid });
      const startTime = performance.now();
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'X-GR-FSP-TENANT-ID': `${tenantId}`,
          'X-Request-ID': uuid,
        },
        body: JSON.stringify(mutation)
      });
      try {
        const data = await resp.json();
        const duration = getTimeDiff(startTime, performance.now());
        if (data?.data?.fetchFspContent) {
          if (data.data.fetchFspContent.pageContentJson !== '') {
            const contentData = [] as Array<any>;
            data.data.fetchFspContent.pageContentJson.map((entry: string) => contentData.push(JSON.parse(entry)));
            log({ message: `Query "fetchFspContent" was successful ${JSON.stringify({ page, duration })}`, level: 'info', requestId: uuid });
            setContent(contentData[0]);
            setDisclaimer(contentData[0]?.disclaimer.replaceAll('%ADTRK', adTrkURIVal));
            setCopyright(contentData[0]?.copyright.replaceAll('%CURRENT', new Date().getFullYear()));
            setLinks(contentData[0]?.links.map((link: any) => ({ url: link.link.url.replaceAll('%ADTRK', adTrkURIVal), text: link.link.text })));
            setComplaintUrl(contentData[0]?.complaint_url.replaceAll('%ADTRK', adTrkURIVal));
          }
          setError(false);
        } else {
          log({ message: `Query "fetchFspContent" failed ${JSON.stringify({ page, duration })}`, level: 'error', requestId: uuid });
          setContentFetched(false);
          setError(true);
        }
      } catch (e) {
        log({ message: `Query "fetchFspContent" failed due to exception ${JSON.stringify({ page })}`, level: 'error', requestId: uuid });
        setError(true);
        console.error(e);
        setContentFetched(false);
      }
    }
    return true;
  };

  fetchContent();

  if (!content) {
    return null;
  }

  return (
    <div className="md:flex md:justify-center w-full pb-24 pt-8 px-4">
      <div className="max-w-screen-2xl">
        <div className="flex text-sm mb-4 flex-wrap">
          {
            links?.map((link: any, index: number) => (
              <div key={`disclaimer_line_${index}`}>
                <a href={link.url} target="_blank" rel="noreferrer" className="font-semibold underline-hover">{link.text}</a>
                { index < links.length - 1 && <span className="ml-4 mr-4">|</span> }
              </div>
            ))
          }
        </div>
        <div className="linebreak-thin" />
        <div className="flex text-sm mt-4">
          <a href={complaintUrl} target="_blank" rel="noreferrer" className="font-semibold underline-hover">{content?.complaint_text}</a>
        </div>
        <div className="w-full pt-4 pb-4">
          <img
            src="/images/footerLogo.svg"
            alt={content?.image_alt}
            width={140}
            height={33}
          />
        </div>
        <p className="text-sm">{copyright}</p>
        <div>
          {
            disclaimer?.split('\n').map((line: string, index: number) => (
              <div key={`disclaimer_line_${index}`} className={line === '' ? 'p-2' : 'text-left'}>
                <div className="text-sm" key={`disclaimer${index}`} dangerouslySetInnerHTML={{ __html: line }} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default OwningFooter;
