import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import { useState } from 'react';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';

interface IOptionsProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  percent: string;
  pageName: string;
  progress?: ProgressProps;
}

export const HelocOptionsPage = (props: IOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const options = props.content.radio_button.map((item: any) => ({
    name: 'options',
    label: item.option.label,
    value: item.option.value,
    active: false
  }));

  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      ${props.pageName}: "${event.target.value}"
    }`;
    await props.handleSubmit(pageData, false);
    setLoading(false);
  };

  const handleBack = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };

  return (
    <Form title={props.content.header} progress={props.progress}>
      { loading
        ? (
          <div className="min-h-[420px] flex items-center justify-center">
            <Loader color="#D13239" className="loader-medium" />
          </div>
        )
        : (
          <div className="min-h-[420px]">
            <RadioButtons className="radioButton" radios={options} onChange={handleSelect} />
            <div
              className="flex justify-center -ml-8 md:block md:ml-0"
              style={{ marginTop: '12rem' }}
            >
              <Button
                buttonStyle="quaternary"
                iconPos="left"
                iconName="chevron-left-large"
                onClick={handleBack}
                buttonAttrs={{ disabled: loading }}
              >Back
              </Button>
            </div>
          </div>
        )}
    </Form>
  );
};
