/* eslint-disable max-len */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Banner from '../../../components/Banner/Banner';
import { cleanObject, fetchFspPageContent, formatAsGraphQLPaylod, getCode, getTimeDiff, isAlphaNumeric, redirectToMyALogin, setCustomErrorBasedOnResponse } from '../../../config/util/common';
import { IConfirmAssetsData, IConfirmAssetsForm } from '../../../config/util/interfaces';
import { PreviousButton } from '../../../components/PreviousButton/PreviousButton';
import RateAlertLogo from '../../../components/RateAlertLogo/RateAlertLogo';
import { RateAlertWizardProgress } from '../../../components/WizardProgress/RateAlertWizardProgress';
import { log } from '../../../config/util/logger';
import ConfirmAssetsForm from './ConfirmAssetsForm';
import { generateLink, getNextPanelBasedOnState } from '../prefiCommon';
import { REDIRECT_TO_LOGIN_ERROR_CODE } from '../../../config/content/constants';

const getAssetDetailData = async (applicationId: string) => {
  const mutation = {
    query:
      `query {
        getAssetDetailData(applicationId: "${applicationId}") {
          borrowerOnly
          assets {
              accountType
              financialInstitution
              accountNumber
              amount
              owner
          }
      }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "getAssetDetailData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getAssetDetailData) {
      log({ message: `Query "getAssetDetailData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.getAssetDetailData };
    }
    log({ message: `Query "getAssetDetailData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "getAssetDetailData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const updateAssetDetailData = async (applicationId: string, assetDetailRequest: any) => {
  const mutation = {
    query:
      `mutation {
        updateAssetDetailData(applicationId: "${applicationId}", assetDetailRequest: ${assetDetailRequest}) {
          applicationType
            application {
                id
                tenant
                applicationType
                createdAt
                updatedAt
                state
                currentState
                currentDynamicState
                invitationNumber
                newLoanId
                oldLoanNumber
                oldLoanId
            }
        }
  }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Mutation "updateAssetDetailData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.updateAssetDetailData) {
      log({ message: `Mutation "updateAssetDetailData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.updateAssetDetailData };
    }
    log({ message: `Mutation "updateAssetDetailData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Mutation "updateAssetDetailData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const getInitialFormState = () => ({
  accountType: '',
  financialInstitution: '',
  accountNumber: '',
  amount: '',
  owner: '',
  isFormOpen: false,
});

function PrefiConfirmAssets(props: any) {
  const navigate = useNavigate();
  const [confirmAssetsInfo, setConfirmAssetsInfo] = useState<IConfirmAssetsData>();
  const [confirmAssetsInfoFetched, setConfirmAssetsInfoFetched] = useState(false);
  const [content, setContent] = useState(null as any);
  const [contentFetched, setContentFetched] = useState(false);
  const [confirmAssetsForm, setConfirmAssetsForm] = useState<Array<IConfirmAssetsForm>>([getInitialFormState()]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [isBorrowerOnly, setIsBorrowerOnly] = useState(false);
  const [tryContinue, setTryContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  if (!content && !contentFetched && !error) {
    fetchFspPageContent('"prefi_confirm_assets"').then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiConfirmAssets: fetchFspPageContent(): Error fetching page content. ${JSON.stringify({ page: 'prefi_other_income' })}`, level: 'error' });
          setError(true);
          setErrorMessage('Error fetching page content. Please Try again...');
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
        }
      }
    );
  }

  if (content && props.applicationId && !confirmAssetsInfo && !confirmAssetsInfoFetched && !error) {
    getAssetDetailData(props.applicationId).then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiConfirmAssets: getAssetDetailData(): Setting custom error based on response ${JSON.stringify({ data })}`, context: { applicationId: props.applicationId }, level: 'error' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          setConfirmAssetsInfoFetched(false);
          if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
            redirectToMyALogin();
          }
        } else {
          setError(false);
          setConfirmAssetsInfoFetched(true);
          setConfirmAssetsInfo(data.content);
          setIsBorrowerOnly(data.content.borrowerOnly);
          let currentAssetDetailData: Array<IConfirmAssetsForm> = [];
          data.content?.assets.forEach((asset: any) => {
            const eachAsset: IConfirmAssetsForm = {
              accountType: asset?.accountType || '',
              financialInstitution: asset?.financialInstitution || '',
              accountNumber: asset?.accountNumber || '',
              amount: asset?.amount?.toString() || '',
              owner: asset?.owner || '',
              isFormOpen: false,
            };
            currentAssetDetailData.push(eachAsset);
          });
          currentAssetDetailData.forEach((item: any) => {
            cleanObject(item);
          });
          setConfirmAssetsForm(currentAssetDetailData);
        }
      }
    );
  }

  const computeAccountTypesValue = (content: any) => (content?.account_types ? content?.account_types?.map((item: any) => ({
    displayName: item.option.label,
    value: item.option.value,
  }))
    : []);

  const computeOwnerTypesValue = (content: any) => (content?.owner_types ? content?.owner_types?.map((item: any) => ({
    displayName: item.option.label,
    value: item.option.value,
  }))
    : []);

  const accountTypes = useMemo(() => computeAccountTypesValue(content), [content]);
  const ownerTypes = useMemo(() => computeOwnerTypesValue(content), [content]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name: string, index: number) => {
    setError(false);
    if (name === 'accountNumber' && !(isAlphaNumeric(event.target.value)) && (event.target.value !== '')) {
      event.preventDefault();
    } else {
      setConfirmAssetsForm((currentAsset: Array<IConfirmAssetsForm>) => {
        let currentAssetData: Array<IConfirmAssetsForm | any> = [...currentAsset];
        currentAssetData[index][name] = event.target.value;
        return currentAssetData;
      });
    }
  };

  const handleAddAssets = () => {
    setConfirmAssetsForm((currentAsset: Array<IConfirmAssetsForm>) => {
      let currentAssetData = [...currentAsset];
      currentAssetData.push(getInitialFormState());
      return currentAssetData;
    });
    setError(false);
    handleToggle(confirmAssetsForm.length);
  };

  const handleDeleteAssets = (index: number) => {
    setConfirmAssetsForm((currentAsset: Array<IConfirmAssetsForm>) => {
      let currentAssetData = [...currentAsset];
      currentAssetData.splice(index, 1);
      if (confirmAssetsForm.length === 0) {
        setConfirmAssetsForm([]);
      }
      return currentAssetData;
    });
  };

  const handleToggle = (index: number) => {
    setConfirmAssetsForm((currentAsset: Array<IConfirmAssetsForm>) => {
      let currentAssetData = [...currentAsset];
      currentAssetData.forEach((formItem: any, formIndex: number) => {
        if (formIndex === index) {
          formItem.isFormOpen = !formItem.isFormOpen;
        } else {
          formItem.isFormOpen = false;
        }
      });
      return currentAssetData;
    });
    window.scrollTo(0, 0);
  };

  const hasError = (name: string, value: string) => {
    if (name.indexOf('accountNumber') > -1) {
      return (!isAlphaNumeric(value) && (value.length > 0) && (value.length <= 64));
    }
    const isError: any = tryContinue && !(value.length > 0);
    return isError;
  };

  const isValidForm = () => {
    let isValid = true;
    if (confirmAssetsForm.length > 0) {
      confirmAssetsForm.forEach((eachItemInform: IConfirmAssetsForm | any) => {
        for (let propName in eachItemInform) {
          if (isBorrowerOnly) {
            if ((propName !== 'owner') && (propName !== 'accountNumber') && (eachItemInform[propName] === null || eachItemInform[propName] === undefined || eachItemInform[propName] === '')) {
              isValid = false;
            }
          } else if ((propName !== 'accountNumber') && (eachItemInform[propName] === null || eachItemInform[propName] === undefined || eachItemInform[propName] === '')) {
            isValid = false;
          }
          if ((propName === 'accountNumber') && !isAlphaNumeric(eachItemInform[propName]) && (eachItemInform[propName].length > 0) && (eachItemInform[propName].length <= 64)) {
            isValid = false;
          }
        }
      });
    }
    return isValid;
  };

  const handleContinue = async () => {
    log({ message: 'PrefiConfirmAssets: handleContinue(): Handling continue', level: 'info' });
    setTryContinue(true);
    setError(false);
    if (confirmAssetsForm && isValidForm()) {
      log({ message: 'PrefiConfirmAssets: handleContinue(): Client validation passed', level: 'info' });
      setLoading(true);
      let requestBody: any = [];
      confirmAssetsForm.forEach((asset: IConfirmAssetsForm) => {
        const eachObject = {
          accountType: asset.accountType,
          financialInstitution: asset.financialInstitution,
          accountNumber: asset.accountNumber,
          amount: asset.amount,
          owner: asset.owner
        };
        requestBody.push(eachObject);
      });
      const updateAssetDetailRequest = formatAsGraphQLPaylod(requestBody);
      updateAssetDetailData(props.applicationId, updateAssetDetailRequest).then(
        (data: any) => {
          setLoading(false);
          if (data.content === 'error') {
            log({ message: `PrefiConfirmAssets: handleContinue(): updateAssetDetailData(): Setting custom error based on response ${JSON.stringify({ data })}`, level: 'info' });
            setError(true);
            setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
            if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
              redirectToMyALogin();
            }
          } else {
            setError(false);
            if (data.content?.application) {
              const nextPanel = getNextPanelBasedOnState(data);
              log({ message: `PrefiConfirmAssets: handleContinue(): updateAssetDetailData(): Success ${JSON.stringify({ nextPanel })}`, level: 'info' });
              navigate(generateLink(`/${nextPanel}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
              window.scrollTo(0, 0);
            }
          }
        }
      );
    } else {
      log({ message: 'PrefiConfirmAssets: handleContinue(): Invalid fields', level: 'info' });
      setError(true);
      setErrorMessage('Fill all required fields');
      window.scrollTo(0, 0);
    }
  };

  return (
    <div>
      {(content && confirmAssetsForm)
        ? (
          <div className="flex items-center justify-center">
            <div className="items-center justify-center w-full max-w-3xl px-1">
              <RateAlertWizardProgress className="lg:hidden px-4 mb-4" />
              <div className="items-center justify-center lg:mb-8">
                <div className="px-4 w-full">
                  {
                    error
                      ? (
                        <Banner className="border-2 mt-4 mb-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
                      )
                      : ''
                  }
                  <RateAlertLogo alt={content?.header} />
                </div>
              </div>
              <div className="px-1 lg:border border-solid border-slate-300 rounded-2xl bg-white">
                <RateAlertWizardProgress className="hidden lg:block px-4 mt-6" />
                <div className="text-xl font-black mt-6 px-4">{content?.header}</div>
                <div className="flex mb-8">
                  <div className="px-4">
                    <div className="mt-4">{(confirmAssetsForm?.length > 0 && confirmAssetsForm.filter((asset: any) => ((asset.owner === 'CoBorrower') || (asset.owner === 'Both')))?.length > 0) ? content?.coborrower_description : content?.description}</div>
                  </div>
                </div>
                {
                  (confirmAssetsForm?.length > 0)
                  && (confirmAssetsForm.map((eachFormItem: any, index: number) => (
                    <ConfirmAssetsForm
                      key={`asset${index}`}
                      content={content}
                      eachFormItem={eachFormItem}
                      index={index}
                      accountTypes={accountTypes}
                      ownerTypes={ownerTypes}
                      isBorrowerOnly={isBorrowerOnly}
                      updateHandleDeleteAssets={handleDeleteAssets}
                      updateHandleToggle={handleToggle}
                      updateHasError={hasError}
                      updateHandleChange={handleChange}
                    />
                  )))
                }
                <div className="flex items-start justify-start">
                  <div className="flex justify-start px-4">
                    <Button
                      buttonSize="large"
                      buttonStyle="tertiary"
                      iconName="plus-add"
                      iconPos="left"
                      className="!w-full md:!w-48"
                      onClick={handleAddAssets}
                    >
                      {content?.form?.add_income_button_label}
                    </Button>
                  </div>
                </div>
                <div className="px-4 mt-2 mb-8 flex justify-between">
                  <div className="flex justify-center mt-12">
                    <PreviousButton isDynamic={false} applicationId={props.applicationId} />
                  </div>
                  <div className="items-center justify-center">
                    <div className="px-4 mt-2 mb-8">
                      <div className="flex justify-center mt-12">
                        <Button
                          buttonStyle="primary"
                          className="!w-full md:!w-48 confirmButton"
                          onClick={handleContinue}
                          buttonAttrs={{ disabled: loading }}
                          loading={loading}
                        >{content?.form?.button_label}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : (
          <div className="px-4 w-full">
            {
              error
                ? (
                  <Banner className="border-2 mt-4 mb-4" text={errorMessage} title="Error" type="error" icon="warning-triangle" isMultiline={true} errorContent={props.errors.errorContent} />
                )
                : ''
            }
          </div>
        )}
    </div>
  );
}
export default PrefiConfirmAssets;
