import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import { MASKS } from '../../config/util/masks';
import { formatDate, loadDate } from '../../config/util/common';

interface ICalendarInputProps {
  className?: string;
  label: string;
  name: string;
  setForm: any;
  form: any;
  maxDate?: Date;
  minDate?: Date;
  hasError: any;
  helperText: string;
  required?: boolean;
  id?: string;
}

const CalendarInput = (props: ICalendarInputProps) => {
  const [dateObject, setDateObject] = useState(props.form[props.name] ? loadDate(props.form[props.name]) : null);
  const today = new Date();
  return (
    <DatePicker
      id={props.id}
      selected={dateObject}
      className={props.className || ''}
      onChange={(inputDate: Date) => {
        let date = inputDate;
        setDateObject(date);
        if (date) {
          props.setForm({ ...props.form, [props.name]: formatDate(date) });
        } else {
          props.setForm({ ...props.form, [props.name]: '' });
        }
      }}
      onKeyDown={(event: any) => {
        if (event.key === 'Backspace') {
          event.target.value = '';
          setDateObject(null);
          setDateObject(null);
          props.setForm({ ...props.form, [props.name]: '' });
        }
      }}
      showMonthDropdown={true}
      showYearDropdown={true}
      maxDate={props.maxDate || today}
      minDate={props.minDate}
      dropdownMode="select"
      required={props.required}
      customInput={(
        <TextInput
          name={props.name}
          value={props.form[props.name]}
          label={props.label}
          mask={MASKS.DATE}
          hasError={props.hasError}
          helperText={props.hasError && props.helperText}
        />
      )}
    />
  );
};

export default CalendarInput;
