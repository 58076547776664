import Button from '@guaranteed-rate/react-components/dist/Button';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import GoogleMapAddress from '../../../components/GoogleMapAddress/GoogleMapAddress';
import { IAffordabilityData, ILoData } from '../../../config/util/interfaces';
import PageLoader from '../../../components/PageLoader/PageLoader';
import { affordabilityPathMap } from '../AffordabilityPage';

interface ILocationProps {
  setAffordabilityData: Dispatch<SetStateAction<IAffordabilityData>>;
  updateProgress: (step: number, progress: ProgressProps) => void;
  handleSubmit: (isReload: boolean, page: string, data?: IAffordabilityData) => Promise<any>;
  affordabilityData: IAffordabilityData;
  progress: ProgressProps;
  content: any;
  loData: ILoData;
}

interface ILocationForm {
  city: string;
  zip: string;
  formattedAddress: string;
  unitNumber: string;
  state: string;
  county: string;
}

const loadExistingData = (data: IAffordabilityData): ILocationForm => {
  if (!data.city) {
    return {
      city: '',
      zip: '',
      formattedAddress: '',
      unitNumber: '',
      state: '',
      county: ''
    };
  }
  return {
    city: data?.city,
    zip: data?.zip,
    formattedAddress:
    data?.formattedAddress,
    unitNumber: data?.unitNumber,
    county: data?.county,
    state: data?.state };
};

export const AffordabilityLocationPage = ({
  content,
  progress,
  affordabilityData,
  loData,
  setAffordabilityData,
  handleSubmit,
  updateProgress,
}: ILocationProps) => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const [locationForm, setLocationForm] = useState(loadExistingData(affordabilityData));
  const [trySubmit, setTrySubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const onAddressChange = (address: any) => {
    setLocationForm({
      formattedAddress: address?.formattedAddress,
      unitNumber: address?.unitNumber,
      city: address?.city,
      zip: address?.zip,
      state: address?.state || '',
      county: address?.county || '',
    });
  };

  const handleClick = async () => {
    setTrySubmit(true);
    if (locationForm.zip !== '') {
      const updatedData = { ...affordabilityData, ...locationForm };
      setLoading(true);
      setAffordabilityData(updatedData);
      await handleSubmit(false, 'location', updatedData);
      setLoading(false);
    }
  };

  const handleBack = () => {
    updateProgress(progress.currentStep - 1, progress);
    if (loData) {
      navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
        ?? '/home-affordability/'}military/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    } else {
      navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
        ?? '/home-affordability/'}officer/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    }
  };

  return content ? (
    <Form title={content.header} progress={progress} formStyle="affordability">
      <div className="min-h-[300px]">
        <h4 className="mb-4 xl:mb-8">{content.description}</h4>
        <div className="flex justify-center">
          <div className="max-w-md grow">
            <GoogleMapAddress
              onChange={onAddressChange}
              value={locationForm.formattedAddress}
              addressObj={locationForm}
              label={content.city_label}
              type="postal_code"
              isAddress
              required={true}
              hasError={trySubmit && locationForm.zip === ''}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-12">
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-48 continue-btn"
          onClick={handleClick}
          loading={loading}
          disabled={loading}
          buttonAttrs={{ disabled: loading }}
        >Continue
        </Button>
      </div>
      <div className="flex -ml-8 md:ml-0 md:block justify-center mt-6 md:-mt-11">
        <Button buttonStyle="quaternary" iconPos="left" iconName="chevron-left-large" onClick={handleBack}>Back</Button>
      </div>
      <div className="text-center pt-12 ">
        <p>{content.location_description}</p>
        <p>{content.other_location_description}</p>
      </div>
    </Form>
  ) : <PageLoader />;
};
