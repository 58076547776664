/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { v4 as uuidv4 } from 'uuid';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { useEffect, useState } from 'react';
import { useScript } from '../../../config/hooks/useScript';

export const BankAccountTest = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const [sdkLoaded, setSdkLoaded] = useState(false);

  const onScriptLoad = () => {
    if (!sdkLoaded) {
      setSdkLoaded(true);
    }
  };

  const launchFinicity = () => {
    if (sdkLoaded) {
      (window as any).finicityConnect.launch(urlParams.get('token'), {
        selector: '#connect-container',
        overlay: 'rgba(255,255,255, 0)',
        success: async (event: any) => {
          setLoading(true);
          window.scrollTo(0, 0);
          submitIOEOrder();
        },
        cancel: (event: any) => {
          // set error true
          console.log('The user cancelled the iframe', event);
        },
        error: (error: any) => {
          // set error true
          console.error('Some runtime error was generated during insideConnect ', error);
        },
        loaded: () => {
          console.log('This gets called only once after the iframe has finished loading ');
        },
        route: (event: any) => {
          console.log('This is called as the user navigates through Connect ', event);
        },
        user: (event: any) => {
          console.log('This is called as the user interacts with Connect ', event);
        }
      });
    }
  };

  const submitIOEOrder = async () => {
    setLoading(true);
    const mutation = {
      query:
        `mutation {
          submitIOEOrder(
            id: "${urlParams.get('id')}",
            orderId: "${urlParams.get('orderId')}",
            data: {
              product: "voai",
            }
          )
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      console.log(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useScript('https://connect2.finicity.com/assets/sdk/finicity-connect.min.js', false, onScriptLoad);

  useEffect(() => {
    if (sdkLoaded) {
      launchFinicity();
    }
  }, [sdkLoaded]);

  return (
    <div>
      {
        !sdkLoaded || loading
          ? (
            <div className="min-h-[420px] flex items-center justify-center">
              <Loader color="#D13239" className="loader-medium" />
            </div>
          )
          : (
            <div>
              <div className="min-h-[420px]">
                <div id="connect-container" className="w-full h-[880px]" />
              </div>
            </div>
          )
      }
    </div>
  );
};
