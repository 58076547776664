import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';

interface ICircleProps {
  status: 'neutral' | 'success' | 'error';
}

const colorClass = {
  neutral: 'bg-gray-500 border-gray-500',
  success: 'bg-green-600 border-green-600',
  error: 'bg-red-600 border-red-600'
};

const CheckCircle = (props: ICircleProps) => (
  <div className={`rounded-xl border w-[20px] h-[20px] ${colorClass[props.status]} text-white text-bold text-lg `}>
    { props.status !== 'neutral'
    && <FontIcon className="!leading-[1.1875rem]" iconName={props.status === 'success' ? 'check-tick' : 'close-small'} /> }
  </div>
);

export default CheckCircle;
