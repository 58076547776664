import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { MASKS } from '../../../config/util/masks';
import { IAffordabilityData } from '../../../config/util/interfaces';
import PageLoader from '../../../components/PageLoader/PageLoader';
import { affordabilityPathMap } from '../AffordabilityPage';

interface IDownPaymentProps {
  setAffordabilityData: Dispatch<SetStateAction<IAffordabilityData>>;
  updateProgress: (step: number, progressContent: ProgressProps) => void;
  affordabilityData: IAffordabilityData;
  progress: ProgressProps;
  content: any;
}

interface IDownPaymentForm {
  cash: string;
  equity: string;
  isHomeOwner: boolean;
}

const loadExistingData = (data: IAffordabilityData): IDownPaymentForm => {
  if (!data?.cash && !data?.equity && !data?.isHomeOwner) {
    return {
      cash: '',
      equity: '',
      isHomeOwner: false,
    };
  }
  return { cash: data?.cash, equity: data?.equity, isHomeOwner: data?.isHomeOwner };
};

export const AffordabilityDownPaymentPage = ({
  progress,
  affordabilityData,
  content,
  setAffordabilityData,
  updateProgress,
}: IDownPaymentProps) => {
  const navigate = useNavigate();
  const [downPaymentForm, setDownPaymentForm] = useState(loadExistingData(affordabilityData));
  const [trySubmit, setTrySubmit] = useState(false);
  const [checked, setChecked] = useState(downPaymentForm.isHomeOwner);
  const urlParams = new URLSearchParams(window.location.search);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setDownPaymentForm({ ...downPaymentForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    if (!trySubmit) {
      setTrySubmit(true);
    }
    if ((downPaymentForm.cash === '' ? 0 : parseInt(downPaymentForm.cash) > 0)) {
      setAffordabilityData({ ...affordabilityData, ...downPaymentForm });
      updateProgress(progress.currentStep + 1, progress);
      navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
        ?? '/home-affordability/'}creditScore/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    }
  };

  const handleBack = () => {
    navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
      ?? '/home-affordability/'}debt/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    updateProgress(progress.currentStep - 1, progress);
  };

  const hasError = () => trySubmit && (downPaymentForm.cash === '' ? 0 : parseInt(downPaymentForm.cash)) <= 0;

  return content ? (
    <Form title={content.header} progress={progress} formStyle="affordability">
      <div className="min-h-[300px]">
        <h4 className="mb-4 xl:mb-8">{content.description}</h4>
        <div className="flex justify-center">
          <div className="max-w-md grow">
            <TextInput
              name="cash"
              value={downPaymentForm.cash}
              label={content.available_cash_label}
              onChange={(input) => handleChange(input, 'cash')}
              mask={MASKS.CURRENCY}
              hasError={hasError()}
              helperText={hasError() ? 'required' : undefined}
              required={true}
            />
            <div className="flex flex-row mb-4 ml-1">
              <input
                type="checkbox"
                className="checkbox"
                id="ownHome"
                checked={checked}
                onChange={() => {
                  setChecked(!checked);
                  setDownPaymentForm({ ...downPaymentForm, isHomeOwner: !checked, equity: '' });
                }}
              />
              <h4>{content.equity_checkbox_label}</h4>
            </div>
            {checked && (
              <div>
                <TextInput
                  name="equity"
                  value={downPaymentForm.equity}
                  label={content.equity_input_label}
                  onChange={(input) => handleChange(input, 'equity')}
                  mask={MASKS.CURRENCY}
                />
                <p className="-mt-4 text-xs">{content.equity_subtext}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-12">
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-48 continue-btn"
          onClick={handleSubmit}
        >Continue
        </Button>
      </div>
      <div className="flex -ml-8 md:ml-0 md:block justify-center mt-6 md:-mt-11">
        <Button buttonStyle="quaternary" iconPos="left" iconName="chevron-left-large" onClick={handleBack}>Back</Button>
      </div>
      <div className="text-center pt-12 ">
        <p>{content.downPayment_description}</p>
        <p>{content.other_downPayment_description}</p>
      </div>
    </Form>
  ) : <PageLoader />;
};
