import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons, {
  useRadio,
} from '@guaranteed-rate/react-components/dist/RadioButtons';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import { IPropertyResponse } from '../../../config/util/interfaces';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { MASKS } from '../../../config/util/masks';
import Form from '../../../components/Form/Form';
import DocumentUpload from '../../../components/DocumentUpload/DocumentUpload';
import useS3Upload from '../../../config/hooks/useS3Upload';
import Alert from '../../../components/Alert/Alert';

interface IAnnualProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  propertyData: IPropertyResponse;
  progress?: ProgressProps;
  applicationId: string;
}

interface IAnnualForm {
  annualTaxes: string;
  annualInsurance: string;
}

const options = [
  {
    name: 'yes',
    label: 'Yes',
    value: 'yes',
    active: false
  },
  {
    name: 'no',
    label: 'No',
    value: 'no',
    active: false
  },
];

enum uploadFieldName {
  MORTGAGE_STATEMENT = 'mortgageStatement',
  HOME_INSURANCE = 'homeInsurance'
}

// load previously entered taxes and insurance if available
const loadAnnualData = (propertyData: IPropertyResponse) => {
  if (propertyData) {
    return {
      annualTaxes: propertyData?.annualTaxes?.toString() || '',
      annualInsurance: propertyData?.annualInsurance?.toString() || ''
    } as IAnnualForm;
  }
  return {
    annualTaxes: '',
    annualInsurance: '',
  } as IAnnualForm;
};

export const HelocAnnualPage = (props: IAnnualProps) => {
  const { uploadFileToS3, fetchError } = useS3Upload(props.applicationId, 'TAX_INSURANCE', 2);
  const [noClicked, setNoClicked] = useState(false);
  const [annualForm, setAnnualForm] = useState(loadAnnualData(props.propertyData)); // this is the insurance and taxes form
  const [trySubmit, setTrySubmit] = useState(false);
  const [showUploadDocs, setShowUploadDocs] = useState(false);
  const [loading, setLoading] = useState(false);
  const { updateCheckedValue, isChecked } = useRadio();
  const [mortgageStatementFile, setMortgageStatementFile] = useState({} as any);
  const [homeInsuranceFile, setHomeInsuranceFile] = useState({} as any);
  const [mortgageStatementError, setMortgageStatementError] = useState('');
  const [insuranceError, setInsuranceError] = useState('');
  const [annualFormError, setAnnualFormError] = useState('');
  const {
    home_insurance_label,
    mortgage_statement_label,
    sub_title: annual_payments_declaration_sub_title,
    title: annual_payments_declaration_title,
  } = props.content.annual_payments_declaration;
  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // Here we pass in the page name as the key for the value selected from the radio button
    if (event.target.value === options[1].value) {
      setNoClicked(true);
      setShowUploadDocs(false);
    } else {
      const { annualTaxes, annualInsurance } = props.propertyData;
      setNoClicked(false);
      setAnnualForm({
        ...annualForm,
        annualTaxes: annualTaxes ? annualTaxes.toString() : '0',
        annualInsurance: annualInsurance ? annualInsurance.toString() : '0'
      });
      setShowUploadDocs(true);
    }
  };

  const validateUploadedFile = (uploadedFile: any): string => {
    const fileSizeInMB = uploadedFile.size / 1024 / 1024;
    const sizeInMB = Math.ceil(fileSizeInMB);
    if (!props?.content?.accepted_file_types.includes(uploadedFile.type)) {
      return props?.content?.file_type_error_label;
    }
    if (sizeInMB > 10) {
      return props?.content?.size_exceeded_label;
    }
    return '';
  };

  const handleSubmit = async () => {
    const mortgageErrorMsg = validateUploadedFile(mortgageStatementFile);
    const insuranceErrorMsg = validateUploadedFile(homeInsuranceFile);
    if (mortgageErrorMsg || insuranceErrorMsg) {
      setMortgageStatementError(mortgageErrorMsg);
      setInsuranceError(insuranceErrorMsg);
    } else {
      setLoading(true);
      setTrySubmit(true);
      let uploadedFileDetails: any = [];
      try {
        uploadedFileDetails = await uploadFileToS3([mortgageStatementFile, homeInsuranceFile]);
      } catch (error) {
        setLoading(false);
        setAnnualFormError(props?.content?.upload_error_label);
        return;
      }
      setMortgageStatementError('');
      setInsuranceError('');
      try {
        const pageData = `{ 
          page: "${props.pageName}"
          annualPaymentData: {
            annualTaxes: "${annualForm.annualTaxes}"
            annualInsurance: "${annualForm.annualInsurance}"
            escrowTaxesInsurance: ${!noClicked}
            taxDocumentUuid: "${uploadedFileDetails[0]?.uuid}",
            taxDocumentName: "${uploadedFileDetails[0]?.fileName}",
            insuranceDocumentUuid: "${uploadedFileDetails[1]?.uuid}",
            insuranceDocumentName: "${uploadedFileDetails[1]?.fileName}",
          }
        }`;
        await props.handleSubmit(pageData, false);
      } catch (e) {
        setAnnualFormError(props?.content?.upload_error_label);
      } finally {
        setLoading(false);
      }
    }
  };

  const onUploadFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length && event.target.name === uploadFieldName.MORTGAGE_STATEMENT) {
      setMortgageStatementError('');
      setMortgageStatementFile(event.target.files[0]);
    } else if (event.target.files?.length) {
      setInsuranceError('');
      setHomeInsuranceFile(event.target.files[0]);
    }
    setAnnualFormError('');
  };

  // go to previous screen
  const handleBack = async () => {
    setShowUploadDocs(false);
  };

  // used to update the taxes and insurance data
  const handleAnnualChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setAnnualForm({ ...annualForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  const hasError = (name: string) => trySubmit && annualForm[name as keyof IAnnualForm]?.length === 0;

  return (
    <Form
      title={
        showUploadDocs
          ? annual_payments_declaration_title
          : props.content.prompt_header
      }
      progress={props.progress}
    >
      {fetchError && (
        <div className="mb-4">
          <Alert text={props.content.file_upload_error} type="error" icon="warning-triangle" />
        </div>
      )}
      {!showUploadDocs ? (
        <div className="min-h-[300px]">
          <RadioButtons
            className="radioButton"
            radios={options}
            onChange={handleSelect}
            updateCheckedValue={updateCheckedValue}
            isChecked={isChecked}
          />
          {noClicked && (
            <>
              <h4 className="my-4">{props.content.description}</h4>
              <TextInput
                name="annualTaxes"
                className="grow"
                value={annualForm.annualTaxes}
                label={props.content.annual_taxes_label}
                onChange={(input) => handleAnnualChange(input, 'annualTaxes')}
                mask={MASKS.CURRENCY}
                hasError={hasError('annualTaxes')}
                helperText={hasError('annualTaxes') ? 'required' : undefined}
                required={true}
              />
              <TextInput
                name="annualInsurance"
                value={annualForm.annualInsurance}
                className="grow"
                label={props.content.annual_insurance_label}
                onChange={(input) => handleAnnualChange(input, 'annualInsurance')}
                mask={MASKS.CURRENCY}
                hasError={hasError('annualInsurance')}
                helperText={
                  hasError('annualInsurance') ? 'required' : undefined
                }
                required={true}
              />
              <div className="md:flex justify-center mt-12 md:space-x-4">
                <Button
                  buttonStyle="primary"
                  className="!w-full md:!w-60 next-button"
                  onClick={() => setShowUploadDocs(true)}
                  loading={loading}
                  buttonAttrs={{ disabled: Number(annualForm.annualInsurance) <= 0 || Number(annualForm.annualTaxes) <= 0 }}
                >
                  Next
                </Button>
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <h4 className="mb-4">{annual_payments_declaration_sub_title}</h4>
          <DocumentUpload
            name={uploadFieldName.MORTGAGE_STATEMENT}
            label={mortgage_statement_label}
            uploadedFile={mortgageStatementFile}
            loading={loading}
            uploadError={mortgageStatementError}
            isMultiUpload={false}
            onFileChangeCallback={onUploadFileChange}
            onDeleteFileCallback={() => setMortgageStatementFile({})}
          />
          <DocumentUpload
            name={uploadFieldName.HOME_INSURANCE}
            label={home_insurance_label}
            uploadedFile={homeInsuranceFile}
            loading={loading}
            uploadError={insuranceError}
            isMultiUpload={false}
            onFileChangeCallback={onUploadFileChange}
            onDeleteFileCallback={() => setHomeInsuranceFile({})}
          />
          {annualFormError && (
            <span className="paystub error">{annualFormError}</span>
          )}
          <div>
            <div className="flex justify-center mt-4">
              <Button
                buttonStyle="primary"
                className="!w-full md:!w-60 next-button"
                onClick={handleSubmit}
                loading={loading}
                buttonAttrs={{
                  disabled:
                    loading
                    || !mortgageStatementFile.name
                    || !homeInsuranceFile.name
                    || mortgageStatementError
                    || insuranceError
                    || annualFormError
                }}
              >
                Next
              </Button>
            </div>
            <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
              <Button
                buttonStyle="quaternary"
                iconPos="left"
                iconName="chevron-left-large"
                onClick={handleBack}
                buttonAttrs={{ disabled: loading }}
              >Back
              </Button>
            </div>
          </div>
        </>
      )}
    </Form>
  );
};
