import { useEffect, useState } from 'react';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import Logo from '@guaranteed-rate/react-components/dist/Logo';
// eslint-disable-next-line import/no-unresolved
import CobrowseIO from 'cobrowse-sdk-js';
import Modal from '@guaranteed-rate/react-components/dist/Modal';
import { config } from '../../config/content/config';

const OwningHeader = () => {
  const [coBrowseCode, setCoBrowseCode] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const tenant = (window as any).tenantId;
  const env = (window as any).env;
  const coBrowseIOConfig = config.coBrowseIO[tenant as keyof typeof config.coBrowseIO];

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  useEffect(() => {
    CobrowseIO.license = config.coBrowseIO.license[env as keyof typeof coBrowseIOConfig];
    CobrowseIO.redactedViews = config.coBrowseIO.redactedViews[env as keyof typeof coBrowseIOConfig];
    CobrowseIO.on('session.updated', (session: any) => {
      console.log('A session was loaded', session);
      if (session.isActive()) {
        setModalOpen(false);
      }
    });
  }, []);

  const handleScreenShare = async () => {
    console.log('Handle Screen Share');
    CobrowseIO.client().then(() => {
      CobrowseIO.start({ register: false });
      CobrowseIO.createSessionCode().then((code: any) => {
        console.log('your code is', code);
        setCoBrowseCode(code);
        setModalOpen(true);
      });
    });
  };
  return (
    <div>
      <div className="owningTopBarOne" />
      <div className="owningTopBarTwo" />
      <div className="flex w-full p-8">
        <div className="z-10 flex justify-between w-full">
          <Logo
            url="http://www.owning.com"
            src="/images/owningLogo.svg"
            alt="Owning"
            width={140}
            height={33}
          />
          <div>
            <button className="font-bold text-md" onClick={handleScreenShare}>
              <FontIcon iconName="presentation" className="mr-1 !leading-8 text-lg" />
              <span className="mr-4 hidden md:inline">Share screen</span>
            </button>
            <a href="tel:8335331682" className="font-bold text-md">
              <span className="mr-4 hidden md:inline">Connect now</span>
              <FontIcon iconName="phone" className="mr-1 !leading-8 text-lg" />
              <span>(833) 533-1682</span>
            </a>
          </div>
        </div>
      </div>
      <Modal title="" isOpen={isModalOpen} toggleModal={toggleModal} clickOffToClose={true} className="co-browse-modal">
        <div>
          <h2 className="text-[32px] md:text-[32px] font-extrabold">Your Code is {coBrowseCode}.</h2>
          <p className="text-sm pt-4">Share this code with a loan team member to work together.</p>
        </div>
      </Modal>
    </div>
  );
};

export default OwningHeader;
