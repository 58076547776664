export const config = {
  googleMapsApiURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCVarbPudKoN48-Id5OSc2Wvcf1LHiu-HQ&loading=async&libraries=places',
  adobeLaunch: {
    owning: {
      dev: 'https://assets.adobedtm.com/dc28b7578231/e841f8f2e0f8/launch-53b1a17acef3-development.min.js',
      stage: 'https://assets.adobedtm.com/dc28b7578231/e841f8f2e0f8/launch-5587b32244f4-staging.min.js',
      qa: 'https://assets.adobedtm.com/dc28b7578231/e841f8f2e0f8/launch-5587b32244f4-staging.min.js',
      prod: 'https://assets.adobedtm.com/dc28b7578231/e841f8f2e0f8/launch-dd8a2b782099.min.js',
    },
    gri: {
      dev: 'https://assets.adobedtm.com/dc28b7578231/1ca2c647e075/launch-b1c63687b184-development.min.js',
      stage: 'https://assets.adobedtm.com/dc28b7578231/1ca2c647e075/launch-1c8d67199b33-staging.min.js',
      qa: 'https://assets.adobedtm.com/dc28b7578231/1ca2c647e075/launch-1c8d67199b33-staging.min.js',
      prod: 'https://assets.adobedtm.com/dc28b7578231/1ca2c647e075/launch-af36fc3ec290.min.js',
    },
    gra: {
      dev: 'https://assets.adobedtm.com/dc28b7578231/434b35f46f30/launch-bf041c995bb5-development.min.js',
      stage: 'https://assets.adobedtm.com/dc28b7578231/434b35f46f30/launch-e568a8152bbf-staging.min.js',
      qa: 'https://assets.adobedtm.com/dc28b7578231/434b35f46f30/launch-e568a8152bbf-staging.min.js',
      prod: 'https://assets.adobedtm.com/dc28b7578231/434b35f46f30/launch-e568a8152bbf-staging.min.js',
    }
  },
  coBrowseIO: {
    license: {
      dev: 'dJA2B626497pFA',
      stage: 'ZxryHABCIC2_qg',
      qa: 'dJA2B626497pFA',
      prod: 'ZxryHABCIC2_qg'
    },
    redactedViews: {
      dev: ['.cbredacted'],
      stage: ['.cbredacted'],
      qa: ['.cbredacted'],
      prod: ['.cbredacted']
    }
  },
  acuantConfig: {
    path: '/static/acuantSdk/',
  },
  threatMetrixConfig: {
    path: '/static/threatMetrix/'
  },
  myAccountUrl: {
    dev: 'https://my.gr-dev.com',
    qa: 'https://my.gr-dev.com',
    stage: 'https://my.rate.com',
    prod: 'https://my.rate.com'
  },
  helocLiteUrls: ['home-equity-apply.rate.com', 'home-equity-apply-stage.rate.com'],
  homeAffordabilityUrls: ['homeaffordability.rate.com', 'homeaffordability-stage.rate.com'],
  prefiUrls: ['myapp.rate.com', 'myapp-stage.rate.com']
};
