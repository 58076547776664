/* eslint-disable max-len */
import Cookies from 'js-cookie';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { footerContent } from '../../config/content/footer';
import { helocProgressList, helocSteps } from '../../config/content/progressList';
import { ProgressProps } from '../../components/ProgressList/IProgressList';
import { HelocPropertyPage } from './HelocPropertyPage/HelocPropertyPage';
import { HelocOptionsPage } from './HelocOptionsPage/HelocOptionsPage';
import { HelocPersonalPage } from './HelocPersonalPage/HelocPersonalPage';
import { HelocErrorPage } from './HelocErrorPage/HelocErrorPage';
import { HelocIncomePage } from './HelocIncomePage/HelocIncomePage';
import { HelocMortgagePage } from './HelocMortgagePage/HelocMortgagePage';
import { HelocPricingPage } from './HelocPricingPage/HelocPricingPage';
import { HelocAdditionalQuestions } from './HelocAdditionalQuestions/HelocAdditionalQuestions';
import { HelocFinalQuestions } from './HelocFinalQuestions/HelocFinalQuestions';
import { HelocSSNPage } from './HelocSSNPage/HelocSSNPage';
import { HelocIncomeVerification } from './HelocVerifyPage/HelocIncomeVerification';
import { ILoData, IAdditionalInfo, IErrorData, IErrorObject, ISupportNavLoData, IThreatMetrixData } from '../../config/util/interfaces';
import { SECURED_PAGES } from '../../config/util/pages';
import { getSupportNavigationContent } from '../../config/content/supportNavigation';
import { fetchLoanOfficers, getLoCookie, logoutHeloc } from '../../config/util/common';
import { HelocVerifiedPage } from './HelocVerifyPage/HelocVerifiedPage';
import { HelocAdditionalOptionPage } from './HelocAdditionalQuestions/HelocAdditionalOptionPage';
import { HelocBankPage } from './HelocBankingPages/HelocBankPage';
import { HelocLoanSelectedPage } from './HelocFinalLoan/HelocLoanSelected';
import { HelocInitialDisclosures } from './HelocClosing/HelocInitialDisclosures';
import { HelocSchedulingPage } from './HelocClosing/HelocSchedulingPage';
import { HelocDashboardPage } from './HelocDashboardPage/HelocDashboardPage';
import LoanOfficerBanner from '../../components/SupportNavigation/LoanOfficerBanner';
import { HelocManualUpload } from './HelocVerifyPage/HelocManualUpload';
import { HelocApplicationsPage } from './HelocAccountPage/HelocApplications';
import { helocGraphQLRequest } from '../../config/content/constants';
import { HelocCompletePage } from './HelocCompletePage/HelocCompletePage';
import { HelocIdentifierPage } from './HelocIdentifierPage/HelocIdentifierPage';
import HelocManualReview from './HelocManualReview/HelocManualReview';
import { HelocAnnualPage } from './HelocPropertyPage/HelocAnnualPage';
import { HelocFullIncomePage } from './HelocIncomePage/HelocFullIncomePage';
import HelocReviewPage from './HelocManualReview/HelocReviewPage';

const updateProgress = (page: string, progressContent: ProgressProps) => {
  const step = Object.keys(helocSteps).includes(page) ? helocSteps[page as keyof typeof helocSteps] : 0;
  progressContent.currentStep = step;
  progressContent.steps.map((value, index) => {
    if (index < step) {
      value.active = false;
      value.complete = true;
    } else if (index === step) {
      value.active = true;
      value.complete = false;
    } else {
      value.active = false;
      value.complete = false;
    }
  });
};

export const HelocPage = () => {
  const navigate = useNavigate();
  const oktaTokens = Cookies.get('_uetsid') || Cookies.get('_uetvid');
  const [globalLoading, setGlobalLoading] = useState(false);
  const [content, setContent] = useState([] as Array<string>);
  const [supportNavContent, setSupportNavContent] = useState<ISupportNavLoData>();
  const [page, setPage] = useState('');
  const [pageData, setPageData] = useState('' as any);
  const [params] = useSearchParams();
  const [applicationId, setApplicationId] = useState(params.get('id') || undefined);
  const [loData, setLoData] = useState(null as unknown as ILoData);
  const [fetchLoData, setFetchLoData] = useState(false);
  const [fetchPageData, setFetchPageData] = useState(false);
  const [fetchUserData, setFetchUserData] = useState(false);
  const [loanOfficers, setLoanOfficers] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState(newAdditionalInfo());
  const [threatMetrix, setThreatMetrix] = useState('');
  const [loId, setLoId] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [authData, setAuthData] = useState(null as any);
  const [showNameMismatchWarning, setShowNameMismatchWarning] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>('');
  const [isManualReview, setIsManualReview] = useState(false);
  const progressContent: ProgressProps = helocProgressList;
  const hideLogin = fetchUserData || ((authToken && authToken !== '') && !authData) || window.location.pathname.indexOf('applications') !== -1;

  // this sets the loan officer data for the application
  const setLo = async (loId: string) => {
    setLoId(loId);
    setFetchLoData(true);

    // fetch the loan officer data based on the loId
    const mutation = {
      query:
        `{
          fetchLoanOfficer(employeeId: "${loId}") {
            name
            id
            title
            subTitle
            email
            phone
            avatarUrl
          }
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.fetchLoanOfficer) {
        setLoData(data.data.fetchLoanOfficer);
      }
    } catch (e) {
      console.error(e);
      setFetchLoData(false);
      return e;
    }
  };

  const fetchAuthData = async (token: any) => {
    setFetchUserData(true);
    const mutation = {
      query:
        `{
          fetchBorrower{
            name {
              first
              middle
              last
            }
            contact {
              name
              id
              email
              phone
              mobile
              avatarUrl
            }
          }
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.fetchBorrower) {
        setAuthData(data.data.fetchBorrower);
      }
      setFetchUserData(false);
    } catch (e) {
      console.error(e);
      setFetchUserData(false);
      return e;
    }
  };
  /**
   * @param pageData the data to submit to gateway
   * @param back used for navigating backwards
   * @param timeout how many times the request has been repeated due to a timeout
   * @returns the page to navigate to and the data for that page
   */
  const submitPage: any = async (requestData: string, back: boolean, timeout: number) => {
    setFetchPageData(true);
    let token = authToken;
    token = Cookies.get('authToken') || '';
    setAuthToken(token);
    if (token !== '' && authData === null && !fetchUserData) {
      fetchAuthData(token);
    }
    // capture the url params so we can attach them to navigate
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('code')) {
      urlParams.delete('code');
    }

    if (errMessage) {
      setErrMessage('');
    }

    // pass in the requestData in the graphQL request.
    // here we select which data we want to recieve from graphQL
    const mutation = {
      query:
        `mutation {
          updateHelocData(data: ${requestData}, id: "${applicationId || ''}", borrowerId: "${pageData?.basicInfoData?.borrowerEntityId || ''}", sub: "${pageData?.basicInfoData?.emailId || ''}") ${helocGraphQLRequest}
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
        Authorization: token,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      setFetchPageData(false);
      if (data?.data?.updateHelocData) {
        window.scrollTo(0, 0);
        if (data.data.updateHelocData.pageContentJson !== '' && data.data.updateHelocData.pageContentJson) {
          const contentData = [] as Array<string>;
          data.data.updateHelocData.pageContentJson.map((entry: string) => contentData.push(JSON.parse(entry)));
          setContent(contentData);
        }
        if (!applicationId && data.data.updateHelocData.id) {
          setApplicationId(data.data.updateHelocData.id);
          urlParams.append('id', data.data.updateHelocData.id);
        }
        if (data?.data?.updateHelocData.logoutRequired) {
          setFetchPageData(true);
          setPage('');
          setPageData('');
          if (Cookies.get('JSESSIONID')) {
            Cookies.remove('JSESSIONID');
          }
          setGlobalLoading(true);
          logoutHeloc();
          return '';
        }
        if (data?.data?.updateHelocData.authRequired || (SECURED_PAGES.indexOf(data.data.updateHelocData.page) !== -1 && token === '')) {
          setFetchPageData(true);
          setPage('');
          setPageData('');
          if (Cookies.get('JSESSIONID')) {
            Cookies.remove('JSESSIONID');
          }
          setGlobalLoading(true);
          window.location.href = `${window.location.origin}/gateway/authorize-heloc-user?tenant-id=${(window as any).tenantId.toLowerCase()}&id=`
            + `${applicationId || data.data.updateHelocData.id}${data.data.updateHelocData.basicInfoData ? `&email=${data.data.updateHelocData.basicInfoData.emailId}` : ''}`;
          return '';
        }
        setPage(data.data.updateHelocData.page);
        setPageData(data?.data?.updateHelocData);
        if (data?.data?.updateHelocData.status === 'DECLINED') {
          urlParams.set('code', '10014');
          navigate(`/heloc/error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
          return '';
        }
        if (data?.data?.updateHelocData.status === 'EXPIRED') {
          urlParams.set('code', '10016');
          navigate(`/heloc/error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
          return '';
        }
        if (data.data.updateHelocData.additionalData) {
          const propertyData = data.data.updateHelocData.propertyData;
          const basicData = data.data.updateHelocData.basicInfoData;
          data.data.updateHelocData.additionalData = {
            ...data.data.updateHelocData.additionalData,
            ownershipType: propertyData.ownershipType,
            trustName: propertyData.trustName,
            maritalStatus: basicData.maritalStatus,
            gender: basicData.gender,
            ethnicity: basicData.ethnicity,
            ethnicityDetail: basicData.ethnicityDetail,
            race: basicData.race,
            asianDetail: basicData.asianDetail,
            islanderDetail: basicData.islanderDetail,
            citizenStatus: basicData.citizenStatus
          };
          setAdditionalInfo(data.data.updateHelocData.additionalData);
        }
        updateProgress(data.data.updateHelocData.page, progressContent);
        navigate(`/heloc/${data.data.updateHelocData.page}/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
        return data?.data?.updateHelocData || pageData;
      } if (data.errors[0].message.indexOf('timeout') !== -1) {
        const pageData = `{ page: "${page}"
                        ${back ? 'back: true' : ''}}`;
        const newData = await submitPage(pageData, back, timeout);
        return newData;
      }
      // if there is an error, we want to show the error page.
      if (data.errors[0].message.indexOf('Exception from downstream FSP service. ') !== -1) {
        const errorStatus = JSON.parse(data.errors[0].message.substring(data.errors[0].message.indexOf('.') + 2)) as IErrorObject;
        // PROPERTY_OWNER_NAMES_NOT_MATCH
        if (Number(errorStatus?.status) === 10019) {
          setShowNameMismatchWarning(true);
          return;
        }
        // fetch the error code form the error message
        const errorData = {
          error: errorStatus,
          page,
          pageData: requestData
        } as IErrorData;
        setPageData(errorData);
        setFetchPageData(false);
        setPage('error');
        window.scrollTo(0, 0);
        navigate(`/heloc/error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
        return data;
      }

      if (data?.errors[0].message) {
        setErrMessage(data?.errors[0].message);
      }

      // load the generic error handler for the page
      window.scrollTo(0, 0);
      setFetchPageData(false);
      setPage('error');
      setPageData({
        error: {} as IErrorObject,
        page,
        pageData: requestData
      });
      navigate(`/heloc/error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
      return data;
    } catch (e) {
      // if there is a timeout, then try again until 3 total tries
      if (resp.status === 504 && timeout < 3) {
        const tryAgainData = await submitPage(pageData, back, timeout + 1);
        return tryAgainData;
      }

      // load the generic error handler for the page
      window.scrollTo(0, 0);
      setFetchPageData(false);
      setPage('error');
      setPageData({
        error: {} as IErrorObject,
        page,
        pageData: requestData
      });
      navigate(`/heloc/error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
      return e;
    }
  };

  const handleApplyAgain = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('id');
    urlParams.delete('code');
    window.location.search = urlParams.toString();
    setApplicationId(undefined);
    await handlePageSubmit('{ page: "" }', false);
  };

  const handlePageSubmit = async (pageData: string, back: boolean) => submitPage(pageData, back, 0);

  const useWiderView = () => pageData.page === 'documents' || pageData.page === 'scheduleClosing';
  /**
   * @param loanAmount the amount of money that the user is requesting
   * @param loanType whether its QUALIFY or FINAL
   * @param autoPay whether the autoPay checkbox is checked
   * @param creditUnion whether the credit union checkbox is checked
   * @param setLoading pass in the components loading function so we can pause the application
   * @returns pricing data for the requested loan amount
   */
  const refreshPricing = async (loanAmount: number, loanType: string, autoPay: boolean, creditUnion: boolean, setLoading: any) => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const mutation = {
      query:
        `{
          refreshHelocPricing(applicationId: "${applicationId}", loanAmount: ${loanAmount}, loanType: "${loanType}", autoPay: ${autoPay}, creditUnion: ${creditUnion}) {
            options {
              fee
              terms {
                term
                apr
                estimatedMonthlyPayment
                pricingTermId
                loanAmount
              }
            }
            maximumAmount
            loanAmount
            autoPay
            creditUnion
          }
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.refreshHelocPricing) {
        // update the existing pageData with the new pricing data
        setPageData({ ...pageData, pricingData: data.data.refreshHelocPricing });
      } else {
        // if you get and error when trying to get pricing data, go to generic error screen
        window.scrollTo(0, 0);
        setFetchPageData(false);
        setPage('error');
        setPageData({
          error: {} as IErrorObject,
          page,
          pageData
        });
        navigate(`/heloc/error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
      }
      setLoading(false);
    } catch (e) {
      // if you get and error when trying to get pricing data, go to generic error screen
      window.scrollTo(0, 0);
      setFetchPageData(false);
      setPage('error');
      setPageData({
        error: {} as IErrorObject,
        page,
        pageData
      });
      navigate(`/heloc/error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
      return e;
    }
  };
  const handleFirstPageLoad = async () => {
    setGlobalLoading(true);
    await handlePageSubmit('{ page: "" }', false);
    setGlobalLoading(false);
  };

  if (page === '') {
    // Show applications page directly
    if (window.location.pathname.indexOf('applications') !== -1) {
      setPage('applications');
      navigate('/heloc/applications');
    } else if (!fetchPageData) {
      // When the ui is loaded for the first time, perform a GET from gateway to set the page
      const oktaTokens = Cookies.get('_uetsid') || Cookies.get('_uetvid');
      const authToken = Cookies.get('authToken') || Cookies.get('idToken');
      if (params.get('loanId')) {
        setFetchPageData(true);
        setGlobalLoading(true);
        window.location.href = `${window.location.origin}/gateway/authorize-heloc-user?tenant-id=${(window as any).tenantId.toLowerCase()}`;
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const loId = urlParams.get('loId') || urlParams.get('emp-id') || Cookies.get('loId');
        if (loId) {
          setLo(loId);
        } else {
          fetchLoanOfficers(setFetchLoData, setLoanOfficers);
        }
        handleFirstPageLoad();
      }
    }
  }

  // if there is no lo data, request it
  if (!fetchLoData) {
    const cookiedId = getLoCookie();
    if (cookiedId) {
      setLo(cookiedId);
    }
    setFetchLoData(true);
  }

  const loadApplication = (id: string) => {
    setApplicationId(id);
    setPage('');
    navigate(`/heloc/?id=${id}`);
  };

  // handle back button
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', (event) => {
      window.history.pushState(null, document.title, window.location.href);
    });
  });

  async function setSupportNavigationContent() {
    const supportNavResp = await getSupportNavigationContent('heloc_support_navigation');
    setSupportNavContent(supportNavResp);
  }

  if (!supportNavContent) {
    setSupportNavigationContent();
  }
  // Route the application based on the page returned from gateway

  const threatMetrixProps: IThreatMetrixData = {
    trackingId: pageData?.trackingId,
    threatMetrixOrgId: pageData?.threatMetrixOrgId,
    threatMetrixProfilingDomain: pageData?.threatMetrixProfilingDomain,
  }
  return (
    <div>
      <div className="p-4 md:p-8 md:flex md:justify-center">
        <div className="md:max-w-7xl">
          <Header authData={authData} fetchUserData={hideLogin} />
          <div className="md:flex md:flow-row md:shrink md:justify-center">
            <div className={`md:w-full ${useWiderView() ? 'md:max-w-7xl' : 'md:max-w-2xl'}`}>
              {globalLoading ? (
                <div className="min-h-[420px]">
                  <div className="flex items-center justify-center">
                    <Loader color="#D13239" className="loader-medium" />
                  </div>
                </div>
              ) : page === '' ? <div />
                : (
                  <div>
                    <Routes>
                      <Route path="/" element={(<HelocPropertyPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} setLo={setLo} />)} />
                      <Route path="/applications/*" element={(<HelocApplicationsPage loadApplication={loadApplication} />)} />
                      <Route path="/property/*" element={(<HelocPropertyPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} setLo={setLo} />)} />
                      <Route path="/officer/*" element={(<HelocPropertyPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} setLo={setLo} loanOfficers={loanOfficers} />)} />
                      <Route path="/occupancy/*" element={(<HelocPropertyPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} setLo={setLo} />)} />
                      <Route path="/purpose/*" element={(<HelocPropertyPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} setLo={setLo} />)} />
                      <Route path="/partnership/*" element={(<HelocPropertyPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} setLo={setLo} />)} />
                      <Route path="/address/*" element={(<HelocPersonalPage progress={progressContent} isDisaster={pageData?.isInDisasterZone} handleSubmit={handlePageSubmit} content={content[0]} pageName={page} showNameMismatchWarning={showNameMismatchWarning} setShowNameMismatchWarning={setShowNameMismatchWarning} tcpaData={pageData.tcpaConfig} basicInfoData={pageData?.basicInfoData} propertyData={pageData?.propertyData} threatMetrixData={threatMetrixProps} threatMetrix={threatMetrix} setThreatMetrix={setThreatMetrix} />)} />
                      <Route path="/personal/*" element={(<HelocPersonalPage progress={progressContent} isDisaster={pageData?.isInDisasterZone} handleSubmit={handlePageSubmit} content={content[0]} pageName="personal" showNameMismatchWarning={showNameMismatchWarning} setShowNameMismatchWarning={setShowNameMismatchWarning} tcpaData={pageData?.tcpaConfig} basicInfoData={pageData?.basicInfoData} propertyData={pageData?.propertyData} threatMetrixData={threatMetrixProps} threatMetrix={threatMetrix} setThreatMetrix={setThreatMetrix} />)} />
                      <Route path="/ssnPage/*" element={(<HelocSSNPage progress={progressContent} handleSubmit={handlePageSubmit} setGlobalLoading={setGlobalLoading} appId={applicationId || ''} content={content[0]} pageName="ssnPage" basicInfoData={pageData?.basicInfoData} propertyInfoData={pageData?.propertyData} />)} />
                      <Route path="/error/*" element={(<HelocErrorPage handleSubmit={handlePageSubmit} handleApplyAgain={handleApplyAgain} errorData={pageData as IErrorData} errMessage={errMessage} />)} />
                      <Route path="/complete/*" element={(<HelocCompletePage content={content[0]} pageName="complete" basicInfoData={pageData?.basicInfoData} />)} />
                    </Routes>
                    {
                      authToken !== ''
                      && (
                        <Routes>
                          <Route path="/income/*" element={(<HelocIncomePage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="income" incomeData={pageData?.incomeData} />)} />
                          <Route path="/liens/*" element={(<HelocOptionsPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="liens" percent="62.5" />)} />
                          <Route path="/mortgage/*" element={(<HelocMortgagePage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="mortgage" mortgageData={pageData?.mortgageData} />)} />
                          <Route path="/prequalify/*" element={(<HelocPricingPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="prequalify" confirmationData={pageData} pricingData={pageData?.pricingData} name={pageData?.basicInfoData?.name?.first} refreshPricing={refreshPricing} />)} />
                          <Route path="/marital/*" element={(<HelocAdditionalOptionPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="marital" fieldName="maritalStatus" additionalInfo={additionalInfo} setAdditionalInfo={setAdditionalInfo} />)} />
                          <Route path="/language/*" element={(<HelocAdditionalOptionPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="language" fieldName="language" additionalInfo={additionalInfo} setAdditionalInfo={setAdditionalInfo} />)} />
                          <Route path="/additionalQuestions/*" element={(<HelocAdditionalQuestions progress={progressContent} handleSubmit={handlePageSubmit} content={content} pageName="additionalQuestions" additionalInfo={additionalInfo} setAdditionalInfo={setAdditionalInfo} />)} />
                          <Route path="/finalQuestions/*" element={(<HelocFinalQuestions progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="finalQuestions" additionalInfo={additionalInfo} setAdditionalInfo={setAdditionalInfo} />)} />
                          <Route path="/verify_identity/*" element={(<HelocManualUpload progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="verify_identity" applicationId={applicationId || ''} />)} />
                          <Route path="/update_income/*" element={(<HelocFullIncomePage handleSubmit={handlePageSubmit} content={content[0]} pageName="update_income" employmentData={pageData?.incomes} />)} />
                          <Route path="/identity_result/*" element={(<HelocVerifiedPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="identity_result" name={pageData?.basicInfoData?.name?.first} identityVerified={pageData?.identityVerified} incomeVerified={pageData?.incomeVerified} employmentVerified={pageData?.employmentVerified} />)} />
                          <Route path="/verify_income/*" element={(<HelocIncomeVerification progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="verify_income" incomeData={pageData?.incomes} verificationData={pageData?.verificationData} applicationId={applicationId || ''} />)} />
                          <Route path="/income_result/*" element={(<HelocVerifiedPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="income_result" name={pageData?.basicInfoData?.name?.first} identityVerified={pageData?.identityVerified} incomeVerified={pageData?.incomeVerified} employmentVerified={pageData?.employmentVerified} />)} />
                          <Route path="/deposit/*" element={(<HelocBankPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="deposit" depositInfo={pageData?.depositInfo} debitInfo={pageData?.debitInfo} accounts={pageData?.accounts} applicationId={applicationId ?? ''} />)} />
                          <Route path="/debit/*" element={(<HelocBankPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="debit" depositInfo={pageData?.depositInfo} debitInfo={pageData?.debitInfo} accounts={pageData?.accounts} applicationId={applicationId ?? ''} />)} />
                          <Route path="/annual/*" element={(<HelocAnnualPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} applicationId={applicationId || ''} />)} />
                          <Route path="/identifier/*" element={(<HelocIdentifierPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="identifier" />)} />
                          <Route path="/review/*" element={(<HelocReviewPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="review" />)} />
                          {isManualReview && <Route path="/manualReview/*" element={(<HelocManualReview progress={progressContent} />)} />}
                          <Route path="/finalOffer/*" element={(<HelocPricingPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="finalOffer" pricingData={pageData?.pricingData} name={pageData?.basicInfoData?.name?.first} refreshPricing={refreshPricing} />)} />
                          <Route path="/loanSelected/*" element={(<HelocLoanSelectedPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="loanSelected" pricingData={pageData?.pricingData} propertyData={pageData?.propertyData} />)} />
                          <Route path="/documents/*" element={(<HelocInitialDisclosures progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="documents" basicInfoData={pageData?.basicInfoData} applicationId={applicationId} />)} />
                          <Route path="/scheduleClosing/*" element={(<HelocSchedulingPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="scheduleClosing" closingResponse={pageData?.closingResponse} applicationId={applicationId || ''} />)} />
                          <Route path="/dashboard/*" element={(<HelocDashboardPage progress={progressContent} handleSubmit={handlePageSubmit} content={content[0]} pageName="personal" schedulingTime={pageData?.schedulingTime} propertyInfo={pageData?.propertyData} basicInfo={pageData?.basicInfoData} />)} />
                        </Routes>
                      )
                    }
                  </div>
                )}
            </div>
          </div>
          <div className="mt-16">
            {supportNavContent && <LoanOfficerBanner supportNavLoData={loData ? { ...loData, heading: supportNavContent.heading, description: supportNavContent.description } : supportNavContent} />}
          </div>
          <Footer data={footerContent} />
        </div>

      </div>
    </div>
  );
};

// default values used for additional info screen
const newAdditionalInfo = () => ({
  maritalStatus: '',
  ownershipType: '',
  trustName: '', // optional
  gender: '',
  ethnicity: '',
  ethnicityDetail: '', // optional
  race: '',
  asianDetail: '', // optional
  islanderDetail: '', // optional
  citizenStatus: '',
  language: ''
} as unknown as IAdditionalInfo);
