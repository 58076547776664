import { useEffect, useState } from 'react';
import { apiFailed, ApiRequestState, apiReset, apiSuccess } from '../util/ApiRequestState';
import { fetchFspPageContent } from '../../../config/util/common';
import { log } from '../../../config/util/logger';

export interface IFspPageContent {
  content: any;
}

export const useFspPageContent = <T, >(pageId: string, logTag: string) => {
  const [fspPageContentState, setFspPageContentState] = useState<
    ApiRequestState<T>
  >(apiReset());

  useEffect(() => {
    fetchFspPageContent(pageId).then((data: any) => {
      if (data.content === 'error') {
        log({
          message: `${logTag}: fetchFspPageContent(): Error fetching page content. ${JSON.stringify(
            { page: pageId }
          )}`,
          level: 'error',
        });
        setFspPageContentState(
          apiFailed(
            fspPageContentState,
            `Error fetching FSP page content for ${pageId}`
          )
        );
      } else {
        setFspPageContentState(apiSuccess(fspPageContentState, data.content));
      }
    });
  }, [pageId]);

  return fspPageContentState;
};
