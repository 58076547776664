import Button from '@guaranteed-rate/react-components/dist/Button';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import { useState } from 'react';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { IAdditionalInfo } from '../../../config/util/interfaces';
import { MASKS } from '../../../config/util/masks';
import { citizenStatus } from '../../../config/content/dropdown';

interface IFinalQuestionsProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  progress?: ProgressProps;
  additionalInfo: IAdditionalInfo;
  setAdditionalInfo: any;
  isLite?: boolean;
}

interface IFinalQuestionForm {
  primaryResidence: string;
  otherOwnership: string;
  borrowingMoney: string;
  otherMortgage: string;
  newCredit: string;
  newLien: string;
  otherDebt: string;
  outstandingJudgment: string;
  delinquentDefault: string;
  lawsuit: string;
  conveyedTitle: string;
  shortSale: string;
  foreclosure: string;
  bankruptcy: string;
  citizenStatus: string;
}

interface IQuestion {
  question: {
    text: string;
    field_name: string;
  };
}

// load the previously entered responses
const loadExistingData = (additionalInfo: IAdditionalInfo) => {
  if (additionalInfo) {
    return {
      primaryResidence: additionalInfo.primaryResidence === true ? 'YES' : additionalInfo.primaryResidence === false ? 'NO' : '',
      otherOwnership: additionalInfo.otherOwnership === true ? 'YES' : additionalInfo.otherOwnership === false ? 'NO' : '',
      borrowingMoney: additionalInfo.borrowingMoney === true ? 'YES' : additionalInfo.borrowingMoney === false ? 'NO' : '',
      otherMortgage: additionalInfo.otherMortgage === true ? 'YES' : additionalInfo.otherMortgage === false ? 'NO' : '',
      newCredit: additionalInfo.newCredit === true ? 'YES' : additionalInfo.newCredit === false ? 'NO' : '',
      newLien: additionalInfo.newLien === true ? 'YES' : additionalInfo.newLien === false ? 'NO' : '',
      otherDebt: additionalInfo.otherDebt === true ? 'YES' : additionalInfo.otherDebt === false ? 'NO' : '',
      outstandingJudgment: additionalInfo.outstandingJudgment === true ? 'YES' : additionalInfo.outstandingJudgment === false ? 'NO' : '',
      delinquentDefault: additionalInfo.delinquentDefault === true ? 'YES' : additionalInfo.delinquentDefault === false ? 'NO' : '',
      lawsuit: additionalInfo.lawsuit === true ? 'YES' : additionalInfo.lawsuit === false ? 'NO' : '',
      conveyedTitle: additionalInfo.conveyedTitle === true ? 'YES' : additionalInfo.conveyedTitle === false ? 'NO' : '',
      shortSale: additionalInfo.shortSale === true ? 'YES' : additionalInfo.shortSale === false ? 'NO' : '',
      foreclosure: additionalInfo.foreclosure === true ? 'YES' : additionalInfo.foreclosure === false ? 'NO' : '',
      bankruptcy: additionalInfo.bankruptcy === true ? 'YES' : additionalInfo.bankruptcy === false ? 'NO' : '',
      citizenStatus: additionalInfo.citizenStatus
    } as IFinalQuestionForm;
  }
  return {
    primaryResidence: '',
    otherOwnership: '',
    borrowingMoney: '',
    otherMortgage: '',
    newCredit: '',
    newLien: '',
    otherDebt: '',
    outstandingJudgment: '',
    delinquentDefault: '',
    lawsuit: '',
    conveyedTitle: '',
    shortSale: '',
    foreclosure: '',
    bankruptcy: '',
    citizenStatus: ''
  } as IFinalQuestionForm;
};

export const HelocFinalQuestions = (props: IFinalQuestionsProps) => {
  const [helocForm, setHelocForm] = useState(loadExistingData(props.additionalInfo));
  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setHelocForm({ ...helocForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  // submit the final question responses as well as the additional info data to the orchestrator
  const handleSubmit = async () => {
    setTrySubmit(true);
    if (canSubmit()) {
      // save the final questions to the additioßnal info object
      props.setAdditionalInfo({ ...props.additionalInfo,
        primaryResidence: helocForm.primaryResidence === 'YES',
        otherOwnership: helocForm.otherOwnership === 'YES',
        borrowingMoney: helocForm.borrowingMoney === 'YES',
        otherMortgage: helocForm.otherMortgage === 'YES',
        newCredit: helocForm.newCredit === 'YES',
        newLien: helocForm.newLien === 'YES',
        otherDebt: helocForm.otherDebt === 'YES',
        outstandingJudgment: helocForm.outstandingJudgment === 'YES',
        delinquentDefault: helocForm.delinquentDefault === 'YES',
        lawsuit: helocForm.lawsuit === 'YES',
        conveyedTitle: helocForm.conveyedTitle === 'YES',
        shortSale: helocForm.shortSale === 'YES',
        foreclosure: helocForm.foreclosure === 'YES',
        bankruptcy: helocForm.bankruptcy === 'YES',
        citizenStatus: helocForm.citizenStatus
      });
      setLoading(true);
      const pageData = `{ 
        page: "${props.pageName}"
        ${props.isLite ? 'lite: true' : ''}
        additionalData: {
          maritalStatus: "${props.additionalInfo.maritalStatus}",
          ownershipType: ${props.isLite ? `"${props.additionalInfo.ownershipType}"` : 'null'},
          trustName: ${props.isLite ? `"${props.additionalInfo.trustName}"` : 'null'},
          gender: "${props.additionalInfo.gender}",
          ethnicity: "${props.additionalInfo.ethnicity}",
          ethnicityDetail: "${props.additionalInfo.ethnicityDetail}",
          race: "${props.additionalInfo.race}",
          asianDetail: "${props.additionalInfo.asianDetail}",
          islanderDetail: "${props.additionalInfo.islanderDetail}",
          citizenStatus: "${helocForm.citizenStatus}",
          language: "${props.additionalInfo.language}",
          primaryResidence: ${helocForm.primaryResidence === 'YES'},
          otherOwnership: ${helocForm.otherOwnership === 'YES'},
          borrowingMoney: ${helocForm.borrowingMoney === 'YES'},
          otherMortgage: ${helocForm.otherMortgage === 'YES'},
          newCredit: ${helocForm.newCredit === 'YES'},
          newLien: ${helocForm.newLien === 'YES'},
          otherDebt: ${helocForm.otherDebt === 'YES'},
          outstandingJudgment: ${helocForm.outstandingJudgment === 'YES'},
          delinquentDefault: ${helocForm.delinquentDefault === 'YES'},
          lawsuit: ${helocForm.lawsuit === 'YES'},
          conveyedTitle: ${helocForm.conveyedTitle === 'YES'},
          shortSale: ${helocForm.shortSale === 'YES'},
          foreclosure: ${helocForm.foreclosure === 'YES'},
          bankruptcy: ${helocForm.bankruptcy === 'YES'},
        }
      }`;

      // save the non sensitive data to the window for adobe
      (window as any).digitalData = {
        pageInfo: {
          additionalInfoData: {
            citizenStatus: helocForm.citizenStatus,
            ownershipType: props.additionalInfo.ownershipType,
          }
        }
      };
      await props.handleSubmit(pageData, false);
      setLoading(false);
    }
  };

  // go to previous screen
  const handleBack = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      ${props.isLite ? 'lite: true' : ''}
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };

  const hasError = (name: string) => trySubmit
  && (!helocForm[name as keyof IFinalQuestionForm]
    || `${helocForm[name as keyof IFinalQuestionForm]}`.length <= 0);

  // verify all of the final questions have been answered
  const canSubmit = () => Object.keys(helocForm)
    .every((key) => !(!helocForm[key as keyof IFinalQuestionForm]
      || `${helocForm[key as keyof IFinalQuestionForm]}`.length <= 0));

  // load the questions from content stack
  const questions = props.content.questions.map((question: IQuestion) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ({
      text: question.question.text,
      isChecked: helocForm[question.question.field_name as keyof IFinalQuestionForm],
      options: [
        {
          name: question.question.field_name,
          label: 'Yes',
          value: 'YES',
          active: false
        }, {
          name: question.question.field_name,
          label: 'No',
          value: 'NO',
          active: false
        }
      ]
    }));
  return (
    <Form title={props.content.header} progress={props.progress}>
      {
        questions.map((question: any, index: number) => (
          <div key={`question${index}`}>
            <div className="flex flex-row pb-4">
              <p className="basis-1/2 md:basis-2/3">{question.text}</p>
              <div className="text-end basis-1/2 md:basis-1/3">
                <RadioButtons
                  className="radioButton radioInline w-16"
                  radios={question.options}
                  onChange={handleChange}
                  isChecked={question.isChecked}
                />
              </div>
            </div>
          </div>
        ))
      }
      <Dropdown
        name="citizenStatus"
        value={helocForm.citizenStatus}
        label={props.content.citizen_status_label}
        onChange={(event) => handleChange(event, 'citizenStatus')}
        className="w-full citizenDropdown"
        options={citizenStatus}
        hasError={hasError('citizenStatus')}
        helperText={hasError('citizenStatus') ? 'required' : undefined}
        required={true}
      />
      <div className="flex justify-center mt-12">
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-48 nextButton"
          onClick={handleSubmit}
          loading={loading}
          buttonAttrs={{ disabled: loading || !canSubmit() }}
        >Next
        </Button>
      </div>
      <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
        <Button
          buttonStyle="quaternary"
          iconPos="left"
          iconName="chevron-left-large"
          onClick={handleBack}
          buttonAttrs={{ disabled: loading }}
        >Back
        </Button>
      </div>
      <div className="text-center pt-12 ">
        <p>{props.content.income_description}</p>
        <p>{props.content.other_income_description}</p>
      </div>
    </Form>
  );
};
