export const SECURED_PAGES = [
  'income',
  'liens',
  'mortgage',
  'prequalify',
  'marital',
  'additionalQuestions',
  'finalQuestions',
  'verify_identity',
  'identity_result',
  'verify_income',
  'income_result',
  'deposit',
  'debit',
  'finalOffer',
  'loanSelected',
  'documents',
  'scheduleClosing',
  'dashboard'
];

// todo: have a page map here
// input strings map state to url

// export const PREFI_PAGE_MAP = [

// ]
