/* eslint-disable max-len */
import Button from '@guaranteed-rate/react-components/dist/Button';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import { useState } from 'react';

export interface IMenuItem {
  label: string;
  action: any;
}

interface IMenuProps {
  items: Array<IMenuItem>;
  icon: string;
  iconSize: string;
  className?: string;
}
const Menu = (props: IMenuProps) => {
  const [active, setActive] = useState(false);

  const onClick = () => {
    if (active) {
      setActive(false);
    } else {
      setActive(true);
      let fn = (event: any) => {
        if (!event.target.classList.contains('font-icons-user-circle') && event.target.id !== 'menuContainer') {
          setActive(false);
          document.removeEventListener('click', fn);
        }
      };
      document.addEventListener('click', fn);
    }
  };

  return (
    <div>
      <Button className="ml-3 float-right" buttonStyle="quaternary" onClick={onClick}>
        <FontIcon fontSize={props.iconSize} iconName={props.icon} />
      </Button>
      {
        active && (
          <div className={`absolute -ml-12 mt-8 shadow-md rounded-md border-slate-300 border-2 ${props.className ? props.className : ''} hover:bg-[var(--color-secondary-input-lightest)] cursor-pointer`} id="menuContainer">
            <ul>
              {props.items.map((menuItem: IMenuItem) => (
                <li className="py-2 px-4" onClick={menuItem.action} key={menuItem.label}>{menuItem.label}</li>
              ))}
            </ul>
          </div>
        )
      }
    </div>
  );
};

export default Menu;
