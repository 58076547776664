import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { fetchFspPageContent, getTimeDiff } from '../../config/util/common';
import { IContentStackFile } from '../../config/util/interfaces';
import { log } from '../../config/util/logger';
import { apiFailed, ApiRequestState, apiReset, apiSuccess } from './util/ApiRequestState';
import { StandardApiResponse, IPrefiFeatureConfig } from './util/types';

const getPrefiFeatureConfig = async (): Promise<StandardApiResponse<IPrefiFeatureConfig>> => {
  const query = {
    query:
      `query {
        prefiFeatureConfig {
          enableCoBorrowerInfoUpdate
        }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: 'Query "prefiFeatureConfig"', level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-Request-ID': uuid,
    },
    body: JSON.stringify(query)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.prefiFeatureConfig) {
      log({ message: `Query "prefiFeatureConfig" was successful ${JSON.stringify({ duration })}`, level: 'info', requestId: uuid });
      return { content: data.data.prefiFeatureConfig };
    }
    log({
      message: `Query "prefiFeatureConfig" failed with errors ${JSON.stringify(
        { duration, errors: data?.errors[0]?.message }
      )}`,
      level: 'error',
      requestId: uuid,
    });
    return { errors: data?.errors[0]?.message };
  } catch (e) {
    log({
      message: `Query "prefiFeatureConfig" failed due to exception ${e?.toString()}`,
      level: 'error',
      requestId: uuid,
    });
    console.error(e);
    return { errors: e?.toString() };
  }
};

export interface IPrefiCommonContent {
  rate_app_banner_download_header: string;
  rate_app_banner_download_description: string;
  rate_app_banner_screenshot: IContentStackFile;
  rate_app_banner_qr_code: IContentStackFile;
  rate_app_url: string;
  rate_app_apple_app_store_url: string;
  rate_app_google_play_store_url: string;
  soft_credit_authorization_notice: string;
  soft_credit_impact_statement: string;
  unfreeze_credit_reminder: string;
  data_security_assurance: string;
  unfreeze_credit_modal_title: string;
  unfreeze_credit_modal_content: string;
  experian_freeze_url: string;
  equifax_freeze_url: string;
  transunion_freeze_url: string;
}

const DEFAULT_FEATURE_FLAGS: IPrefiFeatureConfig = {}; // all flags false by default

export interface IPrefiContextType {
  prefiCommonContent: IPrefiCommonContent | null;
  contentLoading: boolean;
  contentError: string | null;
  prefiFeatureConfig: ApiRequestState<IPrefiFeatureConfig>;
}

export const PrefiContext = createContext<IPrefiContextType>(
  {
    prefiCommonContent: null,
    contentLoading: false,
    contentError: 'Uninitialized',
    prefiFeatureConfig: apiReset(),
  }
);

interface PrefiProviderProps {
  children: ReactNode;
}

// fetches and manages data that can be used across the prefi flow
export const PrefiProvider: React.FC<PrefiProviderProps> = ({ children }) => {
  const [prefiCommonContent, setPrefiCommonContent] = useState<IPrefiCommonContent | null>(null);
  const [contentLoading, setContentLoading] = useState(true);
  const [contentError, setContentError] = useState<string | null>(null);
  const [prefiFeatureConfig, setPrefiFeatureConfig] = useState<ApiRequestState<IPrefiFeatureConfig>>(
    apiSuccess(apiReset(), DEFAULT_FEATURE_FLAGS)
  );

  // fetch prefi feature flags
  useEffect(() => {
    getPrefiFeatureConfig().then((result) => {
      if (result.content && !result.errors) {
        setPrefiFeatureConfig(apiSuccess(apiReset(), result.content));
      } else {
        setPrefiFeatureConfig(apiFailed(apiReset(), result.errors || 'Unknown error'));
      }
    }).catch((err) => {
      setPrefiFeatureConfig(apiFailed(apiReset(), err.toString()));
    });
  }, []);

  // fetch common ContentStack content
  useEffect(() => {
    const fetchPrefiGeneralContent = async () => {
      try {
        setContentLoading(true);
        setPrefiCommonContent(null);
        setContentError(null);
        const data = await fetchFspPageContent('"prefi_general_content"');
        if (data.content === 'error') {
          log({
            message: `PrefiProvider: fetchPrefiGeneralContent(): fetchPrefiGeneralContent(): Error fetching page content ${JSON.stringify({
              page: 'prefi_general_content'
            })}`,
            level: 'error'
          });
          setContentError('Error fetching page content. Please try again.');
          setPrefiCommonContent(null);
        } else {
          setContentError(null);
          setPrefiCommonContent(data.content as any as IPrefiCommonContent);
        }
      } catch (error) {
        console.error('Failed to fetch prefis:', error);
        setContentError('Error fetching page content. Please try again.');
      } finally {
        setContentLoading(false);
      }
    };

    fetchPrefiGeneralContent();
  }, []);

  return (
    <PrefiContext.Provider value={{ prefiCommonContent, contentLoading, contentError, prefiFeatureConfig }}>
      {children}
    </PrefiContext.Provider>
  );
};

export const usePrefiContext = () => useContext(PrefiContext);
