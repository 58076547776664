import Cookies from 'js-cookie';
import merge from 'deepmerge';
import { getBrowserFull } from './browser';

export type LoggerMessage = string
export type LoggerLevel = 'info' | 'debug' | 'warn' | 'error' | 'critical'

let context = {};
export const updateLogContext = (c: unknown) => {
  if (c) {
    context = merge(context, c);
  }
};

const postLog = (data: Record<string, unknown>) =>
  navigator.sendBeacon?.(
    '/gateway/log',
    new Blob([JSON.stringify(data)], {
      type: 'application/json; charset=UTF-8',
    })
  )
  || fetch('/gateway/log', {
    method: 'POST',
    body: JSON.stringify(data),
    keepalive: true,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
  });

export const log = ({
  level = 'info',
  message,
  requestId,
  context: logContext,
  error,
}: {
  level?: LoggerLevel;
  message: LoggerMessage;
  requestId?: string;
  context?: any;
  error?: Error;
}) => {
  const loggedMessage = error ? `${message} - ${error}` : message;
  level = level || (error ? 'error' : 'info');

  const url = {
    full: window.location.href,
    path: `${window.location.pathname}${window.location.search}${window.location.hash}`,
    host: window.location.host,
    pathname: window.location.pathname,
    ...(window.location.search && { search: window.location.search }),
    ...(window.location.hash && { hash: window.location.hash }),
  };

  const params = {
    ...context,
    ...logContext,
    level,
    url,
    env: (window as any).env,
    tenant: (window as any).tenantId,
    browser: getBrowserFull(),
    auth: {
      hasMatc: !!Cookies.get('matc'),
    },
    ...(requestId && { requestId }),
    ...(error?.stack && { stacktrace: error?.stack }),
  };

  if (process.env.REACT_APP_DEBUG === 'true' || localStorage.getItem('debug') === 'true') {
    const flag = ['warn', 'critical'].includes(level)
      ? '📙'
      : level === 'error'
        ? '📕'
        : level === 'debug'
          ? '📘'
          : '📗';

    const args = [`${flag}%c Logger:`, 'color: blue', loggedMessage, params];
    // eslint-disable-next-line default-case
    switch (level) {
    case 'debug':
      window.console.debug.apply(console, args);
      break;
    case 'warn':
      window.console.info.apply(console, args);
      break;
    case 'error':
      window.console.info.apply(console, args);
      break;
    default:
      window.console.info.apply(console, args);
    }
    if (error) {
      console.error(message, error);
    }
  }

  postLog({ message: loggedMessage, ...params });
};
