import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import { useEffect, useState } from 'react';
import Form from '../../../components/Form/Form';
import { IProperty, IPropertyResponse } from '../../../config/util/interfaces';
import { MASKS } from '../../../config/util/masks';
import GoogleMapAddress from '../../../components/GoogleMapAddress/GoogleMapAddress';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { getLoCookie } from '../../../config/util/common';
import { HelocOfficerPage } from '../HelocOfficerPage/HelocOfficerPage';
import PageLoader from '../../../components/PageLoader/PageLoader';
import Alert from '../../../components/Alert/Alert';
import { HelocOwnershipPage } from '../HelocAdditionalQuestions/HelocOwnershipPage';
import { LienProgressBar } from './LienProgressBar';

interface IPropertyProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  propertyData: IPropertyResponse;
  progress?: ProgressProps;
  isLite?: boolean;
  loanOfficers?: Array<any>;
  setLo: (loId: string) => void;
}

interface radioButtonProps {
  option: radioButtonOptionProps;
}

interface radioButtonOptionProps {
  label: string;
  value: string;
}

export interface IPropertyForm {
  address: string;
  unitNumber: string;
  city: string;
  state: string;
  zip: string;
  occupancy: string;
  propertyType?: string;
  purpose: string;
  isSaleProperty: string;
  ownershipType: string;
  trustName: string;
  otherReason: string;
}
interface IAnnualForm {
  annualTaxes: string;
  annualInsurance: string;
  annualHoa: string;
}

// build the current address based on the input object. Used when we go backwards
export const buildLocation = (props: IPropertyProps) => {
  if (props.propertyData?.address?.city != null) {
    return buildLocationFromProperty(props.propertyData.address);
  }
  return '';
};

export const buildLocationFromProperty = (property: IProperty) => `${property.street[0]}${property.street.length > 1
  ? ` ${property.street[1]}` : ''} ${property.city} ${property.region} ${property.postalCode}`;

// load the existing form with the previously entered data, if available
const loadExistingData = (props: IPropertyProps) => {
  if (props.propertyData != null) {
    return {
      address: props.propertyData?.address?.street ? props.propertyData?.address?.street[0] : '',
      unitNumber: props.propertyData?.address?.street?.length > 1 ? props.propertyData.address.street[1] : '',
      city: props.propertyData?.address?.city,
      state: props.propertyData?.address?.region,
      zip: props.propertyData?.address?.postalCode,
      propertyType: props.propertyData?.structureType || '',
      occupancy: props.propertyData?.propertyType || '',
      purpose: props.propertyData?.purpose || '',
      ownershipType: props.propertyData?.ownershipType || '',
      trustName: props.propertyData?.trustName || '',
      otherReason: props.propertyData?.otherReason || ''
    } as IPropertyForm;
  }
  return {
    address: '',
    unitNumber: '',
    city: '',
    state: '',
    zip: '',
    propertyType: '',
    occupancy: '',
    purpose: '',
    ownershipType: '',
    trustName: '',
    otherReason: ''
  } as IPropertyForm;
};

const getPropertyDataForGq = (helocForm: IPropertyForm, loanAmount: string, isLite: boolean) => {
  const cookiedId = getLoCookie();
  return `{ 
    address: "${helocForm.address}"
    apt: "${helocForm.unitNumber}"
    city: "${helocForm.city}"
    state: "${helocForm.state}"
    zip: "${helocForm.zip}"
    loanAmount: "${loanAmount}"
    loanOfficerId: "${cookiedId || ''}"
    ${!isLite ? `ownershipType: "${helocForm.ownershipType || ''}"` : ''} 
    trustName: "${helocForm.trustName || ''}"
  }`;
};

const getAnnualIncomDataForGq = (annualForm: IAnnualForm) => `{
  annualTaxes: "${annualForm.annualTaxes}"
  annualInsurance: "${annualForm.annualInsurance}"
  annualHoa: "${annualForm.annualHoa}"
}`;

// load previously entered taxes and insurance if available
const loadAnnualData = (props: IPropertyProps) => {
  if (props.propertyData != null) {
    return {
      annualTaxes: props?.propertyData?.annualTaxes?.toString() || '',
      annualInsurance: props?.propertyData?.annualInsurance?.toString() || '',
      annualHoa: props?.propertyData?.annualHoa?.toString() || ''
    } as IAnnualForm;
  }
  return {
    annualTaxes: '',
    annualInsurance: '',
    annualHoa: ''
  } as IAnnualForm;
};

// This function is used to check if the address is complete one or not
function getIsFullAddress(addressGroupNames: Array<any>, form: any) {
  if (!addressGroupNames || !form.address) {
    return true;
  }
  return Object.values(addressGroupNames).every((key) => form[key] !== '' || key === 'apartment');
}

// This component is used to render the property, annual, occupancy and purpose pages
export const HelocPropertyPage = (props: IPropertyProps) => {
  const [helocForm, setHelocForm] = useState(loadExistingData(props)); // this is the property data
  const [loanAmount, setLoanAmount] = useState(`${props.propertyData?.requestedLoanAmount ? props.propertyData?.requestedLoanAmount : ''}`); // requested loan amount
  const [annualForm, setAnnualForm] = useState(loadAnnualData(props)); // this is the insurance and taxes form
  const [formattedAddress, setFormattedAddress] = useState(buildLocation(props)); // this is the property address when formatted
  const [includeTaxes, setIncludeTaxes] = useState(props.propertyData?.annualInsurance === 0 && props.propertyData?.annualTaxes === 0); // this checkbox is used when a users mortgage payment includes taxes and insurance already
  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const [loId, setLoId] = useState('');
  const [isSaleProperty, setSaleProperty] = useState(false);

  // This renders the options for the occupancy and purpose pages
  const getRadioButtonOptions = (radioButtonOpt: Array<radioButtonProps>) => (radioButtonOpt ? radioButtonOpt.map((item: any) => ({
    name: 'options',
    label: item.option.label,
    value: item.option.value,
    active: false,
  })) : []);
  const options = getRadioButtonOptions(props.content.radio_button);
  const helocPurposeOptions = getRadioButtonOptions(props.content.heloc_radio_button);

  useEffect(() => {
    if (props.pageName === 'purpose') {
      setHelocForm({ ...helocForm, purpose: '', otherReason: '' });
    } else if (props.pageName === 'occupancy') {
      setHelocForm({ ...helocForm, occupancy: '' });
    }
  }, [props.pageName]);

  // set the non sensitive data to the window so that adobe can pull it for testing
  const setNonSenstiveDataToWindow = () => {
    (window as any).digitalData = {
      pageInfo: {
        propertyData: {
          city: helocForm.city,
          state: helocForm.state,
          zip: helocForm.zip,
          loanAmount,
          loanOfficerId: loId
        },
        annualPaymentData: {
          annualTaxes: annualForm.annualTaxes,
          annualInsurance: annualForm.annualInsurance
        },
        occupancy: helocForm.occupancy,
        purpose: helocForm.purpose
      }
    };
  };

  // used to update the property data
  const updateHelocForm = (value: string, name: string) => {
    setHelocForm({ ...helocForm, [name]: value });
  };

  // used to update the taxes and insurance data
  const handleAnnualChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setAnnualForm({ ...annualForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  // validates the loan amount is within the appropriate bounds
  const loanAmountError = () => {
    const amountNumber = !loanAmount ? 0 : parseInt(loanAmount);
    if (amountNumber <= 0) {
      return true;
    }
    if (amountNumber < (helocForm.state === 'AK' ? 25001 : 20000) || amountNumber > 400000) {
      return true;
    }
    return false;
  };

  // when the user clicks next they submit their data to go to the next page
  const handleSubmit = async (form: any) => {
    setTrySubmit(true);
    const keys = form ? Object.keys(form) : [];
    // if: validates the input based on the current page (annual or property)
    if (
      includeTaxes
      || (loanAmount.length > 0
        && keys.every(
          (key) =>
            form[key].length > 0
            || key === 'apartment'
            || key === 'occupancy'
            || key === 'purpose'
            || key === 'propertyType'
            || key === 'annualHoa'
            || key === 'ownershipType'
            || key === 'trustName'
            || key === 'unitNumber'
            || key === 'otherReason'
        )
        && ((props.pageName === 'property' && !loanAmountError())
          || props.pageName !== 'property'))
    ) {
      setLoading(true);
      const pageData = `{ 
        page: "${props.pageName}"
        ${props.isLite ? 'lite: true' : ''}
        propertyData: ${getPropertyDataForGq(helocForm, loanAmount, props.isLite || false)}
      }`;
      await props.handleSubmit(pageData, false);
      setLoading(false);
      setTrySubmit(false);
    }
  };

  // when the user clicks next they submit their data to go to the next page
  const handleSubmitWithOtherOption = async () => {
    if (helocForm.otherReason) {
      setLoading(true);
      const pageData = `{ 
        page: "${props.pageName}"
        ${props.isLite ? 'lite: true' : ''}
        propertyData: ${getPropertyDataForGq(helocForm, loanAmount, props.isLite || false)}
        annualPaymentData: ${getAnnualIncomDataForGq(annualForm)}
        occupancy: "${helocForm.occupancy}"
        ${props.isLite ? `propertyType: "${helocForm.propertyType}"` : ''}
        purpose: "${helocForm.purpose}"
        otherReason: "${helocForm.otherReason}"
      }`;
      await props.handleSubmit(pageData, false);
      setLoading(false);
    }
  };

  // This is called when the user selects a loan purpose or occupancy type
  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // Here we pass in the page name as the key for the value selected from the radio button
    // If the page is occupancy then we just want to save the data locally and go to the loan purpose page
    if (props.pageName === 'occupancy') {
      updateHelocForm(event.target.value, 'occupancy');
      setLoading(true);
      const pageData = `{ 
        page: "${props.pageName}"
        ${props.isLite ? 'lite: true' : ''}
        occupancy: "${event.target.value}"
        propertyData: ${getPropertyDataForGq(helocForm, loanAmount, props.isLite || false)}
      }`;
      await props.handleSubmit(pageData, false);
      setLoading(false);
    } else if (props.pageName === 'propertyType') {
      updateHelocForm(event.target.value, 'propertyType');
      setLoading(true);
      const pageData = `{ 
        page: "${props.pageName}"
        ${props.isLite ? 'lite: true' : ''}
        occupancy: "${helocForm.occupancy}"
        propertyType: "${event.target.value}"
        propertyData: ${getPropertyDataForGq(helocForm, loanAmount, props.isLite || false)}
      }`;
      await props.handleSubmit(pageData, false);
      setLoading(false);
    } else {
      // If the page is purpose, then we want to submit all the data to the orchestrator
      updateHelocForm(event.target.value, 'purpose');
      // set the non sensitive data to the window so that adobe can pull it for testing
      setNonSenstiveDataToWindow();
      if (event.target.value !== 'OTHER') {
        setLoading(true);
        const pageData = `{ 
          page: "${props.pageName}"
          ${props.isLite ? 'lite: true' : ''}
          propertyData: ${getPropertyDataForGq(helocForm, loanAmount, props.isLite || false)}
          annualPaymentData: ${getAnnualIncomDataForGq(annualForm)}
          occupancy: "${helocForm.occupancy}"
          ${props.isLite ? `propertyType: "${helocForm.propertyType}"` : ''}
          purpose: "${event.target.value}"
        }`;
        await props.handleSubmit(pageData, false);
      }
      setLoading(false);
    }
  };

  const handleFinalSubmit = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      ${props.isLite ? 'lite: true' : ''}
      propertyData: ${getPropertyDataForGq(helocForm, loanAmount, props.isLite || false)}
      annualPaymentData: ${getAnnualIncomDataForGq(annualForm)}
      occupancy: "${helocForm.occupancy}"
      ${props.isLite ? `propertyType: "${helocForm.propertyType}"` : ''}
      purpose: "${helocForm.purpose}"
      otherReason: "${helocForm.otherReason}"
    }`;

    // set the non sensitive data to the window so that adobe can pull it for testing
    setNonSenstiveDataToWindow();
    await props.handleSubmit(pageData, false);
    setLoading(false);
  };

  // This is used to go backwards
  const handleBack = async () => {
    setLoading(true);
    const pageData = `{ 
      propertyData: { 
        loanOfficerId: "${getLoCookie() || ''}"
      }
      page: "${props.pageName}"
      ${props.isLite ? 'lite: true' : ''}
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };

  // This is passed to the GoogleMaps component so that we can save the new address to the property form
  const onAddressChange = (address: any, type: string) => {
    if (trySubmit) {
      setTrySubmit(false);
    }
    setHelocForm(type === 'apartment' ? {
      ...helocForm,
      unitNumber: address.unitNumber,
    } : {
      ...helocForm,
      address: address.address,
      unitNumber: address.unitNumber,
      city: address.city,
      state: address.state,
      zip: address.zip
    });
    setFormattedAddress(address.formattedAddress);
  };

  const hasError = (name: string, form: any) => !includeTaxes && trySubmit && form[name].length <= 0;

  const isFullAddress = trySubmit && getIsFullAddress(props?.content?.address_group_names, helocForm);

  if (props.pageName === 'partnership') {
    return (
      <HelocOwnershipPage
        handleSubmit={(formData, isBack) => (isBack ? handleBack() : handleFinalSubmit())}
        content={props.content}
        pageName="partnership"
        additionalInfo={helocForm}
        setAdditionalInfo={setHelocForm}
        progress={props.progress}
      />
    );
  }

  return (
    <Form title={props.content.header} progress={props.progress}>
      {props.pageName === 'property'
      && (
        <div>
          <div className="min-h-[300px]">
            { isSaleProperty && (
              <div className="mb-4">
                <Alert text={props.content.property_listed_for_sale_error} type="error" icon="warning-triangle" />
              </div>
            )}
            <GoogleMapAddress
              onChange={onAddressChange}
              value={formattedAddress}
              addressObj={helocForm}
              label={props.content.address_field_label}
              type="address"
              hasError={trySubmit && (!helocForm.address || !isFullAddress)}
              helperText={(trySubmit && !isFullAddress)
                ? props?.content?.full_address_error_label : 'required'}
              required={true}
            />
            <TextInput
              name="loanAmount"
              value={loanAmount}
              label={props.content.loan_amount_label}
              onChange={(input) => setLoanAmount(input.target.value)}
              mask={MASKS.CURRENCY}
              hasError={trySubmit && loanAmountError()}
              helperText={trySubmit && loanAmountError()
                ? `Please enter a value between $${(helocForm.state === 'AK' ? '25,001' : '20,000')} and $400,000`
                : undefined}
              required={true}
            />
            <div className="flex">
              <input
                type="checkbox"
                id="isSaleProperty"
                className="checkbox mr-2 mb-6"
                checked={isSaleProperty}
                onChange={() => setSaleProperty(!isSaleProperty)}
              />
              <label>{props?.content?.property_listed_for_sale_label}</label>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <Button
              buttonStyle="primary"
              className="!w-full md:!w-48 nextButton"
              onClick={() => handleSubmit(helocForm)}
              loading={loading}
              buttonAttrs={{ disabled: loading || isSaleProperty }}
            >Next
            </Button>
          </div>
        </div>
      )}
      {props.pageName === 'officer'
      && (
        <HelocOfficerPage
          content={props.content}
          setLo={props.setLo}
          helocForm={helocForm}
          handleSubmit={handleSubmit}
          handleBack={handleBack}
          setLoading={setLoading}
          loanOfficers={props.loanOfficers}
          loading={loading}
        />
      )}
      {props.pageName === 'annual'
      && (
        <div>
          <div className="min-h-[300px]">
            <h4 className="mb-4">{props.content.description}</h4>
            <div className={props.isLite ? 'md:flex md:space-x-4' : 'block'}>
              <TextInput
                name="annualTaxes"
                className="grow"
                disabled={includeTaxes}
                value={annualForm.annualTaxes}
                label={props.content.annual_taxes_label}
                onChange={(input) => handleAnnualChange(input, 'annualTaxes')}
                mask={MASKS.CURRENCY}
                hasError={hasError('annualTaxes', annualForm)}
                helperText={hasError('annualTaxes', annualForm) ? 'required' : undefined}
                required={true}
              />
              <TextInput
                name="annualInsurance"
                value={annualForm.annualInsurance}
                className="grow"
                disabled={includeTaxes}
                label={props.content.annual_insurance_label}
                onChange={(input) => handleAnnualChange(input, 'annualInsurance')}
                mask={MASKS.CURRENCY}
                hasError={hasError('annualInsurance', annualForm)}
                helperText={hasError('annualInsurance', annualForm) ? 'required' : undefined}
                required={true}
              />
            </div>
            {
              props.isLite ? (
                <TextInput
                  name="annualHoa"
                  value={annualForm.annualHoa}
                  label={props.content.annual_hoa_label}
                  onChange={(input) => handleAnnualChange(input, 'annualHoa')}
                  mask={MASKS.CURRENCY}
                />
              )
                : (
                  <div className="flex flex-row mb-4">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={includeTaxes}
                      onChange={() => {
                        setAnnualForm({ ...annualForm, annualInsurance: '', annualTaxes: '' });
                        setIncludeTaxes(!includeTaxes);
                      }}
                    />
                    <h4>{props.content.taxes_included_label}</h4>
                  </div>
                )
            }

          </div>
          <div className="flex justify-center mt-4">
            <Button
              buttonStyle="primary"
              className="!w-full md:!w-48 annualNext"
              onClick={() => handleSubmit(annualForm)}
              loading={loading}
              buttonAttrs={{ disabled: loading }}
            >Next
            </Button>
          </div>
          <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
            <Button
              buttonStyle="quaternary"
              iconPos="left"
              iconName="chevron-left-large"
              onClick={() => handleBack()}
              buttonAttrs={{ disabled: loading }}
            >Back
            </Button>
          </div>
        </div>
      )}
      {(props.pageName === 'occupancy' || props.pageName === 'purpose' || props.pageName === 'propertyType')
      && (
        loading
          ? <PageLoader />
          : (
            <div className="w-full">
              <div className="flex justify-center mb-10 w-full">
                <div className="max-w-md w-full mb-8">
                  <RadioButtons
                    className="radioButton"
                    radios={props.pageName === 'purpose' && !props.isLite ? helocPurposeOptions : options}
                    isChecked={props.pageName === 'purpose' && helocForm.purpose === 'OTHER' ? 'OTHER' : ''}
                    onChange={handleSelect}
                  />
                  {props.pageName === 'purpose' && helocForm.purpose === 'OTHER' && (
                    <div className="flex flex-col justify-center max-w-md w-full">
                      <TextInput
                        name="other"
                        label="Other"
                        value={helocForm.otherReason}
                        onChange={(input) => updateHelocForm(input.target.value, 'otherReason')}
                        required={
                          props.pageName === 'purpose'
                          && helocForm.purpose === 'OTHER'
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
              {props.pageName === 'purpose' && helocForm.purpose === 'OTHER' && (
                <div className="flex justify-center mt-4">
                  <Button
                    buttonStyle="primary"
                    className="!w-full md:!w-48 annualNext"
                    onClick={() => handleSubmitWithOtherOption()}
                    loading={loading}
                    buttonAttrs={{ disabled: !helocForm.otherReason }}
                  >
                    Next
                  </Button>
                </div>
              )}
              <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                <Button
                  buttonStyle="quaternary"
                  iconPos="left"
                  iconName="chevron-left-large"
                  onClick={handleBack}
                  buttonAttrs={{ disabled: loading }}
                >Back
                </Button>
              </div>
            </div>
          )
      )}
    </Form>
  );
};
