import React from 'react';

type IRowData = Record<string, React.ReactNode>;

interface IColumn {
  key: string;
  title: string;
  isClickable?: boolean;
}

interface ICell {
  content: React.ReactNode;
  isClickable?: boolean;
  rowIndex: number;
  onCellClick?: (rowIndex: number) => void;
}

interface ITableRow {
  rowData: IRowData;
  columns: Array<IColumn>;
  rowIndex: number;
  onCellClick?: (rowIndex: number) => void;
}

interface ICustomTableProps {
  rows: Array<IRowData>;
  columns: Array<IColumn>;
  onCellClick?: (rowIndex: number) => void;
  className?: string;
}

const Cell = (props: ICell) => (
  <td
    className="custom-table-td"
    onClick={() => ((props.isClickable && props.onCellClick) ? props.onCellClick(props.rowIndex) : undefined)}
  >
    {props.content}
  </td>
);

const TableRow = (props: ITableRow) => (
  <tr>
    {props.columns.map((column) => (
      <Cell
        key={column.key}
        content={props.rowData[column.key]}
        isClickable={!!column.isClickable}
        rowIndex={props.rowIndex}
        onCellClick={props.onCellClick}
      />
    ))}
  </tr>
);

const CustomTable = (props: ICustomTableProps) => (
  <table className={`custom-table ${props.className}`}>
    <thead>
      <tr>
        {props.columns.map((column) => (
          <th className="custom-table-th" key={column.key}>{column.title}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {props.rows.map((row: IRowData, index: number) => (
        <TableRow
          key={index}
          rowData={row}
          columns={props.columns}
          rowIndex={index}
          onCellClick={props.onCellClick}
        />
      ))}
    </tbody>
  </table>
);

export default CustomTable;
