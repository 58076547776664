/* eslint-disable max-len */
import Cookies from 'js-cookie';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import * as React from 'react';
import { useState } from 'react';
import { formatPhoneDashes, validateEmail, isPhoneValid } from '../../config/util/common';
import { MASKS } from '../../config/util/masks';
import { states, yesNo } from '../../config/content/dropdown';

interface IOwningPreScreenForm {
  price: string;
  state: string;
  downPayment: string;
  percent: string;
  construction: string;
  contractExists: string;
  email: string;
  phone: string;
  selfEmployed: string;
  creditProfile: string;
  veteran: string;
  first: string;
  middle: string;
  last: string;
  suffix: string;
}
const newForm = () => ({
  price: '',
  state: '',
  downPayment: '',
  percent: '',
  construction: 'false',
  contractExists: 'false',
  email: '',
  phone: '',
  selfEmployed: '',
  creditProfile: '',
  veteran: '',
  first: '',
  middle: '',
  last: '',
  suffix: ''
} as IOwningPreScreenForm);

const OwningForm = (props: any) => {
  props.updateHeaderType('one');
  const content = props.pageContent;
  const [loading, setLoading] = useState(false);
  const [owningForm, setOwningForm] = useState(newForm());
  const [construction, setConstruction] = useState(false);
  const [contractExists, setContractExists] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);

  const adTrkURIVal = encodeURIComponent(JSON.stringify(Cookies.get('AdTrk')).replace(/\"/g, ''));
  const owningDisclaimer = content?.disclaimer?.replaceAll('%ADTRK', adTrkURIVal);
  const maxNameLength = 50;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    if (event.target.value === 'Refinance') {
      setOwningForm({ ...owningForm, [name !== '' ? name : event.target.name]: event.target.value, price: '', percent: '', downPayment: '' });
    } else if (event.target.value === 'Purchase' && props.productType === 'Refinance') {
      setOwningForm({ ...owningForm, [name !== '' ? name : event.target.name]: event.target.value, price: '' });
    } else {
      setOwningForm({ ...owningForm, [name !== '' ? name : event.target.name]: event.target.value });
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    let price = parseInt(name === 'price' ? event.target.value.replace('$', '').replaceAll(',', '') : owningForm.price);
    let percent = parseFloat(name === 'percent' ? parseFloat(event.target.value.replace('%', '')).toFixed(2) : owningForm.percent);
    let downPayment = parseInt(name === 'downPayment' ? event.target.value.replace('$', '').replaceAll(',', '') : owningForm.downPayment);
    if (percent > 100) {
      percent = 100;
    }
    if (downPayment > price) {
      downPayment = price;
    }
    if (!Number.isNaN(price) && !Number.isNaN(percent) && name !== 'downPayment') {
      downPayment = Math.round(price * (percent / 100));
      setOwningForm({ ...owningForm, downPayment: `${downPayment}`, percent: `${percent.toFixed(2)}` });
    } else if (!Number.isNaN(downPayment) && !Number.isNaN(price) && (name !== 'percent')) {
      percent = (downPayment / price) * 100;
      if (downPayment > price) {
        percent = 100;
      }
      setOwningForm({ ...owningForm, downPayment: `${downPayment}`, percent: `${percent.toFixed(2)}` });
    }
  };

  const validateKey = (key: string) => {
    if (props.productType === 'Refinance') {
      if (key === 'percent' || key === 'downPayment') {
        return true;
      }
    }
    if (key === 'downPayment') {
      let price = parseInt(owningForm.price);
      let downPayment = parseInt(owningForm.downPayment);
      if (!Number.isNaN(price) && !Number.isNaN(downPayment) && downPayment >= price) {
        return false;
      }
    }
    if (key === 'middle' || key === 'suffix') {
      return true;
    }
    if (key === 'first' || key === 'last') {
      if (owningForm[key].length > maxNameLength) {
        return false;
      }
    }
    if (key === 'phone') {
      return isPhoneValid(owningForm.phone);
    }
    return owningForm[key as keyof IOwningPreScreenForm].length > 0;
  };

  const handleSubmit = async () => {
    setTrySubmit(true);
    const keys = Object.keys(owningForm);
    if (validateEmail(owningForm.email) && keys.every((key) => validateKey(key))) {
      const adTrk = Cookies.get('AdTrk');
      const tcpaAuditId = Cookies.get(`leadid_token-${props.tcpaConfig?.accountCode?.toUpperCase()}-${props.tcpaConfig?.campaignId?.toUpperCase()}`);
      const eligibilityInput = `{ 
        adTrack: "${adTrk}",
        tcpaAuditId: "${tcpaAuditId}",
        product: ${props.productType},
        ${props.productType === 'Refinance'
          ? `loanAmount: ${owningForm.price},`
          : `estimatedHousePrice: ${owningForm.price},
        downPayment: ${owningForm.downPayment},`}
        underConstruction: ${owningForm.construction},
        contractExists: ${owningForm.contractExists},
        propertyTerritory: ${owningForm.state},
        firstName: "${owningForm.first}",
        middleName: "${owningForm.middle}",
        lastName: "${owningForm.last}",
        suffix: "${owningForm.suffix}",
        emailAddress: "${owningForm.email}",
        phoneNumber: "${formatPhoneDashes(owningForm.phone)}",
        selfEmployment: ${owningForm.selfEmployed},
        creditProfile: ${owningForm.creditProfile},
        veteran: ${owningForm.veteran}
      }`;
      const eligibilityInputForDigitalData = `{ 
        "adTrack": "${adTrk}",
        "tcpaAuditId": "${tcpaAuditId}",
        "product": "${props.productType}",
        "${props.productType === 'Refinance' ? 'loanAmount' : 'estimatedHousePrice'}": "${owningForm.price}",
        "downPayment": "${owningForm.downPayment}",
        "underConstruction": ${owningForm.construction},
        "contractExists": ${owningForm.contractExists},
        "propertyTerritory": "${owningForm.state}",
        "selfEmployment": ${owningForm.selfEmployed},
        "creditProfile": "${owningForm.creditProfile}",
        "veteran": ${owningForm.veteran}
      }`;
      (window as any).digitalData = {
        pageInfo: JSON.parse(eligibilityInputForDigitalData)
      };
      const mutation = {
        query:
          `mutation {
          checkSameDayEligibility(data: ${eligibilityInput}) {
            id
            pageContentJson
            eligibilityCheck {
              id
              foundEligible
              dmxGuid
              dmxAppUrl
              nonSameDayDmxGuid
              nonSameDayDmxAppUrl
            }
          }
        }`
      };
      props.handleSubmit(mutation, setLoading);
      return true;
    }
  };

  const hasError = (name: string) => {
    const isError: any = trySubmit && !validateKey(name);
    if (isError) {
      window.scrollTo(0, 0);
    }
    return isError;
  };

  const purchaseTypes = content?.product_type_dropdown ? content.product_type_dropdown.map((option: any) =>
    ({ value: option.type.value, displayName: option.type.label })) : [];
  const creditScores = content?.credit_score_dropdown ? content.credit_score_dropdown.map((option: any) =>
    ({ value: option.type.value, displayName: option.type.label })) : [];

  const updateConstructionCheckbox = () => {
    const value = construction ? 'false' : 'true';
    setOwningForm({ ...owningForm, construction: value });
    setConstruction(!construction);
  };

  const updateConstractExistsCheckbox = () => {
    const value = contractExists ? 'false' : 'true';
    setOwningForm({ ...owningForm, contractExists: value });
    setContractExists(!contractExists);
  };

  return (
    <div>
      <div className="flex justify-center w-full">
        <div className="max-w-2xl w-full px-4">
          <h2 className="font-bold mt-12">{content?.header}</h2>
          <h4 className="text-xl">{content?.description}</h4>
          <div className="mt-4">
            <h4 className="font-bold py-4 text-xl">{content?.loan?.header}</h4>
            <div className="md:grid md:grid-cols-2 md:space-x-4">
              <Dropdown
                name="state"
                value={owningForm.state}
                label={content.loan?.state_label}
                onChange={(event) => handleChange(event, 'state')}
                options={states}
                hasError={hasError('state')}
                helperText={hasError('state') ? 'required field' : undefined}
                className="w-full"
              />
              <TextInput
                name="price"
                value={owningForm.price}
                label={props.productType !== 'Refinance' ? content.loan?.price_label : content.loan.loan_amount_label}
                hasError={hasError('price')}
                helperText={hasError('price') ? 'required field' : undefined}
                onChange={(input) => handleChange(input, 'price')}
                onBlur={(event) => handleBlur(event, 'price')}
                mask={MASKS.CURRENCY}
              />
            </div>
            {props.productType !== 'Refinance' && (
              <div>
                <div className="md:grid md:grid-cols-2 md:space-x-4">
                  <TextInput
                    name="downPayment"
                    value={owningForm.downPayment}
                    label={content.loan?.down_payment_label}
                    hasError={hasError('downPayment')}
                    helperText={hasError('downPayment') ? owningForm.downPayment === '' ? 'required field' : 'Down payment must be less than the purchase price' : undefined}
                    onChange={(input) => handleChange(input, 'downPayment')}
                    onBlur={(event) => handleBlur(event, 'downPayment')}
                    mask={MASKS.CURRENCY}
                  />
                  <TextInput
                    name="percent"
                    value={`${owningForm.percent}%`}
                    label={content.loan?.percent_label}
                    hasError={hasError('percent')}
                    helperText={hasError('percent') ? 'required field' : undefined}
                    onChange={(event) => {
                      event.target.value = event.target.value.replace('%', '');
                      handleChange(event, 'percent');
                    }}
                    onBlur={(event) => handleBlur(event, 'percent')}
                  />
                </div>
                <div className="flex flex-row mb-4">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={construction}
                    onChange={updateConstructionCheckbox}
                    disabled={props.productType === 'Refinance'}
                  />
                  <h4 className="font-bold">{content.loan?.construction_label}</h4>
                </div>
                <div className="flex flex-row mb-4">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={contractExists}
                    onChange={updateConstractExistsCheckbox}
                    disabled={props.productType === 'Refinance'}
                  />
                  <h4 className="font-bold">{content.loan?.purchase_contract_label}</h4>
                </div>
              </div>
            )}
          </div>
          <div className="mt-4 mb-4">
            <h4 className="font-bold text-xl py-4">{content.group?.header}</h4>
            <div className="md:grid md:grid-cols-2 md:space-x-4">
              <div className="grid grid-cols-2 space-x-4">
                <TextInput
                  name="first"
                  value={owningForm.first}
                  label={content.group?.first_name_label}
                  maxLength={maxNameLength}
                  hasError={hasError('first')}
                  helperText={hasError('first') ? 'Please enter a valid name' : undefined}
                  onChange={(input) => handleChange(input, 'first')}
                />
                <TextInput
                  name="middle"
                  value={owningForm.middle}
                  label={content.group?.middle_name_label}
                  maxLength={1}
                  hasError={hasError('middle')}
                  onChange={(input) => handleChange(input, 'middle')}
                />
              </div>
              <div className="grid grid-cols-2 space-x-4">
                <TextInput
                  name="last"
                  value={owningForm.last}
                  label={content.group?.last_name_label}
                  maxLength={maxNameLength}
                  onChange={handleChange}
                  hasError={hasError('last')}
                  helperText={hasError('last') ? 'Please enter a valid name' : undefined}
                />
                <TextInput
                  name="suffix"
                  value={owningForm.suffix}
                  label={content.group?.suffix_label}
                  maxLength={10}
                  hasError={hasError('suffix')}
                  onChange={(event) => handleChange(event, 'suffix')}
                />
              </div>
            </div>
            <TextInput
              name="email"
              value={owningForm.email}
              label={content.group?.email_address}
              onChange={handleChange}
              hasError={trySubmit && !validateEmail(owningForm.email)}
              helperText={trySubmit && !validateEmail(owningForm.email) ? 'Please enter a valid email address' : undefined}
            />
            <div className="md:grid md:grid-cols-1 md:space-x-4">
              <TextInput
                name="phone"
                value={owningForm.phone}
                label={content.group?.phone_number}
                onChange={(input) => handleChange(input, 'phone')}
                hasError={trySubmit && hasError('phone')}
                helperText={trySubmit && hasError('phone') ? 'Please enter a valid phone number' : undefined}
                mask={MASKS.PHONE}
              />
            </div>
            <div className="md:grid">
              <Dropdown
                name="selfEmployed"
                value={owningForm.selfEmployed}
                label={content.group?.self_employed_label}
                onChange={(event) => handleChange(event, 'selfEmployed')}
                className="w-full"
                options={yesNo}
                hasError={hasError('selfEmployed')}
                helperText={hasError('selfEmployed') ? 'required field' : undefined}
              />
              <Dropdown
                name="creditProfile"
                value={owningForm.creditProfile}
                label={content.group?.credit_label}
                onChange={(event) => handleChange(event, 'creditProfile')}
                className="w-full"
                options={creditScores}
                hasError={hasError('creditProfile')}
                helperText={hasError('creditProfile') ? 'required field' : undefined}
              />
              <Dropdown
                name="veteran"
                value={owningForm.veteran}
                label={content.group?.veteran_label}
                onChange={(event) => handleChange(event, 'veteran')}
                className="w-full"
                options={yesNo}
                hasError={hasError('veteran')}
                helperText={hasError('veteran') ? 'required field' : undefined}
              />
            </div>
          </div>
          <div>
            {
              owningDisclaimer?.split('\n').map((line: string, index: number) => (
                <div key={`disclaimer_line_${index}`}>
                  <input type="hidden" id="leadid_tcpa_disclosure" />
                  <div className="text-left text-sm mb-4" key={`disclaimer${index}`} dangerouslySetInnerHTML={{ __html: line }} />
                </div>
              ))
            }
          </div>
          <div className="text-center pt-12">
            <h4 className="font-bold"><FontIcon iconName="shield-check" className="mr-1 !leading-8 text-lg" />{content.subtext}</h4>
          </div>
          <div className="flex justify-center mt-12">
            <Button
              buttonStyle="primary"
              onClick={handleSubmit}
              loading={loading}
              buttonAttrs={{ disabled: loading }}
            >Submit
            </Button>
          </div>
          <div className="mt-8 underline text-center">{content?.enquire_question}</div>
        </div>
      </div>
    </div>
  );
};

export default OwningForm;
