/* eslint-disable max-len */
import { useNavigate } from 'react-router-dom';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Logo from '@guaranteed-rate/react-components/dist/Logo';
import { useState } from 'react';
import Cookies from 'js-cookie';
import { fetchPageContent } from '../../config/util/common';

function OwningLetsDoThis(props: any) {
  const navigate = useNavigate();
  props.updateHeaderType('two');
  const [content, setContent] = useState(null) as any;
  const [contentFetched, setContentFetched] = useState(false);
  const [error, setError] = useState(false);

  if (!content && !contentFetched && !error) {
    fetchPageContent('"owning_lets_do_this"').then(
      (data: any) => {
        if (data.content === 'error') {
          setError(true);
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
        }
      }
    );
  }

  const handleGetStarted = () => {
    navigate('/mortgage/personalizedRates');
  };

  return (
    <div className="bg-[var(--color-data-5)] h-screen pt-8">
      <div className="w-full justify-center">
        <div className="flex items-center justify-center">
          <div className="max-w-2xl w-full px-4">
            <h2 className="font-bold mt-24 text-center text-5xl">{content?.header}</h2>
            <div className="font-bold mt-4 text-center md:text-left">
              {content?.description}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-8 px-4">
          <Button buttonStyle="primary" className="!w-full md:!w-48 mt-4" onClick={handleGetStarted}>
            {content?.get_started_button}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OwningLetsDoThis;
