// states are listed in sequence from start to finish
export const stateKeyToRoute: Record<string, string> = {
  BORROWER_IDENTITY_VALIDATION: 'confirmIdentity',
  INCLUDE_CO_BORROWER_INFO: 'coborrowerExists',
  PRE_FI_DYNAMIC_STATE_START: 'review',
  UPDATE_PERSONAL_INFORMATION: 'personalDetail',
  UPDATE_EMPLOYMENT_INFORMATION: 'employment',
  UPDATE_OTHER_INCOME_SOURCES_INFORMATION: 'otherIncome',
  UPDATE_BORROWER_DEMOGRAPHIC_INFORMATION: 'demographic',
  UPDATE_LANGUAGE_PREFERENCE_INFORMATION: 'languagePreference',
  BORROWER_DECLARATION: 'declarations',
  CO_BORROWER_PRE_FI_DYNAMIC_STATE_START: 'coborrowerReview',
  CO_BORROWER_UPDATE_PERSONAL_INFORMATION: 'coborrowerPersonalDetail',
  CO_BORROWER_UPDATE_EMPLOYMENT_INFORMATION: 'coborrowerEmployment',
  CO_BORROWER_UPDATE_OTHER_INCOME_SOURCES_INFORMATION: 'coborrowerOtherIncome',
  CO_BORROWER_UPDATE_DEMOGRAPHIC_INFORMATION: 'coborrowerDemographic',
  CO_BORROWER_UPDATE_LANGUAGE_PREFERENCE_INFORMATION: 'coborrowerLanguagePreference',
  CO_BORROWER_DECLARATION: 'coborrowerDeclarations',
  UPDATE_CO_BORROWER_SOFT_CREDIT: 'coborrowerConfirmIdentity',
  ASSET_INFO_START: 'confirmAssets',
  LOAN_INFO_START: 'loanDetail',
  SOFT_CREDIT: 'softCredit',
};
export const stateKeyList = Object.keys(stateKeyToRoute);

export const routeToStateKey: Record<string, PrefiStateKey> = Object.entries(stateKeyToRoute).reduce((acc, [stage, route]) => {
  acc[route.toLowerCase()] = stage as PrefiStateKey;
  return acc;
}, {} as Record<string, PrefiStateKey>);

export type PrefiStateKey = keyof typeof stateKeyToRoute;

export const getRouteFromStateKey = (
  currentPanel: string,
  stateKey: PrefiStateKey
) => {
  const route = stateKeyToRoute[stateKey] ?? 'LOAN_INFO_START';
  if (currentPanel === route) {
    return 'loanDetail';
  }
  return route;
};

export const getStateKeyFromRoute = (route: string): PrefiStateKey =>
  routeToStateKey[route.toLowerCase()] ?? 'BORROWER_IDENTITY_VALIDATION';

export const PrefiRoutes = [
  '/confirmIdentity/*',
  '/coborrowerExists/*',
  '/review/*',
  '/personalDetail/*',
  '/coborrowerPersonalDetail/*',
  '/employment/*',
  '/otherIncome/*',
  '/demographic/*',
  '/languagePreference/*',
  '/declarations/*',
  '/coborrowerReview/*',
  '/coborrowerPersonalDetail/*',
  '/coborrowerEmployment/*',
  '/coborrowerOtherIncome/*',
  '/coborrowerDemographic/*',
  '/coborrowerLanguagePreference/*',
  '/coborrowerDeclarations/*',
  '/coborrowerConfirmIdentity/*',
  '/confirmAssets/*',
  '/loanDetail/*',
  '/softCredit/*',
] satisfies Array<string>;
