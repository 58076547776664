/* eslint-disable quote-props */
export const MASKS = {
  CURRENCY: {
    mask: '$AMOUNT',
    unmask: true,
    blocks: {
      AMOUNT: {
        mask: Number,
        max: 999_999_999.99,
        radix: '.',
        thousandsSeparator: ',',
        scale: 2
      }
    }
  },
  CURRENCY_WITHOUT_DECIMAL: {
    mask: '$AMOUNT',
    unmask: true,
    blocks: {
      AMOUNT: {
        mask: Number,
        max: 999_999_999_999,
        thousandsSeparator: ',',
        digits: '0'
      }
    }
  },
  ROUTING: {
    mask: '000000000',
    unmask: true,
  },
  PERCENT: {
    mask: 'AMOUNT%',
    unmask: true,
    eager: true,
    blocks: {
      AMOUNT: {
        mask: Number,
        thousandsSeparator: ',',
        max: 100,
        min: 0,
        scale: 0,
      }
    }
  },
  INTEREST: {
    mask: '%AMOUNT',
    unmask: true,
    eager: true,
    blocks: {
      AMOUNT: {
        mask: Number,
        radix: '.',
        signed: false,
        padFractionalZeros: true,
        max: 20.00,
        min: 0.00,
        scale: 2,
      }
    }
  },
  PHONE: {
    mask: '(000) 000-0000',
    unmask: true,
  },
  SSN: {
    mask: '000-00-0000',
    unmask: true,
  },
  SSN_PW: {
    mask: 'AMOUNT',
    unmask: true,
    eager: true,
    blocks: {
      AMOUNT: {
        mask: Number,
        signed: false,
        max: 999999999,
      }
    }
  },
  SSN_LAST_FOUR: {
    mask: 'AMOUNT',
    unmask: true,
    eager: true,
    blocks: {
      AMOUNT: {
        mask: Number,
        signed: false,
        max: 9999,
      }
    }
  },
  DATE: {
    mask: '00/00/0000',
    permanents: [2, 5] // permanents is an array of indexes of the non-editable characters in the mask
  },
};
