import { IBorrowerCreditScore } from '../../../config/util/interfaces';

export const ACCEPTABLE_CREDIT_THRESHOLD = 580;

export type PrefiSoftCreditStatus =
  | 'loading'
  | 'error'
  | 'poorCredit'
  | 'goodCredit';

interface CalculatePrefiCreditStatusParams {
  creditReportFetchLoading: boolean;
  creditReport?: IBorrowerCreditScore | null | undefined;
  creditReportOfCoBorrower?: IBorrowerCreditScore | null | undefined;
}

export const calculatePrefiCreditStatus = ({
  creditReportFetchLoading,
  creditReport,
  creditReportOfCoBorrower,
}: CalculatePrefiCreditStatusParams): PrefiSoftCreditStatus => {
  if (creditReportFetchLoading) {
    return 'loading';
  }

  // primary borrower's credit must be present
  // if coborrower's credit report is present, it must also contain a score
  const hasSuccessfullyFetchedCreditScoreForAllBorrowers = !!(
    creditReport?.creditScore
    && !(creditReportOfCoBorrower
    && !creditReportOfCoBorrower.creditScore)
  );
  if (!hasSuccessfullyFetchedCreditScoreForAllBorrowers) {
    return 'error';
  }

  // credit score must be above the minimum threshold
  const creditScoreToUse = creditReport.creditScore && creditReportOfCoBorrower?.creditScore
    ? Math.min(creditReportOfCoBorrower.creditScore, creditReport.creditScore)
    : creditReport.creditScore || 0; // if coborrower present, use lower of the two applicants' score
  const hasPoorCreditScore = creditScoreToUse < ACCEPTABLE_CREDIT_THRESHOLD;
  if (hasPoorCreditScore) {
    return 'poorCredit';
  }

  return 'goodCredit';
};
