import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import Form from '../../../components/Form/Form';
import { MASKS } from '../../../config/util/masks';
import { IBasicInfoData, IIncomeData } from '../../../config/util/interfaces';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { loadDate } from '../../../config/util/common';
import { config } from '../../../config/content/config';

interface ICompleteProps {
  content: any;
  pageName: string;
  basicInfoData: IBasicInfoData;
  progress?: ProgressProps;
}

export const HelocCompletePage = (props: ICompleteProps) => {
  // submits the income data to the orchestrator and moves to the next state
  const handleSubmit = async () => {
    window.location.href = `${(config.myAccountUrl as any)[(window as any).env]}/okta/login?email=${props.basicInfoData.emailId}`;
  };

  return (
    <Form title={props.content.header} progress={props.progress}>
      <div className="min-h-[300px]">
        <h4>{props.content.description}</h4>
        <div className="flex justify-center mt-12">
          <Button
            buttonStyle="primary"
            className="!w-full md:!w-64 nextButton"
            onClick={handleSubmit}
          >{props.content.cta}
          </Button>
        </div>
      </div>
    </Form>
  );
};
