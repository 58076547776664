import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import Cookies from 'js-cookie';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { IBasicInfoData, IClosingResponse } from '../../../config/util/interfaces';
import { helocGraphQLRequest } from '../../../config/content/constants';

interface ISchedulingProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  progress?: ProgressProps;
  closingResponse: IClosingResponse;
  applicationId: string;
}

export const HelocSchedulingPage = (props: ISchedulingProps) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const pageData = `{ 
        page: "${props.pageName}"
      }`;
    await props.handleSubmit(pageData, false);
    setLoading(false);
  };

  const pollApplication = async () => {
    const query = {
      query:
        `{
          getHelocApplicationState(id: "${props.applicationId || ''}")
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(query)
    });
    try {
      const data = await resp.json();
      if (data?.data?.getHelocApplicationState === 'CLOSING_SCHEDULING_COMPLETED'
          || data?.data?.getHelocApplicationState === 'CLOSING_DOCUMENTS_GENERATION_IN_PROGRESS'
          || data?.data?.getHelocApplicationState === 'CLOSING_DOCUMENTS_GENERATION_COMPLETED'
      ) {
        handleSubmit();
      } else {
        setTimeout(pollApplication, 15000);
      }
    } catch (e) {
      setTimeout(pollApplication, 15000);
    }
  };

  const onIframeLoaded = (closingData: IClosingResponse) => {
    if (closingData !== null) {
      const iframe = document.getElementById('closingIframe') as HTMLIFrameElement;
      iframe.contentWindow?.postMessage(closingData.credential, closingData.platformUrl);
    }
  };

  pollApplication();

  return (
    <Form title={props.content.header} progress={props.progress}>
      <div>
        { props.closingResponse
          ? (
            <iframe
              title="closingIframe"
              id="closingIframe"
              src="https://svclnk.uat2.exostechnology.com/app/consumer/lenderscheduling.html"
              className="w-full min-h-[1000px]"
              onLoad={() => onIframeLoaded(props.closingResponse)}
            />
          )
          : (
            <div className="min-h-[420px]">
              <div className="flex items-center justify-center">
                <Loader color="#D13239" className="loader-medium" />
              </div>
            </div>
          ) }
      </div>
    </Form>
  );
};
