/* eslint-disable jsx-a11y/no-static-element-interactions */
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import { useCallback, useEffect, useState } from 'react';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import Form from '../../../components/Form/Form';
import { MASKS } from '../../../config/util/masks';
import { IEmploymentIncome } from '../../../config/util/interfaces';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import {
  dateDiffInYears,
  getDateAfterOrBeforeYear,
  listToGraphQl,
  loadDate,
  localeOptions2Digits,
} from '../../../config/util/common';
import PageLoader from '../../../components/PageLoader/PageLoader';
import CalendarInput from '../../../components/CalendarInput/CalendarInput';

interface IIncomeProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  employmentData: Array<IEmploymentIncome>;
  progress?: ProgressProps;
}

interface IEmploymentTypeOption {
  displayName: string;
  value: string;
}

const newEmploymentData = () =>
  ({
    incomeSource: '',
    employerName: '',
    startDate: '',
    endDate: '',
    annualPay: 0,
    currentEmployment: false,
    ownershipInterestGreaterThanOrEqualTo25Percent: null,
  } as IEmploymentIncome);

export const HelocFullIncomePage = (props: IIncomeProps) => {
  const [incomForm, setIncomeForm] = useState<Array<IEmploymentIncome>>([]);
  const [employmentForm, setEmploymentForm] = useState(newEmploymentData());
  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const [addPage, setAddPage] = useState(false);
  const [updatedIndex, setUpdatedIndex] = useState<number | undefined>();

  // First load for income data
  useEffect(() => {
    if (props.employmentData.length === 1 && !props.employmentData?.[0]?.startDate) {
      setEmploymentForm({ ...props.employmentData[0], currentEmployment: false });
      setAddPage(true);
    } else {
      setIncomeForm(props.employmentData);
    }
  }, []);

  // Load the income types from content stack
  const employmentTypes = props.content?.employment_type?.map((option: any) => ({
    value: option.type.value,
    displayName: option.type.label,
  }));

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name = ''
  ) => {
    setEmploymentForm({
      ...employmentForm,
      [name !== '' ? name : event.target.name]: event.target.value,
    });
  };

  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name = ''
  ) => {
    setEmploymentForm({
      ...employmentForm,
      [name !== '' ? name : event.target.name]: parseFloat(
        event.target.value?.length > 0 ? event.target.value : '0'
      ),
    });
  };

  // submits the income data to the orchestrator and moves to the next state
  const handleSubmit = async () => {
    setTrySubmit(true);
    setLoading(true);
    handleAddCancel();
    const pageData = `{ 
        page: "${props.pageName}"
        updateIncomeInput: ${listToGraphQl(incomForm)}
      }`;
    await props.handleSubmit(pageData, false);
    setLoading(false);
  };

  const hasError = (name: string, form: any) =>
    !form[name] || form[name] === 0 || `${form[name]}`.length <= 0;

  const handleAddCancel = () => {
    setEmploymentForm(newEmploymentData());
    setAddPage(false);
    setTrySubmit(false);
    setUpdatedIndex(undefined);
    window.scrollTo(0, 0);
  };
  const handleDelete = () => {
    const list = [...incomForm];
    if (updatedIndex !== undefined && list.length > updatedIndex) {
      list.splice(updatedIndex, 1);
    }
    setIncomeForm(list);
    handleAddCancel();
  };
  const handleAddSubmit = () => {
    setTrySubmit(true);
    if (
      !employmentForm.incomeSource
      || !employmentForm.annualPay
      || !employmentForm.startDate
      || (employmentForm.currentEmployment
        ? false
        : !employmentForm.endDate)
      || (showNameField(employmentForm) ? !employmentForm.employerName : false)
      || (showBussinessOwnershipRadioButton(employmentForm) && employmentForm.ownershipInterestGreaterThanOrEqualTo25Percent === null)
      || validateStartDate()
    ) {
      return;
    }
    let incomeFormData: Array<IEmploymentIncome> = [...incomForm];
    if (updatedIndex !== undefined) {
      incomeFormData[updatedIndex] = employmentForm;
    } else {
      incomeFormData = [...incomeFormData, employmentForm];
    }
    setIncomeForm(incomeFormData);
    handleAddCancel();
  };

  const handleEmploymentEdit = (employment: IEmploymentIncome, index: number) => {
    setEmploymentForm(employment);
    setUpdatedIndex(index);
    setAddPage(true);
    window.scrollTo(0, 0);
  };

  const getIncome = (employment: IEmploymentIncome) => {
    const income = employment.annualPay || 0;
    return income.toLocaleString('en-us', localeOptions2Digits);
  };

  const getEmploymentType = (employment: IEmploymentIncome) =>
    employmentTypes.find(
      ({ value }: IEmploymentTypeOption) => value === employment.incomeSource
    );

  const showNameField = (employment: IEmploymentIncome) =>
    ['EMPLOYED_FULL_TIME', 'SELF_EMPLOYED'].includes(employment.incomeSource);

  const showBussinessOwnershipRadioButton = (employment: IEmploymentIncome) => employment.incomeSource === 'SELF_EMPLOYED';

  const nameFieldLabel: string = showNameField(employmentForm)
    ? employmentForm.incomeSource === 'EMPLOYED_FULL_TIME'
      ? props.content.employment.name_label
      : props.content.employment.business_name_label
    : '';

  const getDropdownOptions = useCallback(() => {
    const hideOther = incomForm.find(
      ({ incomeSource }: IEmploymentIncome, index: number) =>
        incomeSource === 'OTHER' && index !== updatedIndex
    );
    if (hideOther) {
      return employmentTypes.slice(0, -1);
    }
    return employmentTypes;
  }, [incomForm]);

  const validateStartDate = useCallback(() => {
    if (hasError('startDate', employmentForm)) {
      return 'required';
    }
    if (employmentForm.endDate && dateDiffInYears(new Date(employmentForm.startDate), new Date(employmentForm.endDate)) > 120) {
      return 'Date is too far in the past';
    }

    return '';
  }, [employmentForm.endDate, employmentForm.startDate]);

  const renderForm = () => {
    const startDateError: string = validateStartDate();

    return (
      <div>
        <Dropdown
          name="incomeSource"
          value={employmentForm.incomeSource}
          label={props.content.employment.type_label}
          onChange={(event) => {
            handleChange(event, 'incomeSource');
            setEmploymentForm((updatedEmploymentForm) => ({
              ...updatedEmploymentForm,
              employerName: '',
            }));
          }}
          className="w-full employmentTypeDropdown"
          options={getDropdownOptions()}
          hasError={trySubmit && hasError('incomeSource', employmentForm)}
          required={true}
          helperText={trySubmit && hasError('incomeSource', employmentForm) ? 'required' : undefined}
        />
        {showNameField(employmentForm) && (
          <TextInput
            name="employerName"
            value={employmentForm.employerName}
            label={nameFieldLabel}
            onChange={(event) => handleChange(event, 'employerName')}
            hasError={trySubmit && hasError('employerName', employmentForm)}
            required={true}
            helperText={trySubmit && hasError('employerName', employmentForm) ? 'required' : ''}
          />
        )}
        {
          showBussinessOwnershipRadioButton(employmentForm) && (
            <div>
              <p>
                {props.content.employment.business_ownership_flag_label}
                <span style={{ color: '#d13239' }}>*</span>
              </p>
              <div className="flex justify-center w-full">
                <div className="w-full max-w-md">
                  <RadioButtons
                    isChecked=""
                    className="radioButton"
                    hasError={trySubmit && employmentForm.ownershipInterestGreaterThanOrEqualTo25Percent === null}
                    radios={[
                      {
                        active: employmentForm.ownershipInterestGreaterThanOrEqualTo25Percent || false,
                        label: 'Yes',
                        name: 'businessOwnershipYes',
                        value: 'yes',
                      },
                      {
                        active: employmentForm.ownershipInterestGreaterThanOrEqualTo25Percent === false,
                        label: 'No',
                        name: 'businessOwnershipYes',
                        value: 'No'
                      },
                    ]}
                    onChange={() => setEmploymentForm((updatedEmploymentForm) => ({
                      ...updatedEmploymentForm,
                      ownershipInterestGreaterThanOrEqualTo25Percent: !employmentForm.ownershipInterestGreaterThanOrEqualTo25Percent,
                    }))}
                  />
                </div>
              </div>
            </div>
          )
        }
        <TextInput
          name="annualPay"
          value={`${employmentForm.annualPay || ''}`}
          label={props.content.employment.annual_pay_label}
          onChange={(event) => handleNumberChange(event, 'annualPay')}
          mask={MASKS.CURRENCY}
          hasError={trySubmit && hasError('annualPay', employmentForm)}
          helperText={trySubmit && hasError('annualPay', employmentForm) ? 'required' : ''}
          required={true}
        />

        <div className="flex flex-row mb-4">
          <div>
            <input
              type="checkbox"
              className="checkbox"
              checked={employmentForm.currentEmployment}
              onChange={() => {
                setEmploymentForm({
                  ...employmentForm,
                  currentEmployment:
                  !employmentForm.currentEmployment,
                  endDate: ''
                });
              }}
            />
          </div>
          <h4>
            <div className="text-sm text-left">
              {props.content.employment.current_label}
            </div>
          </h4>
        </div>
        <div
          className={
            employmentForm.currentEmployment
              ? 'w-full'
              : 'md:grid md:grid-cols-2'
          }
        >
          <div className="w-full">
            <CalendarInput
              label={props.content.employment.start_label}
              className="startDate"
              name="startDate"
              setForm={setEmploymentForm}
              form={employmentForm}
              required
              maxDate={
                (!employmentForm.currentEmployment && employmentForm.endDate && new Date(employmentForm.endDate))
              || undefined
              }
              hasError={trySubmit && !!startDateError}
              helperText={trySubmit ? startDateError : ''}
            />
          </div>
          {!employmentForm.currentEmployment && (
            <CalendarInput
              label={props.content.employment.end_label}
              className="endDate md:ml-4"
              name="endDate"
              setForm={setEmploymentForm}
              form={employmentForm}
              minDate={employmentForm.startDate ? new Date(employmentForm.startDate) : getDateAfterOrBeforeYear(-120)}
              required
              hasError={trySubmit && hasError('endDate', employmentForm)}
              helperText={trySubmit && hasError('endDate', employmentForm) ? 'required' : ''}
            />
          )}
        </div>
        <div className="flex justify-between mb-16">
          {updatedIndex !== undefined ? (
            <Button
              buttonStyle="tertiary"
              className="!w-full md:!w-24"
              iconName="trash"
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
          ) : <div className="!w-full md:!w-24" />}
          <div className="flex justify-center md:justify-end">
            <Button
              buttonStyle="secondary"
              className="!w-full md:!w-48 mr-4"
              onClick={handleAddCancel}
            >
              Cancel
            </Button>
            <Button
              buttonStyle="primary"
              className="!w-full md:!w-48 saveButton"
              onClick={() => handleAddSubmit()}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : (
        <Form title={props.content.description} progress={props.progress}>
          <div className="min-h-[300px]">
            <div>
              <h4 className="mb-4">{props.content.description}</h4>
              {incomForm.length > 0 && (
                <div className="mb-8">
                  {incomForm.map((employment: IEmploymentIncome, index: number) =>
                    (updatedIndex === index ? (
                      <div key={`employment${index}`}>{renderForm()}</div>
                    ) : (
                      <div
                        className="rounded-lg border-2 p-4 cursor-pointer mb-4 employmentCard"
                        key={`employment${index}`}
                        onClick={() =>
                          !loading && handleEmploymentEdit(employment, index)}
                      >
                        <div className="flex justify-between">
                          <div className="w-full">
                            <div className="flex">
                              <div className="flex flex-col justify-center">
                                <FontIcon
                                  className="text-emerald-500 text-2xl mr-4"
                                  iconName="check-tick-circle-filled"
                                />
                              </div>
                              <div>
                                <h4 className="font-bold">
                                  {showNameField(employment)
                                    ? employment.employerName
                                    : getEmploymentType(employment)
                                      ?.displayName}
                                </h4>
                                {showNameField(employment) && (
                                  <h4 className="text-sm">
                                    Employent Type:{' '}
                                    <span className="font-bold ml-2">
                                      {
                                        getEmploymentType(employment)
                                          ?.displayName
                                      }
                                    </span>
                                  </h4>
                                )}
                                <h4 className="text-sm">
                                  Total Income:{' '}
                                  <span className="font-bold ml-2">
                                    {getIncome(employment)}
                                  </span>
                                </h4>
                                <h4 className="text-sm">
                                  Start date:
                                  <span className="font-bold ml-2">
                                    {employment.startDate
                                      ? loadDate(
                                        employment.startDate
                                      ).toLocaleDateString('en-us')
                                      : '-'}
                                  </span>
                                  {employment.endDate ? (
                                    <span className="ml-4">
                                      End date:
                                      <span className="font-bold ml-2">
                                        {employment.endDate
                                          ? loadDate(
                                            employment.endDate
                                          ).toLocaleDateString('en-us')
                                          : '-'}
                                      </span>
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </h4>
                              </div>
                            </div>
                          </div>
                          <div>
                            <Button
                              className="h-2.5"
                              buttonStyle="tertiary"
                              buttonSize="small"
                            >
                              Edit
                            </Button>
                          </div>
                        </div>
                      </div>
                    )))}
                </div>
              )}
              {!addPage && (
                <Button
                  className="font-bold pt-4 !border-0 addEmployment"
                  buttonStyle="tertiary"
                  buttonAttrs={{ disabled: loading }}
                  onClick={() => {
                    setAddPage(true);
                  }}
                >
                  <FontIcon
                    iconName="plus-circle"
                    className="text-xl mr-2 !leading-loose"
                  />
                  <span className="text-base">
                    {props.content.add_employment_button}
                  </span>
                </Button>
              )}
            </div>
            {addPage && updatedIndex === undefined && renderForm()}
          </div>
          {!addPage && (
            <div>
              <div className="flex justify-center mt-12">
                <Button
                  buttonStyle="primary"
                  className="!w-full md:!w-48 nextButton"
                  onClick={handleSubmit}
                  loading={loading}
                  buttonAttrs={{ disabled: loading || incomForm.length === 0 }}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
        </Form>
      )}
    </div>
  );
};
