const RateAlertLogo = ({ alt }: { alt: string }) => (
  <img
    className="max-w-[150px] md:max-w-[220px]"
    src="/images/logo-rate-alert.svg"
    width="220"
    height="35"
    alt={alt}
  />
);

export default RateAlertLogo;
