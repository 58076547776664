/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Button from '@guaranteed-rate/react-components/dist/Button';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import UploadButton from '../../../components/UploadButton/UploadButton';
import { isMobile, objectToGraphQl } from '../../../config/util/common';
import Banner from '../../../components/Banner/Banner';
import useS3Upload, { IUploadResponse } from '../../../config/hooks/useS3Upload';
import Alert from '../../../components/Alert/Alert';

interface IVerifyProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  progress?: ProgressProps;
  isLite?: boolean;
  applicationId: string;
}
interface IDocument {
  name: string;
  label: string;
  value: string;
  active: boolean;
}

interface IDocumentUploadMessage {
  msg: string;
  variant: any;
}

interface IDocumentUploadRequest {
  documentType: string;
  firstDocument: IUploadResponse;
  secondDocument?: IUploadResponse;
}

const BANNER_LIST = Object.freeze(
  {
    success: 'success',
    error: 'error'
  }
);

export const HelocManualUpload = (props: IVerifyProps) => {
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState('' as any);
  const [uploadPrompt, setUploadPrompt] = useState('');
  const [documentFront, setDocumentFront] = useState('');
  const [frontDocumentFile, setFrontDocumentFile] = useState<File>();
  const [fileNameFront, setFileNameFront] = useState('');
  const [documentBack, setDocumentBack] = useState('');
  const [fileNameBack, setFileNameBack] = useState('');
  const [backDocumentFile, setBackDocumentFile] = useState<File>();
  const deviceIsMobile: boolean = isMobile();
  const [showSmsLinkScreen, setShowSmsLinkScreen] = useState(!deviceIsMobile);
  const [bannerType, setBannerType] = useState<string>('');
  const [docFrontMsg, setDocFrontMsg] = useState<IDocumentUploadMessage>({ msg: '', variant: '' });
  const [docBackMsg, setDocBackMsg] = useState<IDocumentUploadMessage>({ msg: '', variant: '' });
  const [smsErrorMessage, setSMSErrorMessage] = useState<string>('');
  const { uploadFileToS3, fetchError } = useS3Upload(props.applicationId, 'ID_VERIFICATION', 2);

  const options = props.content.documents.map((item: any) => ({
    name: 'documents',
    label: item.document.label,
    value: item.document.value,
    count: item.document.image_count,
    active: false
  } as IDocument));

  const handleSelect = (value: string) => {
    const document = options.find((option: IDocument) => option.value === value);
    if (documentType !== document) {
      if (documentFront) {
        setDocumentFront('');
        setFileNameFront('');
        setDocFrontMsg({ msg: '', variant: '' });
      }
      if (documentBack) {
        setDocumentBack('');
        setFileNameBack('');
        setDocBackMsg({ msg: '', variant: '' });
      }
    }
    setDocumentType(document);
    setUploadPrompt(`Photos of your ${document.label}.${document.count === 1 ? '' : ' Front and back.'}`);
  };

  const getSmsLinkErrorMessage = (message: string, content: any) => {
    switch (true) {
    case message.includes('ID Verification SMS sending retries exhausted'):
      return content?.sending_sms_retries_exhausted_error_label;
    case message.includes('Minimum time gap required to resend'):
      return content?.min_gap_to_resend_error_label;
    case message.includes('Application link tracking expired'):
      return content?.application_link_tracking_expired_error_label;
    default:
      return '';
    }
  };

  const handleSubmit = async () => {
    if (canSubmit() && frontDocumentFile && (documentType.count > 1 ? backDocumentFile : true)) {
      setLoading(true);
      let uploadedFileDetails: any = [];
      try {
        let fileList = [frontDocumentFile];
        if (backDocumentFile) {
          fileList.push(backDocumentFile);
        }
        uploadedFileDetails = await uploadFileToS3(fileList);
      } catch (error) {
        setLoading(false);
        return;
      }

      let identityRequestData: IDocumentUploadRequest = {
        documentType: documentType.value,
        firstDocument: {
          fileName: uploadedFileDetails[0].fileName,
          uuid: uploadedFileDetails[0].uuid
        }
      };

      if (uploadedFileDetails?.[1]?.fileName) {
        identityRequestData.secondDocument = {
          fileName: uploadedFileDetails[1].fileName,
          uuid: uploadedFileDetails[1].uuid
        };
      }

      const pageData = `{
        page: "${props.pageName}"
        ${props.isLite ? 'lite: true' : ''}
        identityData: ${objectToGraphQl(identityRequestData)}
      }`;
      (window as any).digitalData = {
        pageInfo: {
          identityVerificationData: {
            type: documentType.value,
          }
        }
      };
      await props.handleSubmit(pageData, false);
      setLoading(false);
    }
  };

  const handleBack = async () => {
    if (documentType !== '') {
      setDocumentType('');
    } else {
      setLoading(true);
      const pageData = `{ 
      page: "${props.pageName}"
      back: true
    }`;
      await props.handleSubmit(pageData, true);
      setLoading(false);
    }
  };

  const handleSendSmsLink = async () => {
    setLoading(true);
    const mutation = {
      query:
        `mutation {
          sendSmsLink(id: "${props.applicationId}")
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.sendSmsLink) {
        setBannerType(BANNER_LIST.success);
      } else if (data.errors[0]?.message) {
        setSMSErrorMessage(getSmsLinkErrorMessage(data.errors[0]?.message, props.content));
        setBannerType(BANNER_LIST.error);
      } else {
        setBannerType(BANNER_LIST.error);
      }
    } catch (e) {
      setBannerType(BANNER_LIST.error);
    } finally {
      setLoading(false);
    }
  };

  const manualDocumentUpload = (event: any, setFunction: any, setName: any, setFrontFile: any) => {
    let reader = new FileReader();
    let baseString;
    reader.onloadend = function () {
      const fileSizeInMB = event.target.files[0].size / 1024 / 1024;
      const sizeInMB = Math.ceil(fileSizeInMB);
      if (!props?.content?.accepted_file_types.includes(event.target.files[0].type)) {
        if (event.target.id === 'frontFile') {
          setDocFrontMsg({ msg: props?.content?.file_type_front_error_label, variant: 'error' });
        } else {
          setDocBackMsg({ msg: props?.content?.file_type_back_error_label, variant: 'error' });
        }
      } else if (sizeInMB > 10) {
        if (event.target.id === 'frontFile') {
          setDocFrontMsg({ msg: props?.content?.size_exceeded_front_error_label, variant: 'error' });
        } else {
          setDocBackMsg({ msg: props?.content?.size_exceeded_back_error_label, variant: 'error' });
        }
      } else if (event.target.id === 'frontFile') {
        setDocFrontMsg({ msg: props?.content?.file_upload_success_label, variant: 'success' });
      } else {
        setDocBackMsg({ msg: props?.content?.file_upload_success_label, variant: 'success' });
      }
      baseString = reader.result;
      setFunction(baseString);
      setFrontFile(event.target.files[0]);
    };
    if (event?.target?.files[0]) {
      setName(event?.target?.files[0].name);
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const canSubmit = () => (docFrontMsg.variant !== 'error' && docBackMsg.variant !== 'error') && documentType
  && documentFront !== '' && fileNameFront !== '' && (documentType.count > 1 ? documentBack !== '' && fileNameBack !== '' : true);

  if (showSmsLinkScreen) {
    const SendSmsBanner = () => {
      switch (bannerType) {
      case BANNER_LIST.success:
        return <Banner className="mb-4" text="" title={props.content.send_sms_link.success_message} type="success" />;

      case BANNER_LIST.error:
        return <Banner className="mb-4" text="" title={smsErrorMessage || props.content.send_sms_link.error_message} type="error" />;
      default:
        return null;
      }
    };
    return (
      <Form title={props.content.header} progress={props.progress}>
        {SendSmsBanner()}
        {fetchError && (
          <div className="mb-4">
            <Alert text={props.content.file_upload_error} type="error" icon="warning-triangle" />
          </div>
        )}
        <div className="min-h-[300px]">
          <p className="mb-4">{props.content.description} {props.content.send_sms_link.description_mobile_1}</p>
          <p className="mb-4">{props.content.send_sms_link.description_mobile_2}</p>
        </div>
        <div className="flex justify-around md:flex-row-reverse flex-col gap-4">
          <Button
            buttonStyle="primary"
            className="!w-full md:!w-72 nextButton"
            onClick={handleSendSmsLink}
            loading={loading}
            buttonAttrs={{ disabled: loading }}
          >Send text to mobile phone
          </Button>
          <Button
            buttonStyle="secondary"
            className="!w-full md:!w-64 nextButton"
            onClick={() => setShowSmsLinkScreen(false)}
            buttonAttrs={{ disabled: loading }}
          >Continue on desktop
          </Button>
          {props.isLite && (
            <Button
              buttonStyle="quaternary"
              iconPos="left"
              iconName="chevron-left-large"
              onClick={handleBack}
              buttonAttrs={{ disabled: loading }}
            >
              Back
            </Button>
          )}
        </div>
      </Form>
    );
  }

  const DeleteAction = ({ setDocument, setDocMsg, setFileName }: any) => (
    <div
      className="flex justify-center items-center mt-3"
      role="button"
      tabIndex={0}
      onClick={() => { setDocument(''); setDocMsg({ msg: '', variant: '' }); setFileName(''); }}
    >
      <FontIcon
        className="danger text-red-600 font-bold text-[24px] !leading-6 cursor-pointer"
        iconName="trash"
      />
    </div>
  );

  return (
    <Form title={props.content.header} progress={props.progress}>
      <div className="min-h-[300px]">
        {
          !documentType
            ? (
              <div>
                <p>{props.content.description}</p>
                <h4 className="font-bold mt-4 mb-4">{props.content.options_label}</h4>
                <div className="flex justify-center w-full">
                  <div className="w-full max-w-md">
                    <RadioButtons
                      className="radioButton documentButton"
                      radios={options}
                      onChange={(event) => handleSelect(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            )
            : (
              <div>
                <p>{props.content.upload_description}</p>
                <h4 className="font-bold mt-4 mb-4 text-xl">{uploadPrompt}</h4>
                { documentType.count === 1
                  ? (
                    <div className="flex flex-col items-center">
                      {docFrontMsg.msg && (
                        <Banner
                          className="mb-3 whitespace-nowrap !w-[300px]"
                          text=""
                          title={docFrontMsg.msg}
                          type={docFrontMsg.variant}
                        />
                      )}
                      <div className="flex justify-center">
                        <input
                          type="file"
                          id="frontFile"
                          accept={props?.content?.accepted_file_types}
                          className="hidden"
                          onChange={(event) => manualDocumentUpload(event, setDocumentFront, setFileNameFront, setFrontDocumentFile)}
                          disabled={loading}
                        />
                        <UploadButton
                          front={true}
                          fileId="frontFile"
                          imageSrc={documentFront}
                        />
                      </div>
                      {
                        documentFront !== ''
                      && <DeleteAction setDocument={setDocumentFront} setDocMsg={setDocFrontMsg} setFileName={setFileNameFront} />
                      }
                    </div>
                  )
                  : (
                    <div className="flex justify-center min-h-[620px] md:min-h-full">
                      <div className="md:flex md:justify-center mb-5">
                        <div className="mb-4 md:mr-4 md:mb-0 min-h-[70px] w-[300px]">
                          <Banner
                            className={`mb-3 !p-3 h-[48px] whitespace-nowrap ${docFrontMsg.msg ? 'visible' : 'invisible'}`}
                            text=""
                            title={docFrontMsg.msg}
                            type={docFrontMsg.variant}
                          />
                          <input
                            type="file"
                            id="frontFile"
                            accept={props?.content?.accepted_file_types}
                            className="hidden"
                            onChange={(event) => manualDocumentUpload(event, setDocumentFront, setFileNameFront, setFrontDocumentFile)}
                            disabled={loading}
                          />
                          <UploadButton
                            className="flex justify-center"
                            front={true}
                            fileId="frontFile"
                            imageSrc={documentFront}
                          />
                          {
                            documentFront !== ''
                           && <DeleteAction setDocument={setDocumentFront} setDocMsg={setDocFrontMsg} setFileName={setFileNameFront} />
                          }
                        </div>
                        <div className="h-[70px] w-[300px]">
                          <Banner
                            className={`mb-3 !p-3 h-[48px] whitespace-nowrap ${docBackMsg.msg ? 'visible' : 'invisible'}`}
                            text=""
                            title={docBackMsg.msg}
                            type={docBackMsg.variant}
                          />
                          <input
                            type="file"
                            id="backFile"
                            accept={props?.content?.accepted_file_types}
                            className="hidden"
                            onChange={(event) => manualDocumentUpload(event, setDocumentBack, setFileNameBack, setBackDocumentFile)}
                            disabled={loading}
                          />
                          <UploadButton
                            className="flex justify-center"
                            front={false}
                            fileId="backFile"
                            imageSrc={documentBack}
                          />
                          {
                            documentBack !== ''
                          && <DeleteAction setDocument={setDocumentBack} setDocMsg={setDocBackMsg} setFileName={setFileNameBack} />
                          }
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )
        }
      </div>
      <div className="flex justify-center mt-20">
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-64 nextButton"
          onClick={handleSubmit}
          loading={loading}
          buttonAttrs={{ disabled: !canSubmit() || loading }}
        >Next
        </Button>
      </div>
      {
        (!showSmsLinkScreen && (!deviceIsMobile || documentType)) && (
          <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
            <Button
              buttonStyle="quaternary"
              iconPos="left"
              iconName="chevron-left-large"
              onClick={() => {
                if (!deviceIsMobile && !showSmsLinkScreen && !documentType) {
                  setShowSmsLinkScreen(true);
                } else {
                  handleBack();
                }
              }}
              buttonAttrs={{ disabled: loading }}
            >Back
            </Button>
          </div>
        )
      }

      <div className="text-center mt-12">
        <p className="font-semibold text-xs">{props?.content?.accepted_file_types_hint_label}</p>
        <p className="font-semibold text-xs">{props?.content?.accepted_file_size_hint_label}</p>
      </div>
    </Form>
  );
};
