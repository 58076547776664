import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';

interface IAlertProps {
  className?: string;
  text: string;
  icon?: string;
  type: 'warning' | 'error' | 'info' | 'success';
}

const iconMap = {
  warning: 'warning-triangle',
  error: 'minus-circle',
  info: 'information-circle',
  success: 'check-tick-circle',
};

const Alert = (props: IAlertProps) => (
  <div className={`alert border w-full ${props.type} ${props.className ? props.className : ''}`}>
    <div className="flex">
      <FontIcon className="mr-2" iconName={props.icon ? props.icon : iconMap[props.type]} />
      <div>
        {props.text}
      </div>
    </div>
  </div>
);

export default Alert;
