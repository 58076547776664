import { useState } from 'react';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import { PrefiUnfreezeCreditModal } from './PrefiUnfreezeCreditModal';
import { usePrefiContext } from '../../pages/Prefi/PrefiContext';
import { formatRichText } from '../../config/util/common';

interface PrefiSoftCreditNoticeProps {
  className?: string;
}

export const PrefiSoftCreditNotice = ({
  className,
}: PrefiSoftCreditNoticeProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { prefiCommonContent } = usePrefiContext();

  return (
    <>
      <div className={className}>
        <div>
          {formatRichText(
            'soft_credit_authorization_notice',
            prefiCommonContent?.soft_credit_authorization_notice || '',
            prefiCommonContent
          )}
        </div>
        <div className="mt-4">
          {formatRichText(
            'soft_credit_impact_statement',
            prefiCommonContent?.soft_credit_impact_statement || '',
            prefiCommonContent
          )}
        </div>
        <div className="mt-4 flex items-center">
          <span>{prefiCommonContent?.unfreeze_credit_reminder}</span>
          <button className="flex items-center ml-1" onClick={() => setIsModalOpen(true)}>
            <FontIcon
              iconName="information-circle"
              className="text-2xl text-secondary-action-base"
            />
          </button>
        </div>
        <div className="mt-4 flex font-bold items-center">
          <FontIcon
            iconName="shield-check"
            className="text-2xl mr-1"
          />
          <span>{prefiCommonContent?.data_security_assurance}</span>
        </div>
      </div>
      <PrefiUnfreezeCreditModal
        isOpen={isModalOpen}
        toggleModal={() => setIsModalOpen(false)}
      />
    </>
  );
};
