import Button from '@guaranteed-rate/react-components/dist/Button';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Countdown from 'react-countdown';
import { IErrorData } from '../../../config/util/interfaces';
import { timeRenderer } from '../../../config/util/common';
import PageLoader from '../../../components/PageLoader/PageLoader';

interface IErrorProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  handleApplyAgain: () => Promise<any>;
  errorData: IErrorData;
  errMessage?: any;
  isLite?: boolean;
}

const adverseActionCodes = ['10014'];
const applyAgainCodes = ['10016'];
const limitRetryCodes = ['10007'];
const leftAlignCodes = ['10007'];
const propertyListedForSaleCode = ['10018'];

export const HelocErrorPage = (props: IErrorProps) => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(null) as any;
  const [contentFetched, setContentFetched] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [lastTryTime, setLastTryTime] = useState(Date.now());
  const [error, setError] = useState(false);
  const [errorLinks, setErrorLinks] = useState([] as any);

  // request error content to be displayed based on error code or page name
  const fetchContent = async () => {
    if (!contentFetched && !content && !error) {
      setContentFetched(true);
      const mutation = {
        query:
          `query {
            fetchFspContent(page: "heloc_error_page") {
              pageContentJson
            }
          }`
      };
      const uuid = uuidv4();
      const url = '/gateway/graphql';
      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
          'X-Request-ID': uuid,
        },
        body: JSON.stringify(mutation)
      });
      try {
        const data = await resp.json();
        if (data?.data?.fetchFspContent) {
          if (data.data.fetchFspContent.pageContentJson !== '') {
            // convert json string to json object
            const contentData = [] as Array<any>;
            data.data.fetchFspContent.pageContentJson.map((entry: string) => contentData.push(JSON.parse(entry)));
            setContent(contentData[0]);
            const code = urlParams.get('code');
            // if there is an error code, use messaging for that. Otherwise use generic error for the page
            let pageName = code || props.errorData?.error?.status || props.errorData.page;
            pageName = pageName ? `${pageName}` : '';
            pageName = pageName === '10001' ? props.errorData.page : pageName;
            const pages = contentData[0].pages;
            // filter the json pages by the search string (error code or page name)
            if (pageName.length > 0) {
              const pageLinks = [] as any;
              const pageContent = pages.filter((page: any) => {
                if (pageName === 'income') {
                  if (page.page?.name === pageName) {
                    return true;
                  }
                } else if (page.page && page.page.name.indexOf(pageName) !== -1) {
                  return true;
                } else if (page.links && page.links.name.indexOf(pageName) !== -1) {
                  pageLinks.push(page.links);
                }
                return false;
              });
              // if no messaging was found then show default.
              if (pageContent.length > 0) {
                setContent(pageContent[0].page);
              } else {
                setContent(null);
              }
              if (pageLinks.length > 0) {
                setErrorLinks(pageLinks);
              }
            } else {
              setContent(null);
            }
          }
          setError(false);
        } else {
          setContentFetched(false);
          setError(true);
        }
      } catch (e) {
        setError(true);
        console.error(e);
        setContentFetched(false);
      }
    }
    return true;
  };

  fetchContent();

  // default messaging and icons
  const heading = 'We\'re sorry, something went wrong';
  const subheading = 'The page could not be loaded. Please try again.';
  const icon = 'close-small';

  // when the user clicks try again, resubmit the last data
  const handleTryAgain = async () => {
    setLoading(true);
    setLastTryTime(Date.now());
    await props.handleSubmit(props.errorData.pageData, false);
    setLoading(false);
  };

  const handleApplyAgain = async () => {
    setLoading(true);
    const urlParams = window.location.search;
    await props.handleApplyAgain();
    setLoading(false);
  };

  // try to do a reload of the application with a GET call
  const handleGoBack = async () => {
    setLoading(true);
    await props.handleSubmit(`{ 
      page: ""
      ${props.isLite ? 'lite: true' : ''}
    }`, false);
    setLoading(false);
  };

  const canRetry = () => (Date.now() - 300000) >= lastTryTime;

  return (
    <div>
      <div className={`flex flex-col justify-center ${leftAlignCodes.includes(content?.name) ? 'text-left' : 'text-center'}`}>
        {
          !loading
            ? (
              <div className="flex justify-center">
                <div className="max-w-2xl">
                  <FontIcon className="text-red-600 text-8xl mb-8 mt-8" iconName={content ? content.icon : icon} />
                  <h2 className="font-bold my-8">{content ? content.heading : heading}</h2>
                  <h4 className="mb-8">{props?.errMessage || content?.description || subheading}</h4>
                  {
                    errorLinks.map((errorLink: any, index: number) => (
                      <div key={`errorLink${index}`} className="mb-8">
                        <h4>{errorLink.link_label}</h4>
                        {
                          errorLink.link.href && errorLink.link.href !== ''
                            ? <a href={errorLink.link.href} className="tertiary">{errorLink.link.title}</a>
                            : <h4>{errorLink.link.title}</h4>
                        }
                      </div>
                    ))
                  }
                  {
                    adverseActionCodes.includes(content?.name)
                      ? (
                        <div className="flex justify-center flex-col-reverse sm:flex-row mt-8">
                          <Button
                            className="!w-full sm:!w-64"
                            loading={loading}
                            buttonAttrs={{ disabled: loading }}
                            buttonStyle="secondary"
                          >Adverse Action Notice
                          </Button>
                          <Button
                            className="!w-full mb-4 sm:!w-48 sm:ml-4 sm:mb-0"
                            buttonStyle="primary"
                            loading={loading}
                            buttonAttrs={{ disabled: loading }}
                            onClick={handleApplyAgain}
                          >Apply again
                          </Button>
                        </div>
                      )
                      : applyAgainCodes.includes(content?.name)
                        ? (
                          <div className="flex justify-center flex-col-reverse sm:flex-row mt-8">
                            <Button
                              className="!w-full mb-4 sm:!w-48 sm:ml-4 sm:mb-0"
                              buttonStyle="primary"
                              loading={loading}
                              buttonAttrs={{ disabled: loading }}
                              onClick={handleApplyAgain}
                            >Apply again
                            </Button>
                          </div>
                        ) : limitRetryCodes.includes(content?.name)
                          ? (
                            <div className="flex justify-center mt-8 text-center">
                              <div>
                                <Button
                                  className="!w-full mb-4 sm:!w-48 sm:ml-4 sm:mb-0"
                                  buttonStyle="primary"
                                  onClick={handleTryAgain}
                                  loading={loading}
                                  buttonAttrs={{ disabled: !canRetry() || loading }}
                                >Try again
                                </Button>
                                { !canRetry()
                                  && (
                                    <div>
                                      <h4 className="mt-2"><span>Please wait </span>
                                        <Countdown
                                          date={lastTryTime + 300000}
                                          renderer={timeRenderer}
                                          onComplete={() => setLastTryTime(Date.now() - 300001)}
                                        />
                                      </h4>
                                    </div>
                                  )}
                              </div>
                            </div>
                          )
                          : (
                            <div className="flex justify-center flex-col-reverse sm:flex-row mt-8">
                              <Button
                                className="!w-full sm:!w-48"
                                buttonStyle="secondary"
                                onClick={handleGoBack}
                                buttonAttrs={{ disabled: loading }}
                              >Go back
                              </Button>
                              {
                                !propertyListedForSaleCode.includes(content?.name) && (
                                  <Button
                                    className="!w-full mb-4 sm:!w-48 sm:ml-4 sm:mb-0"
                                    buttonStyle="primary"
                                    onClick={handleTryAgain}
                                    loading={loading}
                                    buttonAttrs={{ disabled: loading }}
                                  >Try again
                                  </Button>
                                )
                              }
                            </div>
                          )
                  }
                </div>

              </div>
            )
            : <PageLoader />
        }
      </div>
    </div>
  );
};
