import Logo from '@guaranteed-rate/react-components/dist/Logo';
import * as React from 'react';

function OwningHeaderTypeTwo() {
  return (
    <>
      <div className="OwningHeaderTypeTwoTopBar" />
      <div className="flex justify-between w-full p-6 bg-[var(--color-data-5)]">
        <div className="owning-logo-container z-1 w-fit mx-auto mt-3">
          <Logo
            url="http://www.owning.com"
            src="/images/owningLogo.svg"
            alt="Owning"
            width={125}
            height={24}
          />
        </div>
      </div>
    </>
  );
}

export default OwningHeaderTypeTwo;
