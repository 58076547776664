/* eslint-disable max-len */
import { useState } from 'react';
import Tabs from '@guaranteed-rate/react-components/dist/Tabs';
import Button from '@guaranteed-rate/react-components/dist/Button';
import { useNavigate } from 'react-router-dom';
import { fetchPageContent } from '../../config/util/common';

const tabs = [
  {
    button: 'Rate',
    content: '',
    isDefault: true,
  },
  {
    button: 'APR',
    content: '',
  },
];

function OwningWelcome(props: any) {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };
  const [content, setContent] = useState(null) as any;
  const [contentFetched, setContentFetched] = useState(false);
  const [error, setError] = useState(false);

  if (!content && !contentFetched && !error) {
    fetchPageContent('"owning_prescreen_product_selection"').then(
      (data: any) => {
        if (data.content === 'error') {
          setError(true);
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
        }
      }
    );
  }

  console.log(content);

  const handlePurchaseRefinance = (option: string) => {
    if (option === 'purchase') {
      props.onProductTypeUpdate('Purchase');
      navigate('/mortgage/form');
    } else {
      props.onProductTypeUpdate('Refinance');
      navigate('/mortgage/form');
    }
  };

  return (
    <div>
      <div className="flex justify-center w-full">
        <div className="max-w-2xl w-full px-4">
          <div className="w-full justify-center">
            <div className="flex items-center justify-center">
              <div className="px-4 w-full">
                <h2 className="font-bold mt-10 text-[40px]">{content?.header}</h2>
                <h2 className="font-bold text-[40px]">{content?.sub_header}</h2>
                <h4 className="text-xl mt-4">{content?.description}</h4>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="px-4 w-full mt-8">
                <Button buttonStyle="primary" className="!w-full" onClick={() => { handlePurchaseRefinance('purchase'); }}>{content?.purchase_button_label}</Button>
                <Button buttonStyle="secondary" className="!w-full mt-4" onClick={() => { handlePurchaseRefinance('refinance'); }}>{content?.refinance_button_label}</Button>
              </div>
            </div>
          </div>
          <div className="max-w-2xl mx-auto h-fit rounded-lg shadow-[-5px_-5px_30px_-13px_rgba(0,0,0,0.3)] pb-6 mt-24">
            <div className="pt-2 pl-4 xl:block">
              <Tabs tabs={tabs} tabsAriaLabel="Rate or APR Input" onChange={handleTabChange} isCentered={true} />
            </div>
            <div className="pt-2 pl-4 xl:block">
              {tabIndex === 0
                ? (
                  <div>
                    <div>
                      <h5 className="mt-1">{content?.rate_data?.today_s_rate_label}</h5>
                      <h2 className="font-bold">5.500%</h2>
                    </div>
                    <div>
                      <h5 className="mt-1">{content?.rate_data?.competitor_s_rate_label}</h5>
                      <h2 className="font-bold">6.475%</h2>
                    </div>
                  </div>
                )
                : (
                  <div>
                    APR
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OwningWelcome;
