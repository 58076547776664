import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { IAffordabilityData } from '../../../config/util/interfaces';
import PageLoader from '../../../components/PageLoader/PageLoader';
import { affordabilityPathMap } from '../AffordabilityPage';

interface IOptionsProps {
  setAffordabilityData: Dispatch<SetStateAction<IAffordabilityData>>;
  updateProgress: (step: number, progressContent: ProgressProps) => void;
  affordabilityData: IAffordabilityData;
  progress: ProgressProps;
  content: any;
}

const loadExistingData = (data: IAffordabilityData) => (!data?.creditScore ? '' : data?.creditScore);

export const AffordabilityCreditScorePage = ({
  progress,
  affordabilityData,
  content,
  setAffordabilityData,
  updateProgress
}: IOptionsProps) => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const [creditScore] = useState(loadExistingData(affordabilityData));
  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setAffordabilityData({ ...affordabilityData, creditScore: (content?.credit_score_values as any)[event.target.value.toLowerCase()] });
    updateProgress(4, progress);
    navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
      ?? '/home-affordability/'}military/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
  };

  const handleBack = () => {
    updateProgress(2, progress);
    navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
      ?? '/home-affordability/'}downPayment/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
  };

  return content ? (
    <Form title={content.header} progress={progress} formStyle="affordability">
      <div>
        <div className="min-h-[420px] mt-4">
          <RadioButtons
            className="radioButton"
            radios={content.radio_button.map((item: any) => ({
              name: 'options',
              label: item.option.label,
              value: item.option.value,
              active: creditScore === (content?.credit_score_values as any)[item.option.value.toLowerCase()],
            }))}
            onChange={handleSelect}
          />
        </div>
        <div className="flex justify-center -ml-8 xl:block xl:ml-0 mt-8">
          <Button buttonStyle="quaternary" iconPos="left" iconName="chevron-left-large" onClick={handleBack}>Back</Button>
        </div>
      </div>
    </Form>
  ) : <PageLoader />;
};
