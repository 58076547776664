import React from 'react';

export const useScript = (resourceUrl: string, async: boolean, onLoad: ((this: GlobalEventHandlers, ev: Event) => any) | null) => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = async;
    script.onload = onLoad;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [resourceUrl]);

  return '';
};
