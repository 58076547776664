import * as React from 'react';
import OwningHeaderMain from './OwningHeaderMain';
import OwningHeaderTypeOne from './OwningHeaderTypeOne';
import OwningHeaderTypeTwo from './OwningHeaderTypeTwo';

function OwningHeaderWrapper(props: any) {
  return (
    <>
      {
        props.type === 'main'
          ? (<OwningHeaderMain />) : (props.type === 'one' ? (<OwningHeaderTypeOne />) : (<OwningHeaderTypeTwo />))
      }
    </>
  );
}

export default OwningHeaderWrapper;
