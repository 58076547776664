import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons, { useRadio } from '@guaranteed-rate/react-components/dist/RadioButtons';
import DropDownSearch from '@guaranteed-rate/react-components/dist/DropDownSearch';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { getLoCookie, setLoCookie } from '../../../config/util/common';
import PageLoader from '../../../components/PageLoader/PageLoader';
import { affordabilityPathMap } from '../AffordabilityPage';

interface IOfficerProps {
  setLo: (loId: string) => void;
  setLoData: any;
  loanOfficers: Array<ILoanOfficer>;
  content: any;
  updateProgress: (step: number, progressContent: ProgressProps) => void;
  progress: ProgressProps;
}

interface ILoanOfficer {
  displayName: string;
  employeeId: string;
}

const options = [
  {
    name: 'yes',
    label: 'Yes',
    value: 'yes',
    active: false
  },
  {
    name: 'no',
    label: 'No',
    value: 'no',
    active: false
  }
];

export const AffordabilityOfficerPage = (props: IOfficerProps) => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const [yesClicked, setYesClicked] = useState(false);
  const { updateCheckedValue, isChecked } = useRadio();

  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === options[0].value) {
      setYesClicked(true);
    } else {
      if (yesClicked) {
        setYesClicked(false);
      }
      if (getLoCookie()) {
        setLoCookie('');
        props.setLoData(null);
      }
      props.updateProgress(props.progress.currentStep + 1, props.progress);
      navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
        ?? '/home-affordability/'}location/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    }
  };

  const handleLoSelect = async (selectedItem: any) => {
    setLoCookie(selectedItem.employeeId);
    props.setLo(selectedItem.employeeId);
    props.updateProgress(props.progress.currentStep + 1, props.progress);
    navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
      ?? '/home-affordability/'}location/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
  };

  const handleBack = async () => {
    props.updateProgress(props.progress.currentStep - 1, props.progress);
    navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
      ?? '/home-affordability/'}military/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
  };

  return props.content ? (
    <Form title={props.content.header} progress={props.progress} formStyle="affordability">
      <div className="min-h-[300px]">
        <RadioButtons
          className="radioButton"
          radios={options}
          onChange={handleSelect}
          updateCheckedValue={updateCheckedValue}
          isChecked={isChecked}
        />
        {yesClicked
          && (
            <div>
              <h3 className="font-bold mb-4">{props.content.sub_prompt}</h3>
              {props.loanOfficers.length > 0
                ? (
                  <DropDownSearch
                    label={props.content.search_label}
                    items={props.loanOfficers}
                    optionLabel="displayName"
                    onChange={handleLoSelect}
                  />
                )
                : <div className="mb-8 flex justify-center xl:ml-4 xl:block"><Loader color="black" className="loader-small" /></div>}
              <h4 className="tertiary font-bold -mt-4">
                <a href="https://www.rate.com/site-search?query=loan+officers+near+me&verticalUrl=officer.html" target="_blank" rel="noreferrer">{'I can\'t remember'}</a>
              </h4>
            </div>
          )}
      </div>
      <div className="flex justify-center -ml-8 xl:block xl:ml-0">
        <Button buttonStyle="quaternary" iconPos="left" iconName="chevron-left-large" onClick={handleBack}>Back</Button>
      </div>
    </Form>
  ) : <PageLoader />;
};
