import Button from '@guaranteed-rate/react-components/dist/Button';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import Banner from '../../../components/Banner/Banner';
import { affordabilityPathMap } from '../AffordabilityPage';

interface IErrorProps {
  errorMessage: string;
  isRecalculate: boolean;
  isPreApproval: boolean;
  disclaimer?: string;
  updateProgress: (step: number, progress: ProgressProps) => void;
  progress: ProgressProps;
  contentAvailable: boolean;
  currentPage: string;
}

export const AffordabilityErrorPage = ({
  isRecalculate = false,
  isPreApproval = false,
  disclaimer,
  errorMessage,
  progress,
  contentAvailable = true,
  currentPage,
  updateProgress
}: IErrorProps) => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const heading = 'We\'re sorry, something went wrong';
  const subheading = 'The page could not be loaded. Please try again.';
  const [affordabilityPath] = useState(affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
   ?? '/home-affordability/');
  const handleTryAgain = () => {
    if (!contentAvailable) {
      window.location.href = `${affordabilityPath}`;
    } else if (progress.currentStep === 0) {
      navigate(`${affordabilityPath}income/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    } else {
      navigate(`${affordabilityPath}location/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    }
  };
  const handleGoBack = () => {
    if (!contentAvailable) {
      window.location.href = `${affordabilityPath}`;
    } else if (isPreApproval && (currentPage === 'saveProgress')) {
      navigate(`${affordabilityPath}saveProgress/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    } else if (isRecalculate || isPreApproval) {
      navigate(`${affordabilityPath}review/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`, { state: {
        isEditModalOpen: isRecalculate,
        isRatesTableOpen: isPreApproval
      } });
    } else {
      updateProgress(0, progress);
      navigate(`${affordabilityPath}income/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    }
  };

  return (isRecalculate || isPreApproval)
    ? (
      <div>
        <Banner className="border-2 mt-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
        <p className="text-sm md:text-base mt-4">{disclaimer}</p>
        <div className="flex justify-center mt-8">
          <Button className="!w-full sm:!w-48" buttonStyle="secondary" onClick={handleGoBack}>Go back</Button>
        </div>
      </div>
    ) : (
      <div>
        <div className="flex flex-col justify-center text-center">
          <FontIcon className="text-red-600 text-8xl" iconName="close-small" />
          <h2 className="font-bold">{heading}</h2>
          <h4>{errorMessage || subheading}</h4>
          <div className="flex justify-center flex-col-reverse sm:flex-row mt-8">
            <Button className="!w-full sm:!w-48" buttonStyle="secondary" onClick={handleGoBack}>Go back</Button>
            <Button className="!w-full mb-4 sm:!w-48 sm:ml-4 sm:mb-0" buttonStyle="primary" onClick={handleTryAgain}>Try again</Button>
          </div>
        </div>
      </div>
    );
};
