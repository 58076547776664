import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { useState } from 'react';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { IAdditionalInfo } from '../../../config/util/interfaces';

interface IOptionsProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  additionalInfo: IAdditionalInfo;
  setAdditionalInfo: any;
  pageName: string;
  progress?: ProgressProps;
  fieldName: string;
  isLite?: boolean;
}

export const HelocAdditionalOptionPage = (props: IOptionsProps) => {
  const [loading, setLoading] = useState(false);
  const options = props.content.radio_button.map((item: any) => ({
    name: 'options',
    label: item.option.label,
    value: item.option.value,
    active: false
  }));
  // save the marital data to the global additional info object and move to the next screen
  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    props.setAdditionalInfo({ ...props.additionalInfo, [props.fieldName]: event.target.value });
    // Here we pass in the page name as the key for the value selected from the radio button
    const pageData = `{ 
      page: "${props.pageName}"
      ${props.isLite ? 'lite: true' : ''}
    }`;
    await props.handleSubmit(pageData, false);
    setLoading(false);
  };

  // go to the previous screen
  const handleBack = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      ${props.isLite ? 'lite: true' : ''}
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };
  return (
    <Form title={props.content.header} progress={props.progress}>
      {
        loading ? (
          <div className="min-h-[420px] flex items-center justify-center">
            <Loader color="#D13239" className="loader-medium" />
          </div>
        )
          : (
            <div className="w-full">
              <div className="flex justify-center w-full">
                <div className="w-full max-w-md">
                  <RadioButtons className="radioButton" radios={options} onChange={handleSelect} />
                </div>
              </div>
              <div
                className="flex justify-center -ml-8 md:block md:ml-0"
                style={{ marginTop: '12rem' }}
              >
                {
                  (props.isLite || props.pageName !== 'marital')
                   && <Button buttonStyle="quaternary" iconPos="left" iconName="chevron-left-large" onClick={handleBack}>Back</Button>
                }
              </div>
            </div>
          )
      }
    </Form>
  );
};
