import React from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';

interface WizardProgressProps {
  header: string;
  subheader: string;
  progress: number; // percentage of width of component that the progress bar should span, represented as a number from 0-100
  className?: string;
}

// Implements progress header depicted in the following mocks:
// https://guaranteedrate.invisionapp.com/console/Rate-Alert-Mobile_DM-cloyuajje3mam01ai3ld8er8o/clprdnhkb3w0q014xbaat0psu/play
// https://guaranteedrate.invisionapp.com/console/Rate-Alert-Desktop_DM-cloyucr5t02um01759xb0h6fk/clq2o228n4jg801522al8adhv/play
export const WizardProgress = ({
  header,
  subheader,
  progress,
  className = '',
}: WizardProgressProps) => (
  <div className={className}>
    <div className="flex flex-col">
      <div className="flex">
        <span className="font-bold mr-2">{header}</span>
        <span className="mr-2 flex-1">{subheader}</span>
        {/* TBD RED-4301 <button className="text-secondary-action-base font-bold">Details</button> */}
      </div>
      <div className="mt-1 relative w-full rounded-full h-[2px] overflow-hidden">
        <div
          className="z-10 absolute left-0 top-0 bg-status-ok-dark h-full text-center text-white rounded-full transition-all"
          style={{ width: `${Math.max(0, Math.min(100, progress))}%` }}
        />
        <div className="z-0 absolute left-0 top-1/4 w-full bg-secondary-inactive-lighter h-1/2 text-center text-white rounded-full" />
      </div>
    </div>
  </div>
);
