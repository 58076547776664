/* eslint-disable react/no-this-in-sfc */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@guaranteed-rate/react-components/dist/Button';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import { dateDiffInYears, fetchFspPageContent, formatDate, getDigitsInSSNFormat, getTimeDiff, isNumeric, setCustomErrorBasedOnResponse } from '../../config/util/common';
import Banner from '../../components/Banner/Banner';
import { IConfirmIdentityForm } from '../../config/util/interfaces';
import DateInput from '../../components/DateInput/DateInput';
import RateAlertLogo from '../../components/RateAlertLogo/RateAlertLogo';
import { RateAlertWizardProgress } from '../../components/WizardProgress/RateAlertWizardProgress';
import { PrefiSoftCreditNotice } from '../../components/PrefiSoftCreditNotice/PrefiSoftCreditNotice';
import { PreviousButton } from '../../components/PreviousButton/PreviousButton';
import { MASKS } from '../../config/util/masks';
import { generateLink, getNextPanelBasedOnState } from './prefiCommon';
import { log } from '../../config/util/logger';

const getCoBorrowerBasicInfoData = async (applicationId: string) => {
  const mutation = {
    query:
      `query {
            getCoBorrowerBasicInfoData(applicationId: "${applicationId}") {
              name
          }
        }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "getCoBorrowerBasicInfoData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getCoBorrowerBasicInfoData) {
      log({ message: `Query "getCoBorrowerBasicInfoData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.getCoBorrowerBasicInfoData };
    }
    log({ message: `Query "getCoBorrowerBasicInfoData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "getCoBorrowerBasicInfoData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const updateCoBorrowerSoftCreditDetail = async (applicationId: string, coBorrowerSoftCreditRequest: any) => {
  const mutation = {
    query:
      `mutation {
        updateCoBorrowerSoftCreditDetail(applicationId: "${applicationId}", coBorrowerSoftCreditRequest: ${coBorrowerSoftCreditRequest}) {
          applicationType
            application {
                id
                tenant
                applicationType
                createdAt
                updatedAt
                state
                currentState
                currentDynamicState
                invitationNumber
                newLoanId
                oldLoanNumber
                isValidSSN
                isValidDateOfBirth
                oldLoanId
                selectedDynamicStates
                hasCoBorrower
                employmentVerified
                incomeVerified
                inValidCoBorrowerSSN
            }
    }
  }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Mutation "updateCoBorrowerSoftCreditDetail" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.updateCoBorrowerSoftCreditDetail?.application) {
      log({ message: `Mutation "updateCoBorrowerSoftCreditDetail was successful" ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.updateCoBorrowerSoftCreditDetail };
    }
    log({ message: `Mutation "updateCoBorrowerSoftCreditDetail failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Mutation "updateCoBorrowerSoftCreditDetail failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const newForm = () => ({
  ssn: '',
  birthday: ''
});

function PrefiCoborrowerConfirmIdentity(props: any) {
  const navigate = useNavigate();
  const today = new Date();
  const [confirmIdentityForm, setConfirmIdentityForm] = useState(newForm());
  const [content, setContent] = useState(null as any);
  const [contentFetched, setContentFetched] = useState(false);
  const [coBorrowerBasicInfo, setCoBorrowerBasicInfo] = useState(null as any);
  const [coBorrowerNameFetched, setCoBorrowerNameFetched] = useState(false);
  const [error, setError] = useState(false);
  const [showSSN, setShowSSN] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [trySubmit, setTrySubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  if (!content && !contentFetched && !error) {
    fetchFspPageContent('"prefi_coborrower_confirm_identity"').then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiCoborrowerConfirmIdentity: fetchFspPageContent(): Error fetching page content. ${JSON.stringify({ page: 'prefi_coborrower_confirm_identity' })}`, level: 'error' });
          setError(true);
          setErrorMessage('Error fetching page content. Please try again.');
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
        }
      }
    );
  }

  if (content && props.applicationId && !coBorrowerBasicInfo && !coBorrowerNameFetched && !error) {
    getCoBorrowerBasicInfoData(props.applicationId).then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiCoborrowerConfirmIdentity: getCoBorrowerBasicInfoData(): Setting custom error based on response ${JSON.stringify({ data })}`, context: { applicationId: props.applicationId }, level: 'error' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          setCoBorrowerNameFetched(false);
        } else {
          setError(false);
          setCoBorrowerNameFetched(true);
          setCoBorrowerBasicInfo(data.content);
        }
      }
    );
  }

  const handleSSNChange = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if (!(isNumeric(event.target.value)) && (event.target.value !== '')) {
      event.preventDefault();
    } else {
      setConfirmIdentityForm({ ...confirmIdentityForm, [name]: event.target.value });
    }
  };

  const isValid9Digits = () => !confirmIdentityForm.ssn || (confirmIdentityForm.ssn.length === 9 && isNumeric(confirmIdentityForm.ssn));

  const isValidDate = () => (new Date(confirmIdentityForm.birthday)) && dateDiffInYears(new Date(confirmIdentityForm.birthday), today) >= 18 && (new Date(confirmIdentityForm.birthday).getFullYear() >= 1900) && parseInt(confirmIdentityForm.birthday.substring(confirmIdentityForm.birthday.length - 4)) >= 1900;

  const validateKey = (key: string) => confirmIdentityForm[key as keyof IConfirmIdentityForm].length > 0;

  const hasError = (name: string) => {
    if (trySubmit) {
      return (name === 'birthday' ? (confirmIdentityForm.birthday.length < 10 || !isValidDate()) : (confirmIdentityForm.ssn.length < 9 || !isValid9Digits()));
    }
    return false;
  };

  const getErrorText = (birthday: string): string => (birthday.length < 10 ? (birthday.length === 0 ? 'required field' : 'Please enter a valid date') : ((((new Date(birthday).getFullYear()) < 1900) || parseInt(birthday.substring(birthday.length - 4)) < 1900) ? 'Date is too far in the past' : 'You must be at least 18 years old.'));

  const handleContinue = async () => {
    log({ message: 'PrefiCoborrowerConfirmIdentity: handleContinue(): Handling continue', level: 'info' });
    setTrySubmit(true);
    setError(false);
    const keys = Object.keys(confirmIdentityForm);
    if (isValidDate() && isValid9Digits() && keys.every((key) => validateKey(key))) {
      log({ message: 'PrefiCoborrowerConfirmIdentity: handleContinue(): Client validation passed', level: 'info' });
      setLoading(true);
      const coBorrowerSoftCreditRequest = `{
        birthDate: "${formatDate(new Date(confirmIdentityForm.birthday))}",
        taxIdentificationNumber: "${getDigitsInSSNFormat(confirmIdentityForm.ssn)}"
      }`;
      updateCoBorrowerSoftCreditDetail(props.applicationId, coBorrowerSoftCreditRequest).then(
        (data: any) => {
          setLoading(false);
          if (data.content === 'error') {
            log({ message: `PrefiCoborrowerConfirmIdentity: handleContinue(): updateCoBorrowerSoftCreditDetail(): Setting custom error based on response ${JSON.stringify({ data })}`, level: 'info' });
            setError(true);
            setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          } else {
            setError(false);
            if (data.content?.application?.inValidCoBorrowerSSN) {
              log({ message: `PrefiCoborrowerConfirmIdentity: handleContinue(): updateCoBorrowerSoftCreditDetail(): Co-borrower cannot use your SSN ${JSON.stringify({ data: data.content })}`, level: 'info' });
              setError(true);
              setErrorMessage('Co-borrower cannot use your SSN.');
              window.scrollTo(0, 0);
            } else if (data.content?.application?.isValidDateOfBirth && data.content?.application?.isValidSSN) {
              const nextPanel = getNextPanelBasedOnState(data);
              log({ message: `PrefiCoborrowerConfirmIdentity: handleContinue(): updateCoBorrowerSoftCreditDetail(): Success ${JSON.stringify({ nextPanel })}`, level: 'info' });
              navigate(generateLink(`/${nextPanel}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
              window.scrollTo(0, 0);
            } else {
              log({ message: 'PrefiCoborrowerConfirmIdentity: handleContinue(): updateCoBorrowerSoftCreditDetail(): Validation was unsuccessful.', level: 'info' });
              setError(true);
              setErrorMessage('Validation was unsuccessful. Please try again.');
              window.scrollTo(0, 0);
            }
          }
        }
      );
    } else {
      log({ message: 'PrefiCoborrowerConfirmIdentity: handleContinue(): Invalid fields', level: 'info' });
    }
  };

  return (
    <div>
      {(content && confirmIdentityForm)
        ? (
          <div className="flex justify-center w-full">
            <div className="max-w-4xl w-full px-1">
              <RateAlertWizardProgress className="lg:hidden px-4 mb-4" />
              <div className="flex items-center justify-center lg:mb-8">
                <div className="px-4 w-full">
                  {
                    error
                      ? (
                        <Banner className="border-2 mt-4 mb-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
                      )
                      : ''
                  }
                  <div>
                    <RateAlertLogo alt={content?.header} />
                  </div>
                </div>
              </div>
              <div className="w-full px-1 lg:border border-solid border-slate-300 rounded-2xl bg-white">
                <RateAlertWizardProgress className="hidden lg:block px-28 mt-6" />
                <div className="lg:px-24">
                  <h1 className="px-4 font-normal mt-8">{content?.form?.form_label}</h1>
                </div>
                <div className="flex items-center justify-center lg:px-24">
                  <div className="px-4 w-full">
                    <div>{content?.description}<span className="font-bold">{coBorrowerBasicInfo?.name} </span> {content?.description_continuation}</div>
                  </div>
                </div>
                <div className="flex items-center justify-center lg:px-24">
                  <div className="px-4 w-full mt-4">
                    <div className="lg:grid grid-cols-2 pt-4">
                      <div className="md:space-x-4">
                        <div onClick={(event: any) => event.target.className.indexOf('eye') !== -1 && setShowSSN(!showSSN)}>
                          <TextInput
                            name="ssn"
                            label={content?.form?.ssn_label}
                            className="w-auto lg:mr-4"
                            value={confirmIdentityForm.ssn}
                            mask={showSSN ? MASKS.SSN : MASKS.SSN_PW}
                            type="password"
                            hasError={hasError('ssn')}
                            helperText={hasError('ssn') ? (confirmIdentityForm.ssn.length === 0 ? 'required field' : 'You must enter 9 digit numeric characters') : ''}
                            onChange={(input) => handleSSNChange('ssn', input)}
                          />
                        </div>
                      </div>
                      <div>
                        <DateInput
                          label={content?.form?.birthday_label}
                          name="birthday"
                          form={confirmIdentityForm}
                          setForm={setConfirmIdentityForm}
                          hasError={hasError}
                          errorText={getErrorText(confirmIdentityForm.birthday)}
                          id="date"
                        />
                      </div>
                    </div>
                    <PrefiSoftCreditNotice />
                  </div>
                </div>
                <div className="px-4 mt-2 mb-8 w-full flex justify-between">
                  <div className="flex justify-center mt-12">
                    <PreviousButton isDynamic={false} applicationId={props.applicationId} />
                  </div>
                  <div className="items-center justify-center">
                    <div className="px-4 mt-2 mb-8 w-full">
                      <div className="flex justify-center mt-12">
                        <Button
                          buttonStyle="primary"
                          className="!w-full md:!w-48 confirmButton"
                          onClick={handleContinue}
                          buttonAttrs={{ disabled: loading }}
                          loading={loading}
                        >{content?.form?.button_label}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="px-4 w-full">
            {
              error
                ? (
                  <Banner className="border-2 mt-4 mb-4" text={errorMessage} title="Error" type="error" icon="warning-triangle" isMultiline={true} errorContent={props.errors.errorContent} />
                )
                : ''
            }
          </div>
        )}
    </div>
  );
}

export default PrefiCoborrowerConfirmIdentity;
