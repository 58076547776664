/* eslint-disable max-len */
import { useNavigate } from 'react-router-dom';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import Img from '@guaranteed-rate/react-components/dist/Img';
import { useState } from 'react';
import Countdown from 'react-countdown';
import Button from '@guaranteed-rate/react-components/dist/Button';
import { fetchPageContent, timeRenderer } from '../../config/util/common';

function OwningSweet(props: any) {
  const navigate = useNavigate();
  props.updateHeaderType('two');
  const [content, setContent] = useState(null) as any;
  const [contentFetched, setContentFetched] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!content && !contentFetched && !error) {
    fetchPageContent('"owning_sweet"').then(
      (data: any) => {
        if (data.content === 'error') {
          setError(true);
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
        }
      }
    );
  }

  const handleSubmit = () => {
    // for time being local loading has been kept, will be integrated with backend response during integration
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate('/mortgage/congratulations');
    }, 5000);
  };
  return (
    <div className="bg-[var(--color-data-5)] h-screen pt-16 -mt-16">
      <div className="w-full justify-center">
        <div className="flex items-center justify-center">
          <div className="max-w-2xl w-full px-4">
            <h2 className="font-bold mt-24 text-center text-5xl">{content?.header}</h2>
            <div className="font-bold mt-4 text-center">
              {content?.description}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-8 px-4">
          <Button
            buttonStyle="primary"
            className="!w-full md:!w-48 mt-4"
            onClick={handleSubmit}
            loading={loading}
            buttonAttrs={{ disabled: loading }}
          >
            {content?.button_label}
          </Button>
        </div>
        <div className="mt-16 -ml-8">
          <Img
            src="/images/thumb.svg"
            alt="thumb"
            width={159}
            height={293}
          />
        </div>
        <div className="absolute bottom-12 items-center justify-center w-full">
          <div className="max-w-xs lg:max-w-md mx-auto">
            <div className="grid grid-flow-row-dense grid-cols-12 space-x-1 border-2 rounded-lg p-2 bg-[var(--color-primary-brand-lightest)]">
              <div className="col-span-1 -mt-1">
                <FontIcon iconName="information-circle" className="mr-1 !leading-8 text-lg" />
              </div>
              <div className="col-span-11 font-bold">
                <span>{content?.info?.header}</span>
              </div>
              <div className="col-span-1" />
              <div className="col-span-11 -mt-1">
                <span>{content?.info?.description}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OwningSweet;
