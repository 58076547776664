import { useState } from 'react';
import Cookies from 'js-cookie';
import Logo from '@guaranteed-rate/react-components/dist/Logo';
import { fetchPageContent } from '../../config/util/common';

function OwningFooter() {
  const [content, setContent] = useState(null) as any;
  const [error, setError] = useState(false);

  const adTrkURIVal = encodeURIComponent(JSON.stringify(Cookies.get('AdTrk'))?.replace(/\"/g, ''));
  const owningDisclaimerLinks = content?.owning_disclaimer_links.replaceAll('\n\n', '\n').replaceAll('%ADTRK', adTrkURIVal);
  const owningDisclaimer = content?.owning_disclaimer.replaceAll('%ADTRK', adTrkURIVal);

  if (!content) {
    fetchPageContent('"owning_footer"').then(
      (data: any) => {
        if (data.content === 'error') {
          setError(true);
        } else {
          setError(false);
          setContent(data.content);
        }
      }
    );
  }

  return (
    <div className="justify-center w-full px-4 py-24">
      <div>
        {
          owningDisclaimerLinks?.split('\n').map((line: string, index: number) => (
            <div key={`disclaimer_line_${index}`}><input type="hidden" id="leadid_tcpa_disclosure" />
              <div className="text-left text-sm mb-4" key={`disclaimer${index}`} dangerouslySetInnerHTML={{ __html: line }} />
            </div>
          ))
        }
      </div>
      <div className="w-full pt-4 pb-4">
        <Logo
          url="http://www.owning.com"
          src="/images/footerLogo.svg"
          alt="footerlogo"
          width={140}
          height={33}
        />
      </div>
      <div>
        {
          owningDisclaimer?.split('\n').map((line: string, index: number) => (
            <div key={`disclaimer_line_${index}`}>
              <div className="text-left text-sm mb-4" key={`disclaimer${index}`} dangerouslySetInnerHTML={{ __html: line }} />
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default OwningFooter;
