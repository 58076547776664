import Modal from '@guaranteed-rate/react-components/dist/Modal';
import clsx from 'clsx';
import { usePrefiContext } from '../../pages/Prefi/PrefiContext';
import { formatRichText } from '../../config/util/common';

interface IPrefiUnfreezeCreditModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  className?: string;
}

export const PrefiUnfreezeCreditModal = ({
  isOpen,
  toggleModal,
  className,
}: IPrefiUnfreezeCreditModalProps) => {
  const { prefiCommonContent } = usePrefiContext();

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      title=""
      className={clsx('text-center', className)}
      clickOffToClose
    >
      <h1 className="font-black">{prefiCommonContent?.unfreeze_credit_modal_title}</h1>
      {formatRichText(
        'unfreeze_credit_modal_content',
        prefiCommonContent?.unfreeze_credit_modal_content || '',
        prefiCommonContent,
        {
          linkClasses: 'underline cursor-pointer'
        }
      )}
    </Modal>
  );
};
