import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Tabs from '@guaranteed-rate/react-components/dist/Tabs';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { MASKS } from '../../../config/util/masks';
import { IAffordabilityData } from '../../../config/util/interfaces';
import PageLoader from '../../../components/PageLoader/PageLoader';
import { affordabilityPathMap } from '../AffordabilityPage';

interface IDebtProps {
  setAffordabilityData: Dispatch<SetStateAction<IAffordabilityData>>;
  updateProgress: (step: number, progressContent: ProgressProps) => void;
  affordabilityData: IAffordabilityData;
  progress: ProgressProps;
  content: any;
}

interface IDebtForm {
  autoLoan: string;
  personalLoan: string;
  creditCardLoan: string;
  studentLoan: string;
  otherLoan: string;
  isMonthly: boolean;
}

const loadExistingData = (data: IAffordabilityData): IDebtForm => {
  if (!data?.affordableDebtData) {
    return {
      autoLoan: '',
      personalLoan: '',
      creditCardLoan: '',
      studentLoan: '',
      otherLoan: '',
      isMonthly: true,
    };
  }
  return data?.affordableDebtData;
};

export const AffordabilityDebtPage = ({
  progress,
  affordabilityData,
  content,
  updateProgress,
  setAffordabilityData,
}: IDebtProps) => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const [debtForm, setDebtForm] = useState(loadExistingData(affordabilityData));

  const tabs = [
    {
      button: 'Monthly',
      content: '',
      isDefault: debtForm.isMonthly
    },
    {
      button: 'Annual',
      content: '',
      isDefault: !debtForm.isMonthly
    },
  ];

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });

  const getTotalDebt = () => {
    const autoDebt = debtForm.autoLoan === '' ? 0 : parseInt(debtForm.autoLoan);
    const personalDebt = debtForm.personalLoan === '' ? 0 : parseInt(debtForm.personalLoan);
    const creditDebt = debtForm.creditCardLoan === '' ? 0 : parseInt(debtForm.creditCardLoan);
    const studentDebt = debtForm.studentLoan === '' ? 0 : parseInt(debtForm.studentLoan);
    const otherDebt = debtForm.otherLoan === '' ? 0 : parseInt(debtForm.otherLoan);
    const total = autoDebt + personalDebt + creditDebt + studentDebt + otherDebt;
    return total;
  };

  const totalDebt = getTotalDebt();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setDebtForm({ ...debtForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  const handleTabChange = (index: number) => {
    setDebtForm({ ...debtForm, isMonthly: (index === 0) });
    console.log(debtForm.isMonthly);
  };

  const handleSubmit = async () => {
    setAffordabilityData({ ...affordabilityData, affordableDebtData: debtForm });
    updateProgress(progress.currentStep + 1, progress);
    navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
      ?? '/home-affordability/'}downPayment/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
  };

  const handleBack = () => {
    updateProgress(progress.currentStep - 1, progress);
    navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
      ?? '/home-affordability/'}income/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
  };

  if (!content) {
    return <PageLoader />;
  }

  const otherInput = (
    <TextInput
      name="otherLoan"
      value={debtForm.otherLoan}
      label={content.other_debt_label}
      onChange={(input) => handleChange(input, 'otherLoan')}
      mask={MASKS.CURRENCY}
    />
  );

  return (
    <Form title={content.header} progress={progress} formStyle="affordability">
      <div className="min-h-[300px]">
        <h4>{content.description}</h4>
        <div className="mb-4 border-b xl:hidden">
          <Tabs tabs={tabs} tabsAriaLabel="Monthly or Annual Input" onChange={handleTabChange} isCentered={true} />
        </div>
        <div className="mb-4 border-b hidden  pt-8 xl:block">
          <Tabs tabs={tabs} tabsAriaLabel="Monthly or Annual Input" onChange={handleTabChange} isCentered={false} />
        </div>
        <div className="block xl:flex xl:flex-row xl:space-x-4">
          <div className="flex flex-col xl:grow">
            <TextInput
              name="autoLoan"
              value={debtForm.autoLoan}
              label={content.auto_loan_label}
              onChange={(input) => handleChange(input, 'autoLoan')}
              mask={MASKS.CURRENCY}
            />
            <TextInput
              name="creditCardLoan"
              value={debtForm.creditCardLoan}
              label={content.credit_card_label}
              onChange={(input) => handleChange(input, 'creditCardLoan')}
              mask={MASKS.CURRENCY}
            />
            <div className="hidden xl:block">
              {otherInput}
            </div>
          </div>
          <div className="flex flex-col xl:grow">
            <TextInput
              name="personalLoan"
              value={debtForm.personalLoan}
              label={content.personal_loan_label}
              onChange={(input) => handleChange(input, 'personalLoan')}
              mask={MASKS.CURRENCY}
            />
            <TextInput
              name="studentLoan"
              value={debtForm.studentLoan}
              label={content.student_loan_label}
              onChange={(input) => handleChange(input, 'studentLoan')}
              mask={MASKS.CURRENCY}
            />
          </div>
          <div className="xl:hidden">
            {otherInput}
          </div>
        </div>
        <h4 className="pb-4">Total debt payment: <span id="total-debt" className="font-bold">{formatter.format(totalDebt)}</span></h4>
      </div>
      <div className="flex justify-center mt-12">
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-48 continue-btn"
          onClick={handleSubmit}
        >Continue
        </Button>
      </div>
      <div className="flex -ml-8 md:ml-0 md:block justify-center mt-6 md:-mt-11">
        <Button
          className="back-btn"
          buttonStyle="quaternary"
          iconPos="left"
          iconName="chevron-left-large"
          onClick={handleBack}
        >
          Back
        </Button>
      </div>
      <div className="text-center pt-12 ">
        <p>{content.debt_description}</p>
        <p>{content.other_debt_description}</p>
      </div>
    </Form>
  );
};
