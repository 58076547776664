import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import { MASKS } from '../../config/util/masks';

interface IDateInputProps {
  className?: string;
  label: string;
  name: string;
  form: any;
  hasError?: (name: string) => boolean;
  errorText?: string;
  setForm: any;
  required?: boolean;
  disabled?: boolean;
  id?: string;
  max?: Date;
  min?: Date;
  useMask?: boolean;
}

const maxDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const handleSpecialCharacters = (oldValue: string, newValue: string) => {
  // Check backspace
  if (oldValue.length > newValue.length) {
    if (oldValue.length === 3 || oldValue.length === 6) {
      return newValue.substring(0, newValue.length - 1);
    }
  } else if (oldValue.length < newValue.length) {
    // get last character
    const c = newValue.slice(newValue.length - 1);
    if (c === '/') {
      // first 2 chars
      if (oldValue.length === 1) {
        return `0${oldValue}`;
      }
      if (oldValue.length === 4) {
        return `${oldValue.slice(0, oldValue.indexOf('/'))}/0${oldValue.slice(oldValue.length - 1)}/`;
      }
    }
  }
  return newValue;
};

const DateInput = (props: IDateInputProps) => {
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let input: string = event.target.value;
    input = handleSpecialCharacters(props.form[props.name], input);
    const values: Array<string> = input.split('/').map((v: string) => v.replace(/\D/g, '')).filter((v) => v !== '');
    if (values[0]) {
      values[0] = checkValue(values[0], 12);
    }
    if (values[1]) {
      values[1] = checkValue(values[1], 31);
    }
    if (values[2]) {
      if (values[2].length === 4) {
        const newDate = new Date(input);
        if (newDate.toString() !== 'Invalid Date') {
          if (props.min && (parseInt(values[2]) < props.min.getFullYear() || newDate < props.min)) {
            // make min current Date;
            values[0] = checkValue(`${(`0${props.min.getMonth() + 1}`).slice(-2)}`, 12);
            values[1] = checkValue(`${(`0${props.min.getDate()}`).slice(-2)}`, 31);
            values[2] = `${props.min.getFullYear()}`;
          } else if (props.max && newDate > props.max) {
            // make max current Date;
            values[0] = checkValue(`${(`0${props.max.getMonth() + 1}`).slice(-2)}`, 12);
            values[1] = checkValue(`${(`0${props.max.getDate()}`).slice(-2)}`, 31);
            values[2] = `${props.max.getFullYear()}`;
          } else {
            const month = parseInt(values[0]);
            if (month !== 2) {
              const maxDate = maxDays[month - 1];
              const date = parseInt(values[1]);
              values[1] = date > maxDate ? `${maxDate}` : values[1];
            } else {
              const year = parseInt(values[2]);
              const isLeapYear = ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
              const maxDate = isLeapYear ? 29 : 28;
              const date = parseInt(values[1]);
              values[1] = date > maxDate ? `${maxDate}` : values[1];
            }
          }
        }
      }
    }
    const output: Array<string> = values.map((v: string, i: number) => (v.length === 2 && i < 2 ? `${v}/` : v));
    props.setForm({ ...props.form, [props.name]: output.join('').substring(0, 10) });
  };

  // ensures the number is under the max or prepended with 0's
  const checkValue = (str: string, max: number): string => {
    if (str.charAt(0) !== '0' || str === '00') {
      let num: number = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) {
        num = 1;
      }
      str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? `0${num}` : num.toString();
    }
    return str;
  };

  return (
    <TextInput
      type="text"
      className={props.className ?? ''}
      label={props.label}
      value={props.form[props.name]}
      hasError={props.hasError?.(props.name)}
      helperText={props.hasError?.(props.name) ? props.errorText ?? '' : ''}
      onChange={(input) => handleDateChange(input)}
      id={props.id ?? 'dateInput'}
      mask={props.useMask ? MASKS.DATE : undefined}
      name={props.name}
      required={props.required ?? false}
      disabled={props.disabled ?? false}
    />
  );
};

export default DateInput;
