/* eslint-disable max-len */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@guaranteed-rate/react-components/dist/Button';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import { fetchFspPageContent, getCode, getTimeDiff, objectToGraphQl, redirectToMyALogin, setCustomErrorBasedOnResponse } from '../../config/util/common';
import Banner from '../../components/Banner/Banner';
import { PreviousButton } from '../../components/PreviousButton/PreviousButton';
import RateAlertLogo from '../../components/RateAlertLogo/RateAlertLogo';
import { RateAlertWizardProgress } from '../../components/WizardProgress/RateAlertWizardProgress';
import { generateLink, getDemographicCategoryList, getNextPanelBasedOnState } from './prefiCommon';
import { log } from '../../config/util/logger';
import { REDIRECT_TO_LOGIN_ERROR_CODE } from '../../config/content/constants';

const getHmdaInformationData = async (applicationId: string, isCoBorrower: boolean) => {
  const mutation = {
    query:
      `query {
          getHmdaInformationData(applicationId: "${applicationId}", isCoBorrower: ${isCoBorrower}) {
            hmdaGendertypeFemaleIndicator
            hmdaGendertypeMaleIndicator
            hmdaGendertypeDoNotWishIndicator
            hmdaGendertypeNotApplicableIndicator
            hmdaSexInfoNotProvided
            hmdaEthnicityHispanicLatinoIndicator
            hmdaEthnicityNotHispanicLatinoIndicator
            hmdaEthnicityNotApplicableIndicator
            hmdaEthnicityInfoNotProvided
            hmdaEthnicityDoNotWishIndicator
            hmdaMexicanIndicator
            hmdaPuertoRicanIndicator
            hmdaCubanIndicator
            hmdaHispanicLatinoOtherOriginIndicator
            hmdaAsianIndianIndicator
            hmdaChineseIndicator
            hmdaFilipinoIndicator
            hmdaJapaneseIndicator
            hmdaKoreanIndicator
            hmdaVietnameseIndicator
            hmdaAsianOtherRaceIndicator
            hmdaNativeHawaiianIndicator
            hmdaGuamanianOrChamorroIndicator
            hmdaSamoanIndicator
            hmdaPacificIslanderOtherIndicator
            hmdaAmericanIndianIndicator
            hmdaAsianIndicator
            hmdaAfricanAmericanIndicator
            hmdaPacificIslanderIndicator
            hmdaWhiteIndicator
            hmdaRaceDoNotWishProvideIndicator
            hmdaRaceInfoNotProvided
            hmdaOtherHispanicLatinoOrigin
            hmdaAmericanIndianTribe
            hmdaOtherAsianRace
            hmdaOtherPacificIslanderRace
        }
      }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "getHmdaInformationData" ${JSON.stringify({ applicationId, isCoBorrower })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getHmdaInformationData) {
      log({ message: `Query "getHmdaInformationData" was successful ${JSON.stringify({ applicationId, duration, isCoBorrower })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.getHmdaInformationData };
    }
    log({ message: `Query "getHmdaInformationData" failed with errors ${JSON.stringify({ applicationId, duration, isCoBorrower, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "getHmdaInformationData" failed due to exception ${JSON.stringify({ applicationId, isCoBorrower })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const updateHmdaInformationData = async (applicationId: string, isCoBorrower: boolean, hmdaInformation: any) => {
  const mutation = {
    query:
      `mutation {
          updateHmdaInformationData(applicationId: "${applicationId}", isCoBorrower: ${isCoBorrower}, hmdaInformation: ${hmdaInformation}) {
            applicationType
              application {
                  id
                  tenant
                  applicationType
                  createdAt
                  updatedAt
                  state
                  currentState
                  currentDynamicState
                  invitationNumber
                  newLoanId
                  oldLoanNumber
                  oldLoanId
              }
          }
      }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Mutation "updateHmdaInformationData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.updateHmdaInformationData) {
      log({ message: `Mutation "updateHmdaInformationData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.updateHmdaInformationData };
    }
    log({ message: `Mutation "updateHmdaInformationData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Mutation "updateHmdaInformationData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

function PrefiDemographic(props: any) {
  const navigate = useNavigate();
  const [content, setContent] = useState(null) as any;
  const [contentFetched, setContentFetched] = useState(false);
  const [borrowerHmdaInfo, setBorrowerHmdaInfo] = useState<any>();
  const [borrowerHmdaInfoFetched, setBorrowerHmdaInfoFetched] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const [genderList, setGenderList] = useState<Array<string>>([]);
  const [ethnicityList, setEthnicityList] = useState<Array<string>>([]);
  const [raceList, setRaceList] = useState<Array<string>>([]);
  const urlParams = new URLSearchParams(window.location.search);
  let isGenderExistsVal = false;
  let isEthnicityExixtsVal = false;
  let isRaceExixtsVal = false;

  const getContent = async () => {
    const data: any = await fetchFspPageContent(props.isCoborrowerPage ? '"prefi_coborrower_demographic"' : '"prefi_demographic"');
    if (data.content === 'error') {
      log({ message: `PrefiDemographic: fetchFspPageContent(): Error fetching page content. ${JSON.stringify({ page: props.isCoborrowerPage ? 'prefi_coborrower_demographic' : 'prefi_demographic' })}`, level: 'error' });
      setError(true);
      setContentFetched(false);
    } else {
      setError(false);
      setContentFetched(true);
      setContent(data.content);
      if (data?.content) {
        for (let i = 0; i < data.content?.checkbox_module.length; i++) {
          if (data.content.checkbox_module[i].category.header.includes('sex')) {
            setGenderList(getDemographicCategoryList(data, i));
          }
          if (data.content.checkbox_module[i].category.header.includes('ethnicity')) {
            setEthnicityList(getDemographicCategoryList(data, i));
          }
          if (data.content.checkbox_module[i].category.header.includes('race')) {
            setRaceList(getDemographicCategoryList(data, i));
          }
        }
      }
    }
  };

  if (!content && !contentFetched && !error) {
    getContent();
  }

  if (content && props.applicationId && !borrowerHmdaInfo && !borrowerHmdaInfoFetched && !error) {
    getHmdaInformationData(props.applicationId, props.isCoborrowerPage).then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiDemographic: getHmdaInformationData(): isCoborrowerPage ${props.isCoborrowerPage}: Setting custom error based on response ${JSON.stringify({ data })}`, context: { applicationId: props.applicationId }, level: 'error' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          setBorrowerHmdaInfoFetched(false);
          if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
            redirectToMyALogin();
          }
        } else {
          setError(false);
          setBorrowerHmdaInfoFetched(true);
          setBorrowerHmdaInfo(data.content);
        }
      }
    );
  }

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setBorrowerHmdaInfo({
      ...borrowerHmdaInfo,
      [name !== '' ? name : event.target.name]: event.target.checked
    });
  };

  const handleText = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setBorrowerHmdaInfo({
      ...borrowerHmdaInfo,
      [name !== '' ? name : event.target.name]: event.target.value
    });
  };

  const nestedCheckbox = (header: string, checkbox_group: Array<any>, indexVal: number) => (
    <div key={`checkboxWrapper${indexVal}`}>
      <h4 className="px-2 font-bold mt-4 mb-4">{header}</h4>
      {checkbox_group.map(({ option }, indexCheck: number) => (
        <div key={`checkbox${option.label}${indexCheck}`} className="py-1">
          <input
            type="checkbox"
            className="checkbox"
            checked={borrowerHmdaInfo?.[option.value]}
            readOnly={false}
            name={option.value}
            onChange={(event) => handleCheck(event, option.value)}
          />
          <span className="absolute leading-tight">{option.label}</span>
          {option.input.length > 0
            && option.input.map((data: any, index: number) => (
              <div key={`input${option.label}${index}`} className="py-1 ml-8">
                {data.option.type === 'input' ? (
                  <TextInput
                    name={data.option.value}
                    label={data.option.label}
                    value={borrowerHmdaInfo?.[data.option.value]}
                    onChange={(event) => handleText(event, data.option.value)}
                  />
                ) : (
                  <div>
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={borrowerHmdaInfo?.[data.option.value]}
                      readOnly={false}
                      name={data.option.value}
                      onChange={(event) =>
                        handleCheck(event, data.option.value)}
                    />
                    <span className="absolute leading-tight">
                      {data.option.label}
                    </span>
                  </div>
                )}
              </div>
            ))}
        </div>
      ))}
    </div>
  );

  const validateDemographicForm = () => {
    isGenderExistsVal = genderList.every((x) => borrowerHmdaInfo[x] === '' || borrowerHmdaInfo[x] === null || borrowerHmdaInfo[x] === false) === false;
    isEthnicityExixtsVal = ethnicityList.every((x) => borrowerHmdaInfo[x] === '' || borrowerHmdaInfo[x] === null || borrowerHmdaInfo[x] === false) === false;
    isRaceExixtsVal = raceList.every((x) => borrowerHmdaInfo[x] === '' || borrowerHmdaInfo[x] === null || borrowerHmdaInfo[x] === false) === false;
  };

  const isValidDemographicForm = (): boolean => {
    validateDemographicForm();
    return isGenderExistsVal && isEthnicityExixtsVal && isRaceExixtsVal;
  };

  const handleContinue = async () => {
    log({ message: `PrefiDemographic: isCoborrowerPage ${props.isCoborrowerPage}: handleContinue(): Handling continue`, level: 'info' });
    setTrySubmit(true);
    setError(false);
    if (isValidDemographicForm()) {
      setError(false);
      log({ message: `PrefiDemographic: isCoborrowerPage ${props.isCoborrowerPage}: handleContinue(): Client validation passed`, level: 'info' });
      setLoading(true);
      const updatehmdaInformation = objectToGraphQl(borrowerHmdaInfo);
      updateHmdaInformationData(props.applicationId, props.isCoborrowerPage, updatehmdaInformation).then(
        (data: any) => {
          setLoading(false);
          if (data.content === 'error') {
            log({ message: `PrefiDemographic: isCoborrowerPage ${props.isCoborrowerPage}: handleContinue(): updateHmdaInformationData(): Setting custom error based on response ${JSON.stringify({ data })}`, level: 'info' });
            setError(true);
            setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
            window.scrollTo(0, 0);
            if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
              redirectToMyALogin();
            }
          } else {
            setError(false);
            if (data.content?.application) {
              const nextPanel = getNextPanelBasedOnState(data);
              log({ message: `PrefiDemographic: isCoborrowerPage ${props.isCoborrowerPage}: handleContinue(): updateHmdaInformationData(): Success ${JSON.stringify({ nextPanel })}`, level: 'info' });
              navigate(generateLink(`/${nextPanel}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
              window.scrollTo(0, 0);
            }
          }
        }
      );
    } else {
      setError(true);
      setErrorMessage(`Please select at least ${!isGenderExistsVal ? '"one gender option"' : ''}${(!isGenderExistsVal && !isEthnicityExixtsVal && !isRaceExixtsVal) ? ', "one ethnicity option"' : ((!isGenderExistsVal && !isEthnicityExixtsVal) ? 'and "one ethnicity option"' : (!isEthnicityExixtsVal ? '"one ethnicity option"' : ''))} ${((!isGenderExistsVal || !isEthnicityExixtsVal) && !isRaceExixtsVal) ? 'and "one race option"' : (!isRaceExixtsVal ? '"one race option"' : '')}`);
      window.scrollTo(0, 0);
    }
  };

  return (
    <div>
      {(content && borrowerHmdaInfo)
        ? (
          <div className="flex justify-center w-full">
            <div className="max-w-2xl w-full px-1">
              <RateAlertWizardProgress className="lg:hidden px-4 mb-4" />
              <div className="flex items-center justify-center">
                <div className="px-4 w-full">
                  {
                    error
                      ? (
                        <Banner className="border-2 mt-4 mb-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
                      )
                      : ''
                  }
                  <RateAlertLogo alt={content?.header} />
                  <RateAlertWizardProgress className="hidden lg:block mt-6" />
                  <div className="mt-4">{content?.description}</div>
                </div>
              </div>
              <div className="text-xl font-black mt-6 px-4">{content?.header}</div>
              <div className="px-4 mt-2 mb-4 w-full">

                {content?.checkbox_module && content?.checkbox_module?.map((data: any, indexVal: number) => (
                  nestedCheckbox(data.category.header, data.category.checkbox_group, indexVal)
                ))}

                <div className="flex justify-center mt-12 text-sm mb-2">
                  {content.form.disclaimer_content}
                </div>
              </div>
              <div className="px-4 mt-2 mb-8 w-full flex justify-between">
                <div className="flex justify-center mt-12">
                  <PreviousButton isDynamic={true} applicationId={props.applicationId} />
                </div>
                <div className="items-center justify-center">
                  <div className="px-4 mt-2 mb-8 w-full">
                    <div className="flex justify-center mt-12">
                      <Button
                        buttonStyle="primary"
                        className="!w-full md:!w-48 confirmButton"
                        onClick={handleContinue}
                        buttonAttrs={{ disabled: loading }}
                        loading={loading}
                      >{content?.form?.button_label}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="px-4 w-full">
            {
              error
                ? (
                  <Banner className="border-2 mt-4 mb-4" text={errorMessage} title="Error" type="error" icon="warning-triangle" isMultiline={true} errorContent={props.errors.errorContent} />
                )
                : ''
            }
          </div>
        )}
    </div>
  );
}

export default PrefiDemographic;
