import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import Tabs from '@guaranteed-rate/react-components/dist/Tabs';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { MASKS } from '../../../config/util/masks';
import { IAffordabilityData } from '../../../config/util/interfaces';
import PageLoader from '../../../components/PageLoader/PageLoader';
import { affordabilityPathMap } from '../AffordabilityPage';

interface IIncomeProps {
  setAffordabilityData: Dispatch<SetStateAction<IAffordabilityData>>;
  updateProgress: (step: number, progressContent: ProgressProps) => void;
  affordabilityData: IAffordabilityData;
  progress: ProgressProps;
  content: any;
}

interface IIncomeForm {
  base: string;
  overTime: string;
  bonus: string;
  otherIncome: string;
  isMonthly: boolean;
}

const loadExistingData = (data: IAffordabilityData): IIncomeForm => {
  if (!data?.affordableIncomeData) {
    return {
      base: '',
      overTime: '',
      bonus: '',
      otherIncome: '',
      isMonthly: true,
    };
  }
  return data?.affordableIncomeData;
};

const getTotalIncome = (incomeForm: IIncomeForm) => {
  const baseIncome = incomeForm.base === '' ? 0 : parseInt(incomeForm.base);
  const overtimeIncome = incomeForm.overTime === '' ? 0 : parseInt(incomeForm.overTime);
  const bonusIncome = incomeForm.bonus === '' ? 0 : parseInt(incomeForm.bonus);
  const otherIncome = incomeForm.otherIncome === '' ? 0 : parseInt(incomeForm.otherIncome);
  const total = baseIncome + overtimeIncome + bonusIncome + otherIncome;
  return total;
};

export const AffordabilityIncomePage = ({
  progress,
  affordabilityData,
  content,
  updateProgress,
  setAffordabilityData
}: IIncomeProps) => {
  const navigate = useNavigate();
  const [incomeForm, setIncomeForm] = useState(loadExistingData(affordabilityData));
  const [trySubmit, setTrySubmit] = useState(false);
  const [moreOptions, setMoreOptions] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });

  const totalIncome = getTotalIncome(incomeForm);

  const tabs = [
    {
      button: 'Monthly',
      content: '',
      isDefault: incomeForm.isMonthly
    },
    {
      button: 'Annual',
      content: '',
      isDefault: !incomeForm.isMonthly
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setIncomeForm({ ...incomeForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  const handleTabChange = (index: number) => {
    setIncomeForm({ ...incomeForm, isMonthly: (index === 0) });
  };

  const handleSubmit = async () => {
    if (!trySubmit) {
      setTrySubmit(true);
    }
    if (totalIncome > 0 && Number(incomeForm.base)) {
      setAffordabilityData({ ...affordabilityData, affordableIncomeData: incomeForm });
      updateProgress(progress.currentStep + 1, progress);
      navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
        ?? '/home-affordability/'}debt/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    }
  };

  const hasError = () => trySubmit && !Number(incomeForm.base);

  return content ? (
    <Form title={content.header} progress={progress} formStyle="affordability">
      <div className="min-h-[300px]">
        <h4>{content.description}</h4>
        <div className="mb-4 border-b xl:hidden">
          <Tabs tabs={tabs} tabsAriaLabel="Monthly or Annual Input" onChange={handleTabChange} isCentered={true} />
        </div>
        <div className="mb-4 border-b hidden pt-8 xl:block">
          <Tabs tabs={tabs} tabsAriaLabel="Monthly or Annual Input" onChange={handleTabChange} isCentered={false} />
        </div>
        <div className="block xl:flex xl:flex-row xl:space-x-4">
          <div className="flex flex-col xl:grow">
            <TextInput
              name="base"
              value={incomeForm.base}
              label={incomeForm.isMonthly ? content.monthly_income_label : content.annual_income_label}
              onChange={(input) => handleChange(input, 'base')}
              mask={MASKS.CURRENCY}
              hasError={hasError()}
              helperText={hasError() ? 'required' : undefined}
              required={true}
            />
            <div className={`xl:block ${!moreOptions && 'hidden'}`}>
              <TextInput
                name="overTime"
                value={incomeForm.overTime}
                label={content.overtime_income_label}
                onChange={(input) => handleChange(input, 'overTime')}
                mask={MASKS.CURRENCY}
              />
            </div>
          </div>
          <div className={`xl:block ${!moreOptions && 'hidden'} xl:grow`}>
            <div className="flex flex-col">
              <TextInput
                name="bonus"
                value={incomeForm.bonus}
                label={content.bonus_income_label}
                onChange={(input) => handleChange(input, 'bonus')}
                mask={MASKS.CURRENCY}
              />
              <TextInput
                name="otherIncome"
                value={incomeForm.otherIncome}
                label={content.other_income_label}
                onChange={(input) => handleChange(input, 'otherIncome')}
                mask={MASKS.CURRENCY}
              />
            </div>
          </div>
        </div>
        <div className={`xl:block ${!moreOptions && 'hidden'}`}>
          <h4 className="pb-4">Total income: <span id="total-income" className="font-bold">{formatter.format(totalIncome)}</span></h4>
          <div className="xl:hidden">
            <Button
              buttonStyle="quaternary"
              iconPos="left"
              iconName=""
              id="collapse-btn"
              onClick={() => setMoreOptions(false)}
              buttonSize="medium"
            ><FontIcon fontSize="20px" iconName="minus-subtract" className="font-bold" /> Show less options
            </Button>
          </div>
        </div>
        <div className={`xl:hidden ${moreOptions && 'hidden'}`}>
          <Button
            buttonStyle="quaternary"
            className="expand-btn"
            iconPos="left"
            iconName=""
            onClick={() => setMoreOptions(true)}
            buttonSize="medium"
          ><FontIcon fontSize="20px" iconName="plus-add" className="font-bold" /> Show more options
          </Button>
        </div>
      </div>
      <div className="flex justify-center mt-12">
        <Button
          buttonStyle="primary"
          className="continue-btn !w-full md:!w-48"
          onClick={handleSubmit}
        >Continue
        </Button>
      </div>
      <div className="text-center pt-12 ">
        <p>{content.income_description}</p>
        <p>{content.other_income_description}</p>
      </div>
    </Form>
  ) : <PageLoader />;
};
