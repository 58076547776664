/* eslint-disable object-shorthand */
/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import Form from '../../../components/Form/Form';
import { useScript } from '../../../config/hooks/useScript';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import Card from '../../../components/Card/Card';
import { IIncomeData, IIOEOrderDetails } from '../../../config/util/interfaces';
import Alert from '../../../components/Alert/Alert';
import useS3Upload from '../../../config/hooks/useS3Upload';
import { listToGraphQl } from '../../../config/util/common';

interface IIncomeVerificationProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  progress?: ProgressProps;
  verificationData: any;
  incomeData: Array<IIncomeData>;
  applicationId: string;
}

type IncomeAndEmploymentOrderState = 'COMPLETED' | 'IN_PROGRESS' | 'FAILED' | 'SUBMITTED';

type IncomeAndEmploymentOrderObject = Record<string, IncomeAndEmploymentOrderState>;

type verificationState = '' | 'auto' | 'manual' | 'payroll';

const formattedIEOrderStatus = (incomeAndEmploymentOrderStatus: IncomeAndEmploymentOrderObject) => {
  const newObj: any = {};
  Object.keys(incomeAndEmploymentOrderStatus).forEach((item) => {
    let state = '';
    if (item === 'voiePayroll' && (incomeAndEmploymentOrderStatus?.voieInstant === 'COMPLETED')) {
      state = 'COMPLETED';
    } else {
      state = incomeAndEmploymentOrderStatus[item];
    }
    newObj[item] = state;
  });
  return newObj;
};

const imageStyles = {
  height: '46px',
  width: '41px',
};

export const HelocIncomeVerification = (props: IIncomeVerificationProps) => {
  const [loading, setLoading] = useState(false);
  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [trueWorkLoaded, setTrueWorkLoaded] = useState(false);
  const sdksLoaded = trueWorkLoaded && sdkLoaded;
  const [verificationType, setVerificationType] = useState('' as verificationState);
  const [files, setFiles] = useState([] as any);
  const [orderIdDetails, setOrderIdDetails] = useState<IIOEOrderDetails>();
  const [incomeVerificationError, setIncomeVerificationError] = useState('');
  const [ieOrderStatus, setIEOrderStatus] = useState<any>(props?.verificationData?.incomeAndEmploymentOrderStatus
    ? formattedIEOrderStatus(props?.verificationData?.incomeAndEmploymentOrderStatus) : null);
  const [paystubError, setPaystubError] = useState('');
  const { fetchUrlAndUploadTheFileToS3 } = useS3Upload(props.applicationId, 'INCOME_VERIFICATION');

  const onScriptLoad = () => {
    if (!sdkLoaded) {
      setSdkLoaded(true);
      if (verificationType === 'auto') {
        launchFinicity();
      }
    }
  };

  const onTrueWorkLoad = () => {
    if (!trueWorkLoaded) {
      setTrueWorkLoaded(true);
      if (verificationType === 'payroll') {
        launchTrueWork();
      }
    }
  };

  const onFileChange = (event: any) => {
    if (paystubError) {
      setPaystubError('');
    }
    const fileList = [];
    for (let i = 0; i < event.target.files.length; i++) {
      fileList.push(event.target.files.item(i));
    }
    setFiles(fileList);
  };

  const deleteFile = (index: number) => {
    const newFiles = files.map((file: any) => file);
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const launchTrueWork = () => {
    let credentials = (window as any).Truework.credentials.init({
      publishableKey: 'tw_pk_gYjeLx42e33zxF_sIpSPik-aa60skS6M3gwYI_efz1E',
      sessionToken: orderIdDetails?.identifier,
      env: (window as any).Truework.credentials.Env.Production,
    });
    credentials.onOpen(() => {
      console.log('Open');
    });
    credentials.onClose(() => {
      setVerificationType('');
      setLoading(false);
    });
    credentials.onSuccess(() => {
      console.log('Success');
      submitIOEOrder();
      setLoading(false);
    });
    credentials.onError((e: any) => {
      setIncomeVerificationError(props.content.income_verification_error_message);
      console.log(e);
      setLoading(false);
    });
    credentials.open();
    setLoading(true);
  };

  const launchFinicity = () => {
    (window as any).finicityConnect.launch(orderIdDetails?.identifier, {
      selector: '#connect-container',
      overlay: 'rgba(255,255,255, 0)',
      success: async (event: any) => {
        submitIOEOrder();
        setIncomeVerificationError('');
      },
      cancel: (event: any) => {
        // set error true
        console.log('The user cancelled the iframe', event);
      },
      error: (error: any) => {
        // set error true
        console.error('Some runtime error was generated during insideConnect ', error);
        setIncomeVerificationError(props.content.income_verification_error_message);
      },
      loaded: () => {
        console.log('This gets called only once after the iframe has finished loading ');
      },
      route: (event: any) => {
        console.log('This is called as the user navigates through Connect ', event);
      },
      user: (event: any) => {
        console.log('This is called as the user interacts with Connect ', event);
      }
    });
  };

  useScript('https://js.truework.com/v1', false, onTrueWorkLoad);

  useScript('https://connect2.finicity.com/assets/sdk/finicity-connect.min.js', false, onScriptLoad);

  const handleBack = async () => {
    if (verificationType === '') {
      setLoading(true);
      const pageData = `{ 
        page: "${props.pageName}"
        back: true
      }`;
      await props.handleSubmit(pageData, true);
      setLoading(false);
    } else {
      if (verificationType === 'auto') {
        (window as any).finicityConnect.destroy();
      }
      setVerificationType('');
    }
  };

  const onPageSubmit = async () => {
    setLoading(true);
    const pageData = `{ 
        page: "${props.pageName}"
      }`;
    await props.handleSubmit(pageData, false);
    setLoading(false);
  };

  useEffect(() => {
    if (verificationType === 'auto' && sdkLoaded) {
      launchFinicity();
    } else if (verificationType === 'payroll' && trueWorkLoaded) {
      launchTrueWork();
    }
  }, [verificationType, sdkLoaded, trueWorkLoaded]);

  const handleUpload = async () => {
    setLoading(true);

    (window as any).digitalData = {
      pageInfo: {
        incomeFiles: files.map((file: any) => file.name)
      }
    };

    try {
      setLoading(true);
      const response = await fetchUrlAndUploadTheFileToS3(files);
      const mutation = {
        query:
          `mutation {
            submitIOEOrder(
              id: "${props.applicationId}",
              data: {
                awsS3Documents: ${listToGraphQl(response)}
              }
            ) {
              id
              page
              verificationData {
                incomeAndEmploymentOrderStatus {
                  voi
                  voieInstant
                  voiePayroll
                }
              }
            }
          }`
      };
      await submitDataToMutation(mutation);
    } catch (e) {
      console.log(e);
      setPaystubError(props?.content?.paystub_upload_error_label);
    } finally {
      setLoading(false);
    }
  };

  const createIOEOrder = async (type: verificationState, product: string) => {
    setLoading(true);
    const mutation = {
      query:
        `mutation {
          createIOEOrder(
            id: "${props.applicationId}",
            data: {
              product: "${product}",
            }
          ) {
            orderId
            product
            identifier
          }
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.createIOEOrder) {
        setOrderIdDetails(data.data.createIOEOrder);
        setVerificationType(type);
        setIncomeVerificationError('');
        setLoading(false);
      } else {
        console.log(data?.errors[0]);
        setIncomeVerificationError(props.content.income_verification_error_message);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setIncomeVerificationError(props.content.income_verification_error_message);
      setLoading(false);
    }
  };

  const submitIOEOrder = async () => {
    const mutation = {
      query:
        `mutation {
          submitIOEOrder(
            id: "${props.applicationId}",
            data: {
              product: "${orderIdDetails?.product}",
              orderId: "${orderIdDetails?.orderId}",
            }
          ) {
            id
            page
            verificationData {
              incomeAndEmploymentOrderStatus {
                voi
                voieInstant
                voiePayroll
              }
            }
          }
        }`
    };
    await submitDataToMutation(mutation);
  };

  const submitDataToMutation = async (mutation: any) => {
    setLoading(true);
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.submitIOEOrder?.verificationData?.incomeAndEmploymentOrderStatus) {
        setIEOrderStatus(formattedIEOrderStatus(
          data?.data?.submitIOEOrder?.verificationData?.incomeAndEmploymentOrderStatus
        ));
        await onPageSubmit();
      }
      setVerificationType('');
      setIncomeVerificationError('');
      console.log(data);
    } catch (e) {
      console.log(e);
      setIncomeVerificationError(props.content.income_verification_error_message);
    } finally {
      setLoading(false);
    }
  };

  const handleManualVerification = () => {
    if ((!ieOrderStatus?.manualUpload || ieOrderStatus?.manualUpload === 'IN_PROGRESS')) {
      setVerificationType('manual');
      setIncomeVerificationError('');
    }
  };

  const isTrueworkRequired: boolean = props.incomeData?.findIndex((income) => income.incomeSource === 'EMPLOYED_FULL_TIME') !== -1;

  return (
    <Form title={verificationType === '' ? props.content.prompt_header : props.content.header} progress={props.progress}>
      {incomeVerificationError && (
        <div className="mb-8">
          <Alert text={incomeVerificationError} type="error" />
        </div>
      )}
      {
        verificationType === ''
          ? (
            <div>
              {loading
                ? (
                  <div className="min-h-[420px] flex items-center justify-center">
                    <Loader color="#D13239" className="loader-medium" />
                  </div>
                )
                : (
                  <div className="min-h-[420px]">
                    <Card
                      className="mb-6 autoVerify flex finicity"
                      role="button"
                      tabIndex={0}
                      onClick={() => (!ieOrderStatus?.voi || (ieOrderStatus?.voi === 'IN_PROGRESS')
                        ? createIOEOrder('auto', 'voai') : null)}
                    >
                      <div
                        className="p-6 flex flex-row items-center justify-between gap-4"
                        role="button"
                        tabIndex={0}
                      >
                        <img style={imageStyles} src="https://images.contentstack.io/v3/assets/bltff9521c11371bfa5/bltaa9f340f54d96329/66420040e25e7dfe1689f473/banking.svg" alt="Banking" />
                        <div className="flex items-center gap-4">
                          <div className="content-group">
                            <div className="flex justify-between">
                              <span className="flex mb-2 flex-col md:flex-row">
                                <h4 className="font-bold">{props.content.auto_title}</h4>
                                <span
                                  className={`ml-0 md:ml-4 px-1 rounded ieOrderStatusTag ${props?.content?.ieorderstatus_tag[ieOrderStatus?.voi.toLowerCase()]}`}
                                >
                                  {(ieOrderStatus?.voi && (ieOrderStatus?.voi !== 'IN_PROGRESS'))
                                    ? props?.content?.ieorderstatus_tag[ieOrderStatus?.voi.toLowerCase()] : 'Most Popular'}
                                </span>
                              </span>
                            </div>
                            <p>{props.content.auto_description}</p>
                          </div>
                        </div>
                        {(ieOrderStatus && (ieOrderStatus?.voi === 'IN_PROGRESS')) && (
                          <FontIcon className="text-black" iconName="chevron-right-large" />
                        )}
                      </div>
                    </Card>
                    {isTrueworkRequired && (
                      <Card
                        className="mb-6 payrollVerify flex truework"
                        role="button"
                        tabIndex={0}
                        onClick={() => (
                          (!ieOrderStatus?.voiePayroll || (ieOrderStatus?.voiePayroll === 'IN_PROGRESS'))
                            ? createIOEOrder('payroll', 'voie')
                            : null
                        )}
                      >
                        <div className="p-6 flex flex-row items-center justify-between gap-4 w-full">
                          <div className="flex items-center gap-4">
                            <img style={imageStyles} src="https://images.contentstack.io/v3/assets/bltff9521c11371bfa5/blt622d5283c83dadb6/66420040e25e7d34b089f46f/truework.svg" alt="employment" />
                            <div className="content-group">
                              <div className="flex justify-between">
                                <span className="flex mb-2 flex-col md:flex-row">
                                  <h4 className="font-bold">{props.content.payroll_title}</h4>
                                  {(ieOrderStatus?.voiePayroll && (ieOrderStatus?.voiePayroll !== 'IN_PROGRESS')) && (
                                    <span
                                      className={`ml-0 md:ml-4 px-1 rounded ieOrderStatusTag ${props?.content?.ieorderstatus_tag[ieOrderStatus?.voiePayroll.toLowerCase()]}`}
                                    >
                                      {props?.content?.ieorderstatus_tag[ieOrderStatus?.voiePayroll.toLowerCase()]}
                                    </span>
                                  )}
                                </span>
                              </div>
                              <p>{props.content.payroll_description}</p>
                            </div>
                          </div>
                          {(ieOrderStatus && (ieOrderStatus?.voiePayroll === 'IN_PROGRESS')) && (
                            <FontIcon className="text-black" iconName="chevron-right-large" />
                          )}
                        </div>
                      </Card>
                    )}
                    <Card className="mb-6 manualVerify">
                      <div
                        onClick={handleManualVerification}
                        className="p-6 flex flex-row items-center justify-between gap-4"
                        role="button"
                        tabIndex={0}
                      >
                        <div className="flex items-center gap-4">
                          <img style={imageStyles} src="https://images.contentstack.io/v3/assets/bltff9521c11371bfa5/bltc62c3a915714d017/6642004042461a437eeb5dab/upload.svg" alt="Paystub" />
                          <div className="content-group">
                            <div className="flex justify-between">
                              <span className="flex mb-2 flex-col md:flex-row">
                                <h4 className="font-bold">{props.content.manual_title}</h4>
                                {(ieOrderStatus?.manualUpload && (ieOrderStatus?.manualUpload !== 'IN_PROGRESS')) && (
                                  <span
                                    className={`ml-0 md:ml-4 px-1 rounded ieOrderStatusTag ${props?.content?.ieorderstatus_tag[ieOrderStatus?.manualUpload.toLowerCase()]}`}
                                  >
                                    {props?.content?.ieorderstatus_tag[ieOrderStatus?.manualUpload.toLowerCase()]}
                                  </span>
                                )}
                              </span>
                            </div>
                            <p>{props.content.manual_description}</p>
                          </div>
                        </div>
                        <FontIcon className="text-black" iconName="chevron-right-large" />
                      </div>
                    </Card>
                  </div>
                )}
            </div>
          )
          : verificationType === 'payroll'
            ? !sdksLoaded || loading
              ? (
                <div className="min-h-[420px] flex items-center justify-center">
                  <Loader color="#D13239" className="loader-medium" />
                </div>
              )
              : (
                <div>
                  <div className="min-h-[420px]">
                    <div id="connect-container" className="w-full h-[880px]" />
                  </div>
                  <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                    <Button buttonStyle="quaternary" iconPos="left" iconName="chevron-left-large" onClick={handleBack}>Back</Button>
                  </div>
                </div>
              )
            : verificationType === 'auto'
              ? !sdksLoaded || loading
                ? (
                  <div className="min-h-[420px] flex items-center justify-center">
                    <Loader color="#D13239" className="loader-medium" />
                  </div>
                )
                : (
                  <div>
                    <div className="min-h-[420px]">
                      <div id="connect-container" className="w-full h-[880px]" />
                    </div>
                    <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                      <Button buttonStyle="quaternary" iconPos="left" iconName="chevron-left-large" onClick={handleBack}>Back</Button>
                    </div>
                  </div>
                )
              : (
                <div>
                  <div className="min-h-[420px]">
                    <h3 className="font-bold mb-4">{props.content.manual.subheader}</h3>
                    <p className="mb-[10px]">{props.content.manual.subtext}</p>
                    <Card className="mb-6">
                      <div>
                        <input type="file" id="documentUpload" className="hidden" multiple={true} onChange={onFileChange} />
                        <label htmlFor="documentUpload">
                          <div className="text-center p-4 cursor-pointer flex justify-center items-center gap-[4px]">
                            <FontIcon iconName="cloud-upload" className="tertiary text-xl !leading-5" /> <span className="font-bold tertiary">Browse Files</span> or <span className="font-bold tertiary">take a photo</span>
                          </div>
                        </label>
                      </div>
                    </Card>
                    <div className="mb-6">
                      {files.map((file: any, index: number) => (
                        <div className="flex justify-between gap-4" key={`${file.name}${index}`}>
                          <span className="font-bold tertiary">{file.name}</span>
                          <button onClick={() => deleteFile(index)}>
                            {loading ? (
                              <Loader className="paystub loading" />
                            ) : <FontIcon iconName={!paystubError ? 'trash' : 'redo'} className="paystub text-xl !leading-5" />}
                          </button>
                        </div>
                      ))}
                      {paystubError && <span className="paystub error">{paystubError}</span>}
                    </div>
                  </div>
                  <div className="flex justify-center mt-12">
                    <Button
                      buttonStyle="primary"
                      className="!w-full md:!w-64"
                      onClick={handleUpload}
                      loading={loading}
                      buttonAttrs={{ disabled: files.length === 0 || loading }}
                    >Submit
                    </Button>
                  </div>
                  <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                    <Button buttonStyle="quaternary" iconPos="left" iconName="chevron-left-large" onClick={handleBack}>Back</Button>
                  </div>
                </div>
              )
      }
    </Form>
  );
};
