import Bowser from 'bowser';
import { nanoid } from 'nanoid';

export const getBrowser = () => {
  // eslint-disable-next-line import/no-named-as-default-member
  const { browser, ...rest } = Bowser.parse(window.navigator.userAgent);
  return {
    ...browser,
    ...rest,
  };
};

export const clientId = nanoid(24);

export const getCookieEnabled = () => window.navigator.cookieEnabled;

export const getReferrer = () =>
  ((document.referrer && document.referrer !== window.location.hostname)
    ? document.referrer
    : '');

export const getResolution = () => ({
  viewport: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  screen: {
    width: window.screen.width,
    height: window.screen.height,
    pixelDepth: window.screen.pixelDepth,
  },
});

export const getBrowserFull = () => ({
  ...getBrowser(),
  clientId,
  cookieEnabled: getCookieEnabled(),
  referrer: getReferrer(),
  ...getResolution(),
});

export default getBrowserFull;
