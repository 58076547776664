/* eslint-disable max-len */
import * as React from 'react';
import Cookies from 'js-cookie';
import { useState } from 'react';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { fetchFspPageContent, getCode, getTimeDiff, redirectToMyALogin, setCustomErrorBasedOnResponse } from '../../config/util/common';
import Banner from '../../components/Banner/Banner';
import RateAlertLogo from '../../components/RateAlertLogo/RateAlertLogo';
import { RateAlertWizardProgress } from '../../components/WizardProgress/RateAlertWizardProgress';
import { generateLink, getNextPanelBasedOnState } from './prefiCommon';
import { log } from '../../config/util/logger';
import { REDIRECT_TO_LOGIN_ERROR_CODE } from '../../config/content/constants';

const getCoBorrowerBasicInfoData = async (applicationId: string) => {
  const mutation = {
    query:
      `query {
          getCoBorrowerBasicInfoData(applicationId: "${applicationId}") {
            name
        }
      }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "getCoBorrowerBasicInfoData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getCoBorrowerBasicInfoData) {
      log({ message: `Query "getCoBorrowerBasicInfoData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.getCoBorrowerBasicInfoData };
    }
    log({ message: `Query "getCoBorrowerBasicInfoData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "getCoBorrowerBasicInfoData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const includeCoborrowerInfoData = async (applicationId: string, includeCoborrowerInfoRequest: any) => {
  const mutation = {
    query:
      `mutation {
        includeCoborrowerInfoData(applicationId: "${applicationId}", includeCoborrowerInfoRequest: ${includeCoborrowerInfoRequest}) {
          applicationType
            application {
                id
                tenant
                applicationType
                createdAt
                updatedAt
                state
                currentState
                currentDynamicState
                invitationNumber
                newLoanId
                oldLoanNumber
                isValidSSN
                isValidDateOfBirth
                oldLoanId
            }
    }
  }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Mutation "includeCoborrowerInfoData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.includeCoborrowerInfoData?.application) {
      log({ message: `Mutation "includeCoborrowerInfoData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.includeCoborrowerInfoData };
    }
    log({ message: `Mutation "includeCoborrowerInfoData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Mutation "includeCoborrowerInfoData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

function PrefiCoborrowerExists(props: any) {
  const navigate = useNavigate();
  const [content, setContent] = useState(null as any);
  const [contentFetched, setContentFetched] = useState(false);
  const [coBorrowerBasicInfo, setCoBorrowerBasicInfo] = useState(null as any);
  const [coBorrowerNameFetched, setCoBorrowerNameFetched] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [isCoborrowerExistsChecked, setCoborrowerExistsChecked] = useState(null);
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  if (!content && !contentFetched && !error) {
    fetchFspPageContent('"prefi_coborrower_exists"').then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiCoborrowerExists: fetchFspPageContent(): Error fetching page content. ${JSON.stringify({ page: 'prefi_coborrower_exists' })}`, level: 'error' });
          setError(true);
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
        }
      }
    );
  }

  if (content && props.applicationId && !coBorrowerBasicInfo && !coBorrowerNameFetched && !error) {
    getCoBorrowerBasicInfoData(props.applicationId).then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiCoborrowerExists: getCoBorrowerBasicInfoData(): Setting custom error based on response ${JSON.stringify({ data })}`, context: { applicationId: props.applicationId }, level: 'error' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          setCoBorrowerNameFetched(false);
          if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
            redirectToMyALogin();
          }
        } else {
          setError(false);
          setCoBorrowerNameFetched(true);
          setCoBorrowerBasicInfo(data.content);
        }
      }
    );
  }

  const coborrower_exists = content?.coborrower_exists ? content?.coborrower_exists?.map((item: any) => ({
    name: 'coborrower',
    label: item.option.label,
    value: item.option.value,
    active: false
  })) : [];

  const handleCoborrowerExists = async (isExixts: any) => {
    log({ message: `PrefiCoborrowerExists: handleCoborrowerExists(): Handling continue ${JSON.stringify({ isExixts })}`, level: 'info' });
    setError(false);
    setLoading(true);
    const includeCoborrowerInfoRequest = `{
      includeCoBorrower: ${isExixts === 'yes'}
      }`;
    includeCoborrowerInfoData(props.applicationId, includeCoborrowerInfoRequest).then(
      (data: any) => {
        setLoading(false);
        if (data.content === 'error') {
          log({ message: `PrefiCoborrowerExists: handleCoborrowerExists(): includeCoborrowerInfoData(): Setting custom error based on response ${JSON.stringify({ data })}`, level: 'info' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
            redirectToMyALogin();
          }
        } else {
          // PrefiCoborrowerExists
          const nextPanel = getNextPanelBasedOnState(data);
          log({ message: `PrefiCoborrowerExists: handleCoborrowerExists(): includeCoborrowerInfoData(): Success ${JSON.stringify({ nextPanel })}`, level: 'info' });
          navigate(generateLink(`/${nextPanel}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
          window.scrollTo(0, 0);
        }
      }
    );
  };

  return (
    <div>
      {
        (content && coBorrowerBasicInfo)
          ? (
            <div className="flex items-center justify-center">
              <div className="items-center justify-center w-full max-w-3xl px-1">
                <RateAlertWizardProgress className="lg:hidden px-4 mb-4" />
                <div className="items-center justify-center lg:mb-8">
                  <div className="px-4 w-full">
                    {
                      error
                        ? (
                          <Banner className="border-2 mt-4 mb-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
                        )
                        : ''
                    }
                    <div>
                      <RateAlertLogo alt={content?.header} />
                    </div>
                  </div>
                </div>
                <div className="w-full px-1 lg:border border-solid border-slate-300 rounded-2xl bg-white">
                  <RateAlertWizardProgress className="hidden lg:block px-4 mt-6" />
                  <div className="flex items-center justify-center mb-8">
                    <div className="px-4 w-full">
                      <div className="mt-4"><span className="font-bold">{coBorrowerBasicInfo?.name} </span> {content?.description}</div>
                    </div>
                  </div>
                  <div className="items-center justify-center">
                    <div className="mb-2 w-full">
                      <RadioButtons
                        className="radioButton inline-block place-self-auto p-1 w-full font-bold sm:w-1/2"
                        isChecked={isCoborrowerExistsChecked}
                        updateCheckedValue={(event) => { setCoborrowerExistsChecked(event.target.value); }}
                        radios={coborrower_exists}
                        disabled={loading}
                        onChange={(event) => handleCoborrowerExists(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="px-4 w-full">
              {
                error
                  ? (
                    <Banner className="border-2 mt-4 mb-4" text={errorMessage} title="Error" type="error" icon="warning-triangle" isMultiline={true} errorContent={props.errors.errorContent} />
                  )
                  : ''
              }
            </div>
          )
      }
    </div>
  );
}

export default PrefiCoborrowerExists;
