import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { IAffordabilityData, ILoData } from '../../../config/util/interfaces';
import PageLoader from '../../../components/PageLoader/PageLoader';
import { affordabilityPathMap } from '../AffordabilityPage';

interface IOptionsProps {
  setAffordabilityData: Dispatch<SetStateAction<IAffordabilityData>>;
  updateProgress: (step: number, progressContent: ProgressProps) => void;
  affordabilityData: IAffordabilityData;
  progress: ProgressProps;
  content: any;
  loData: ILoData;
}

function loadExistingData(data: IAffordabilityData) {
  if (data?.isMilitary === undefined) return '';
  return data?.isMilitary ? 'true' : 'false';
}

export const AffordabilityMilitaryPage = ({
  progress,
  content,
  affordabilityData,
  loData,
  updateProgress,
  setAffordabilityData
}: IOptionsProps) => {
  const [military] = useState(loadExistingData(affordabilityData));
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setAffordabilityData({ ...affordabilityData, isMilitary: event.target.value === 'true' });
    updateProgress(progress.currentStep + 1, progress);
    if (loData) {
      navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
        ?? '/home-affordability/'}location/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    } else {
      navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
        ?? '/home-affordability/'}officer/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    }
  };

  const handleBack = () => {
    updateProgress(progress.currentStep - 1, progress);
    navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
      ?? '/home-affordability/'}creditScore/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
  };

  return content ? (
    <Form title={content.header} progress={progress} formStyle="affordability">
      <div className="min-h-[300px]">
        <h4 className="mb-8">{content.description}</h4>
        <RadioButtons
          className="radioButton"
          radios={content.radio_button.map((item: any) => ({
            name: 'options',
            label: item.option.label,
            value: item.option.value,
            active: military === item.option.value,
          }))}
          onChange={handleSelect}
        />
      </div>
      <div className="flex justify-center -ml-8 xl:block xl:ml-0">
        <Button buttonStyle="quaternary" iconPos="left" iconName="chevron-left-large" onClick={handleBack}>Back</Button>
      </div>
    </Form>
  ) : <PageLoader />;
};
