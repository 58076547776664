import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import Logo from '@guaranteed-rate/react-components/dist/Logo';
import * as React from 'react';

function OwningHeaderTypeOne() {
  return (
    <>
      <div className="flex justify-between w-full p-4">
        <div className="owning-logo-container z-1 w-fit mx-auto mt-2">
          <Logo
            url="http://www.owning.com"
            src="/images/owningLogo.svg"
            alt="Owning"
            width={88}
            height={17}
          />
        </div>
        <button className="font-bold text-md scale-150">
          <FontIcon iconName="user" className="!leading-8 text-lg" />
        </button>
      </div>
      <div className="header-linebreak-thin" />
    </>
  );
}

export default OwningHeaderTypeOne;
