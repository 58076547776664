import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import { useState, useEffect } from 'react';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { IAdditionalInfo } from '../../../config/util/interfaces';
import { IPropertyForm } from '../HelocPropertyPage/HelocPropertyPage';
import Banner from '../../../components/Banner/Banner';

interface IOwnershipProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  progress?: ProgressProps;
  additionalInfo: IAdditionalInfo | IPropertyForm;
  setAdditionalInfo: any;
  isLite?: boolean;
}

export const HelocOwnershipPage = (props: IOwnershipProps) => {
  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);

  // load the ownership types from content stack
  const options = props.content.radio_button.map((item: any) => ({
    name: 'options',
    label: item.option.label,
    value: item.option.value,
    active: false
  }));

  // move to the next screen
  const handleSubmit = async () => {
    setTrySubmit(true);
    if (props.additionalInfo.ownershipType !== ''
     && (props.additionalInfo.ownershipType !== 'REVOCABLE_TRUST' || props.additionalInfo.trustName !== '')) {
      setLoading(true);
      const pageData = `{ 
        page: "${props.pageName}"
        ${props.isLite ? 'lite: true' : ''}
      }`;
      await props.handleSubmit(pageData, false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.pageName === 'partnership') {
      props.setAdditionalInfo({ ...props.additionalInfo, trustName: '', ownershipType: '' });
    }
  }, [props.pageName]);

  // save the ownership data to the global additional info object
  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    props.additionalInfo.trustName = '';
    props.setAdditionalInfo({ ...props.additionalInfo, trustName: '', ownershipType: event.target.value });
  };

  // go to previous screen
  const handleBack = async () => {
    setTrySubmit(true);
    const pageData = `{ 
      page: "${props.pageName}"
      ${props.isLite ? 'lite: true' : ''}
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };

  return (
    <Form title={props.content.header} progress={props.progress}>
      <div className="min-h-[300px]">
        <div className="flex justify-center w-full">
          <div className="w-full max-w-md">
            {props.additionalInfo.ownershipType === 'IRREVOCABLE_TRUST' && (
              <Banner
                className="border-2 mt-4 mb-4 irrevocableTrust"
                text=""
                title={props.content.warning_message}
                type="error"
                icon="warning-triangle"
              />
            )}
            <RadioButtons
              className="radioButton"
              radios={options}
              onChange={handleSelect}
              isChecked={props.additionalInfo.ownershipType}
            />
            {
              props.additionalInfo.ownershipType === 'REVOCABLE_TRUST' && (
                <TextInput
                  name="trust"
                  value={props.additionalInfo.trustName}
                  label={props.content.trust_label}
                  onChange={(event: any) => props.setAdditionalInfo({ ...props.additionalInfo, trustName: event.target.value })}
                  hasError={trySubmit && props.additionalInfo.ownershipType === 'REVOCABLE_TRUST' && props.additionalInfo.trustName === ''}
                  helperText={trySubmit && props.additionalInfo.ownershipType === 'REVOCABLE_TRUST'
          && props.additionalInfo.trustName === '' ? 'required' : undefined}
                  className="mt-4"
                  required={true}
                />
              )
            }
          </div>
        </div>

      </div>

      <div className="flex justify-center mt-12">
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-48 nextButton"
          onClick={handleSubmit}
          loading={loading}
          buttonAttrs={{
            disabled:
              loading
              || props.additionalInfo.ownershipType === ''
              || props.additionalInfo.ownershipType === 'IRREVOCABLE_TRUST',
          }}
        >Next
        </Button>
      </div>
      <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
        <Button
          buttonStyle="quaternary"
          iconPos="left"
          iconName="chevron-left-large"
          onClick={handleBack}
          buttonAttrs={{ disabled: loading }}
        >Back
        </Button>
      </div>
    </Form>
  );
};
