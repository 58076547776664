import Dropdown, { IDropdownOption } from '@guaranteed-rate/react-components/dist/Dropdown';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import React, { useMemo, useEffect } from 'react';
import { IPersonalDetailFormLabels } from './types';

export interface IPersonalDetailFormData {
  firstName: string;
  lastName: string;
  dependentsCount: string;
  maritalStatus: string;
  propertyUse?: string; // only present for primary borrower
}

export interface IPersonalDetailFormProps {
  formData: IPersonalDetailFormData;
  formLabels?: IPersonalDetailFormLabels;
  onFormDataChange: (formData: IPersonalDetailFormData) => void;
  onValidityChange: (isValid: boolean) => void;
  isTouched: boolean;
  maritalStatusOptions: Array<IDropdownOption>;
  enablePropertyUseField: boolean;
  propertyUseOptions?: Array<IDropdownOption>;
}

export const getNewPersonalDetailForm = (): IPersonalDetailFormData => ({
  firstName: '',
  lastName: '',
  dependentsCount: '',
  maritalStatus: '',
  propertyUse: '',
});

const keysToValidate: Array<keyof IPersonalDetailFormData> = Object.keys(
  getNewPersonalDetailForm()
) as Array<keyof IPersonalDetailFormData>;

export const validatePersonalDetailForm = (
  formData: IPersonalDetailFormData,
  enablePropertyUseField: boolean,
) => {
  const validityMap: Record<keyof IPersonalDetailFormData, boolean> = {} as any;
  keysToValidate.forEach((key: keyof IPersonalDetailFormData) => {
    if (key === 'propertyUse' && !enablePropertyUseField) {
      // do not validate
    } else {
      validityMap[key] = !!formData[key]?.length;
    }
  });
  return validityMap;
};

export const PersonalDetailForm = ({
  formData,
  formLabels,
  onFormDataChange,
  onValidityChange,
  isTouched,
  maritalStatusOptions,
  enablePropertyUseField,
  propertyUseOptions
}: IPersonalDetailFormProps) => {
  const validityMap = useMemo(
    () => {
      const validityMap = validatePersonalDetailForm(formData, enablePropertyUseField);
      return validityMap;
    },
    [formData]
  );

  const isValid = useMemo(() => Object.values(validityMap).every((isValid) => isValid), [validityMap]);

  useEffect(() => {
    onValidityChange(isValid);
  }, [isValid]);

  const handleFormChange = (formData: IPersonalDetailFormData) => {
    onFormDataChange(formData);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const newFormData: IPersonalDetailFormData = {
      ...formData,
      [name]: value,
    };
    handleFormChange(newFormData);
  };

  const hasError = (key: keyof IPersonalDetailFormData) => isTouched && !validityMap[key];

  return (
    <div>
      <TextInput
        name="firstName"
        label={formLabels?.first_name_label}
        className="w-full"
        value={formData?.firstName}
        hasError={hasError('firstName')}
        helperText={hasError('firstName') ? 'required field' : ''}
        onChange={handleTextChange}
        required={true}
      />
      <TextInput
        name="lastName"
        label={formLabels?.last_name_label}
        className="w-full"
        value={formData?.lastName}
        hasError={hasError('lastName')}
        helperText={hasError('lastName') ? 'required field' : ''}
        onChange={handleTextChange}
        required={true}
      />
      <TextInput
        name="dependentsCount"
        maxLength={2}
        minLength={1}
        label={formLabels?.number_of_dependents_label}
        className="w-full"
        value={formData?.dependentsCount}
        hasError={hasError('dependentsCount')}
        helperText={hasError('dependentsCount') ? 'required field' : ''}
        onChange={handleTextChange}
        required={true}
      />
      <Dropdown
        name="maritalStatus"
        value={formData?.maritalStatus}
        label={formLabels?.marital_status_label}
        onChange={(e) => handleFormChange({
          ...formData,
          maritalStatus: e.target.value,
        })}
        className="w-full"
        options={maritalStatusOptions}
        hasError={hasError('maritalStatus')}
        helperText={hasError('maritalStatus') ? 'required field' : ''}
        required={true}
      />
      {enablePropertyUseField
      && (
        <Dropdown
          name="propertyUse"
          value={formData?.propertyUse}
          label={formLabels?.property_use_label}
          onChange={(e) => handleFormChange({
            ...formData,
            propertyUse: e.target.value,
          })}
          className="w-full"
          options={propertyUseOptions || []}
          hasError={hasError('propertyUse')}
          helperText={hasError('propertyUse') ? 'required field' : ''}
          required={true}
        />
      )}
    </div>
  );
};
