import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons, { useRadio } from '@guaranteed-rate/react-components/dist/RadioButtons';
import DropDownSearch from '@guaranteed-rate/react-components/dist/DropDownSearch';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { setLoCookie } from '../../../config/util/common';
import { IPropertyForm } from '../HelocPropertyPage/HelocPropertyPage';
import { ILoanOfficer } from '../../../config/util/interfaces';

interface IOfficerProps {
  handleSubmit: (form: IPropertyForm) => Promise<any>;
  setLo: (loId: string) => void;
  content: any;
  helocForm: IPropertyForm;
  handleBack: () => Promise<any>;
  setLoading: (loading: boolean) => void;
  isLite?: boolean;
  loanOfficers?: any;
  loading: boolean;
}

const options = [
  {
    name: 'yes',
    label: 'Yes',
    value: 'yes',
    active: false
  },
  {
    name: 'no',
    label: 'No',
    value: 'no',
    active: false
  }
];

export const HelocOfficerPage = (props: IOfficerProps) => {
  const [loData, setLoData] = useState(props.loanOfficers);
  const [yesClicked, setYesClicked] = useState(false);
  const { updateCheckedValue, isChecked } = useRadio();

  useEffect(() => {
    if (props.loanOfficers && props.loanOfficers?.length > 0) {
      setLoData(props.loanOfficers);
    }
  }, [props.helocForm.state, props.loanOfficers]);

  const handleSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // Here we pass in the page name as the key for the value selected from the radio button
    if (event.target.value === options[0].value) {
      setYesClicked(true);
      if (props.isLite && props.loanOfficers?.length === 0) {
        props.setLoading(true);
      }
    } else {
      await handleLoSelect({ employeeId: '' });
    }
  };

  const handleLoSelect = async (selectedItem: any) => {
    props.setLoading(true);
    if (selectedItem.employeeId) {
      setLoCookie(selectedItem.employeeId);
      props.setLo(selectedItem.employeeId);
    }
    props.handleSubmit(props.helocForm);
  };

  return (

    <div>
      <div className="min-h-[300px]">
        <RadioButtons
          className="radioButton"
          radios={options}
          onChange={handleSelect}
          updateCheckedValue={updateCheckedValue}
          isChecked={isChecked}
        />
        {yesClicked
          && (props.loading && props.isLite
            ? (
              <div className="flex items-center justify-center">
                <Loader color="#D13239" className="loader-medium" />
              </div>
            )
            : (
              <div>
                <h4 className="font-bold mb-4">{props.content.sub_prompt}</h4>
                <DropDownSearch
                  label={props.content.search_label}
                  items={loData}
                  optionLabel="displayName"
                  onChange={handleLoSelect}
                />
              </div>
            ))}
      </div>
      <div className="flex justify-center mt-8 -ml-8 mb-4 xl:block xl:mb-8 xl:ml-0">
        <Button
          buttonStyle="quaternary"
          iconPos="left"
          iconName="chevron-left-large"
          onClick={props.handleBack}
          buttonAttrs={{ disabled: props.loading }}
        >Back
        </Button>
      </div>
    </div>
  );
};
