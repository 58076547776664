/* eslint-disable react/no-danger */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Form from '../../../components/Form/Form';
import { IHelocPageResponse, IPricingData, ITerm, PRICING_TYPES } from '../../../config/util/interfaces';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { MASKS } from '../../../config/util/masks';
import { localeOptions } from '../../../config/util/common';
import { HelocConfirmationPage } from './HelocConfirmationPage';
import LoanCard from '../../../components/LoanCard/LoanCard';
import Banner from '../../../components/Banner/Banner';

interface IPrequalifyProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pricingData: IPricingData;
  name: string;
  pageName: string;
  progress?: ProgressProps;
  confirmationData?: IHelocPageResponse;
  refreshPricing: any;
}

export const HelocPricingPage = (props: IPrequalifyProps) => {
  const disabled = props.pageName === 'prequalify' && (props.confirmationData?.disablePrequalify || false);
  const options = disabled ? [] : props.pricingData?.options?.map((option) => ({ displayName: `${parseFloat(option.fee).toFixed(2)}%`, value: option.fee, ...option })) || [];
  const [showConfirmation, setShowConfirmaiton] = useState(disabled);
  const [loading, setLoading] = useState(false);
  const [amountField, setAmountField] = useState(`${props.pricingData?.loanAmount}`);
  const maximum = props.pricingData?.maximumAmount || 0;
  const minimum = props.confirmationData?.propertyData?.address?.region === 'AK' ? 25000 : 20000;
  const [selectedOrigination, setSelectedOrigination] = useState(disabled
    ? { fee: props.pricingData?.selected.fee, terms: [props.pricingData?.selected.term], displayName: `${parseFloat(props.pricingData?.selected.fee).toFixed(2)}%`, value: props.pricingData?.selected.fee }
    : options[0]);
  const terms = selectedOrigination ? selectedOrigination.terms.filter((term: ITerm) => term.apr && term.estimatedMonthlyPayment && term.loanAmount && term.pricingTermId && term.term).sort((a: ITerm, b: ITerm) => parseInt(b.term.slice(0, 2)) - parseInt(a.term.slice(0, 2))) : [];
  const [selectedTerm, setSelectedTerm] = useState(disabled ? props.pricingData.selected.term : terms[0]);
  const loanAmount = selectedTerm?.loanAmount || props.pricingData?.loanAmount;
  const header = props.pageName === 'prequalify' ? `${props.content.header} ${maximum.toLocaleString('en-us', localeOptions)}.` : props.content.header;
  const [autoPay, setAutopay] = useState(props.pricingData?.autoPay !== null ? props.pricingData?.autoPay : true);
  const creditUnion = true;
  const fee = selectedOrigination ? parseFloat(selectedOrigination.fee) * 0.01 * loanAmount : 0;
  const loanTotal = (loanAmount - fee);

  const canSubmit = () => selectedTerm?.term;

  const sendToConfirmation = () => {
    if (canSubmit()) {
      setShowConfirmaiton(true);
      window.scrollTo(0, 0);
    }
  };

  const handleSubmit = async () => {
    if (canSubmit()) {
      setLoading(true);
      const pageData = `{ 
          page: "${props.pageName}"
          pricingInput: { 
            pricingTermId: "${selectedTerm.pricingTermId}"
            loanAmount: ${selectedTerm.loanAmount}
            pricingType: "${PRICING_TYPES[props.pageName as keyof typeof PRICING_TYPES]}"
            autoPay: ${autoPay}
            creditUnion: ${creditUnion}
          }
          disablePrequalify: ${props.confirmationData?.disablePrequalify || false}
        }`;

      const pricingData = {
        loanAmount: selectedTerm.loanAmount,
        loanType: PRICING_TYPES[props.pageName as keyof typeof PRICING_TYPES],
        autoPay,
        creditUnion
      };
      if ((window as any).digitalData?.pageInfo) {
        (window as any).digitalData.pageInfo.pricingData = pricingData;
      } else {
        (window as any).digitalData = {
          pageInfo: {
            pricingData
          }
        };
      }
      await props.handleSubmit(pageData, false);
      setLoading(false);
    }
  };

  const refreshPricing = async (aPay: boolean, cUnion: boolean) => {
    const amount = parseInt(amountField);
    if (loanAmount !== amount || aPay !== autoPay || cUnion !== creditUnion) {
      const refreshData = {
        loanAmount: amount,
        loanType: PRICING_TYPES[props.pageName as keyof typeof PRICING_TYPES],
        autoPay: aPay,
        creditUnion: cUnion
      };
      if ((window as any).digitalData?.pageInfo) {
        (window as any).digitalData.pageInfo.pricingRefreshData = refreshData;
      } else {
        (window as any).digitalData = {
          pageInfo: {
            pricingRefreshData: refreshData
          }
        };
      }
      await props.refreshPricing(amount, PRICING_TYPES[props.pageName as keyof typeof PRICING_TYPES], aPay, cUnion, setLoading);
    }
  };

  const getTag = (term: ITerm) => {
    if (term.term.indexOf('10') !== -1) {
      return 'Lowest rate';
    }
    if (!selectedOrigination.terms.find((option: ITerm) => option.apr < term.apr)) {
      return 'Lowest rate';
    }
    if (!selectedOrigination.terms.find((option: ITerm) => option.estimatedMonthlyPayment < term.estimatedMonthlyPayment)) {
      return 'Lowest payment';
    }
    return 'Pay down faster';
  };

  const onOriginationChange = (originationFee: string) => {
    const origination = options.find((option: any) => option.fee === originationFee) || options[0];
    setSelectedOrigination(origination);
    if (selectedTerm?.term) {
      setSelectedTerm(origination.terms.find((term: any) => term.term === selectedTerm.term) || origination.terms[0]);
    }
  };
  const handleBack = async () => {
    if (showConfirmation) {
      setShowConfirmaiton(false);
    } else {
      setLoading(true);
      const pageData = `{ 
        page: "${props.pageName}"
        back: true
      }`;
      await props.handleSubmit(pageData, true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!disabled) {
      onOriginationChange(selectedOrigination?.fee);
    }
  }, [props.pricingData]);

  const amount: number = parseInt(amountField);
  const loanAmountError: boolean = amount < minimum || amount > maximum;

  return (
    <Form title={showConfirmation ? props.content.confirmation.header : header} progress={props.progress}>
      {
        !showConfirmation ? (
          <div>
            <p>{props.content.description}</p>
            <h3 className="font-bold mt-8 mb-4">{props.content.slider_label}</h3>
            <div className="flex">
              <TextInput
                className="grow"
                name="loanAmount"
                value={amountField}
                label={props.content.loan_amount_label}
                onChange={(event) => setAmountField(event.target.value)}
                hasError={loanAmountError}
                mask={MASKS.CURRENCY}
                disabled={loading}
              />
              <Button
                buttonStyle="secondary"
                buttonSize="medium"
                className="!w-24 ml-4 !h-[3.25rem] updateButton"
                onClick={() => refreshPricing(autoPay, creditUnion)}
                buttonAttrs={{ disabled: loanAmountError }}
              >Update
              </Button>
            </div>
            <h4 className={`text-md -mt-4 ${loanAmountError ? 'text-red-500' : ''}`}>{`Please enter a value between ${minimum.toLocaleString('en-us', localeOptions)} and ${maximum.toLocaleString('en-us', localeOptions)}`}</h4>
            <div className="linebreak mt-8 mb-8" />
            { loading
              ? (
                <div className="min-h-[420px] flex items-center justify-center">
                  <Loader color="#D13239" className="loader-medium" />
                </div>
              )
              : props.pricingData.options.length > 0
                ? (
                  <div className="min-h-[420px]">
                    <div>
                      <h3 className="font-bold mt-8 mb-4">{props.content.term_table_label}</h3>
                      <div className="flex flex-wrap lg:flex-nowrap gap-4">
                        {terms.map((term: ITerm, index: number) => (
                          <div key={`LoanCard${index}${term.term.slice(0, 2)}`}>
                            <LoanCard
                              selected={term.term === selectedTerm.term}
                              setSelected={setSelectedTerm}
                              term={term}
                              tag={getTag(term)}
                            />
                          </div>
                        )) }
                      </div>
                      <div className="linebreak mt-8 mb-8" />
                      <div>
                        <h3 className="font-bold mt-8 mb-4">{props.content.origination_fee_header}</h3>
                        <p className="mb-4">{props.content.origination_disclaimer}</p>
                        <Dropdown
                          name="originationFees"
                          label={props.content.origination_fee_label}
                          value={selectedOrigination.fee}
                          onChange={(event: any) => onOriginationChange(event.target.value)}
                          options={options}
                        />
                        <p className="text-sm -mt-4">{props.content.origination_fee_sub_text}</p>
                      </div>
                    </div>
                    <div className="linebreak mt-8 mb-8" />
                    <div>
                      <h4 className="font-bold mt-8 mb-4 text-xl">{props.content.loan.header}</h4>
                      <h3 className="font-bold">{loanAmount.toLocaleString('en-US', localeOptions)}</h3>
                      <p>{props.content.loan.subtext}</p>
                      <div className="md:flex mt-4 md:justify-between grid   grid-cols-2">
                        <div className="mb-4">
                          <h4>{selectedOrigination.displayName} {props.content.loan.origination_fee_label}</h4>
                          <span className="font-bold">{fee.toLocaleString('en-us', localeOptions)}</span>
                        </div>
                        <div className="mb-4">
                          <h4>{props.content.loan.cash_label}</h4>
                          <span className="font-bold">{props.content.loan.closing_cash.toLocaleString('en-us', localeOptions)}</span>
                        </div>
                        <div className="mb-4">
                          <h4>{props.content.loan.total_label}</h4>
                          <span className="font-bold">{loanTotal.toLocaleString('en-us', localeOptions)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="linebreak mt-4 mb-8" />
                    <div>
                      <h4 className="font-bold text-xl mb-4">{props.content.loan.draw_header}</h4>
                      <div className="text-left" dangerouslySetInnerHTML={{ __html: props.content.loan.draw_description.replace('%AMOUNT', loanAmount.toLocaleString('en-us', localeOptions)) }} />
                    </div>
                    <div className="linebreak mt-4 mb-8" />
                    <div>
                      <h4 className="font-bold mt-4 mb-4">{props.content.loan.discount_header}</h4>
                      <div className="flex">
                        <input
                          type="checkbox"
                          id="autoPay"
                          className="checkbox mr-2 mb-4"
                          checked={autoPay}
                          onChange={() => { refreshPricing(!autoPay, creditUnion); setAutopay(!autoPay); }}
                        />
                        <label>{props.content.loan.autopay_label}</label>
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div className="min-h-[420px] flex justify-center">
                    <div>
                      <h2 className="font-bold mb-4">No loans available</h2>
                      <p>There were no loans available for the selected pricing. Please enter a new loan amount.</p>
                    </div>
                  </div>
                )}
            <div className="mt-6">{props.content.disclaimer.replaceAll('\n\n', '\n').split('\n').map((line: string, index: number) =>
              <p className="text-xs mb-4" key={`disclaimer${index}`}>{line}</p>)}
            </div>
            <div className="flex justify-center mt-12">
              <Button
                buttonStyle="primary"
                className="!w-full md:!w-48 nextButton"
                onClick={props.pageName === 'prequalify' ? sendToConfirmation : handleSubmit}
                loading={loading}
                buttonAttrs={{ disabled: !canSubmit() || loading }}
              >Next
              </Button>
            </div>
            {props.pageName === 'prequalify' && (
              <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                <Button
                  buttonStyle="quaternary"
                  iconPos="left"
                  iconName="chevron-left-large"
                  onClick={handleBack}
                  buttonAttrs={{ disabled: loading }}
                >Back
                </Button>
              </div>
            )}
            <Banner className="mt-12" title={props.content.security_header} text={props.content.security_subtext} icon="lock" type="info" />
          </div>
        ) : (
          <HelocConfirmationPage
            handleSubmit={handleSubmit}
            handleGoToPage={props.handleSubmit}
            confirmationData={props.confirmationData}
            content={props.content}
            selectedTerm={selectedTerm}
            loanAmount={selectedTerm.loanAmount}
            pageName={props.pageName}
            setShowConfirmation={setShowConfirmaiton}
          />
        )
      }
    </Form>
  );
};
