import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { useState } from 'react';
import ProgressBarLoader from '../../../components/ProgressBarLoader/ProgressBarLoader';

interface ILienProgressBarProps {
  mortgageSubmissionLoader: boolean;
  content: any;
}

export const LienProgressBar = (props: ILienProgressBarProps) => {
  const [step, setStep] = useState<number>(0);
  const { content } = props;

  if (step === 4 && props.mortgageSubmissionLoader) {
    return (
      <>
        <div className="min-h-[200px] flex items-center justify-center">
          <Loader color="#D13239" className="loader-medium" />
        </div>
        <div className="text-xl font-semibold mt-6">{content.loader_text}</div>
      </>
    );
  }

  return (
    <div className="min-h-[500px] w-full pt-4">
      <div className="w-full mb-10">
        <ProgressBarLoader
          timer={4}
          title={content.property_text}
          onCompleted={() => setStep(1)}
          loaderColorClass="bg-red-600"
          icon={content.home_icon}
        />
      </div>
      {step >= 1 && (
        <div className="w-full mb-10">
          <ProgressBarLoader
            timer={4}
            title={content.info_checking}
            onCompleted={() => setStep(2)}
            loaderColorClass="bg-blue-600"
            icon={content.user_icon}
          />
        </div>
      )}
      {step >= 2 && (
        <div className="w-full mb-10">
          <ProgressBarLoader
            loaderColorClass="bg-green-600"
            timer={4}
            title={content.income_check}
            onCompleted={() => setStep(3)}
            icon={content.income_icon}
          />
        </div>
      )}
      {step >= 3 && (
        <div className="w-full mb-10">
          <ProgressBarLoader
            timer={4}
            title={content.fetching_loans}
            onCompleted={() => setStep(4)}
            loaderColorClass="bg-purple-600"
            icon={content.mortgage_icon}
          />
        </div>
      )}
    </div>
  );
};
