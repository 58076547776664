/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
import Button from '@guaranteed-rate/react-components/dist/Button';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { fetchFspPageContent, formatAsGraphQLPaylod, getCode, getTimeDiff, redirectToMyALogin, setCustomErrorBasedOnResponse } from '../../config/util/common';
import { MASKS } from '../../config/util/masks';
import { PreviousButton } from '../../components/PreviousButton/PreviousButton';
import Banner from '../../components/Banner/Banner';
import RateAlertLogo from '../../components/RateAlertLogo/RateAlertLogo';
import { RateAlertWizardProgress } from '../../components/WizardProgress/RateAlertWizardProgress';
import { generateLink } from './prefiCommon';
import { log } from '../../config/util/logger';
import { REDIRECT_TO_LOGIN_ERROR_CODE } from '../../config/content/constants';

const getLoanDetailData = async (applicationId: string) => {
  const mutation = {
    query:
      `query {
        getLoanDetailData(applicationId: "${applicationId}") {
          applications
              {
                  id
                  borrower {
                    authorizedCreditReportIndicator
                    creditReportAuthorizationMethod
                    dateAuthorizedCreditReport
                    firstName
                  },
                  coborrower {
                      authorizedCreditReportIndicator
                      creditReportAuthorizationMethod
                      dateAuthorizedCreditReport
                      firstName
                  },
                  prequalCreditReportIndicator
              }
              proposedHazardInsuranceAmount
              borrowerRequestedLoanAmount
              propertyAppraisedValueAmount
              proposedRealEstateTaxesAmount
      }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "getLoanDetailData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getLoanDetailData) {
      log({ message: `Query "getLoanDetailData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.getLoanDetailData };
    }
    log({ message: `Query "getLoanDetailData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "getLoanDetailData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const updateLoanDetailData = async (applicationId: string, updateLoanDetailRequest: any) => {
  const mutation = {
    query:
      `mutation {
        updateLoanDetailData(applicationId: "${applicationId}", loanDetailDataRequest: ${updateLoanDetailRequest}) {
          applicationType
            application {
                id
                tenant
                applicationType
                createdAt
                updatedAt
                state
                currentState
                currentDynamicState
                invitationNumber
                newLoanId
                oldLoanNumber
                oldLoanId
            }
        }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Mutation "updateLoanDetailData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.updateLoanDetailData) {
      log({ message: `Mutation "updateLoanDetailData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.updateLoanDetailData };
    }
    log({ message: `Mutation "updateLoanDetailData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Mutation "updateLoanDetailData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

interface ILoanDetailForm {
  currentLoanBalance: string;
  estimatedPropertyValue: string;
  annualTaxes: string;
  annualInsurance: string;
  checkboxes: {
    all: boolean;
    incomeVerification: boolean;
    eConsent: boolean;
    hardCredit: boolean;
  };
  checkboxesForCoborrower: {
    all: boolean;
    incomeVerification: boolean;
    eConsent: boolean;
    hardCredit: boolean;
  };
}

interface ICheckbox {
  checkbox: {
    label: string;
    name: string;
    link: {
      href: string;
      title: string;
    };
  };
}

const newForm = () => ({
  currentLoanBalance: '',
  estimatedPropertyValue: '',
  annualTaxes: '',
  annualInsurance: '',
  checkboxes: {
    all: false,
    incomeVerification: false,
    eConsent: false,
    hardCredit: false
  },
  checkboxesForCoborrower: {
    all: false,
    incomeVerification: false,
    eConsent: false,
    hardCredit: false
  }
});

const PrefiLoanDetail = (props: any) => {
  const navigate = useNavigate();
  const [loanInfo, setLoanInfo] = useState<any>();
  const [loanInfoFetched, setLoanInfoFetched] = useState(false);
  const [content, setContent] = useState(null) as any;
  const [contentFetched, setContentFetched] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [loanDetailForm, setLoanDetailForm] = useState(newForm());
  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  const getContent = async () => {
    const data: any = await fetchFspPageContent('"prefi_loan_detail"');
    if (data.content === 'error') {
      log({ message: `PrefiLoanDetail: fetchFspPageContent(): Error fetching page content. ${JSON.stringify({ page: 'prefi_loan_detail' })}`, level: 'error' });
      setError(true);
      setContentFetched(false);
    } else {
      setError(false);
      setContentFetched(true);
      setContent(data.content);
    }
  };

  if (!content && !contentFetched && !error) {
    getContent();
  }

  if (content && props.applicationId && !loanInfo && !loanInfoFetched && !error) {
    getLoanDetailData(props.applicationId).then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiLoanDetail: getLoanDetailData(): Setting custom error based on response ${JSON.stringify({ data })}`, context: { applicationId: props.applicationId }, level: 'error' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          setLoanInfoFetched(false);
          if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
            redirectToMyALogin();
          }
        } else {
          setLoanInfo(data.content);
          setError(false);
          setLoanInfoFetched(true);
          setLoanDetailForm({
            ...loanDetailForm,
            currentLoanBalance: Math.ceil(data?.content?.borrowerRequestedLoanAmount)?.toString() ?? '',
            estimatedPropertyValue: data.content.propertyAppraisedValueAmount ? Math.ceil(data.content.propertyAppraisedValueAmount).toString() : '',
            annualTaxes: data.content.proposedRealEstateTaxesAmount ? Math.ceil((parseFloat(data.content.proposedRealEstateTaxesAmount) * 12)).toString() : '',
            annualInsurance: data.content.proposedHazardInsuranceAmount ? Math.ceil((parseFloat(data.content.proposedHazardInsuranceAmount) * 12)).toString() : '',
          });
        }
      }
    );
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setLoanDetailForm({ ...loanDetailForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoanDetailForm({
      ...loanDetailForm,
      checkboxes: {
        all: event.target.checked,
        incomeVerification: event.target.checked,
        eConsent: event.target.checked,
        hardCredit: event.target.checked
      }
    });
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setLoanDetailForm({
      ...loanDetailForm,
      checkboxes: {
        ...loanDetailForm.checkboxes, [name !== '' ? name : event.target.name]: event.target.checked
      }
    });
  };

  const CheckBox = (props: any) => {
    const splitLabel = props.label.split('%LINK');
    return (
      <div className="flex align-center mb-2 ml-4">
        <input
          type="checkbox"
          name={props.name}
          key={props.name}
          className="checkbox"
          checked={loanDetailForm.checkboxes[props.name as keyof ILoanDetailForm['checkboxes']]}
          onChange={(event) => handleCheck(event, props.name)}
        />
        <span className="flex-1">{splitLabel[0]}
          <a className="text-blue-700" href={props.link.href} target="_blank" rel="noreferrer">{props.link.title}</a>
          {splitLabel[1]}
        </span>
      </div>
    );
  };

  const handleCheckAllForCoborrower = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoanDetailForm({
      ...loanDetailForm,
      checkboxesForCoborrower: {
        all: event.target.checked,
        incomeVerification: event.target.checked,
        eConsent: event.target.checked,
        hardCredit: event.target.checked
      }
    });
  };

  const handleCheckForCoborrower = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setLoanDetailForm({
      ...loanDetailForm,
      checkboxesForCoborrower: {
        ...loanDetailForm.checkboxesForCoborrower, [name !== '' ? name : event.target.name]: event.target.checked
      }
    });
  };

  const CheckBoxForCoborrower = (props: any) => {
    const splitLabel = props.label.split('%LINK');
    return (
      <div className="flex align-center mb-2 ml-4">
        <input
          type="checkbox"
          name={props.name}
          key={props.name}
          className="checkbox"
          checked={loanDetailForm.checkboxesForCoborrower[props.name as keyof ILoanDetailForm['checkboxesForCoborrower']]}
          onChange={(event) => handleCheckForCoborrower(event, props.name)}
        />
        <span className="flex-1">{splitLabel[0]}
          <a className="text-blue-700" href={props.link.href} target="_blank" rel="noreferrer">{props.link.title}</a>
          {splitLabel[1]}
        </span>
      </div>
    );
  };

  const hasError = (key: string, form: ILoanDetailForm) => {
    const value = form[key as keyof ILoanDetailForm];
    if (typeof value === 'string' && value.length > 0) {
      return false;
    }
    if (key === 'annualTaxes' || key === 'annualInsurance' || key === 'checkboxes' || key === 'checkboxesForCoborrower') {
      return false;
    }
    return true;
  };

  const handleContinue = async () => {
    log({ message: 'PrefiLoanDetail: handleContinue(): Handling continue', level: 'info' });
    setTrySubmit(true);
    setError(false);
    const keys = Object.keys(loanDetailForm);

    if (keys.every((key) => !hasError(key, loanDetailForm))) {
      log({ message: 'PrefiLoanDetail: handleContinue(): Client validation passed', level: 'info' });
      setLoading(true);
      const requestBody = {
        borrowerConsentAndAuthorize: {
          thirdPartyConsent: loanDetailForm.checkboxes.incomeVerification,
          defaultConsent: loanDetailForm.checkboxes.eConsent,
          hardCredit: loanDetailForm.checkboxes.hardCredit,
        },
        coBorrowerConsentAndAuthorize: {
          thirdPartyConsent: loanDetailForm.checkboxesForCoborrower.incomeVerification,
          defaultConsent: loanDetailForm.checkboxesForCoborrower.eConsent,
          hardCredit: loanDetailForm.checkboxesForCoborrower.hardCredit,
        },
        loanDetail: {
          applications: [
            {
              id: loanInfo.applications[0].id,
              borrower: loanInfo.applications[0].borrower?.firstName ? { firstName: loanInfo.applications[0].borrower?.firstName } : {},
              coborrower: loanInfo.applications[0].coborrower?.firstName ? { firstName: loanInfo.applications[0].coborrower?.firstName } : {},
              prequalCreditReportIndicator: true
            }
          ],
          proposedRealEstateTaxesAmount: loanDetailForm.annualTaxes ? (parseFloat(loanDetailForm.annualTaxes) / 12) : null,
          proposedHazardInsuranceAmount: loanDetailForm.annualInsurance ? (parseFloat(loanDetailForm.annualInsurance) / 12) : null,
          borrowerRequestedLoanAmount: loanDetailForm.currentLoanBalance,
          propertyAppraisedValueAmount: loanDetailForm.estimatedPropertyValue
        }
      };
      const updateLoanDetailRequest = formatAsGraphQLPaylod(requestBody);
      updateLoanDetailData(props.applicationId, updateLoanDetailRequest).then(
        (data: any) => {
          setLoading(false);
          if (data.content === 'error') {
            log({ message: `PrefiLoanDetail: handleContinue(): updateLoanDetailData(): Setting custom error based on response ${JSON.stringify({ data })}`, level: 'info' });
            setError(true);
            setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
            window.scrollTo(0, 0);
            if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
              redirectToMyALogin();
            }
          } else {
            setError(false);
            if (data.content?.application) {
              // TODO ticket item pending for adding this state into the backend
              const nextPanel = 'softCredit';
              log({ message: `PrefiLoanDetail: handleContinue(): updateLoanDetailData(): Success ${JSON.stringify({ nextPanel })}`, level: 'info' });
              navigate(generateLink(`/${nextPanel}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
              window.scrollTo(0, 0);
            }
          }
        }
      );
    } else {
      window.scrollTo(0, 0);
    }
  };

  return (
    <div>
      {
        (content && loanInfo)
          ? (
            <div>
              <div className="flex justify-center w-full">
                <div className="max-w-2xl w-full px-1">
                  <RateAlertWizardProgress className="lg:hidden px-4 mb-4" />
                  <div className="flex items-center justify-center mb-8">
                    <div className="px-4 w-full">
                      {
                        error
                          ? (
                            <Banner className="border-2 mt-4 mb-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
                          )
                          : ''
                      }
                      <RateAlertLogo alt={content?.header} />
                      <RateAlertWizardProgress className="hidden lg:block mt-6" />
                      <div className="mt-4">{content?.description}</div>
                      <h3 className="mt-8 mb-4 font-black">{content?.loandetail?.header}</h3>
                      <div className="lg:grid grid-cols-2 gap-4 pt-4">
                        <TextInput
                          name="currentLoanBalance"
                          value={loanDetailForm.currentLoanBalance}
                          label={content?.loandetail?.currentloanbalance?.label}
                          onChange={(input) => handleChange(input, 'currentLoanBalance')}
                          mask={MASKS.CURRENCY}
                          hasError={trySubmit && hasError('currentLoanBalance', loanDetailForm)}
                          helperText={trySubmit && hasError('currentLoanBalance', loanDetailForm) ? 'required' : undefined}
                          required={true}
                        />
                        <TextInput
                          name="estimatedPropertyValue"
                          value={loanDetailForm.estimatedPropertyValue}
                          label={content?.loandetail?.estimatedpropertyvalue?.label}
                          onChange={(input) => handleChange(input, 'estimatedPropertyValue')}
                          mask={MASKS.CURRENCY}
                          hasError={trySubmit && hasError('estimatedPropertyValue', loanDetailForm)}
                          helperText={trySubmit && hasError('estimatedPropertyValue', loanDetailForm) ? 'required' : undefined}
                          required={true}
                        />
                      </div>
                      <div className="lg:grid grid-cols-2 gap-4">
                        <TextInput
                          name="annualTaxes"
                          value={loanDetailForm.annualTaxes}
                          label={content?.loandetail?.annualtaxes?.label}
                          onChange={(input) => handleChange(input, 'annualTaxes')}
                          mask={MASKS.CURRENCY}
                        />
                        <TextInput
                          name="annualInsurance"
                          value={loanDetailForm.annualInsurance}
                          label={content?.loandetail?.annualinsurance?.label}
                          onChange={(input) => handleChange(input, 'annualInsurance')}
                          mask={MASKS.CURRENCY}
                        />
                      </div>
                      <h3 className="mt-8 mb-2 font-black">{content?.termsandconditions?.header}</h3>
                      <div className="flex mb-2 mt-8">
                        <input
                          type="checkbox"
                          className="checkbox"
                          onChange={(input) => handleCheckAll(input)}
                        />
                        <span className="flex-1">I, <span className="font-bold">{loanInfo?.applications[0]?.borrower?.firstName}, </span> {content?.termsandconditions?.all?.label}</span>
                      </div>
                      {content?.checkboxes.map(({ checkbox }: ICheckbox, index: number) => (
                        <CheckBox key={`loanCheckBox${index}`} name={checkbox.name} label={checkbox.label} link={checkbox.link} />))}
                      {
                        (loanInfo?.applications[0]?.coborrower?.firstName)
                        && (
                          <div>
                            <div className="flex mb-2 mt-8">
                              <input
                                type="checkbox"
                                className="checkbox"
                                onChange={(input) => handleCheckAllForCoborrower(input)}
                              />
                              <span className="flex-1">I, <span className="font-bold">{loanInfo?.applications[0]?.coborrower?.firstName}, </span> {content?.termsandconditions?.all?.label}</span>
                            </div>
                            {content?.checkboxesforcoborrower.map(({ checkbox }: ICheckbox, index: number) => (
                              <CheckBoxForCoborrower key={`loanCheckBoxForCoborrower${index}`} name={checkbox.name} label={checkbox.label} link={checkbox.link} />))}
                          </div>
                        )
                      }
                      <div className="px-4 mt-2 mb-8 w-full flex justify-between">
                        <div className="flex justify-center mt-12">
                          <PreviousButton isDynamic={false} applicationId={props.applicationId} />
                        </div>
                        <div className="items-center justify-center">
                          <div className="px-4 mt-2 mb-8 w-full">
                            <div className="flex justify-center mt-12">
                              <Button
                                buttonStyle="primary"
                                className="!w-full md:!w-48 confirmButton"
                                onClick={handleContinue}
                                buttonAttrs={{ disabled: loading }}
                                loading={loading}
                              >{content?.form?.button_label}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          )
          : (
            <div className="px-4 w-full">
              {
                error
                  ? (
                    <Banner className="border-2 mt-4 mb-4" text={errorMessage} title="Error" type="error" icon="warning-triangle" isMultiline={true} errorContent={props.errors.errorContent} />
                  )
                  : ''
              }
            </div>
          )
      }
    </div>

  );
};

export default PrefiLoanDetail;
