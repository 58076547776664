/* eslint-disable max-len */

// fallback defaults for when the header/description is not provided
export const DEFAULT_RATE_APP_DOWNLOAD_CTA_HEADER = 'Finance meets mindfulness';
export const DEFAULT_RATE_APP_DOWNLOAD_CTA_DESCRIPTION = (
  <>
    The only fintech app that combines{' '}
    <span className="font-bold">financial</span> and{' '}
    <span className="font-bold">personal</span> wellness so you can live your
    best life.
  </>
);
export const DEFAULT_RATE_APP_QR_CODE_URL = '/images/rate-app-qr-code.png';
export const DEFAULT_RATE_APP_SCREENSHOT_URL = '/images/rate-app-screenshot.png';
export const DEFAULT_RATE_APP_APPLE_APP_STORE_URL = 'https://apps.apple.com/us/app/rate/id1630195034';
export const DEFAULT_RATE_APP_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.guaranteedrate.superapp';
