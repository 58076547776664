/* eslint-disable max-len */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Banner from '../../components/Banner/Banner';
import { cleanObject, fetchFspPageContent, formatAsGraphQLPaylod, getCode, getTimeDiff, redirectToMyALogin, setCustomErrorBasedOnResponse } from '../../config/util/common';
import { MASKS } from '../../config/util/masks';
import { IOtherIncomeForm, IOtherIncomeData } from '../../config/util/interfaces';
import { PreviousButton } from '../../components/PreviousButton/PreviousButton';
import RateAlertLogo from '../../components/RateAlertLogo/RateAlertLogo';
import { RateAlertWizardProgress } from '../../components/WizardProgress/RateAlertWizardProgress';
import { generateLink, getNextPanelBasedOnState } from './prefiCommon';
import { log } from '../../config/util/logger';
import { REDIRECT_TO_LOGIN_ERROR_CODE } from '../../config/content/constants';

const getOtherIncomeSourcesDetailsData = async (applicationId: string) => {
  const mutation = {
    query:
      `query {
        getOtherIncomeSourcesDetailsData(applicationId: "${applicationId}") {
          incomeType
          amount
          owner
      }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "getOtherIncomeSourcesDetailsData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getOtherIncomeSourcesDetailsData) {
      log({ message: `Query "getOtherIncomeSourcesDetailsData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.getOtherIncomeSourcesDetailsData };
    }
    log({ message: `Query "getOtherIncomeSourcesDetailsData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "getOtherIncomeSourcesDetailsData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const updateOtherIncomeSourcesData = async (applicationId: string, isCoBorrower: boolean, updateOtherIncomeSourcesRequest: any) => {
  const mutation = {
    query:
      `mutation {
        updateOtherIncomeSourcesData(applicationId: "${applicationId}", isCoBorrower: ${isCoBorrower}, updateOtherIncomeSourcesRequest: ${updateOtherIncomeSourcesRequest}) {
          applicationType
            application {
                id
                tenant
                applicationType
                createdAt
                updatedAt
                state
                currentState
                currentDynamicState
                invitationNumber
                newLoanId
                oldLoanNumber
                oldLoanId
            }
        }
  }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Mutation "updateOtherIncomeSourcesData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.updateOtherIncomeSourcesData) {
      log({ message: `Mutation "updateOtherIncomeSourcesData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.updateOtherIncomeSourcesData };
    }
    log({ message: `Mutation "updateOtherIncomeSourcesData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Mutation "updateOtherIncomeSourcesData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const newBorrowerForm = () => ({
  incomeType: '',
  monthlyAmount: '',
  owner: 'Borrower',
  isFormOpen: false,
});

const newCoBorrowerForm = () => ({
  incomeType: '',
  monthlyAmount: '',
  owner: 'CoBorrower',
  isFormOpen: false,
});

function PrefiOtherIncome(props: any) {
  const navigate = useNavigate();
  const [otherIncomeInfo, setOtherIncomeInfo] = useState<IOtherIncomeData>();
  const [otherIncomeInfoFetched, setOtherIncomeInfoFetched] = useState(false);
  const [content, setContent] = useState(null as any);
  const [contentFetched, setContentFetched] = useState(false);
  const [otherIncomeForm, setOtherIncomeForm] = useState([props.isCoborrowerPage ? newCoBorrowerForm() : newBorrowerForm()]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [trySubmit, setTrySubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  if (!content && !contentFetched && !error) {
    fetchFspPageContent('"prefi_other_income"').then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiOtherIncome: fetchFspPageContent(): isCoborrowerPage ${props.isCoborrowerPage}: Error fetching page content. ${JSON.stringify({ page: 'prefi_other_income' })}`, level: 'error' });
          setError(true);
          setErrorMessage('Error fetching page content. Please Try again...');
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
        }
      }
    );
  }

  if (content && props.applicationId && !otherIncomeInfo && !otherIncomeInfoFetched && !error) {
    getOtherIncomeSourcesDetailsData(props.applicationId).then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiOtherIncome: getOtherIncomeSourcesDetailsData(): isCoborrowerPage ${props.isCoborrowerPage}: Setting custom error based on response ${JSON.stringify({ data })}`, context: { applicationId: props.applicationId }, level: 'error' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          setOtherIncomeInfoFetched(false);
          if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
            redirectToMyALogin();
          }
        } else {
          setError(false);
          setOtherIncomeInfoFetched(true);
          setOtherIncomeInfo(data.content);
          let currentOtherIncomeData: any = [];
          data.content.forEach((income: any) => {
            const eachIncome: IOtherIncomeForm = {
              incomeType: income.incomeType,
              monthlyAmount: income.amount.toString(),
              owner: income?.owner || 'Borrower',
              isFormOpen: false
            };
            if (eachIncome.owner === (props.isCoborrowerPage ? 'CoBorrower' : 'Borrower')) {
              currentOtherIncomeData.push(eachIncome);
            }
          });
          currentOtherIncomeData.forEach((item: any) => {
            cleanObject(item);
          });
          setOtherIncomeForm(currentOtherIncomeData);
        }
      }
    );
  }

  const incomeTypes = content?.income_types ? content?.income_types?.map((item: any) => ({
    displayName: item.option.label,
    value: item.option.value,
  })) : [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name: string, index: number) => {
    setError(false);
    setOtherIncomeForm((currentIncome: any) => {
      let newObj = [...currentIncome];
      newObj[index][name] = event.target.value;
      return newObj;
    });
  };

  const handleAddIncome = () => {
    setOtherIncomeForm((currentIncome: any) => {
      let newObj = [...currentIncome];
      newObj.push(props.isCoborrowerPage ? newCoBorrowerForm() : newBorrowerForm());
      return newObj;
    });
    setError(false);
    handleToggle(otherIncomeForm.length);
  };

  const handleDeleteIncome = (index: number) => {
    setOtherIncomeForm((currentIncome: any) => {
      let newObj = [...currentIncome];
      newObj.splice(index, 1);
      if (otherIncomeForm.length === 0) {
        setOtherIncomeForm([]);
      }
      return newObj;
    });
  };

  const handleToggle = (index: number) => {
    setOtherIncomeForm((currentIncome: any) => {
      let newObj = [...currentIncome];
      newObj.forEach((formItem: any, formIndex: number) => {
        if (formIndex === index) {
          formItem.isFormOpen = !formItem.isFormOpen;
        } else {
          formItem.isFormOpen = false;
        }
      });
      return newObj;
    });
    window.scrollTo(0, 0);
  };

  const hasError = (name: string, value: string) => {
    const isError: any = trySubmit && !(value.length > 0);
    return isError;
  };

  const isValidForm = () => {
    let isValid = true;
    if (otherIncomeForm.length > 0) {
      otherIncomeForm.forEach((eachItemInform: any) => {
        for (let propName in eachItemInform) {
          if (eachItemInform[propName] === null || eachItemInform[propName] === undefined || eachItemInform[propName] === '') {
            isValid = false;
          }
        }
      });
    }
    return isValid;
  };

  const handleContinue = async () => {
    log({ message: `PrefiOtherIncome: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: Handling continue`, level: 'info' });
    setTrySubmit(true);
    setError(false);
    if (otherIncomeForm && isValidForm()) {
      log({ message: `PrefiOtherIncome: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: Client validation passed`, level: 'info' });
      setLoading(true);
      let requestBody: any = [];
      otherIncomeForm.forEach((income: IOtherIncomeForm) => {
        const eachObject = {
          incomeType: income.incomeType,
          monthlyAmount: income.monthlyAmount
        };
        requestBody.push(eachObject);
      });
      const updateOtherIncomeSourcesRequest = formatAsGraphQLPaylod(requestBody);
      updateOtherIncomeSourcesData(props.applicationId, props.isCoborrowerPage, updateOtherIncomeSourcesRequest).then(
        (data: any) => {
          setLoading(false);
          if (data.content === 'error') {
            log({ message: `PrefiOtherIncome: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: updateOtherIncomeSourcesData(): Setting custom error based on response ${JSON.stringify({ data })}`, level: 'info' });
            setError(true);
            setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
            if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
              redirectToMyALogin();
            }
          } else {
            setError(false);
            if (data.content?.application) {
              const nextPanel = getNextPanelBasedOnState(data);
              log({ message: `PrefiOtherIncome: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: updateOtherIncomeSourcesData(): Success ${JSON.stringify({ nextPanel })}`, level: 'info' });
              navigate(generateLink(`/${nextPanel}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
              window.scrollTo(0, 0);
            }
          }
        }
      );
    } else {
      log({ message: `PrefiOtherIncome: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: Invalid fields`, level: 'info' });
      setError(true);
      setErrorMessage('Fill all required fields');
      window.scrollTo(0, 0);
    }
  };

  return (
    <div>
      {(content && otherIncomeForm)
        ? (
          <div className="flex items-center justify-center">
            <div className="items-center justify-center w-full max-w-3xl px-1">
              <RateAlertWizardProgress className="lg:hidden px-4 mb-4" />
              <div className="items-center justify-center lg:mb-8">
                <div className="px-4 w-full">
                  {
                    error
                      ? (
                        <Banner className="border-2 mt-4 mb-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
                      )
                      : ''
                  }
                  <RateAlertLogo alt={content?.header} />
                </div>
              </div>
              <div className="w-full px-1 lg:border border-solid border-slate-300 rounded-2xl bg-white">
                <RateAlertWizardProgress className="hidden lg:block px-4 mt-6" />
                <div className="text-xl font-black mt-6 px-4">{props.isCoborrowerPage ? content?.coborrower_header : content?.header}</div>
                <div className="flex items-center justify-center mb-8">
                  <div className="px-4 w-full">
                    <div className="mt-4">{content?.description}</div>
                  </div>
                </div>
                {
                  (otherIncomeForm?.length > 0 && otherIncomeForm.filter((income: any) => income.owner === (props.isCoborrowerPage ? 'CoBorrower' : 'Borrower'))?.length > 0)
                  && (otherIncomeForm.filter((income: any) => income.owner === (props.isCoborrowerPage ? 'CoBorrower' : 'Borrower'))?.map((eachFormItem: any, index: number) => (
                    <div key={`income${index}`} className="items-center justify-center border-b pb-2 mb-8">
                      <div className="px-4 w-full">
                        <div className="flex justify-between w-full p-2 my-2">
                          <span className="text-xl font-black">
                            {
                              incomeTypes[incomeTypes.map((e: { value: any }) => e.value).indexOf(eachFormItem.incomeType)]?.displayName
                                ? incomeTypes[incomeTypes.map((e: { value: any }) => e.value).indexOf(eachFormItem.incomeType)]?.displayName
                                : 'Enter Income Details'
                            }
                          </span>
                          <div className="w-1/4">
                            <div className="grid grid-flow-col items-end justify-end">
                              <Button
                                buttonStyle="icon"
                                iconName="trash"
                                className="red-icon"
                                onClick={() => { handleDeleteIncome(index); }}
                              />
                              <Button
                                buttonStyle="icon"
                                iconName={`${eachFormItem.isFormOpen ? 'chevron-up-large' : 'chevron-down-large'}`}
                                onClick={() => { handleToggle(index); }}
                              />
                            </div>
                          </div>
                        </div>
                        {
                          eachFormItem.isFormOpen
                            ? (
                              <div className="w-full mt-4">
                                <Dropdown
                                  name="incomeType"
                                  value={eachFormItem.incomeType}
                                  label={content?.form?.income_type_label}
                                  onChange={(event) => handleChange(event, 'incomeType', index)}
                                  className="w-full"
                                  options={incomeTypes}
                                  hasError={hasError(`incomeType${index}`, eachFormItem.incomeType)}
                                  helperText={hasError(`incomeType${index}`, eachFormItem.incomeType) ? 'required' : undefined}
                                  required={true}
                                />
                                <TextInput
                                  name="monthlyAmount"
                                  label={content?.form?.income_amount_label}
                                  className="w-full"
                                  value={eachFormItem.monthlyAmount}
                                  hasError={hasError(`monthlyAmount${index}`, eachFormItem.monthlyAmount)}
                                  helperText={hasError(`monthlyAmount${index}`, eachFormItem.monthlyAmount) ? 'required field' : ''}
                                  onChange={(input) => handleChange(input, 'monthlyAmount', index)}
                                  mask={MASKS.CURRENCY}
                                  required={true}
                                />
                              </div>
                            )
                            : ''
                        }
                      </div>
                    </div>
                  )))
                }
                <div className="flex items-start justify-start">
                  <div className="flex justify-start px-4">
                    <Button
                      buttonSize="large"
                      buttonStyle="tertiary"
                      iconName="plus-add"
                      iconPos="left"
                      className="!w-full md:!w-48"
                      onClick={handleAddIncome}
                    >
                      {content?.form?.add_income_button_label}
                    </Button>
                  </div>
                </div>
                <div className="px-4 mt-2 mb-8 w-full flex justify-between">
                  <div className="flex justify-center mt-12">
                    <PreviousButton isDynamic={true} applicationId={props.applicationId} />
                  </div>
                  <div className="items-center justify-center">
                    <div className="px-4 mt-2 mb-8 w-full">
                      <div className="flex justify-center mt-12">
                        <Button
                          buttonStyle="primary"
                          className="!w-full md:!w-48 confirmButton"
                          onClick={handleContinue}
                          buttonAttrs={{ disabled: loading }}
                          loading={loading}
                        >{content?.form?.button_label}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : (
          <div className="px-4 w-full">
            {
              error
                ? (
                  <Banner className="border-2 mt-4 mb-4" text={errorMessage} title="Error" type="error" icon="warning-triangle" isMultiline={true} errorContent={props.errors.errorContent} />
                )
                : ''
            }
          </div>
        )}
    </div>
  );
}
export default PrefiOtherIncome;
