import { useEffect, useState } from 'react';
import Button from '@guaranteed-rate/react-components/dist/Button';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import GoogleMapAddress from '../../../components/GoogleMapAddress/GoogleMapAddress';
import { IAddressForm, IAddressResponse } from '../../../config/util/interfaces';
import { IBorrowerAddressForm } from '../HelocPersonalPage/HelocPersonalPage';
import { buildLocation, getDateErrorText, loadAddressData } from '../../../config/util/common';
import CalendarInput from '../../../components/CalendarInput/CalendarInput';

interface IHelocBorrowerAddress {
  content: any;
  isDisaster: boolean;
  propertyAddressData: IAddressResponse;
  borrowerAddressForm: IBorrowerAddressForm;
  personalAddressData: IAddressResponse;
  propertyType: string;
  handleSubmit: () => void;
  loading: boolean;
  handleBack: () => void;
  setBorrowerAddressForm: (addressFormData: IBorrowerAddressForm) => void;
}

const HelocBorrowerAddress = (props: IHelocBorrowerAddress) => {
  const [isDisasterPage, setIsDisasterPage] = useState(props.isDisaster);
  const [isSameAsPropertyAddress, setSameAsPropertyAddress] = useState<boolean>(
    props.propertyType === 'PRIMARY' && !props.personalAddressData?.street
  );
  const [formattedAddress, setFormattedAddress] = useState<string>(buildLocation(props.personalAddressData));
  const { residenceStartDate: residenceDate, residenceAddress } = props.borrowerAddressForm;
  const [residenceStartDate, setResidenceStartDate] = useState<string | null>(
    residenceDate
  );
  const disasterText = props.content.disaster_text.replace('%PROPERTY%', buildLocation(props.propertyAddressData));
  const [trySubmit, setTrySubmit] = useState<boolean>(false);
  const today = new Date();

  useEffect(() => {
    if (isSameAsPropertyAddress) {
      addressChangeHandler({
        ...loadAddressData(props.propertyAddressData),
        formattedAddress: buildLocation(props.propertyAddressData),
      });
    }
  }, [isSameAsPropertyAddress]);

  const onSubmit = () => {
    if (formattedAddress && !getDateErrorText(residenceDate)) {
      props.handleSubmit();
    } else {
      setTrySubmit(true);
    }
  };

  const addressChangeHandler = (address: any): void => {
    let residenceAddress: IAddressForm = props.borrowerAddressForm.residenceAddress;
    if (address) {
      Object.keys(residenceAddress).map((key: string) => {
        if (address[key]) {
          residenceAddress[key as keyof IAddressForm] = address[key];
        }
      });
    } else {
      residenceAddress = loadAddressData(address);
    }
    props.setBorrowerAddressForm({ ...props.borrowerAddressForm, residenceAddress });
    setFormattedAddress(address?.formattedAddress || '');
  };

  const onDateChangeHandler = (date: string) => {
    setResidenceStartDate(date);
    props.setBorrowerAddressForm({ ...props.borrowerAddressForm, residenceStartDate: date });
  };
  const getErrorText: string = trySubmit ? getDateErrorText(residenceStartDate || '') : '';
  const addressError: boolean = trySubmit && !formattedAddress;
  return isDisasterPage ? (
    <div>
      <div className="min-h-[300px]">
        <div className="flex justify-center">
          <div className="max-w-2xl text-center">
            <div className="flex justify-center">
              <FontIcon className="text-yellow-600 text-8xl mb-8 mt-8" iconName="warning-triangle" />
            </div>
            <h2 className="font-bold mb-8">{props.content.disaster_header}</h2>
            <h4 className="mb-8 text-left">{disasterText}</h4>
          </div>
        </div>
      </div>
      <div>
        <div className="flex justify-center mt-12">
          <Button
            buttonStyle="primary"
            className="!w-full md:!w-48 disasterNextButton"
            onClick={() => setIsDisasterPage(false)}
            loading={props.loading}
            buttonAttrs={{ disabled: props.loading }}
          >
            Next
          </Button>
        </div>
        <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
          <Button
            buttonStyle="quaternary"
            iconPos="left"
            iconName="chevron-left-large"
            onClick={props.handleBack}
            buttonAttrs={{ disabled: props.loading }}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="min-h-[300px]">
        <GoogleMapAddress
          onChange={addressChangeHandler}
          value={formattedAddress}
          addressObj={residenceAddress}
          label={props.content.address_field_label}
          type="address"
          required
          hasError={addressError}
          disabled={isSameAsPropertyAddress}
        />
        <div>
          <CalendarInput
            label={props.content.living_date_label}
            className="w-full birthdayInput"
            name="residenceStartDate"
            form={{ residenceStartDate }}
            setForm={(data: any) => onDateChangeHandler(data.residenceStartDate)}
            id="date"
            required={true}
            maxDate={today}
            hasError={getErrorText}
            helperText={getErrorText}
          />

        </div>
        <div className="flex flex-row mb-4">
          <input
            type="checkbox"
            className="checkbox"
            onChange={() => setSameAsPropertyAddress(!isSameAsPropertyAddress)}
            checked={isSameAsPropertyAddress}
            id="sameAsAddress"
          />
          <h4>{props.content.same_as_label}</h4>
        </div>
      </div>

      <div>
        <div className="flex justify-center mt-12">
          <Button
            buttonStyle="primary"
            className="!w-full md:!w-48 nextButton"
            onClick={onSubmit}
            loading={props.loading}
            buttonAttrs={{ disabled: props.loading }}
          >
            Next
          </Button>
        </div>
        <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
          <Button
            buttonStyle="quaternary"
            iconPos="left"
            iconName="chevron-left-large"
            onClick={() => (props.isDisaster
              ? isDisasterPage ? props.handleBack() : setIsDisasterPage(true)
              : props.handleBack())}
            buttonAttrs={{ disabled: props.loading }}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HelocBorrowerAddress;
