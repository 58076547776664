/* eslint-disable max-len */
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import { fetchFspPageContent, currencyFormatter, objectToGraphQl, retrieveStringEquivalent, setCustomErrorBasedOnResponse, getTimeDiff, getCode, redirectToMyALogin } from '../../config/util/common';
import { IBorrowerInfo } from '../../config/util/interfaces';
import { CHECK_CORRECT, CHECK_INCORRECT, REDIRECT_TO_LOGIN_ERROR_CODE } from '../../config/content/constants';
import Banner from '../../components/Banner/Banner';
import RateAlertLogo from '../../components/RateAlertLogo/RateAlertLogo';
import { RateAlertWizardProgress } from '../../components/WizardProgress/RateAlertWizardProgress';
import { generateLink, getNextPanelBasedOnState } from './prefiCommon';
import { log } from '../../config/util/logger';
import { PreviousButton } from '../../components/PreviousButton/PreviousButton';

const getBorrowerInfoData = async (applicationId: string) => {
  const mutation = {
    query:
      `query {
        getBorrowerInfoData(applicationId: "${applicationId}") {
          loanApplication
          {
              id
              coborrower {
                  birthDate
                  emailAddressText
                  firstName
                  lastName
                  suffixToName
                  homePhoneNumber
                  mobilePhone
                  transUnionScore
                  languagePreference
                  languageCodeOtherDescription
                  dependentCount
                  hmdaGendertypeFemaleIndicator
                  hmdaGendertypeMaleIndicator
                  hmdaGendertypeDoNotWishIndicator
                  hmdaEthnicityHispanicLatinoIndicator
                  hmdaEthnicityNotHispanicLatinoIndicator
                  hmdaEthnicityDoNotWishIndicator
                  hmdaMexicanIndicator
                  hmdaPuertoRicanIndicator
                  hmdaCubanIndicator
                  hmdaHispanicLatinoOtherOriginIndicator
                  hmdaAsianIndianIndicator
                  hmdaChineseIndicator
                  hmdaFilipinoIndicator
                  hmdaJapaneseIndicator
                  hmdaKoreanIndicator
                  hmdaVietnameseIndicator
                  hmdaAsianOtherRaceIndicator
                  hmdaNativeHawaiianIndicator
                  hmdaGuamanianOrChamorroIndicator
                  hmdaSamoanIndicator
                  hmdaPacificIslanderOtherIndicator
                  hmdaAmericanIndianIndicator
                  hmdaAsianIndicator
                  hmdaAfricanAmericanIndicator
                  hmdaPacificIslanderIndicator
                  hmdaWhiteIndicator
                  hmdaRaceDoNotWishProvideIndicator
                  homeownerPastThreeYearsIndicator
                  intentToOccupyIndicator
                  specialBorrowerSellerRelationshipIndicator
                  priorPropertyUsageType
                  undisclosedBorrowedFundsIndicator
                  undisclosedMortgageApplicationIndicator
                  undisclosedCreditApplicationIndicator
                  propertyProposedCleanEnergyLienIndicator
                  undisclosedComakerOfNoteIndicator
                  outstandingJudgementsIndicator
                  presentlyDelinquentIndicatorURLA
                  partyToLawsuitIndicatorURLA
                  priorPropertyDeedInLieuConveyedIndicator
                  priorPropertyShortSaleCompletedIndicator
                  priorPropertyForeclosureCompletedIndicator
                  bankruptcyIndicator
                  selfDeclaredMilitaryServiceIndicator
                  experianCreditScore
                  equifaxScore
                  authorizedCreditReportIndicator
                  creditReportAuthorizationMethod
                  dateAuthorizedCreditReport
                  applicationTakenMethodType
                  priorPropertyTitleType
                  bankruptcyIndicatorChapterSeven
                  bankruptcyIndicatorChapterEleven
                  bankruptcyIndicatorChapterTwelve
                  bankruptcyIndicatorChapterThirteen
                  maritalStatusType
                  urla2020CitizenshipResidencyType
                  hmdaOtherHispanicLatinoOrigin
                  hmdaAmericanIndianTribe
                  hmdaOtherAsianRace
                  hmdaOtherPacificIslanderRace
              }
              employment {
                  id
                  owner
                  currentEmploymentIndicator
                  basePayAmount
                  employerName
                  selfEmployedIndicator
                  positionDescription
                  addressCity
                  addressState
                  bonusAmount
                  commissionsAmount
                  overtimeAmount
                  startDate
                  endDate
              }
              otherIncomeSources {
                  id
                  owner
                  monthlyAmount
                  description
              }
              prequalCreditReportIndicator
              creditReportReferenceIdentifier
              propertyUsageType
          }
      }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "getBorrowerInfoData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getBorrowerInfoData) {
      log({ message: `Query "getBorrowerInfoData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.getBorrowerInfoData };
    }
    log({ message: `Query "getBorrowerInfoData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "getBorrowerInfoData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const getCoBorrowerSelectedDynamicStates = async (invitationNumber?: string | null, loanId?: string | null, oldLoanNumber?: string | null) => {
  const mutation = {
    query:
      `query {
        getALPMortgage(
          getMortgageRequest: {
                invitationNumber: "${invitationNumber}",
                loanId: "${loanId}"
                oldLoanNumber: "${oldLoanNumber}"
            })
            {
                application {
                  coBorrowerSelectedDynamicStates
                }
                errorCode
                errorMessage
            }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Method getCoBorrowerSelectedDynamicStates Query "getALPMortgage getMortgageRequest" ${JSON.stringify({ invitationNumber, loanId, oldLoanNumber })}`, context: { invitationNumber, loanId, oldLoanNumber }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getALPMortgage && !(data?.data?.getALPMortgage?.errorMessage)) {
      log({ message: `Method getCoBorrowerSelectedDynamicStates Query "getALPMortgage getMortgageRequest" was successful ${JSON.stringify({ invitationNumber, loanId, oldLoanNumber, duration })}`, context: { invitationNumber, loanId, oldLoanNumber }, level: 'info', requestId: uuid });
      return { content: data.data.getALPMortgage };
    }
    if ((data?.data?.getALPMortgage === null) && !(data?.errors)) {
      log({ message: `Method getCoBorrowerSelectedDynamicStates Query "getALPMortgage getMortgageRequest" data?.data?.getALPMortgage is null ${JSON.stringify({ invitationNumber, loanId, oldLoanNumber, duration })}`, context: { invitationNumber, loanId, oldLoanNumber }, level: 'info', requestId: uuid });
      return { content: 'info', info: !(data?.data?.getALPMortgage) ? 'no data' : '' };
    }
    if (data?.data?.getALPMortgage?.errorMessage) {
      log({ message: `Method getCoBorrowerSelectedDynamicStates Query "getALPMortgage getMortgageRequest" failed with errorMessage ${JSON.stringify({ error: { invitationNumber, loanId, oldLoanNumber, duration, message: data.data.getALPMortgage.errorMessage, code: data.data.getALPMortgage.errorCode } })}`, context: { invitationNumber, loanId, oldLoanNumber }, level: 'error', requestId: uuid });
      return { content: 'error', errors: data?.data?.getALPMortgage?.errorCode };
    }
    log({ message: `Method getCoBorrowerSelectedDynamicStates Query "getALPMortgage getMortgageRequest" failed with errors ${JSON.stringify({ invitationNumber, loanId, oldLoanNumber, duration, errors: data?.errors[0]?.message })}`, context: { invitationNumber, loanId, oldLoanNumber }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Method getCoBorrowerSelectedDynamicStates Query "getALPMortgage getMortgageRequest" failed due to exception ${JSON.stringify({ invitationNumber, loanId, oldLoanNumber })}`, context: { invitationNumber, loanId, oldLoanNumber }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const selectDynamicStates = async (applicationId: string, dynamicStates: Array<string>) => {
  let selectedDynamicStatesRequest = objectToGraphQl({
    selectedDynamicStates: null,
    coBorrowerPreFiDynamicStates: dynamicStates
  });
  const mutation = {
    query:
      `mutation {
        selectDynamicStates(applicationId: "${applicationId}", selectedDynamicStatesRequest: ${selectedDynamicStatesRequest}) {
          applicationType
            application {
                id
                tenant
                applicationType
                createdAt
                updatedAt
                state
                currentState
                currentDynamicState
            }
    }
  }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Mutation "selectDynamicStates" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.selectDynamicStates) {
      log({ message: `Mutation "selectDynamicStates" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.selectDynamicStates };
    }
    log({ message: `Mutation "selectDynamicStates" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Mutation "selectDynamicStates" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

function PrefiCoborrowerReview(props: any) {
  const navigate = useNavigate();
  const [content, setContent] = useState(null as any);
  const [contentFetched, setContentFetched] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [coBorrowerInfo, setCoBorrowerInfo] = useState<IBorrowerInfo>();
  const [coBorrowerInfoFetched, setCoBorrowerInfoFetched] = useState(false);
  const [isPersonalData, setIsPersonalData] = useState(false);
  const [isDemographicData, setIsDemographicData] = useState(false);
  const [isCheckedPersonal, setPersonalChecked] = useState<string | null>(null || CHECK_INCORRECT || CHECK_CORRECT);
  const [isCheckedEmployment, setEmploymentChecked] = useState<string | null>(null || CHECK_INCORRECT || CHECK_CORRECT);
  const [isCheckedOtherIncome, setOtherIncomeChecked] = useState<string | null>(null || CHECK_INCORRECT || CHECK_CORRECT);
  const [isCheckedDemographic, setDemographicChecked] = useState<string | null>(null || CHECK_INCORRECT || CHECK_CORRECT);
  const [isCheckedPreference, setPreferenceChecked] = useState<string | null>(null || CHECK_INCORRECT || CHECK_CORRECT);
  const [dynamicStates, setDynamicStates] = useState([] as Array<string>);
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  if (!content && !contentFetched && !error) {
    fetchFspPageContent('"prefi_coborrower_review"').then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiCoborrowerReview: fetchFspPageContent(): Error fetching page content. ${JSON.stringify({ page: 'prefi_review' })}`, level: 'error' });
          setError(true);
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
        }
      }
    );
  }

  if (content && props.applicationId && !coBorrowerInfo && !coBorrowerInfoFetched && !error) {
    getBorrowerInfoData(props.applicationId).then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiCoborrowerReview: getBorrowerInfoData(): Setting custom error based on response ${JSON.stringify({ data })}`, context: { applicationId: props.applicationId }, level: 'error' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          setCoBorrowerInfoFetched(false);
          if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
            redirectToMyALogin();
          }
        } else {
          setError(false);
          setCoBorrowerInfoFetched(true);
          const coBorrowerData = data.content?.loanApplication;
          setCoBorrowerInfo(coBorrowerData);
          const personalDataExists: boolean = (coBorrowerData?.coborrower?.firstName?.length > 0 && coBorrowerData?.coborrower?.lastName?.length > 0 && coBorrowerData?.coborrower?.maritalStatusType?.length > 0 && (coBorrowerData?.coborrower?.dependentCount !== null) && coBorrowerData?.propertyUsageType?.length > 0);
          setIsPersonalData(personalDataExists);
          const demographicDataExists: boolean = ((coBorrowerData?.coborrower?.hmdaGendertypeMaleIndicator || coBorrowerData?.coborrower?.hmdaGendertypeFemaleIndicator || coBorrowerData?.coborrower?.hmdaGendertypeDoNotWishIndicator)
          && ((retrieveStringEquivalent(ethnicity, coBorrowerData?.coborrower).length > 0) || (retrieveStringEquivalent(ethnicity_custom_input, coBorrowerData?.coborrower).length > 0) || (retrieveStringEquivalent(ethnicity_others, coBorrowerData?.coborrower).length > 0) || (retrieveStringEquivalent(ethnicity_do_not_wish, coBorrowerData?.coborrower).length > 0))
          && ((retrieveStringEquivalent(race, coBorrowerData?.coborrower).length > 0) || (retrieveStringEquivalent(race_custom_input, coBorrowerData?.coborrower).length > 0) || (retrieveStringEquivalent(race_others, coBorrowerData?.coborrower).length > 0) || (retrieveStringEquivalent(race_do_not_wish, coBorrowerData?.coborrower).length > 0)));
          setIsDemographicData(demographicDataExists);
          setPersonalChecked(!personalDataExists ? CHECK_INCORRECT : null);
          setEmploymentChecked((!coBorrowerData?.employment || coBorrowerData?.employment?.length === 0 || coBorrowerData?.employment.filter((emp: any) => emp.owner === 'CoBorrower').length === 0) ? CHECK_INCORRECT : null);
          setOtherIncomeChecked((!coBorrowerData?.otherIncomeSources || coBorrowerData?.otherIncomeSources?.length === 0 || coBorrowerData?.otherIncomeSources.filter((income: any) => income.owner === 'CoBorrower').length === 0) ? CHECK_INCORRECT : null);
          setDemographicChecked(!demographicDataExists ? CHECK_INCORRECT : null);
          setPreferenceChecked(!coBorrowerData?.coborrower?.languagePreference ? CHECK_INCORRECT : null);
          getCoBorrowerSelectedDynamicStates(props.ids.invitationNumber, props.ids.loanId, props.ids.oldLoanNumber).then(
            (dynamicStateData) => {
              let defaultDynamicStates: Array<string> = dynamicStateData?.content?.application?.coBorrowerSelectedDynamicStates || [];
              if (!personalDataExists) {
                defaultDynamicStates.push('CO_BORROWER_UPDATE_PERSONAL_INFORMATION');
              }
              if (!coBorrowerData?.otherIncomeSources || coBorrowerData?.otherIncomeSources?.length === 0 || coBorrowerData?.otherIncomeSources.filter((income: any) => income.owner === 'CoBorrower').length === 0) {
                defaultDynamicStates.push('CO_BORROWER_UPDATE_OTHER_INCOME_SOURCES_INFORMATION');
              }
              if (!coBorrowerData?.employment || coBorrowerData?.employment?.length === 0 || coBorrowerData?.employment.filter((emp: any) => emp.owner === 'CoBorrower').length === 0) {
                defaultDynamicStates.push('CO_BORROWER_UPDATE_EMPLOYMENT_INFORMATION');
              }
              if (!demographicDataExists) {
                defaultDynamicStates.push('CO_BORROWER_UPDATE_DEMOGRAPHIC_INFORMATION');
              }
              if (!coBorrowerData?.coborrower?.languagePreference) {
                defaultDynamicStates.push('CO_BORROWER_UPDATE_LANGUAGE_PREFERENCE_INFORMATION');
              }
              setPersonalChecked(defaultDynamicStates?.includes('CO_BORROWER_UPDATE_PERSONAL_INFORMATION') ? CHECK_INCORRECT : CHECK_CORRECT);
              setEmploymentChecked(defaultDynamicStates?.includes('CO_BORROWER_UPDATE_EMPLOYMENT_INFORMATION') ? CHECK_INCORRECT : CHECK_CORRECT);
              setOtherIncomeChecked(defaultDynamicStates?.includes('CO_BORROWER_UPDATE_OTHER_INCOME_SOURCES_INFORMATION') ? CHECK_INCORRECT : CHECK_CORRECT);
              setDemographicChecked(defaultDynamicStates?.includes('CO_BORROWER_UPDATE_DEMOGRAPHIC_INFORMATION') ? CHECK_INCORRECT : CHECK_CORRECT);
              setPreferenceChecked(defaultDynamicStates?.includes('CO_BORROWER_UPDATE_LANGUAGE_PREFERENCE_INFORMATION') ? CHECK_INCORRECT : CHECK_CORRECT);
              defaultDynamicStates = defaultDynamicStates?.filter((value, index) => defaultDynamicStates.indexOf(value) === index);
              setDynamicStates(defaultDynamicStates);
            }
          );
        }
      }
    );
  }

  const ethnicity: any = content?.hmda?.ethnicity;
  const ethnicity_others: any = content?.hmda?.ethnicity_others;
  const ethnicity_do_not_wish: any = content?.hmda?.ethnicity_do_not_wish;
  const ethnicity_custom_input: any = {
    hmdaotherhispaniclatinoorigin: coBorrowerInfo?.coborrower?.hmdaOtherHispanicLatinoOrigin
  };

  const race: any = content?.hmda?.race;
  const race_others: any = content?.hmda?.race_others;
  const race_do_not_wish: any = content?.hmda?.race_do_not_wish;
  const race_custom_input: any = {
    hmdaamericanindiantribe: coBorrowerInfo?.coborrower?.hmdaAmericanIndianTribe,
    hmdaotherasianrace: coBorrowerInfo?.coborrower?.hmdaOtherAsianRace,
    hmdaotherpacificislanderrace: coBorrowerInfo?.coborrower?.hmdaOtherPacificIslanderRace
  };

  const personal = content?.review ? content?.review?.map((item: any) => ({
    name: 'personal',
    label: item.option.label,
    value: item.option.value,
    active: false
  })) : [];

  const employment = content?.review ? content?.review?.map((item: any) => ({
    name: 'employment',
    label: item.option.label,
    value: item.option.value,
    active: false
  })) : [];

  const otherIncome = content?.review ? content?.review?.map((item: any) => ({
    name: 'otherIncome',
    label: item.option.label,
    value: item.option.value,
    active: false
  })) : [];

  const demographic = content?.review ? content?.review?.map((item: any) => ({
    name: 'demographic',
    label: item.option.label,
    value: item.option.value,
    active: false
  })) : [];

  const preference = content?.review ? content?.review?.map((item: any) => ({
    name: 'preference',
    label: item.option.label,
    value: item.option.value,
    active: false
  })) : [];

  const handleReview = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    if (event.target.value === CHECK_INCORRECT) {
      setDynamicStates([...dynamicStates, name]);
    } else {
      setDynamicStates(dynamicStates.filter((val) => val !== name));
    }
  };

  const handleContinue = async () => {
    log({ message: 'PrefiCoborrowerReview: handleContinue(): Handling continue', level: 'info' });
    setLoading(true);
    selectDynamicStates(props.applicationId, dynamicStates.filter((value, index) => dynamicStates.indexOf(value) === index)).then(
      (data: any) => {
        setLoading(false);
        if (data.content === 'error') {
          log({ message: `PrefiCoborrowerReview: handleContinue(): selectDynamicStates(): Setting custom error based on response ${JSON.stringify({ data })}`, level: 'info' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
            redirectToMyALogin();
          }
        } else {
          setError(false);
          if (data?.content?.application) {
            const nextPanel = getNextPanelBasedOnState(data);
            log({ message: `PrefiCoborrowerReview: handleContinue(): selectDynamicStates(): Success ${JSON.stringify({ nextPanel })}`, level: 'info' });
            navigate(generateLink(`/${nextPanel}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
            window.scrollTo(0, 0);
          }
        }
      }
    );
  };

  let isAllChecked = (!!isCheckedPersonal && !!isCheckedEmployment && !!isCheckedOtherIncome && !!isCheckedDemographic && !!isCheckedPreference);

  return (
    <div>
      {
        (content && coBorrowerInfo)
          ? (
            <div className="flex items-center justify-center">
              <div className="items-center justify-center w-full max-w-3xl px-1">
                <RateAlertWizardProgress className="lg:hidden px-4 mb-4" />
                <div className="items-center justify-center lg:mb-8">
                  <div className="px-4 w-full">
                    {
                      error
                        ? (
                          <Banner className="border-2 mt-4 mb-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
                        )
                        : ''
                    }
                    <RateAlertLogo alt={content?.header} />
                  </div>
                </div>
                <div className="w-full px-1 lg:border border-solid border-slate-300 rounded-2xl bg-white">
                  <RateAlertWizardProgress className="hidden lg:block px-4 mt-6" />
                  <div className="flex items-center justify-center">
                    <div className="px-4 w-full">
                      <div className="mt-8 text-xl font-black">{content?.header}</div>
                    </div>
                  </div>
                  <div className="flex items-center justify-center mb-8">
                    <div className="px-4 w-full">
                      <div className="mt-4">{content?.description}</div>
                    </div>
                  </div>
                  <div className="items-center justify-center">
                    <div className="px-4 w-full">
                      <span className="text-xl font-black">{content?.form?.personal?.label}</span>
                      {
                        (isPersonalData)
                          ? (
                            <div>
                              <div className="grid grid-cols-2 gap-4 pt-4">
                                <div>
                                  <div className="text-sm">{content?.form?.personal?.name_label}</div>
                                  <div className="text-sm font-bold">{coBorrowerInfo?.coborrower?.firstName}{' '}{coBorrowerInfo?.coborrower?.lastName}</div>
                                </div>
                                <div>
                                  <div className="text-sm">{content?.form?.personal?.marital_status_label}</div>
                                  <div className="text-sm font-bold">{coBorrowerInfo?.coborrower?.maritalStatusType}</div>
                                </div>
                              </div>
                              <div className="grid grid-cols-2 gap-4 pt-4">
                                <div>
                                  <div className="text-sm">{content?.form?.personal?.dependents_label}</div>
                                  <div className="text-sm font-bold">{coBorrowerInfo?.coborrower?.dependentCount}</div>
                                </div>
                                <div>
                                  <div className="text-sm">{content?.form?.personal?.property_use_label}</div>
                                  <div className="text-sm font-bold">{coBorrowerInfo?.propertyUsageType?.replace(/([a-z])([A-Z])/, '$1 $2')}</div>
                                </div>
                              </div>
                            </div>
                          )
                          : (
                            <div className="text-sm pt-4">Not Available</div>
                          )
                      }
                    </div>
                    <div className="px-2 w-full">
                      <RadioButtons
                        className="radioButton mt-3 inline-block place-self-auto w-1/2 p-2 font-bold"
                        isChecked={isPersonalData ? isCheckedPersonal : CHECK_INCORRECT}
                        radios={personal}
                        updateCheckedValue={(event) => { setPersonalChecked(event.target.value); }}
                        onChange={(input) => { handleReview(input, 'CO_BORROWER_UPDATE_PERSONAL_INFORMATION'); setPersonalChecked((!isPersonalData) ? CHECK_INCORRECT : null); }}
                        disabled={!isPersonalData}
                      />
                    </div>
                  </div>

                  <div className="items-center justify-center">
                    <div className="px-4 w-full">
                      <span className="text-xl font-black">{content?.form?.employment?.label}</span>
                      {
                        (coBorrowerInfo?.employment && coBorrowerInfo?.employment?.length > 0 && coBorrowerInfo?.employment.filter((emp: any) => emp.owner === 'CoBorrower').length > 0)
                          ? (coBorrowerInfo?.employment.filter((emp: any) => emp.owner === 'CoBorrower').map((employer: any, index: number) => (
                            <div key={`reviewEmp${index}`} className="grid grid-cols-2 gap-4 pt-4">
                              <div>
                                <div className="text-sm">{content?.form?.employment?.employer_label}</div>
                                <div className="text-sm font-bold">{employer?.employerName}</div>
                              </div>
                              <div>
                                <div className="text-sm">{content?.form?.employment?.total_annual_salary_label}</div>
                                <div className="text-sm font-bold">{currencyFormatter.format(Math.ceil((employer?.basePayAmount + employer?.bonusAmount + employer?.commissionsAmount + employer?.overtimeAmount) * 12))}</div>
                              </div>
                            </div>
                          ))) : (
                            <div className="text-sm pt-4">Not Available</div>
                          )
                      }
                    </div>
                    <div className="px-2 w-full">
                      <RadioButtons
                        className="radioButton mt-3 inline-block place-self-auto w-1/2 p-2 font-bold"
                        isChecked={coBorrowerInfo?.employment ? isCheckedEmployment : CHECK_INCORRECT}
                        radios={employment}
                        updateCheckedValue={(event) => { setEmploymentChecked(event.target.value); }}
                        onChange={(input) => { handleReview(input, 'CO_BORROWER_UPDATE_EMPLOYMENT_INFORMATION'); setEmploymentChecked((!coBorrowerInfo?.employment || coBorrowerInfo?.employment.length === 0 || coBorrowerInfo?.employment.filter((emp: any) => emp.owner === 'CoBorrower').length === 0) ? CHECK_INCORRECT : null); }}
                        disabled={!coBorrowerInfo?.employment || coBorrowerInfo?.employment.length === 0 || coBorrowerInfo?.employment.filter((emp: any) => emp.owner === 'CoBorrower').length === 0}
                      />
                    </div>
                  </div>

                  <div className="items-center justify-center">
                    <div className="px-4 w-full">
                      <span className="text-xl font-black">{content?.form?.other_income?.label}</span>
                      {
                        (coBorrowerInfo?.otherIncomeSources && coBorrowerInfo?.otherIncomeSources?.length > 0 && coBorrowerInfo?.otherIncomeSources.filter((income: any) => income.owner === 'CoBorrower').length > 0)
                          ? (coBorrowerInfo?.otherIncomeSources.filter((income: any) => income.owner === 'CoBorrower').map((income: any, index: number) => (
                            <div key={`reviewIncome${index}`} className="grid grid-cols-2 gap-4 pt-4">
                              <div>
                                <div className="text-sm">{content?.form?.other_income?.income_type_label}</div>
                                <div className="text-sm font-bold">
                                  {income?.description === 'VABenefitsNonEducational' ? 'VA Benefits'
                                    : (income?.description === 'DefinedContributionPlan' ? 'Retirement' : income?.description?.replace(/([a-z])([A-Z])/, '$1 $2'))}
                                </div>
                              </div>
                              <div>
                                <div className="text-sm">{content?.form?.other_income?.income_amount_label}</div>
                                <div className="text-sm font-bold">{currencyFormatter.format(income?.monthlyAmount)}</div>
                              </div>
                            </div>
                          ))
                          ) : (
                            <div className="text-sm pt-4">Not Available</div>
                          )
                      }
                    </div>
                    <div className="px-2 w-full">
                      <RadioButtons
                        className="radioButton mt-3 inline-block place-self-auto w-1/2 p-2 font-bold"
                        isChecked={isCheckedOtherIncome}
                        radios={otherIncome}
                        updateCheckedValue={(event) => { setOtherIncomeChecked(event.target.value); }}
                        onChange={(input) => { handleReview(input, 'CO_BORROWER_UPDATE_OTHER_INCOME_SOURCES_INFORMATION'); setOtherIncomeChecked((!coBorrowerInfo?.otherIncomeSources || coBorrowerInfo?.otherIncomeSources.length === 0 || coBorrowerInfo?.otherIncomeSources.filter((income: any) => income.owner === 'CoBorrower').length === 0) ? CHECK_INCORRECT : null); }}
                        disabled={!coBorrowerInfo?.otherIncomeSources || coBorrowerInfo?.otherIncomeSources.length === 0 || coBorrowerInfo?.otherIncomeSources.filter((income: any) => income.owner === 'CoBorrower').length === 0}
                      />
                    </div>
                  </div>

                  <div className="items-center justify-center">
                    <span className="text-xl font-black px-4 w-full">{content?.form?.demographic?.label}</span>
                    <div className="px-4 w-full">
                      {
                        isDemographicData
                          ? (
                            <div>
                              <div className="grid grid-cols-2 gap-4 pt-4">
                                <div>
                                  <div className="text-sm">{content?.form?.demographic?.gender_label}</div>
                                  <div className="text-sm font-bold">
                                    {coBorrowerInfo?.coborrower?.hmdaGendertypeMaleIndicator ? 'Male' : ''}
                                    {(coBorrowerInfo?.coborrower?.hmdaGendertypeMaleIndicator && coBorrowerInfo?.coborrower?.hmdaGendertypeFemaleIndicator)
                                      ? ',Female' : (coBorrowerInfo?.coborrower?.hmdaGendertypeFemaleIndicator ? 'Female' : '')}
                                    {((coBorrowerInfo?.coborrower?.hmdaGendertypeMaleIndicator || coBorrowerInfo?.coborrower?.hmdaGendertypeFemaleIndicator) && coBorrowerInfo?.coborrower?.hmdaGendertypeDoNotWishIndicator)
                                      ? ',Do not wish to provide' : (coBorrowerInfo?.coborrower?.hmdaGendertypeDoNotWishIndicator ? 'Do not wish to provide' : '')}
                                  </div>
                                </div>
                                <div>
                                  <div className="text-sm">{content?.form?.demographic?.ethnicity_label}</div>
                                  <div className="text-sm font-bold break-normal text-wrap">
                                    {retrieveStringEquivalent(ethnicity, coBorrowerInfo?.coborrower).length > 0 ? retrieveStringEquivalent(ethnicity, coBorrowerInfo?.coborrower).toString() : ''}
                                    <span className="text-pretty break-words">
                                      {((retrieveStringEquivalent(ethnicity, coBorrowerInfo?.coborrower).length > 0) && (retrieveStringEquivalent(ethnicity_custom_input, coBorrowerInfo?.coborrower).length > 0))
                                        ? `,${retrieveStringEquivalent(ethnicity_custom_input, coBorrowerInfo?.coborrower).toString()}` : ((retrieveStringEquivalent(ethnicity_custom_input, coBorrowerInfo?.coborrower).length > 0) ? (retrieveStringEquivalent(ethnicity_custom_input, coBorrowerInfo?.coborrower).toString()) : '')}
                                    </span>
                                    <span className="text-pretty break-words">
                                      {(((retrieveStringEquivalent(ethnicity, coBorrowerInfo?.coborrower).length === 0) && (retrieveStringEquivalent(ethnicity_custom_input, coBorrowerInfo?.coborrower).length === 0)) && (retrieveStringEquivalent(ethnicity_others, coBorrowerInfo?.coborrower).length > 0))
                                        ? retrieveStringEquivalent(ethnicity_others, coBorrowerInfo?.coborrower).toString() : ''}
                                    </span>
                                    <span className="text-pretty break-words">
                                      {(((retrieveStringEquivalent(ethnicity, coBorrowerInfo?.coborrower).length === 0) && (retrieveStringEquivalent(ethnicity_custom_input, coBorrowerInfo?.coborrower).length === 0)) && (retrieveStringEquivalent(ethnicity_others, coBorrowerInfo?.coborrower).length === 0) && (retrieveStringEquivalent(ethnicity_do_not_wish, coBorrowerInfo?.coborrower).length > 0))
                                        ? retrieveStringEquivalent(ethnicity_do_not_wish, coBorrowerInfo?.coborrower).toString() : ''}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="grid grid-cols-2 gap-4 pt-4">
                                <div>
                                  <div className="text-sm">{content?.form?.demographic?.race_label}</div>
                                  <div className="text-sm font-bold break-normal text-wrap">
                                    {retrieveStringEquivalent(race, coBorrowerInfo?.coborrower).length > 0 ? retrieveStringEquivalent(race, coBorrowerInfo?.coborrower).toString() : ''}
                                    <span className="text-pretty break-words">
                                      {((retrieveStringEquivalent(race, coBorrowerInfo?.coborrower).length > 0) && (retrieveStringEquivalent(race_custom_input, coBorrowerInfo?.coborrower).length > 0))
                                        ? `,${retrieveStringEquivalent(race_custom_input, coBorrowerInfo?.coborrower).toString()}` : ((retrieveStringEquivalent(race_custom_input, coBorrowerInfo?.coborrower).length > 0) ? (retrieveStringEquivalent(race_custom_input, coBorrowerInfo?.coborrower).toString()) : '')}
                                    </span>
                                    <span className="text-pretty break-words">
                                      {(((retrieveStringEquivalent(race, coBorrowerInfo?.coborrower).length === 0) && (retrieveStringEquivalent(race_custom_input, coBorrowerInfo?.coborrower).length === 0)) && (retrieveStringEquivalent(race_others, coBorrowerInfo?.coborrower).length > 0))
                                        ? retrieveStringEquivalent(race_others, coBorrowerInfo?.coborrower).toString() : ''}
                                    </span>
                                    <span className="text-pretty break-words">
                                      {(((retrieveStringEquivalent(race, coBorrowerInfo?.coborrower).length === 0) && (retrieveStringEquivalent(race_custom_input, coBorrowerInfo?.coborrower).length === 0)) && (retrieveStringEquivalent(race_others, coBorrowerInfo?.coborrower).length === 0) && (retrieveStringEquivalent(race_do_not_wish, coBorrowerInfo?.coborrower).length > 0))
                                        ? retrieveStringEquivalent(race_do_not_wish, coBorrowerInfo?.coborrower).toString() : ''}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="text-sm pt-4">Not Available</div>
                          )
                      }
                    </div>
                    <div className="px-2 w-full">
                      <RadioButtons
                        className="radioButton mt-3 inline-block place-self-auto w-1/2 p-2 font-bold"
                        isChecked={!isDemographicData ? CHECK_INCORRECT : isCheckedDemographic}
                        radios={demographic}
                        updateCheckedValue={(event) => { setDemographicChecked(!isDemographicData ? CHECK_INCORRECT : event.target.value); }}
                        onChange={(input) => { handleReview(input, 'CO_BORROWER_UPDATE_DEMOGRAPHIC_INFORMATION'); setDemographicChecked(!isDemographicData ? CHECK_INCORRECT : null); }}
                        disabled={!isDemographicData}
                      />
                    </div>
                  </div>

                  <div className="items-center justify-center">
                    <span className="text-xl font-black px-4 w-full">{content?.form?.preference?.label}</span>
                    <div className="px-4 w-full">
                      {
                        coBorrowerInfo?.coborrower?.languagePreference
                          ? (
                            <div>
                              <div className="grid grid-cols-2 gap-4 pt-4">
                                <div>
                                  <div className="text-sm">{content?.form?.preference?.language_label}</div>
                                  <div className="text-sm font-bold">
                                    {(coBorrowerInfo?.coborrower?.languagePreference === 'LanguageRefusalIndicator')
                                      ? 'Do not wish to provide'
                                      : (coBorrowerInfo?.coborrower?.languagePreference === 'OtherIndicator'
                                        ? coBorrowerInfo?.coborrower?.languageCodeOtherDescription
                                        : coBorrowerInfo?.coborrower?.languagePreference.replace('Indicator', ''))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="text-sm pt-4">Not Available</div>
                          )
                      }
                    </div>
                    <div className="px-2 w-full">
                      <RadioButtons
                        className="radioButton mt-3 inline-block place-self-auto w-1/2 p-2 font-bold"
                        isChecked={coBorrowerInfo?.coborrower?.languagePreference ? isCheckedPreference : CHECK_INCORRECT}
                        radios={preference}
                        updateCheckedValue={(event) => { setPreferenceChecked(!coBorrowerInfo?.coborrower?.languagePreference ? CHECK_INCORRECT : event.target.value); }}
                        onChange={(input) => { handleReview(input, 'CO_BORROWER_UPDATE_LANGUAGE_PREFERENCE_INFORMATION'); setPreferenceChecked(!coBorrowerInfo?.coborrower?.languagePreference ? CHECK_INCORRECT : null); }}
                        disabled={!coBorrowerInfo?.coborrower?.languagePreference}
                      />
                    </div>
                  </div>

                  <div className="px-4 mt-2 mb-8 w-full flex justify-between">
                    <div className="flex justify-center mt-12">
                      <PreviousButton isDynamic={false} applicationId={props.applicationId} />
                    </div>
                    <div className="items-center justify-center">
                      <div className="px-4 mt-2 mb-8 w-full">
                        <div className="flex justify-center mt-12">
                          <Button
                            buttonStyle="primary"
                            className="!w-full md:!w-48 confirmButton"
                            onClick={handleContinue}
                            buttonAttrs={{ disabled: !isAllChecked || loading }}
                            loading={loading}
                          >{content?.form?.button_label}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="px-4 w-full">
              {
                error
                  ? (
                    <Banner className="border-2 mt-4 mb-4" text={errorMessage} title="Error" type="error" icon="warning-triangle" isMultiline={true} errorContent={props.errors.errorContent} />
                  )
                  : ''
              }
            </div>
          )
      }
    </div>
  );
}

export default PrefiCoborrowerReview;
