import Img from '@guaranteed-rate/react-components/dist/Img';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import { ISupportNavLoData } from '../../config/util/interfaces';
import { formatPhoneNumber } from '../../config/util/common';

interface ILOBannerProps {
  /** This is called when the location is selected in the ui */
  supportNavLoData: ISupportNavLoData;
}

const LoanOfficerBanner = (props: ILOBannerProps) => (
  <div className="border-t border-b">
    <div className="ml-8 md:ml-0 py-8 md:px-8 md:flex justify-center ">
      <div className="pr-8 flex flex-col justify-center">
        <h3 className="font-bold">{props.supportNavLoData.heading}</h3>
        <h4>{props.supportNavLoData.description}</h4>
      </div>
      <div className="flex mt-4 mb-4 md:mt-0 md:mb-0">
        <div className="pr-4 mt-2">
          <div className="w-20 h-20 rounded-full overflow-hidden mr-2">
            <Img src={props.supportNavLoData.avatarUrl} alt={props.supportNavLoData.name} width={80} height={80} />
          </div>
        </div>
        <div className="pr-4 md:mt-4">
          <h4 className="font-bold mb-1">{props.supportNavLoData.name}</h4>
          <h4 className="mb-1">{props.supportNavLoData.title}</h4>
          <h4 className="mb-1">{props.supportNavLoData.subTitle}</h4>
        </div>
      </div>
      <div>
        <div className="mb-1 md:mt-4">
          <a href={`mailto:${props.supportNavLoData.email}`} className="flex items-center font-bold">
            <FontIcon iconName="mail" className="mr-1 leading-6 text-xl" />
            <h4>{props.supportNavLoData.email}</h4>
          </a>
        </div>
        <div className="mb-1">
          <a href={`tel:${props.supportNavLoData.phone}`} className="flex items-center font-bold">
            <FontIcon iconName="phone" className="mr-1 leading-6 text-xl" />
            <h4>{formatPhoneNumber(props.supportNavLoData.phone)}</h4>
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default LoanOfficerBanner;
