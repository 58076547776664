/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ITerm } from '../../config/util/interfaces';
import { localeOptions, localeOptions2Digits } from '../../config/util/common';

interface ILoanCardProps {
  className?: string;
  tag: string;
  selected: boolean;
  setSelected: any;
  term: ITerm;
}

const iconMap = {
  warning: 'warning-triangle',
  error: 'minus-circle',
  info: 'information-circle',
};

const LoanCard = (props: ILoanCardProps) => (
  <div
    className={`w-[209px] rounded-lg border-2 loanCard ${props.selected ? 'cardSelected' : ''} ${props.className ? props.className : ''}`}
    onClick={() => props.setSelected(props.term)}
    tabIndex={0}
    key={props.term.term}
  >
    <div className="p-4">
      <div className="flex justify-between">
        <span className={`mr-1 px-1 rounded leading-5 text-xs loanTag  ${props.selected ? 'tagSelected' : ''}`}>{props.tag}</span>
        <input type="radio" className="checkbox !mr-0" checked={props.selected} readOnly={true} />
      </div>
      <div>
        <h3 className="font-bold mt-2">{props.term.estimatedMonthlyPayment.toLocaleString('en-us', localeOptions2Digits)}</h3>
        <p>Monthly payment</p>
      </div>
      <div className="linebreak mt-2 mb-2" />
      <div>
        <div className="flex justify-between">
          <span className="text-sm">Loan term</span>
          <span className="font-bold text-sm">{props.term.term.toLowerCase().replace('yr', 'years')}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-sm">APR</span>
          <span className="font-bold text-sm">{props.term.apr}%</span>
        </div>
      </div>
      <div className="linebreak mt-2 mb-2" />
      <div>
        <span className="font-bold text-sm">{props.term.loanAmount.toLocaleString('en-us', localeOptions)}</span>
      </div>
      <div className="-mt-2">
        <span className="text-sm">Loan Amount</span>
      </div>
    </div>
  </div>
);

export default LoanCard;
