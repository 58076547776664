import { useLocation } from 'react-router';
import { useMemo } from 'react';
import { WizardProgress } from './WizardProgress';
import { PrefiStateKey, getStateKeyFromRoute, stateKeyList } from '../../config/util/prefiNavigation';

interface RateAlertWizardProgressProps {
  className?: string;
  progressPercentageOverride?: number;
  subheaderOverride?: string;
}

const stateKeyToProgress = (stateKey: PrefiStateKey) => {
  const index = stateKeyList.indexOf(stateKey) ?? 0;
  const progress = Math.round(((index + 1) / stateKeyList.length) * 100);
  return progress;
};

const stateKeyToSubheader: Record<PrefiStateKey, string> = {
  BORROWER_IDENTITY_VALIDATION: 'Identity',
  INCLUDE_CO_BORROWER_INFO: 'Information',
  PRE_FI_DYNAMIC_STATE_START: 'Information',
  UPDATE_PERSONAL_INFORMATION: 'Personal',
  CO_BORROWER_UPDATE_PERSONAL_INFORMATION: 'Personal',
  UPDATE_EMPLOYMENT_INFORMATION: 'Employment',
  CO_BORROWER_UPDATE_EMPLOYMENT_INFORMATION: 'Employment',
  UPDATE_OTHER_INCOME_SOURCES_INFORMATION: 'Other income',
  CO_BORROWER_UPDATE_OTHER_INCOME_SOURCES_INFORMATION: 'Other income',
  UPDATE_BORROWER_DEMOGRAPHIC_INFORMATION: 'Demographics',
  UPDATE_CO_BORROWER_DEMOGRAPHIC_INFORMATION: 'Demographics',
  UPDATE_LANGUAGE_PREFERENCE_INFORMATION: 'Language',
  CO_BORROWER_UPDATE_LANGUAGE_PREFERENCE_INFORMATION: 'Language',
  UPDATE_CO_BORROWER_SOFT_CREDIT: 'Coborrower',
  ASSET_INFO_START: 'Assets',
  LOAN_INFO: 'Loan details',
  BORROWER_DECLARATION: 'Declaration',
  CO_BORROWER_DECLARATION: 'Declaration',
  APPLICATION_COMPLETE: 'Submitted', // will be force-changed to 'Success' if loan application has acceptable credit
  LOAN_INFO_COMPLETE: 'Submitted', // will be force-changed to 'Success' if loan application has acceptable credit
  SOFT_CREDIT: 'Submitted', // will be force-changed to 'Success' if loan application has acceptable credit
};

export const RateAlertWizardProgress = ({ className, progressPercentageOverride, subheaderOverride }: RateAlertWizardProgressProps) => {
  const { pathname } = useLocation();

  const stateKey = useMemo(() => {
    const lastSegment = pathname.substring(pathname.lastIndexOf('/') + 1);
    return getStateKeyFromRoute(lastSegment);
  }, [pathname]);

  const progress = typeof (progressPercentageOverride) === 'number' ? progressPercentageOverride : stateKeyToProgress(stateKey);
  const subheader = typeof (subheaderOverride) === 'string' ? subheaderOverride : stateKeyToSubheader[stateKey];

  return (
    <WizardProgress header="Application" subheader={subheader} progress={progress} className={className} />
  );
};
