import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { getTimeDiff } from '../../../config/util/common';
import { log } from '../../../config/util/logger';

export const getPrimaryBorrowerPersonalInformation = async (
  applicationId: string
) => {
  const mutation = {
    query: `query {
          getPrimaryBorrowerPersonalInformation(applicationId: "${applicationId}") {
            name
            maritalStatus
            dependentsCount
            propertyUse
        }
      }`,
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({
    message: `Query "getPrimaryBorrowerPersonalInformation" ${JSON.stringify({
      applicationId,
    })}`,
    context: { applicationId },
    level: 'info',
    requestId: uuid,
  });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation),
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getPrimaryBorrowerPersonalInformation) {
      log({
        message: `Query "getPrimaryBorrowerPersonalInformation" was successful ${JSON.stringify(
          { applicationId, duration }
        )}`,
        context: { applicationId },
        level: 'info',
        requestId: uuid,
      });
      return { content: data?.data?.getPrimaryBorrowerPersonalInformation };
    }
    log({
      message: `Query "getPrimaryBorrowerPersonalInformation" failed with errors ${JSON.stringify(
        { applicationId, duration, errors: data?.errors?.[0]?.message }
      )}`,
      context: { applicationId },
      level: 'error',
      requestId: uuid,
    });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({
      message: `Query "getPrimaryBorrowerPersonalInformation" failed due to exception ${JSON.stringify(
        { applicationId }
      )}`,
      context: { applicationId },
      level: 'error',
      requestId: uuid,
    });
    console.error(e);
    return { content: 'error' };
  }
};

export const updatePrimaryBorrowerPersonalInformation = async (
  applicationId: string,
  borrowerDataRequest: any
) => {
  const mutation = {
    query: `mutation {
          updatePrimaryBorrowerPersonalInformation(applicationId: "${applicationId}", borrowerDataRequest: ${borrowerDataRequest}) {
            applicationType
              application {
                id
                tenant
                applicationType
                createdAt
                updatedAt
                state
                currentState
                currentDynamicState
                invitationNumber
                newLoanId
                oldLoanNumber
                oldLoanId
              }
          }
      }`,
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({
    message: `Mutation "updatePrimaryBorrowerPersonalInformation" ${JSON.stringify(
      { applicationId }
    )}`,
    context: { applicationId },
    level: 'info',
    requestId: uuid,
  });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation),
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.updatePrimaryBorrowerPersonalInformation) {
      log({
        message: `Mutation "updatePrimaryBorrowerPersonalInformation was successful" ${JSON.stringify(
          { applicationId, duration }
        )}`,
        context: { applicationId },
        level: 'info',
        requestId: uuid,
      });
      return { content: data?.data?.updatePrimaryBorrowerPersonalInformation };
    }
    log({
      message: `Mutation "updatePrimaryBorrowerPersonalInformation failed with errors ${JSON.stringify(
        { applicationId, duration, errors: data?.errors[0]?.message }
      )}`,
      context: { applicationId },
      level: 'error',
      requestId: uuid,
    });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({
      message: `Mutation "updatePrimaryBorrowerPersonalInformation failed due to exception ${JSON.stringify(
        { applicationId }
      )}`,
      context: { applicationId },
      level: 'error',
      requestId: uuid,
    });
    console.error(e);
    return { content: 'error' };
  }
};

export const getCoBorrowerPersonalInformation = async (
  applicationId: string
) => {
  const mutation = {
    query: `query {
          getCoBorrowerPersonalInformation(applicationId: "${applicationId}") {
            name
            maritalStatus
            dependentsCount
        }
      }`,
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({
    message: `Query "getCoBorrowerPersonalInformation" ${JSON.stringify({
      applicationId,
    })}`,
    context: { applicationId },
    level: 'info',
    requestId: uuid,
  });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation),
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getCoBorrowerPersonalInformation) {
      log({
        message: `Query "getCoBorrowerPersonalInformation" was successful ${JSON.stringify(
          { applicationId, duration }
        )}`,
        context: { applicationId },
        level: 'info',
        requestId: uuid,
      });
      return { content: data?.data?.getCoBorrowerPersonalInformation };
    }
    log({
      message: `Query "getCoBorrowerPersonalInformation" failed with errors ${JSON.stringify(
        { applicationId, duration, errors: data?.errors?.[0]?.message }
      )}`,
      context: { applicationId },
      level: 'error',
      requestId: uuid,
    });
    return { content: 'error', errors: data?.errors?.[0]?.message };
  } catch (e) {
    log({
      message: `Query "getCoBorrowerPersonalInformation" failed due to exception ${JSON.stringify(
        { applicationId }
      )}`,
      context: { applicationId },
      level: 'error',
      requestId: uuid,
    });
    console.error(e);
    return { content: 'error' };
  }
};

export const updateCoBorrowerPersonalInformation = async (
  applicationId: string,
  borrowerDataRequest: any
) => {
  const mutation = {
    query: `mutation {
          updateCoBorrowerPersonalInformation(applicationId: "${applicationId}", borrowerData: ${borrowerDataRequest}) {
            applicationType
              application {
                id
                tenant
                applicationType
                createdAt
                updatedAt
                state
                currentState
                currentDynamicState
                invitationNumber
                newLoanId
                oldLoanNumber
                oldLoanId
              }
          }
      }`,
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({
    message: `Mutation "updateCoBorrowerPersonalInformation" ${JSON.stringify({
      applicationId,
    })}`,
    context: { applicationId },
    level: 'info',
    requestId: uuid,
  });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation),
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.updateCoBorrowerPersonalInformation) {
      log({
        message: `Mutation "updateCoBorrowerPersonalInformation was successful" ${JSON.stringify(
          { applicationId, duration }
        )}`,
        context: { applicationId },
        level: 'info',
        requestId: uuid,
      });
      return { content: data?.data?.updateCoBorrowerPersonalInformation };
    }
    log({
      message: `Mutation "updateCoBorrowerPersonalInformation failed with errors ${JSON.stringify(
        { applicationId, duration, errors: data?.errors[0]?.message }
      )}`,
      context: { applicationId },
      level: 'error',
      requestId: uuid,
    });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({
      message: `Mutation "updateCoBorrowerPersonalInformation failed due to exception ${JSON.stringify(
        { applicationId }
      )}`,
      context: { applicationId },
      level: 'error',
      requestId: uuid,
    });
    console.error(e);
    return { content: 'error' };
  }
};
