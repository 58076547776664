import Button from '@guaranteed-rate/react-components/dist/Button';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { stateKeyToRoute } from '../../config/util/prefiNavigation';
import { generateLink } from '../../pages/Prefi/prefiCommon';
import { getCode, redirectToMyALogin } from '../../config/util/common';
import { REDIRECT_TO_LOGIN_ERROR_CODE } from '../../config/content/constants';

export const PreviousButton = (props: {
  isDynamic: boolean;
  applicationId: string;
}) => {
  const { isDynamic, applicationId } = props;
  const mutationType = isDynamic ? 'previousDynamicState' : 'previousState';
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const handlePrevious = async () => {
    setLoading(true);
    const mutation = {
      query: `mutation {
              ${mutationType}(applicationId: "${applicationId}") {
                applicationType
                application {
                    currentState
                    currentDynamicState
                }
            }
          }`,
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
        'X-Request-ID': uuid,
        Authorization: Cookies.get('matc') ?? '',
      },
      body: JSON.stringify(mutation),
    });
    try {
      const data = await response.json();
      if (data?.data) {
        const previousPanelKey = isDynamic
          ? data.data.previousDynamicState.application.currentDynamicState as keyof typeof stateKeyToRoute
          : ((data.data.previousState.application.currentState === 'PRE_FI_DYNAMIC_STATE_START'
            || data.data.previousState.application.currentState === 'CO_BORROWER_PRE_FI_DYNAMIC_STATE_START')
            ? (data.data.previousState.application.currentDynamicState === null
              ? data.data.previousState.application.currentState as keyof typeof stateKeyToRoute
              : data.data.previousState.application.currentDynamicState as keyof typeof stateKeyToRoute)
            : data.data.previousState.application.currentState as keyof typeof stateKeyToRoute);
        navigate(generateLink(`/${stateKeyToRoute[previousPanelKey]}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
        window.scrollTo(0, 0);
      } else if (getCode(data?.errors[0]?.message) === REDIRECT_TO_LOGIN_ERROR_CODE) {
        redirectToMyALogin();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Button
      buttonStyle="secondary"
      className="!w-full md:!w-48 sm:!w-24 confirmButton"
      onClick={handlePrevious}
      loading={loading}
    >
      Back
    </Button>
  );
};
