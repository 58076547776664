/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { v4 as uuidv4 } from 'uuid';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { useEffect, useState } from 'react';
import { useScript } from '../../../config/hooks/useScript';

export const PayrollTest = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const [trueWorkLoaded, setTrueWorkLoaded] = useState(false);

  const onTrueWorkLoad = () => {
    if (!trueWorkLoaded) {
      setTrueWorkLoaded(true);
    }
  };

  const launchTrueWork = () => {
    let credentials = (window as any).Truework.credentials.init({
      publishableKey: urlParams.get('publishableKey') ?? 'tw_pk_test_PXw5KzWujz-pB5TIT-qlgNS0J_yPd3B0ew0Evy_cXUE',
      sessionToken: urlParams.get('token'),
      env: (window as any).Truework.credentials.Env.Sandbox,
    });
    credentials.onOpen(() => {
      console.log('Open');
    });
    credentials.onClose(() => {
      setLoading(false);
    });
    credentials.onSuccess(() => {
      console.log('Success');
      submitIOEOrder();
      setLoading(false);
    });
    credentials.onError((e: any) => {
      console.log(e);
      setLoading(false);
    });
    credentials.open();
    setLoading(true);
  };

  const submitIOEOrder = async () => {
    setLoading(true);
    const mutation = {
      query:
        `mutation {
          submitIOEOrder(
            id: "${urlParams.get('id')}",
            orderId: "${urlParams.get('orderId')}",
            data: {
              product: "voie",
            }
          )
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      console.log(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useScript('https://js.truework.com/v1', false, onTrueWorkLoad);

  useEffect(() => {
    if (trueWorkLoaded) {
      launchTrueWork();
    }
  }, [trueWorkLoaded]);

  return (
    <div>
      {
        !trueWorkLoaded || loading
          ? (
            <div className="min-h-[420px] flex items-center justify-center">
              <Loader color="#D13239" className="loader-medium" />
            </div>
          )
          : (
            <div>
              <div className="min-h-[420px]">
                <div id="connect-container" className="w-full h-[880px]" />
              </div>
            </div>
          )
      }
    </div>
  );
};
