import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import { formatRichText } from '../../config/util/common';

interface IBannerProps {
  className?: string;
  text: string;
  title: string;
  icon?: string;
  type: 'warning' | 'error' | 'info' | 'success';
  isMultiline?: boolean;
  errorContent?: any;
}

const iconMap = {
  warning: 'warning-triangle',
  error: 'minus-circle',
  info: 'information-circle',
  success: 'check-tick-circle',
};

const Banner = (props: IBannerProps) => (
  <div className={`alert p-4 w-full ${props.type} ${props.className ? props.className : ''}`}>
    <div className="flex">
      {(props.icon || iconMap[props.type])
      && <FontIcon className="mr-2 font-bold text-xl !leading-6" iconName={props.icon || iconMap[props.type]} />}
      <div>
        <h4 className="font-bold text-md">{props.title}</h4>
        {
          props?.isMultiline
            ? (
              props.text?.split('\n').map((line: string, index: number) => (
                <div key={`info_${index}`}>
                  <div className="text-left text-sm mb-4" key={`info${index}`}>
                    {
                      formatRichText('message', line, props.errorContent)
                    }
                  </div>
                </div>
              ))
            )
            : (
              <p className="text-xs">{props.text}</p>
            )
        }
      </div>
    </div>
  </div>
);

export default Banner;
