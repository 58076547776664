/* eslint-disable max-len */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Banner from '../../../components/Banner/Banner';
import { setCustomErrorBasedOnResponse } from '../../../config/util/common';
import { PreviousButton } from '../../../components/PreviousButton/PreviousButton';
import RateAlertLogo from '../../../components/RateAlertLogo/RateAlertLogo';
import { RateAlertWizardProgress } from '../../../components/WizardProgress/RateAlertWizardProgress';
import { generateLink, getNextPanelBasedOnState } from '../prefiCommon';
import { log } from '../../../config/util/logger';
import { getNewPersonalDetailForm, IPersonalDetailFormData, PersonalDetailForm } from './PersonalDetailForm';
import { useFspPageContent } from './useFspPageContent';
import { IPersonalDetailScreenContent } from './types';
import { getCoBorrowerPersonalInformation, getPrimaryBorrowerPersonalInformation, updateCoBorrowerPersonalInformation, updatePrimaryBorrowerPersonalInformation } from './util';

const PrefiPersonalDetail = (props: any) => {
  const navigate = useNavigate();
  const [personalDetailInfo, setPersonalDetailInfo] = useState<any>();
  const [isFormValid, setIsFormValid] = useState(false);

  const [personalDetailInfoFetched, setPersonalDetailInfoFetched] = useState(false);
  const [personalDetailForm, setPersonalDetailForm] = useState<IPersonalDetailFormData>(getNewPersonalDetailForm());
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [trySubmit, setTrySubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);

  const { isCoborrowerPage } = props;
  const logTag = `PrefiPersonalDetail isCoborrowerPage ${props.isCoborrowerPage}`;

  const fspPageContentState = useFspPageContent<IPersonalDetailScreenContent>('"prefi_personal_detail"', logTag);

  const maritalStatusOptions = fspPageContentState?.response?.marital_status_values ? fspPageContentState?.response?.marital_status_values?.map((item) => ({
    displayName: item.option.label,
    value: item.option.value,
  })) : [];

  const propertyUseOptions = fspPageContentState?.response?.property_use_values ? fspPageContentState?.response?.property_use_values?.map((item) => ({
    displayName: item.option.label,
    value: item.option.value,
  })) : [];

  if (fspPageContentState.status === 'succeeded' && props.applicationId && !personalDetailInfo && !personalDetailInfoFetched && !error) {
    let getDataPromise: Promise<any> = Promise.resolve();
    if (isCoborrowerPage) {
      getDataPromise = getCoBorrowerPersonalInformation(props.applicationId);
    } else {
      getDataPromise = getPrimaryBorrowerPersonalInformation(props.applicationId);
    }
    getDataPromise.then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `${logTag}: getPersonalInformationData: Setting custom error based on response ${JSON.stringify({ data })}`, context: { applicationId: props.applicationId }, level: 'error' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          setPersonalDetailInfoFetched(false);
        } else {
          setError(false);
          setPersonalDetailInfoFetched(true);
          setPersonalDetailInfo(data.content);
          let currentPersonalDetail = {
            firstName: data.content.name.split(' ')[0],
            lastName: data.content.name.split(' ')[1],
            dependentsCount: `${data.content.dependentsCount ?? ''}`,
            maritalStatus: data.content.maritalStatus,
            propertyUse: data.content.propertyUse
          };
          setPersonalDetailForm(currentPersonalDetail);
        }
      }
    );
  }

  const handleContinue = async () => {
    log({ message: `${logTag}: handleContinue(): Handling continue`, level: 'info' });
    setTrySubmit(true);
    setError(false);
    if (isFormValid) {
      log({ message: `${logTag}: handleContinue(): Client validation passed`, level: 'info' });
      setLoading(true);

      let updatePromise: Promise<any> = Promise.resolve();
      const methodTag = isCoborrowerPage ? 'updateCoBorrowerData()' : 'updateBorrowerData()';
      if (isCoborrowerPage) {
        const borrowerDataRequest = `{
          firstName: "${personalDetailForm.firstName}"
          lastName: "${personalDetailForm.lastName}"
          maritalStatusType:"${personalDetailForm.maritalStatus}"
          dependentCount: ${personalDetailForm.dependentsCount}
        }`;
        updatePromise = updateCoBorrowerPersonalInformation(props.applicationId, borrowerDataRequest);
      } else {
        const borrowerDataRequest = `{
          borrower: {
            firstName: "${personalDetailForm.firstName}"
            lastName: "${personalDetailForm.lastName}"
            maritalStatusType:"${personalDetailForm.maritalStatus}"
            dependentCount: ${personalDetailForm.dependentsCount}
          },
          propertyUsageType: "${personalDetailForm.propertyUse}"
        }`;
        updatePromise = updatePrimaryBorrowerPersonalInformation(props.applicationId, borrowerDataRequest);
      }
      updatePromise.then(
        (data: any) => {
          setLoading(false);
          if (data.content === 'error') {
            log({ message: `${logTag}: handleContinue(): ${methodTag}: Setting custom error based on response ${JSON.stringify({ data })}`, level: 'info' });
            setError(true);
            setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
            window.scrollTo(0, 0);
          } else {
            setError(false);
            if (data.content?.application) {
              const nextPanel = getNextPanelBasedOnState(data);
              log({ message: `${logTag}: handleContinue(): ${methodTag}: Success ${JSON.stringify({ nextPanel })}`, level: 'info' });
              navigate(generateLink(`/${nextPanel}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
              window.scrollTo(0, 0);
            }
          }
        }
      );
    } else {
      log({ message: `${logTag}: handleContinue(): Invalid fields`, level: 'info' });
    }
  };

  return (
    <div>
      {(fspPageContentState.status === 'succeeded' && personalDetailInfo)
        ? (
          <div className="flex justify-center w-full">
            <div className="max-w-2xl w-full px-1">
              <RateAlertWizardProgress className="lg:hidden px-4 mb-4" />
              <div className="flex items-center justify-center">
                <div className="px-4 w-full">
                  {
                    error
                      ? (
                        <Banner className="border-2 mt-4 mb-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
                      )
                      : ''
                  }
                  <RateAlertLogo alt={fspPageContentState?.response?.header || ''} />
                  <RateAlertWizardProgress className="hidden lg:block mt-6" />
                  <div className="text-xl font-black mt-6">{isCoborrowerPage ? fspPageContentState?.response?.coborrower_header : fspPageContentState?.response?.header}</div>
                  <div className="my-4">{fspPageContentState?.response?.description}</div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="px-4 w-full mt-4">
                  <PersonalDetailForm
                    formData={personalDetailForm}
                    onFormDataChange={setPersonalDetailForm}
                    onValidityChange={setIsFormValid}
                    isTouched={trySubmit}
                    formLabels={fspPageContentState.response?.form}
                    maritalStatusOptions={maritalStatusOptions}
                    propertyUseOptions={propertyUseOptions}
                    enablePropertyUseField={!isCoborrowerPage}
                  />
                </div>
              </div>
              <div className="px-4 mt-2 mb-8 w-full flex justify-between">
                <div className="flex justify-center mt-12">
                  <PreviousButton isDynamic={true} applicationId={props.applicationId} />
                </div>
                <div className="flex justify-center mt-12">
                  <Button
                    buttonStyle="primary"
                    className="!w-full md:!w-48 confirmButton"
                    onClick={handleContinue}
                    buttonAttrs={{ disabled: loading }}
                    loading={loading}
                  >{fspPageContentState?.response?.form?.button_label}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="px-4 w-full">
            {
              error
                ? (
                  <Banner className="border-2 mt-4 mb-4" text={errorMessage} title="Error" type="error" icon="warning-triangle" isMultiline={true} errorContent={props.errors.errorContent} />
                )
                : ''
            }
          </div>
        )}
    </div>
  );
};

export default PrefiPersonalDetail;
