import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { fetchPageContent } from '../../config/util/common';
import OwningHeaderWrapper from '../../components/Header/OwningHeader/OwningHeaderWrapper';
import OwningWelcome from './OwningWelcome';
import OwningForm from './OwningForm';
import OwningGri from './OwningGri';
import OwningLetsDoThis from './OwningLetsDoThis';
import OwningPersonalizedRates from './OwningPersonalizedRates';
import OwningSweet from './OwningSweet';
import OwningCongratulations from './OwningCongratulations';
import OwningError from './OwningError';
import { Error404Page } from '../Error/Error404Page';
import Footer from '../../components/Footer/Footer';

export const OwningPreScreen = () => {
  const navigate = useNavigate();
  const [headerType, setHeaderType] = useState('main');
  const [content, setContent] = useState(null) as any;
  const [footerContent, setFooterContent] = useState(null) as any;
  const [error, setError] = useState(false);
  const [contentFetched, setContentFetched] = useState(false);
  const [params] = useSearchParams();
  const [applicationId, setApplicationId] = useState(params.get('id') || undefined);
  const [tcpaConfig, setTcpaConfig] = useState(null) as any;
  const [productType, setProductType] = useState('Purchase');
  const [redirectUrl, setRedirectUrl] = useState('');

  window.onpopstate = (e) => {
    navigate(0);
  };

  if (!content && !contentFetched && !error) {
    fetchPageContent('"owning_prescreen_form"').then(
      (data: any) => {
        if (data.content === 'error') {
          setError(true);
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
          setTcpaConfig(data.tcpaConfig);
        }
      }
    );
  }

  if (!footerContent) {
    fetchPageContent('"owning_footer"').then(
      (data: any) => {
        if (data.content === 'error') {
          setError(true);
        } else {
          setError(false);
          setFooterContent(data.content);
        }
      }
    );
  }

  const handleFormSubmit = async (mutation: string, setLoading: any) => {
    setLoading(true);
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.checkSameDayEligibility?.eligibilityCheck) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.append('id', data.data.checkSameDayEligibility.id);
        setApplicationId(data.data.checkSameDayEligibility.id);
        setLoading(false);
        if (data.data.checkSameDayEligibility.eligibilityCheck.foundEligible) {
          (window as any).digitalData.pageInfo.isEligible = true;
          setRedirectUrl(data.data.checkSameDayEligibility.eligibilityCheck.dmxAppUrl);
          navigate(`/mortgage/qualified/?${urlSearchParams}`);
        } else {
          (window as any).digitalData.pageInfo.isEligible = false;
          setRedirectUrl(data.data.checkSameDayEligibility.eligibilityCheck.nonSameDayDmxAppUrl);
          navigate(`/mortgage/notEligible/?${urlSearchParams}`);
        }
        window.scrollTo(0, 0);
      } else {
        console.error(data?.errors[0].message);
        setLoading(false);
        setError(true);
        navigate('/error');
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(true);
      navigate('/error');
    }
    return true;
  };

  const handleGriSubmit = async (mutation: string, setLoading: any) => {
    setLoading(true);
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.acceptNonSameDayLead?.eligibilityCheck) {
        const adTrk = Cookies.get('AdTrk');
        window.location.href = `${data.data.acceptNonSameDayLead.eligibilityCheck.nonSameDayDmxAppUrl}${adTrk && `&AdTrk=${adTrk}`}`;
      } else {
        console.error(data?.errors[0].message);
        setLoading(false);
        setError(true);
        navigate('/error');
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(true);
      navigate('/error');
    }
    return true;
  };

  return content && (
    <>
      <div className="owning-pre-screen">
        <div className="w-full">
          <OwningHeaderWrapper type={headerType} />
          <div className="block">
            <Routes>
              <Route
                path="/"
                element={(
                  <OwningWelcome
                    onProductTypeUpdate={setProductType}
                  />
                )}
              />
              <Route
                path="/form"
                element={(
                  <OwningForm
                    updateHeaderType={setHeaderType}
                    productType={productType}
                    pageContent={content}
                    tcpaConfig={tcpaConfig}
                    handleSubmit={handleFormSubmit}
                  />
                )}
              />
              <Route
                path="/qualified/*"
                element={(
                  <OwningLetsDoThis
                    updateHeaderType={setHeaderType}
                    owningRedirectUrl={redirectUrl}
                  />
                )}
              />
              <Route
                path="/notEligible/*"
                element={(
                  <OwningGri
                    pageContent={content}
                    applicationId={applicationId}
                    handleSubmit={handleGriSubmit}
                  />
                )}
              />
              <Route
                path="/personalizedRates"
                element={(
                  <OwningPersonalizedRates
                    updateHeaderType={setHeaderType}
                  />
                )}
              />
              <Route
                path="/sweet"
                element={(
                  <OwningSweet
                    updateHeaderType={setHeaderType}
                  />
                )}
              />
              <Route
                path="/congratulations"
                element={(
                  <OwningCongratulations
                    updateHeaderType={setHeaderType}
                  />
                )}
              />
              <Route
                path="/error"
                element={(
                  <OwningError />
                )}
              />
              <Route
                path="*"
                element={
                  <Error404Page isHeader={false} isFooter={false} />
                }
              />
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
