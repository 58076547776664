/* eslint-disable max-len */
import * as React from 'react';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import { MASKS } from '../../../config/util/masks';

function ConfirmAssetsForm(props: any) {
  const data = {
    content: props.content,
    eachFormItem: props.eachFormItem,
    index: props.index,
    accountTypes: props.accountTypes,
    ownerTypes: props.ownerTypes,
    isBorrowerOnly: props.isBorrowerOnly,
  };

  return (
    <div
      key={`asset${data.index}`}
      className="items-center justify-center border-b pb-2 mb-8"
    >
      <div className="px-4 w-full">
        <div className="flex justify-between w-full p-2 my-2">
          <span className="text-xl font-black">
            {
              (data.eachFormItem.financialInstitution && (data.eachFormItem.accountNumber || data.eachFormItem.accountType))
                ? (`${data.eachFormItem.financialInstitution} ${data.eachFormItem?.accountNumber ? data.eachFormItem.accountNumber : data.accountTypes[data.accountTypes.map((e: { value: any }) => e.value).indexOf(data.eachFormItem.accountType)]?.displayName}`)
                : (`Asset ${data.index + 1}`)
            }
          </span>
          <div className="w-1/4">
            <div className="flex items-end justify-end">
              <Button
                buttonStyle="icon"
                iconName="trash"
                className="red-icon"
                onClick={() => {
                  props.updateHandleDeleteAssets(data.index);
                }}
              />
              <Button
                buttonStyle="icon"
                iconName={`${
                  data.eachFormItem.isFormOpen
                    ? 'chevron-up-large'
                    : 'chevron-down-large'
                }`}
                onClick={() => {
                  props.updateHandleToggle(data.index);
                }}
              />
            </div>
          </div>
        </div>
        {data.eachFormItem.isFormOpen ? (
          <div className="w-full mt-4">
            <Dropdown
              name="accountType"
              value={data.eachFormItem.accountType}
              label={data.content?.form?.account_type_label}
              onChange={(event) => props.updateHandleChange(event, 'accountType', data.index)}
              className="w-full"
              options={data.accountTypes}
              hasError={props.updateHasError(
                `accountType${data.index}`,
                data.eachFormItem.accountType
              )}
              helperText={
                props.updateHasError(`accountType${data.index}`, data.eachFormItem.accountType)
                  ? 'required'
                  : undefined
              }
              required={true}
            />
            <TextInput
              name="financialInstitution"
              label={data.content?.form?.financial_institution_label}
              className="w-full"
              value={data.eachFormItem.financialInstitution}
              hasError={props.updateHasError(
                `financialInstitution${data.index}`,
                data.eachFormItem.financialInstitution
              )}
              helperText={
                props.updateHasError(
                  `financialInstitution${data.index}`,
                  data.eachFormItem.financialInstitution
                )
                  ? 'required field'
                  : ''
              }
              onChange={(input) => props.updateHandleChange(input, 'financialInstitution', data.index)}
              required={true}
            />
            <TextInput
              name="accountNumber"
              label={data.content?.form?.account_number_label}
              className="w-full"
              value={data.eachFormItem.accountNumber}
              maxLength={64}
              hasError={props.updateHasError(`accountNumber${data.index}`, data.eachFormItem.accountNumber)}
              helperText={
                props.updateHasError(`accountNumber${data.index}`, data.eachFormItem.accountNumber)
                  ? 'You must enter alpha numeric characters'
                  : ''
              }
              onChange={(input) => props.updateHandleChange(input, 'accountNumber', data.index)}
            />
            <TextInput
              name="amount"
              label={data.content?.form?.balance_label}
              className="w-full"
              value={data.eachFormItem.amount}
              hasError={props.updateHasError(`amount${data.index}`, data.eachFormItem.amount)}
              helperText={
                props.updateHasError(`amount${data.index}`, data.eachFormItem.amount)
                  ? 'required field'
                  : ''
              }
              onChange={(input) => props.updateHandleChange(input, 'amount', data.index)}
              mask={MASKS.CURRENCY}
              required={true}
            />
            {
              (!data.isBorrowerOnly)
              && (
                <Dropdown
                  name="owner"
                  value={data.eachFormItem.owner}
                  label={data.content?.form?.owner_label}
                  onChange={(event) => props.updateHandleChange(event, 'owner', data.index)}
                  className="w-full"
                  options={data.ownerTypes}
                  hasError={props.updateHasError(`owner${data.index}`, data.eachFormItem.owner)}
                  helperText={
                    props.updateHasError(`owner${data.index}`, data.eachFormItem.owner)
                      ? 'required'
                      : undefined
                  }
                  required={true}
                />
              )
            }
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default ConfirmAssetsForm;
