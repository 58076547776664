/* eslint-disable max-len */
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { useState, useEffect, ReactNode } from 'react';
import PrefiHeader from '../../components/Header/PrefiHeader/PrefiHeader';
import PrefiConfirmIdentity from './PrefiConfirmIdentity';
import PrefiCoborrowerExists from './PrefiCoborrowerExists';
import PrefiReview from './PrefiReview';
import PrefiPersonalDetail from './PrefiPersonalDetail/PrefiPersonalDetail';
import PrefiEmployment from './PrefiEmployment';
import PrefiOtherIncome from './PrefiOtherIncome';
import PrefiDemographic from './PrefiDemographic';
import PrefiLanguagePreference from './PrefiLanguagePreference';
import PrefiCoborrowerConfirmIdentity from './PrefiCoborrowerConfirmIdentity';
import PrefiConfirmAssets from './PrefiConfirmAssets/PrefiConfirmAssets';
import PrefiLoanDetail from './PrefiLoanDetail';
import PrefiDeclarations from './PrefiDeclarations';
import PrefiCoborrowerReview from './PrefiCoborrowerReview';
import PrefiSoftCredit from './PrefiSoftCredit';
import Footer from '../../components/Footer/Footer';
import Banner from '../../components/Banner/Banner';
import PageLoader from '../../components/PageLoader/PageLoader';
import PrefiLoanOfficer from '../../components/SupportNavigation/PrefiLoanOfficer';
import { Error404Page } from '../Error/Error404Page';
import { fetchFspPageContent, getCode, getEncompassIdData, getTimeDiff, redirectToMyALogin, setCustomErrorBasedOnResponse } from '../../config/util/common';
import { PrefiRoutes, stateKeyToRoute } from '../../config/util/prefiNavigation';
import { config } from '../../config/content/config';
import { PrefiProvider } from './PrefiContext';
import { log, updateLogContext } from '../../config/util/logger';
import { generateLink, normalizeSearchParam } from './prefiCommon';
import { REDIRECT_TO_LOGIN_ERROR_CODE } from '../../config/content/constants';

// Will have either an invitationNumber, loanId, or oldLoanNumber
const getALPMortgage = async (invitationNumber?: string | null, loanId?: string | null, oldLoanNumber?: string | null) => {
  const mutation = {
    query:
      `query {
        getALPMortgage(
          getMortgageRequest: {
                invitationNumber: "${invitationNumber}",
                loanId: "${loanId}"
                oldLoanNumber: "${oldLoanNumber}"
            })
            {
                application {
                    id
                    currentState
                    currentDynamicState
                    isEditable
                }
                errorCode
                errorMessage
            }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "getALPMortgage getMortgageRequest" ${JSON.stringify({ invitationNumber, loanId, oldLoanNumber })}`, context: { invitationNumber, loanId, oldLoanNumber }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getALPMortgage && !(data?.data?.getALPMortgage?.errorMessage)) {
      log({ message: `Query "getALPMortgage getMortgageRequest" was successful ${JSON.stringify({ invitationNumber, loanId, oldLoanNumber, duration })}`, context: { invitationNumber, loanId, oldLoanNumber }, level: 'info', requestId: uuid });
      return { content: data.data.getALPMortgage };
    }
    if ((data?.data?.getALPMortgage === null) && !(data?.errors)) {
      log({ message: `Query "getALPMortgage getMortgageRequest" data?.data?.getALPMortgage is null ${JSON.stringify({ invitationNumber, loanId, oldLoanNumber, duration })}`, context: { invitationNumber, loanId, oldLoanNumber }, level: 'info', requestId: uuid });
      return { content: 'info', info: !(data?.data?.getALPMortgage) ? 'no data' : '' };
    }
    if (data?.data?.getALPMortgage?.errorMessage) {
      log({ message: `Query "getALPMortgage getMortgageRequest" failed with errorMessage ${JSON.stringify({ error: { invitationNumber, loanId, oldLoanNumber, duration, message: data.data.getALPMortgage.errorMessage, code: data.data.getALPMortgage.errorCode } })}`, context: { invitationNumber, loanId, oldLoanNumber }, level: 'error', requestId: uuid });
      return { content: 'error', errors: data?.data?.getALPMortgage?.errorCode };
    }
    log({ message: `Query "getALPMortgage getMortgageRequest" failed with errors ${JSON.stringify({ invitationNumber, loanId, oldLoanNumber, duration, errors: data?.errors[0]?.message })}`, context: { invitationNumber, loanId, oldLoanNumber }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "getALPMortgage getMortgageRequest" failed due to exception ${JSON.stringify({ invitationNumber, loanId, oldLoanNumber })}`, context: { invitationNumber, loanId, oldLoanNumber }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const createALPMortgage = async (applicationType: string, invitationNumber: string | null, oldLoanNumber: string | null) => {
  const mutation = {
    query:
      `query {
        createALPMortgage(
            createMortgageRequest: {
                applicationType: "${applicationType}"
                invitationNumber: "${invitationNumber}"
                oldLoanNumber: "${oldLoanNumber}"
            })
            {
                application {
                    id
                    currentState
                    currentDynamicState
                }
                errorCode
                errorMessage
            }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "createALPMortgage createMortgageRequest" ${JSON.stringify({ invitationNumber, applicationType, oldLoanNumber })}`, context: { invitationNumber, oldLoanNumber }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.createALPMortgage && !(data?.data?.createALPMortgage?.errorMessage)) {
      log({ message: `Query "createALPMortgage createMortgageRequest" was successful ${JSON.stringify({ invitationNumber, applicationType, oldLoanNumber, duration })}`, context: { invitationNumber, oldLoanNumber }, level: 'info', requestId: uuid });
      return { content: data.data.createALPMortgage };
    }
    if ((data?.data?.createALPMortgage === null) && !(data?.errors)) {
      log({ message: `Query "createALPMortgage createMortgageRequest" data?.data?.createALPMortgage is null ${JSON.stringify({ invitationNumber, applicationType, oldLoanNumber, duration })}`, context: { invitationNumber, oldLoanNumber }, level: 'info', requestId: uuid });
      return { content: 'info', info: !(data?.data?.createALPMortgage) ? 'no data' : '' };
    }
    if (data?.data?.createALPMortgage?.errorMessage) {
      log({ message: `Query "createALPMortgage createMortgageRequest" failed with errorMessage ${JSON.stringify({ invitationNumber, applicationType, oldLoanNumber, duration, error: { message: data.data.createALPMortgage.errorMessage, code: data.data.createALPMortgage.errorCode } })}`, context: { invitationNumber, oldLoanNumber }, level: 'error', requestId: uuid });
      return { content: 'error', errors: data?.data?.createALPMortgage?.errorCode };
    }
    log({ message: `Query "createALPMortgage createMortgageRequest" failed with errors ${JSON.stringify({ invitationNumber, applicationType, oldLoanNumber, duration, errors: data?.errors[0]?.message })}`, context: { invitationNumber, oldLoanNumber }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "createALPMortgage createMortgageRequest" failed due to exception ${JSON.stringify({ invitationNumber, applicationType, oldLoanNumber })}`, context: { invitationNumber, oldLoanNumber }, level: 'error', requestId: uuid });
    return { content: 'error' };
  }
};

const SupportCard = ({ applicationId }: { applicationId: string }) => (
  <div className="mx-4">
    <div className="h-12" />
    <div className="max-w-4xl mx-auto">
      <PrefiLoanOfficer applicationId={applicationId} />
    </div>
  </div>
);

const Error = ({ errorMessage, errorContent, errorFromResponse }: { errorMessage: string; errorContent: any; errorFromResponse?: string }) => {
  useEffect(() => {
    log({ message: `Error shown to user ${JSON.stringify({ errorMessage })}`, level: 'info' });
    if (errorFromResponse) {
      console.error(errorFromResponse);
    }
  }, []);

  return (
    <div className="flex items-center justify-center lg:mb-8">
      <div className="w-full px-4">
        <Banner
          className="mt-4 mb-4 border-2"
          text={errorMessage}
          title="Error"
          type="error"
          icon="warning-triangle"
          isMultiline={true}
          errorContent={errorContent}
        />
      </div>
    </div>
  );
};

const Layout = ({ applicationId, children }: { applicationId?: string; children: ReactNode }) => (
  <div className="p-4 pre-fi-screen md:p-8 md:flex md:justify-center">
    <div className="w-full md:max-w-7xl">
      <PrefiHeader />
      <div className="block">
        {children}
      </div>
      {applicationId && (
        <Routes>
          {PrefiRoutes.map((path: string) => (
            <Route
              key={path}
              path={path}
              element={<SupportCard applicationId={applicationId} />}
            />
          ))}
        </Routes>
      )}
      <div className="h-12" />
      <div className="px-4 mx-auto max-w-screen-2xl 2xl:px-11">
        <div className="linebreak-thin" />
      </div>
      <Footer />
    </div>
  </div>
);

export const PrefiPage = () => {
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(true);
  const [redirecting, setRedirecting] = useState(false);
  const [errorContent, setErrorContent] = useState(null as any);
  const [errorContentFetching, setErrorContentFetching] = useState(false);
  const [applicationId, setApplicationId] = useState<string>();
  const [applicationFetching, setApplicationFetching] = useState(false);
  const [invitationNumber, setInvitationNumber] = useState<string | null>(null);
  const [loanId, setLoanId] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [notFoundError, setNotFoundError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [errorFromResponse, setErrorFromResponse] = useState();
  const urlParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const invitationNumberParam = normalizeSearchParam(urlParams.get('invitationNumber'));
  const loanIdParam = normalizeSearchParam(urlParams.get('loanId'));
  const oldLoanNumberParam = normalizeSearchParam(urlParams.get('oldLoanNumber'));

  useEffect(() => {
    updateLogContext({ applicationType: 'MORTGAGE_PREFI' });
  }, []);

  useEffect(() => {
    if (invitationNumber || loanId || applicationId || oldLoanNumberParam) {
      updateLogContext({
        ...(invitationNumber && { invitationNumber }),
        ...(applicationId && { applicationId }),
        ...(loanId && { loanId }),
        ...(oldLoanNumberParam && { oldLoanNumber: oldLoanNumberParam }),
      });
    }
  }, [invitationNumber, loanId, applicationId, oldLoanNumberParam]);

  useEffect(() => {
    log({
      level: 'info',
      message: `user navigated to - ${location.pathname}${location.search}${location.hash}`,
    });
  }, [location.pathname, location.search, location.hash]);

  useEffect(() => {
    window.history.pushState({}, '', `?${urlParams.toString()}`);

    const handlePopState = () => {
      window.history.pushState({}, '', `?${urlParams.toString()}`);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  if (redirecting) {
    return (
      <div>Redirecting...</div>
    );
  }

  const token = Cookies.get('matc') || '';
  if ((invitationNumber || loanId || oldLoanNumberParam) && token === '') {
    log({ message: 'PrefiPage: Redirecting to /gateway/authorize-mortgage-user', level: 'info' });
    setRedirecting(true);
    window.location.href = `${window.location.origin}/gateway/authorize-mortgage-user?invitationNumber=${invitationNumberParam}&loanId=${loanIdParam}&oldLoanNumber=${oldLoanNumberParam}&tenant-id=${(window as any).tenantId.toLowerCase()}`;
    return null;
  }

  if (!errorContent && !errorContentFetching && !error) {
    fetchFspPageContent('"prefi_errors"').then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiPage: fetchFspPageContent(): Error fetching page content. ${JSON.stringify({ page: 'prefi_errors' })}`, level: 'error' });
          setError(true);
          if (data.errors) {
            setErrorFromResponse(data.errors);
          }
          setErrorMessage('Error fetching page content. Please try again.');
          setErrorContentFetching(false);
          setErrorContent(null);
        } else {
          setError(false);
          setErrorContentFetching(false);
          setErrorContent(data.content);
        }
      }
    );
  }

  const errorTypes = errorContent?.errors ? errorContent?.errors?.map((item: any) => ({
    status: item.option.status,
    message: item.option.description,
  })) : [];

  const updateApplicationRoute = (data: any) => {
    const application = data?.content?.application;
    const dataApplicationId = application?.id;
    log({ message: 'PrefiPage: updateApplicationRoute(): Setting application route', level: 'debug' });
    setPageLoading(false);
    setError(false);

    const nextPanelKey = (application?.currentState === 'PRE_FI_DYNAMIC_STATE_IN_PROGRESS' || application?.currentState === 'CO_BORROWER_PRE_FI_DYNAMIC_STATE_IN_PROGRESS')
      ? application?.currentDynamicState as keyof typeof stateKeyToRoute
      : application?.currentState as keyof typeof stateKeyToRoute;
    const nextPanel = nextPanelKey?.length > 0 ? stateKeyToRoute[nextPanelKey] : 'loandetail';
    if (!nextPanel) {
      log({ message: `PrefiPage: updateApplicationRoute(): Unable to determine next panel ${JSON.stringify({ nextPanel, nextPanelKey })}`, context: { applicationId: dataApplicationId }, level: 'error' });
      setError(true);
      setErrorMessage('Unable to determine application status.');
    } else if (nextPanelKey === 'APPLICATION_COMPLETE') {
      setPageLoading(true);
      getEncompassIdData(dataApplicationId).then(
        (data) => {
          const encompassId = data.content?.encompassId;
          log({ message: `PrefiPage: updateApplicationRoute(): nextPanelKey=APPLICATION_COMPLETE Redirecting to MyAccount ${JSON.stringify({ encompassId, nextPanelKey })}`, context: { applicationId: dataApplicationId }, level: 'info' });
          setRedirecting(true);
          window.location.href = encompassId
            ? `${(config.myAccountUrl as any)[(window as any).env]}/loan/${encompassId}`
            : `${(config.myAccountUrl as any)[(window as any).env]}/okta/login`;
        }
      );
    } else {
      log({ message: `PrefiPage: updateApplicationRoute(): Navigating to next panel ${JSON.stringify({ nextPanel, nextPanelKey })}`, context: { applicationId: dataApplicationId }, level: 'info' });
      navigate(generateLink(`/${nextPanel}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
    }
    window.scrollTo(0, 0);
    const urlParamErrorCode = normalizeSearchParam(urlParams.get('code'));
    if (urlParamErrorCode) {
      setError(true);
      const customErrorMessage = errorTypes[errorTypes.map((e: any) => e.status).indexOf(urlParamErrorCode?.toString())]?.message;
      log({ message: `PrefiPage: updateApplicationRoute(): Setting error message ${JSON.stringify({ urlParamErrorCode, customErrorMessage })}`, context: { applicationId: dataApplicationId }, level: 'info' });
      setErrorMessage(customErrorMessage);
    }
  };

  // Wait until we have error content so we can display errors
  if (errorContent) {
    if (!invitationNumber && invitationNumberParam) {
      setInvitationNumber(invitationNumberParam);
    }

    if (!loanId && loanIdParam) {
      setLoanId(loanIdParam);
    }

    if ((invitationNumber || loanId || oldLoanNumberParam) && !applicationFetching && !applicationId && !error) {
      log({ message: 'PrefiPage: Initializing ALP Mortgage', context: { invitationNumber: invitationNumberParam, loanId: loanIdParam, oldLoanNumber: oldLoanNumberParam }, level: 'info' });
      setApplicationFetching(true);

      getALPMortgage(invitationNumberParam, loanIdParam, oldLoanNumberParam).then(
        (data: any) => {
          if (data.content?.application?.isEditable === false) {
            const applicationId = data.content?.application?.id;
            setPageLoading(true);
            getEncompassIdData(applicationId).then(
              (data) => {
                const encompassId = data.content?.encompassId;
                log({ message: `PrefiPage: getALPMortgage(): Application Not Editable. Redirecting back to MyAccount ${JSON.stringify({ encompassId })}`, context: { invitationNumber: invitationNumberParam, loanId: loanIdParam, oldLoanNumber: oldLoanNumberParam }, level: 'info' });
                setRedirecting(true);
                window.location.href = encompassId
                  ? `${(config.myAccountUrl as any)[(window as any).env]}/loan/${encompassId}`
                  : `${(config.myAccountUrl as any)[(window as any).env]}/okta/login`;
              }
            );
          } else if (data.content === 'error' || data.content === 'info') {
            if (data.info === 'no data') {
              if (!invitationNumberParam && !oldLoanNumberParam) {
                log({ message: `PrefiPage: getALPMortgage():  No ALP Mortgage found. ${JSON.stringify({ data })}`, context: { invitationNumber: invitationNumberParam, loanId: loanIdParam }, level: 'info' });
                setPageLoading(false);
                setNotFoundError(true);
              }

              // Error Code 10003 corresponds to "Application ID Not Found" and hence calling the Create method
              if (invitationNumberParam || oldLoanNumberParam) {
                log({ message: 'PrefiPage: getALPMortgage(): No ALP Mortgage found. Creating one.', context: { invitationNumber: invitationNumberParam, oldLoanNumber: oldLoanNumberParam }, level: 'info' });
                createALPMortgage('MORTGAGE_PREFI', invitationNumberParam, oldLoanNumberParam).then(
                  (mortgageData: any) => {
                    setPageLoading(false);
                    setApplicationFetching(false);
                    if (mortgageData.content === 'error' || mortgageData.content === 'info') {
                      log({ message: `PrefiPage: createALPMortgage(): Setting custom error based on response ${JSON.stringify({ invitationNumber: invitationNumberParam, oldLoanNumber: oldLoanNumberParam, data })}`, context: { invitationNumber: invitationNumberParam, oldLoanNumber: oldLoanNumberParam, }, level: 'info' });
                      setError(true);
                      if (mortgageData.errors) {
                        setErrorFromResponse(mortgageData.errors);
                        if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
                          setRedirecting(true);
                          redirectToMyALogin();
                        }
                      }
                      setErrorMessage(setCustomErrorBasedOnResponse(mortgageData, errorTypes));
                    } else {
                      log({ message: 'PrefiPage: createALPMortgage(): ALP Mortgage created.', context: { invitationNumber: invitationNumberParam, oldLoanNumber: oldLoanNumberParam, applicationId: mortgageData.content.application.id }, level: 'info' });
                      setApplicationId(mortgageData.content.application.id);
                      updateApplicationRoute(mortgageData);
                    }
                  }
                );
              } else {
                log({ message: `PrefiPage: getALPMortgage():  No ALP Mortgage found, and no method to create one. ${JSON.stringify({ invitationNumber: invitationNumberParam, loanId: loanIdParam, oldLoanNumberParam, data })}`, level: 'info' });
                setPageLoading(false);
                setNotFoundError(true);
              }
            } else {
              log({ message: `PrefiPage: getALPMortgage(): Setting custom error based on response ${JSON.stringify({ data })}`, context: { invitationNumber: invitationNumberParam, loanId: loanIdParam, oldLoanNumber: oldLoanNumberParam }, level: 'info' });
              setPageLoading(false);
              setError(true);
              if (data.errors) {
                setErrorFromResponse(data.errors);
              }
              setErrorMessage(setCustomErrorBasedOnResponse(data, errorTypes));
              if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
                setRedirecting(true);
                redirectToMyALogin();
              }
              setApplicationFetching(false);
            }
          } else {
            const dataApplicationId = data.content.application.id;
            log({ message: 'PrefiPage: getALPMortgage(): ALP Mortgage found.', context: { invitationNumber: invitationNumberParam, loanId: loanIdParam, oldLoanNumber: oldLoanNumberParam, applicationId: dataApplicationId }, level: 'info' });
            setApplicationFetching(false);
            setApplicationId(dataApplicationId);
            updateApplicationRoute(data);
          }
        }
      );
    }
  }

  if (error) {
    return (
      <Layout applicationId={applicationId}>
        <Error errorMessage={errorMessage} errorContent={errorContent} errorFromResponse={errorFromResponse} />
      </Layout>
    );
  }

  // Application entry point requires an invitation number or loan id param
  if (notFoundError || (!invitationNumberParam && !loanIdParam && !oldLoanNumberParam)) {
    return (
      <Layout>
        <Error404Page isHeader={false} isFooter={false} />
      </Layout>
    );
  }

  if (pageLoading || !errorContent) {
    return <PageLoader />;
  }

  return (
    <PrefiProvider>
      <Layout applicationId={applicationId}>
        <Routes>
          <Route
            path="/confirmIdentity/*"
            element={(
              <PrefiConfirmIdentity applicationId={applicationId} errors={{ errorTypes, errorContent }} />
            )}
          />
          <Route
            path="/coborrowerExists/*"
            element={(
              <PrefiCoborrowerExists applicationId={applicationId} errors={{ errorTypes, errorContent }} />
            )}
          />
          <Route
            path="/review/*"
            element={(
              <PrefiReview applicationId={applicationId} errors={{ errorTypes, errorContent }} ids={{ invitationNumber: invitationNumberParam, loanId: loanIdParam, oldLoanNumber: oldLoanNumberParam }} />
            )}
          />
          <Route
            path="/personalDetail/*"
            element={(
              <PrefiPersonalDetail applicationId={applicationId} errors={{ errorTypes, errorContent }} isCoborrowerPage={false} />
            )}
          />
          <Route
            path="/employment/*"
            element={(
              <PrefiEmployment applicationId={applicationId} errors={{ errorTypes, errorContent }} isCoborrowerPage={false} />
            )}
          />
          <Route
            path="/otherIncome/*"
            element={(
              <PrefiOtherIncome applicationId={applicationId} errors={{ errorTypes, errorContent }} isCoborrowerPage={false} />
            )}
          />
          <Route
            path="/demographic/*"
            element={(
              <PrefiDemographic applicationId={applicationId} errors={{ errorTypes, errorContent }} isCoborrowerPage={false} />
            )}
          />
          <Route
            path="/languagePreference/*"
            element={(
              <PrefiLanguagePreference applicationId={applicationId} errors={{ errorTypes, errorContent }} isCoborrowerPage={false} />
            )}
          />
          <Route
            path="/declarations/*"
            element={(
              <PrefiDeclarations applicationId={applicationId} errors={{ errorTypes, errorContent }} isCoborrowerPage={false} />
            )}
          />
          <Route
            path="/coborrowerReview/*"
            element={(
              <PrefiCoborrowerReview applicationId={applicationId} errors={{ errorTypes, errorContent }} ids={{ invitationNumber: invitationNumberParam, loanId: loanIdParam, oldLoanNumber: oldLoanNumberParam }} />
            )}
          />
          <Route
            path="/coborrowerPersonalDetail/*"
            element={(
              <PrefiPersonalDetail applicationId={applicationId} errors={{ errorTypes, errorContent }} isCoborrowerPage={true} />
            )}
          />
          <Route
            path="/coborrowerEmployment/*"
            element={(
              <PrefiEmployment applicationId={applicationId} errors={{ errorTypes, errorContent }} isCoborrowerPage={true} />
            )}
          />
          <Route
            path="/coborrowerOtherIncome/*"
            element={(
              <PrefiOtherIncome applicationId={applicationId} errors={{ errorTypes, errorContent }} isCoborrowerPage={true} />
            )}
          />
          <Route
            path="/coborrowerDemographic/*"
            element={(
              <PrefiDemographic applicationId={applicationId} errors={{ errorTypes, errorContent }} isCoborrowerPage={true} />
            )}
          />
          <Route
            path="/coborrowerLanguagePreference/*"
            element={(
              <PrefiLanguagePreference applicationId={applicationId} errors={{ errorTypes, errorContent }} isCoborrowerPage={true} />
            )}
          />
          <Route
            path="/coborrowerDeclarations/*"
            element={(
              <PrefiDeclarations applicationId={applicationId} errors={{ errorTypes, errorContent }} isCoborrowerPage={true} />
            )}
          />
          <Route
            path="/coborrowerConfirmIdentity/*"
            element={(
              <PrefiCoborrowerConfirmIdentity applicationId={applicationId} errors={{ errorTypes, errorContent }} />
            )}
          />
          <Route
            path="/confirmAssets/*"
            element={(
              <PrefiConfirmAssets applicationId={applicationId} errors={{ errorTypes, errorContent }} />
            )}
          />
          <Route
            path="/loanDetail/*"
            element={(
              <PrefiLoanDetail applicationId={applicationId} errors={{ errorTypes, errorContent }} />
            )}
          />
          <Route
            path="/softCredit/*"
            element={(
              <PrefiSoftCredit applicationId={applicationId} errors={{ errorTypes, errorContent }} />
            )}
          />
          <Route
            path="*"
            element={
              <Error404Page isHeader={false} isFooter={false} />
            }
          />
        </Routes>
      </Layout>
    </PrefiProvider>
  );
};
