export const helocProgressList = {
  title: 'My HELOC application',
  currentStep: 0,
  steps: [
    {
      title: 'Application',
    },
    {
      title: 'Pre-approved',
    },
    {
      title: 'Approved',
    },
    {
      title: 'Closing',
    }
  ]
};

export const helocSteps = {
  welcome: 0,
  property: 0,
  occupancy: 0,
  purpose: 0,
  partnership: 0,
  personal: 0,
  ssnPage: 0,
  account: 0,
  income: 0,
  liens: 0,
  mortgage: 0,
  prequalify: 1,
  marital: 1,
  additionalQuestions: 1,
  finalQuestions: 1,
  verify_identity: 1,
  identity_result: 1,
  verify_income: 1,
  income_result: 1,
  deposit: 1,
  debit: 1,
  annual: 1,
  manualReview: 1,
  review: 1,
  identifier: 1,
  finalOffer: 2,
  loanSelected: 2,
  documents: 3,
  scheduleClosing: 3,
  dashboard: 3,
  error: 0
};

export const affordabilityProgressList = {
  title: 'How much can I afford?',
  currentStep: 0,
  steps: [
    {
      title: 'Income',
    },
    {
      title: 'Debt',
    },
    {
      title: 'Down Payment',
    },
    {
      title: 'Credit Score',
    },
    {
      title: 'Military',
    },
    {
      title: 'Officer',
    },
    {
      title: 'Location',
    }
  ]
};
