import Logo from '@guaranteed-rate/react-components/dist/Logo';
import Menu, { IMenuItem } from '../Menu/Menu';
import { logoutHeloc } from '../../config/util/common';

const Header = ({ authData, fetchUserData, logoUrl, isLite = false }: any) => {
  const url = isLite && ((window as any).env === 'stage' || (window as any).env === 'prod') ? ''
    : logoUrl || `/heloc/${isLite ? 'lite/' : ''}`;

  const logout = () => logoutHeloc();

  const menuItems = [{ label: 'Logout', action: logout } as IMenuItem];
  return (
    <div>
      <div className="flex md:hidden">
        <div className="flex-0 flex">
          <Logo url={url} src="/images/brand-logo.svg" alt="Guaranteed Rate, Inc" width={60} height={60} />
        </div>
        <div className="flex-1 flex justify-end">
          { !fetchUserData && (authData?.name?.first
            ? (
              <div className="flex">
                <h3 className="font-bold text-xl">Hello, {authData.name.first}</h3>
                <Menu icon="user-circle" items={menuItems} iconSize="24px" />
              </div>
            ) : (
              <h3 className="font-bold text-xl">
                <a href={`${window.location.origin}/gateway/authorize-heloc-user?tenant-id=${(window as any).tenantId.toLowerCase()}`}>
                  Log In
                </a>
              </h3>
            ))}
        </div>
      </div>
      <div className="hidden md:block w-full">
        <div className="mb-12 flex justify-between w-full">
          <div>
            <Logo url={url} src="/images/brand-logo.svg" alt="Guaranteed Rate, Inc" width={70} height={40} />
          </div>
          { !fetchUserData && (authData?.name?.first
            ? (
              <div className="flex">
                <h3 className="font-bold text-xl">Hello, {authData.name.first}</h3>
                <Menu icon="user-circle" items={menuItems} iconSize="24px" />
              </div>
            ) : (
              <h3 className="font-bold text-xl">
                <a href={`${window.location.origin}/gateway/authorize-heloc-user?tenant-id=${(window as any).tenantId.toLowerCase()}`}>
                  Log In
                </a>
              </h3>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
