/* eslint-disable jsx-a11y/no-static-element-interactions */
import Button from '@guaranteed-rate/react-components/dist/Button';
import Modal from '@guaranteed-rate/react-components/dist/Modal';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import LoanTable from '@guaranteed-rate/react-components/dist/LoanTable';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import { Dispatch, SetStateAction, useEffect, useState, memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import ReviewTable from '../../../components/ReviewTable/ReviewTable';
import { MASKS } from '../../../config/util/masks';
import { getKeyByValue } from '../../../config/util/common';
import GoogleMapAddress from '../../../components/GoogleMapAddress/GoogleMapAddress';
import { IAffordabilityData, IAffordabilityInputResponse, ILeadInput, IMortgageRates } from '../../../config/util/interfaces';
import PageLoader from '../../../components/PageLoader/PageLoader';
import { affordabilityPathMap } from '../AffordabilityPage';

interface IReviewProps {
  reviewData: IReviewData;
  content: any;
  affordabilityData: IAffordabilityData;
  isApplyLoading: boolean;
  leadInput: ILeadInput;
  setAffordabilityData: Dispatch<SetStateAction<IAffordabilityData>>;
  setLeadInput: Dispatch<SetStateAction<ILeadInput>>;
  handleSubmit: (isReload: boolean, page: string, data?: IAffordabilityData) => Promise<any>;
  handleDMXSubmit: (leadInput: ILeadInput) => Promise<any>;
}

export interface IReviewData {
  apr: string;
  estimatedMonthlyPayment: string;
  principalAndInterest: string;
  pmi: string;
  taxes: string;
  totalAmount: string;
  insurance: string;
  loanAmount: string;
  rate: string;
  loanTermsInMonths: string;
  downPayment: string;
  downPaymentPercent: string;
  totalClosingCosts: string;
  discountPoints: {
    closest: string;
  };
  affordabilityInputResponse: IAffordabilityInputResponse;
}

interface IReviewForm {
  income: string;
  other: string;
  autoLoan: string;
  creditCardLoan: string;
  personalLoan: string;
  studentLoan: string;
  otherLoan: string;
  cash: string;
  equity: string;
  creditScore: string;
  isMilitary: string;
  occupancyType: string;
  propertyType: string;
  points: string;
}

interface ILocationForm {
  city: string;
  unitNumber: string;
  zip: string;
  state: string;
  county: string;
}

const makeNum = (val: string | null) => (!val ? 0 : Number(val.replace(/[^0-9.]/g, '')));

function getPurchasePrice(loan: string, cash: string, equity: string) {
  return makeNum(loan) + makeNum(cash) + makeNum(equity);
}

function formatMortgageRates(rates: Array<IMortgageRates>) {
  return rates.map((item) => ({
    ...item,
    apr: makeNum(item?.apr),
    rate: makeNum(item?.rate),
    discountPoints: {
      ...item?.discountPoints,
      cost: makeNum(item?.discountPoints?.cost),
    },
    calculatedMonthlyPayment: makeNum(item?.calculatedMonthlyPayment)
  }));
}

const loadExistingData = (data: IAffordabilityInputResponse, points: string, content: any): IReviewForm => {
  if (!data) {
    return {
      income: '',
      other: '',
      autoLoan: '',
      creditCardLoan: '',
      personalLoan: '',
      studentLoan: '',
      otherLoan: '',
      cash: '',
      equity: '',
      creditScore: '',
      isMilitary: 'NO',
      occupancyType: '',
      propertyType: '',
      points: '',
    };
  }
  return {
    ...data?.affordableDebtInfo,
    income: data?.affordableIncomeInfo?.base,
    other: data?.affordableIncomeInfo?.otherIncome,
    cash: data?.cash,
    equity: data?.equity,
    creditScore: getKeyByValue(content?.edit_modal?.credit_score_values, data?.creditScore) || '',
    isMilitary: data?.isMilitary ? 'YES' : 'NO',
    occupancyType: data?.occupancyType?.toUpperCase(),
    propertyType: getKeyByValue(content?.edit_modal?.property_types, data?.propertyType) || '',
    points,
  };
};

const findCreditScoreLabel = (scores: any, creditScore: any) => {
  if (!scores) {
    return '';
  }
  return scores[creditScore.toLowerCase()];
};

const formatNumber = (value: string) => (!value ? 0 : parseInt(value));

const formatDropdown = (list: Array<any>) => list.map((item: any) => ({ displayName: item.option.label, value: item.option.value }));

const EditModalSectionTitle = (props: { title: string }) => <div className="text-[24px] md:text-[30px] font-bold py-1">{props.title}</div>;

const isErrorProne = (reviewForm: IReviewForm, locationForm: ILocationForm) => {
  const income = reviewForm?.income ? parseInt(reviewForm?.income) : 0;
  const cash = reviewForm?.cash ? parseInt(reviewForm?.cash) : 0;
  return income > 0 && cash > 0 && locationForm?.city && locationForm?.zip;
};

function loadExistingLocationData(data: any): ILocationForm {
  if (!data) {
    return { city: '', unitNumber: '', zip: '', state: '', county: '' };
  }
  return {
    city: data?.city,
    unitNumber: data?.unitNumber,
    zip: data?.zip,
    state: data?.state || '',
    county: data?.county || ''
  };
}

interface IPaymentDetails {
  content: any;
  reviewData: IReviewData;
  showDetails: boolean;
  setShowDetails: Dispatch<SetStateAction<boolean>>;
}

interface ILoanDetails {
  content: any;
  reviewData: IReviewData;
}

const PaymentDetails = memo(({ content, reviewData, showDetails, setShowDetails }: IPaymentDetails) => (
  <ReviewTable
    header={content.payment_header}
    labels={[content.principal_label, content.pmi_label,
      content.property_taxes_label, content.home_insurance_label]}
    values={[reviewData?.principalAndInterest, reviewData?.pmi,
      reviewData?.taxes, reviewData?.insurance]}
    showDetails={showDetails}
    percent={50}
    setShowDetails={setShowDetails}
    tooltip={content.affordability_scale_info}
  />
));

const LoanDetails = memo(({ content, reviewData }: ILoanDetails) => (
  <ReviewTable
    header={content.details_header}
    labels={[content.amount_label, content.interest_label, content.term_label,
      content.down_payment_label, content.closing_costs_label, content.points_label]}
    values={[reviewData?.loanAmount, `${reviewData?.rate} / ${reviewData?.apr}`,
      reviewData?.loanTermsInMonths, `${reviewData?.downPayment} (${reviewData?.downPaymentPercent})`,
      reviewData?.totalClosingCosts, reviewData.discountPoints?.closest]}
    showDetails={true}
  />
));

export const AffordabilityReviewPage = ({
  reviewData,
  content,
  affordabilityData,
  leadInput,
  isApplyLoading,
  setAffordabilityData,
  setLeadInput,
  handleSubmit,
  handleDMXSubmit,
}: IReviewProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);
  const [isModalOpen, setModalOpen] = useState(location.state?.isEditModalOpen || false);
  const [isTableOpen, setTableOpen] = useState(location.state?.isRatesTableOpen || false);
  const [reviewForm, setReviewForm] = useState<IReviewForm>(loadExistingData(
    reviewData?.affordabilityInputResponse,
    reviewData?.discountPoints.closest,
    content
  ));
  const [locationForm, setLocationForm] = useState<ILocationForm>(
    loadExistingLocationData(reviewData?.affordabilityInputResponse)
  );
  const [trySubmit, setTrySubmit] = useState(false);
  const [rates, setRates] = useState(null as any);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isDisclaimerOpen, toggleMortgageDisclaimer] = useState(false);
  const [latestProductDate, setLatestProductDate] = useState('');
  const [isRatesLoading, setRatesLoading] = useState(false);
  const [ratesError, setRatesError] = useState('');

  const urlParams = new URLSearchParams(window.location.search);

  const inputValues = Object.values(reviewData?.affordabilityInputResponse).toString();

  const onAddressChange = (address: any, type: string) => {
    if (trySubmit) {
      setTrySubmit(false);
    }
    if (type === 'locality') {
      setLocationForm({
        city: address?.city,
        unitNumber: address?.unitNumber,
        zip: address?.zip || locationForm.zip,
        state: address?.state || '',
        county: address?.county || ''
      });
    } else {
      setLocationForm({
        zip: address?.zip,
        city: address?.city,
        unitNumber: address?.unitNumber,
        state: address?.state || '',
        county: address?.county || ''
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '',) => {
    if (trySubmit) {
      setTrySubmit(false);
    }
    setReviewForm({ ...reviewForm, [name || event.target.name]: event.target.value });
  };

  const handleUpdate = async () => {
    setTrySubmit(true);
    if (isErrorProne(reviewForm, locationForm)) {
      const updatedData: IAffordabilityData = {
        affordableIncomeData: {
          base: reviewForm.income,
          bonus: affordabilityData?.affordableIncomeData?.bonus ?? '',
          overTime: affordabilityData?.affordableIncomeData?.overTime ?? '',
          otherIncome: reviewForm.other,
          isMonthly: true
        },
        affordableDebtData: {
          autoLoan: reviewForm.autoLoan,
          creditCardLoan: reviewForm.creditCardLoan,
          personalLoan: reviewForm.personalLoan,
          studentLoan: reviewForm.studentLoan,
          otherLoan: reviewForm.otherLoan,
          isMonthly: true,
        },
        cash: reviewForm.cash,
        equity: reviewForm.equity,
        isHomeOwner: affordabilityData?.isHomeOwner ?? false,
        formattedAddress: affordabilityData?.formattedAddress ?? '',
        unitNumber: locationForm.unitNumber,
        city: locationForm.city,
        zip: locationForm.zip,
        state: locationForm.state,
        county: locationForm.county,
        isMilitary: reviewForm.isMilitary === 'YES',
        creditScore: content?.edit_modal?.credit_score_values[reviewForm.creditScore.toLowerCase()],
        occupancyType: reviewForm.occupancyType.toLowerCase() || 'PRIMARY',
        propertyType: reviewForm.propertyType.toLowerCase() || 'Attached',
        points: reviewForm.points
      };
      setUpdateLoading(true);
      setAffordabilityData(updatedData);
      await handleSubmit(false, 'review', updatedData);
      setUpdateLoading(false);
    }
  };

  const toggleModal = () => {
    if (trySubmit) {
      setTrySubmit(false);
    }
    setModalOpen(!isModalOpen);
  };

  const toggleTable = () => {
    setTableOpen(!isTableOpen);
  };

  const fetchRates = async () => {
    if (ratesError) {
      setRatesError('');
    }
    setRatesLoading(true);
    const uuid = uuidv4();
    const mutation = {
      query: `{
        fetchMortgageRates(
          mortgageRateInput: { 
        cashoutAmount: "0"
        loanPurpose: "Purchase",
        sourceID: "SuperApp",
        zipCode: "${reviewData?.affordabilityInputResponse?.zip}",
        isMilitary: ${reviewData?.affordabilityInputResponse?.isMilitary},
        purchasePrice: "${getPurchasePrice(reviewData?.loanAmount,
        reviewData?.affordabilityInputResponse?.cash,
        reviewData?.affordabilityInputResponse?.equity)}",
        baseLoanAmount: "${makeNum(reviewData?.loanAmount)}",
        creditScore: "${content?.edit_modal?.credit_score_values[reviewForm.creditScore.toLowerCase()]}",
        waiveEscrows : false,
        propertyType: "${reviewForm.propertyType
          ? content?.edit_modal?.property_types[reviewForm.propertyType.toLowerCase()] : 'Attached'}",
        propertyUse: "${content?.edit_modal?.occupancy_types[reviewForm.occupancyType.toLowerCase() || 'PrimaryResidence']}"
      }) 
      {
        rates {
        rate
        apr
        productId
        armFixedPeriodMonths
        margin
        monthlyMi
        totalClosingCosts
        pmtPrincipalAndInterest
        label
        rebate
        loanType
        fees
        lastUpdated
        discountDollarAmount
        totalPayment
        calculatedMonthlyPayment
        discountPoints{
        cost
        raw
        closest
        }
        }
        latestProductUpdatedDate
    }
    }`
    };
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      setRatesLoading(false);
      if (data?.data?.fetchMortgageRates?.rates) {
        setRates(formatMortgageRates(data?.data?.fetchMortgageRates?.rates));
        setLatestProductDate(data?.data?.fetchMortgageRates?.latestProductUpdatedDate);
      } else {
        setRates(null);
        setLatestProductDate('');
        console.error(data?.errors[0]?.message);
        setRatesError(data?.errors[0]?.message);
        console.log('error retrieving data');
      }
    } catch (e) {
      setRatesLoading(false);
      console.log('error retrieving data');
    }
  };

  const navigateProgress = async (isMortageRate = false) => {
    const downPayment = makeNum(reviewData?.affordabilityInputResponse?.cash)
    + makeNum(reviewData?.affordabilityInputResponse?.equity);
    const loanAmount = makeNum(reviewData?.loanAmount);
    const input = {
      estimatedHousePrice: downPayment + loanAmount,
      downPayment,
      loanAmount,
      cashOutAmount: 0,
      creditProfile: findCreditScoreLabel(content?.self_selected_credit_score, reviewForm.creditScore) || '',
      firstName: leadInput?.firstName || '',
      lastName: leadInput?.lastName || '',
      email: leadInput?.email || '',
      phone: leadInput?.phone || '',
      propertyType: 'single-family',
      isMilitary: affordabilityData?.isMilitary || reviewData?.affordabilityInputResponse?.isMilitary,
      postalCode: affordabilityData?.zip || reviewData?.affordabilityInputResponse?.zip,
      city: affordabilityData?.city || locationForm.city,
      state: affordabilityData?.state || locationForm.state,
      county: affordabilityData?.county || locationForm.county,
    };
    setLeadInput(input);
    if (!isMortageRate) {
      navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
        ?? '/home-affordability/'}saveProgress/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
    } else {
      handleDMXSubmit(input);
    }
  };

  useEffect(() => {
    if (inputValues) {
      fetchRates();
    }
  }, [inputValues]);

  useEffect(() => {
    if (trySubmit && isErrorProne(reviewForm, locationForm) && !updateLoading) {
      setModalOpen(false);
    }
  }, [trySubmit, updateLoading]);

  if (!content) {
    return <PageLoader />;
  }

  return updateLoading ? <PageLoader /> : (
    <div className="md:flex justify-center">
      <div className="md:max-w-2xl md:grow">
        <h2 className="text-[32px] md:text-[48px] font-extrabold pt-4 md:pt-8 md:leading-[4rem]">
          {content.header.replace('%AMOUNT', '')}
          <span
            role="button"
            tabIndex={0}
            className="border-b-[3px] border-black mb-1 hover:border-b-4"
            onClick={toggleModal}
            id="edit-btn"
          >
            <span>{reviewData.totalAmount}</span>
            <span>
              <img
                className="ml-1 mb-2 md:mb-3 h-[26px] md:h-[40px] inline !leading-loose cursor-pointer"
                src="/images/edit.svg"
                alt="edit-icon"
              />
            </span>
          </span>
        </h2>
        <div className="min-h-[400px] mb-8">
          <h3 className="mb-4 md:mb-8 font-bold text-xl md:text-2xl mt-4 md:mt-0">
            {content.description.replace('%AMOUNT', reviewData.estimatedMonthlyPayment)}
          </h3>
          <div className="flex justify-center mb-4">
            <PaymentDetails content={content} showDetails={showDetails} setShowDetails={setShowDetails} reviewData={reviewData} />
          </div>
          <div className="flex justify-center">
            <LoanDetails content={content} reviewData={reviewData} />
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <Button
            buttonStyle="primary"
            className="!w-full md:!w-48 primary-btn"
            onClick={() => navigateProgress(false)}
          >{content.cta_label}
          </Button>
        </div>
        <div className="flex justify-center mt-4">
          <Button
            buttonStyle="tertiary"
            iconPos="left"
            iconName=""
            buttonSize="medium"
            className="secondary-btn"
            onClick={toggleTable}
          >{content.secondary_cta}
          </Button>
        </div>
        <div className="flex mt-8">
          <h4 className="ml-4">{content.disclaimer}</h4>
        </div>
        {
          isTableOpen ? (
            <Modal
              className={`hac-rate ${rates ? 'rates-visible' : ''} ${isDisclaimerOpen ? 'disclaimer-visible' : ''}`}
              title=""
              isOpen={isTableOpen}
              toggleModal={toggleTable}
              clickOffToClose={true}
            >
              <div>
                <h2 className="text-[32px] md:text-[48px] font-extrabold">{content.table_modal.header}</h2>
                <h4 className="py-8">{content.table_modal.description}</h4>
                {
                  rates !== null
                    ? <LoanTable rates={rates} />
                    : isRatesLoading ? (
                      <div className="mb-8 flex justify-center xl:ml-4 xl:block">
                        <Loader color="black" className="loader-small" />
                      </div>
                    ) : <p className="text-sm font-semibold">{ratesError}</p>
                }
                <div
                  className="flex flex-col justify-start"
                >
                  {isDisclaimerOpen && (
                    <div id="disclaimer" className="mt-6 whitespace-pre-line">
                      {content?.table_modal?.mortgage_rates_disclaimers_description}
                    </div>
                  )}
                  <div className="mt-6">
                    <Button
                      buttonStyle="tertiary"
                      className="toggle-btn"
                      iconName=""
                      onClick={() => toggleMortgageDisclaimer(!isDisclaimerOpen)}
                      buttonSize="medium"
                    >
                      {content?.table_modal?.mortgage_rates_disclaimers_title}
                      <FontIcon
                        iconName={`chevron-${isDisclaimerOpen ? 'up' : 'down'}-small`}
                        className="ml-1 font-bold !leading-6 text-[16px] md:text-[20px]"
                      />
                    </Button>
                  </div>
                  {latestProductDate !== '' && (
                    <p className="text-sm my-2">
                      {content?.mortgage_rates_hint_label.replace('current_datetime', latestProductDate)}
                    </p>
                  )}
                </div>
                <div className="flex justify-center mt-4">
                  <Button
                    buttonStyle="primary"
                    className="!w-full md:!w-48 apply-btn"
                    onClick={() => navigateProgress(false)}
                    loading={isApplyLoading}
                    disabled={isApplyLoading}
                    buttonAttrs={{ disabled: isApplyLoading }}
                  >{content.cta_label}
                  </Button>
                </div>
              </div>
            </Modal>
          ) : null
        }
        {isModalOpen ? (
          <Modal title="" isOpen={isModalOpen} toggleModal={toggleModal} clickOffToClose={true}>
            <div>
              <h2 className="text-[32px] md:text-[48px] font-extrabold">{content.edit_modal.header}</h2>
              <h4 className="py-8">{content.edit_modal.description}</h4>
              <div className="md:flex md:space-x-4">
                <div className="grow">
                  <EditModalSectionTitle title={content.edit_modal.income_section_label} />
                  <TextInput
                    name="income"
                    value={reviewForm.income}
                    label={content.edit_modal.income_label}
                    onChange={(input) => handleChange(input, 'income')}
                    mask={MASKS.CURRENCY}
                    hasError={trySubmit && formatNumber(reviewForm.income) <= 0}
                    helperText={trySubmit && formatNumber(reviewForm.income) <= 0 ? 'required' : undefined}
                    required
                  />
                  <TextInput
                    name="other"
                    value={reviewForm.other}
                    label={content.edit_modal.other_label}
                    onChange={(input) => handleChange(input, 'other')}
                    mask={MASKS.CURRENCY}
                  />
                  <EditModalSectionTitle title={content.edit_modal.debt_section_label} />
                  <TextInput
                    name="autoLoan"
                    value={reviewForm.autoLoan}
                    label={content.edit_modal.auto_loan_label}
                    onChange={(event) => handleChange(event, 'autoLoan')}
                    mask={MASKS.CURRENCY}
                  />
                  <TextInput
                    name="creditCardLoan"
                    value={reviewForm.creditCardLoan}
                    label={content.edit_modal.credit_card_loan_label}
                    onChange={(event) => handleChange(event, 'creditCardLoan')}
                    mask={MASKS.CURRENCY}
                  />
                  <TextInput
                    name="personalLoan"
                    value={reviewForm.personalLoan}
                    label={content.edit_modal.personal_loan_label}
                    onChange={(event) => handleChange(event, 'personalLoan')}
                    mask={MASKS.CURRENCY}
                  />
                  <TextInput
                    name="studentLoan"
                    value={reviewForm.studentLoan}
                    label={content.edit_modal.student_loan_label}
                    onChange={(event) => handleChange(event, 'studentLoan')}
                    mask={MASKS.CURRENCY}
                  />
                  <TextInput
                    name="otherLoan"
                    value={reviewForm.otherLoan}
                    label={content.edit_modal.other_loan_label}
                    onChange={(event) => handleChange(event, 'otherLoan')}
                    mask={MASKS.CURRENCY}
                  />
                  <EditModalSectionTitle title={content.edit_modal.down_payment_section_label} />
                  <TextInput
                    name="cash"
                    value={reviewForm.cash}
                    label={content.edit_modal.cash_label}
                    onChange={(input) => handleChange(input, 'cash')}
                    mask={MASKS.CURRENCY}
                    hasError={trySubmit && formatNumber(reviewForm.cash) <= 0}
                    helperText={trySubmit && formatNumber(reviewForm.cash) <= 0 ? 'required' : undefined}
                    required
                  />
                  <TextInput
                    name="equity"
                    value={reviewForm.equity}
                    label={content.edit_modal.hoa_label}
                    onChange={(input) => handleChange(input, 'equity')}
                    mask={MASKS.CURRENCY}
                  />
                </div>
                <div className="grow md:max-w-[49%]">
                  <EditModalSectionTitle title={content.edit_modal.credit_score_section_label} />
                  <Dropdown
                    name="creditScore"
                    value={reviewForm.creditScore}
                    label={content.edit_modal.credit_score_label}
                    onChange={(event) => handleChange(event, 'creditScore')}
                    className="w-full"
                    options={formatDropdown(content.credit_score)}
                  />
                  <EditModalSectionTitle title={content.edit_modal.military_section_label} />
                  <Dropdown
                    name="isMilitary"
                    value={reviewForm.isMilitary}
                    label={content.edit_modal.military_label}
                    onChange={(event) => handleChange(event, 'isMilitary')}
                    className="w-full"
                    options={formatDropdown(content.military)}
                  />
                  <EditModalSectionTitle title={content.edit_modal.property_section_label} />
                  <GoogleMapAddress
                    onChange={onAddressChange}
                    value={locationForm.city}
                    addressObj={locationForm}
                    label={content.edit_modal.city_or_town_label}
                    type="locality"
                    hasError={trySubmit && !locationForm.city}
                    required
                    name="city"
                  />
                  <Dropdown
                    name="occupancyType"
                    value={reviewForm.occupancyType}
                    label={content.edit_modal.property_use_label}
                    onChange={(event) => handleChange(event, 'occupancyType')}
                    className="w-full"
                    options={formatDropdown(content.property_use)}
                  />
                  <Dropdown
                    name="propertyType"
                    value={reviewForm.propertyType}
                    label={content.edit_modal.property_type_label}
                    onChange={(event) => handleChange(event, 'propertyType')}
                    className="w-full"
                    options={formatDropdown(content.property_type)}
                  />
                  <GoogleMapAddress
                    onChange={onAddressChange}
                    value={locationForm.zip}
                    addressObj={locationForm}
                    label={content.edit_modal.zip_label}
                    type="postal_code"
                    hasError={trySubmit && !locationForm.zip}
                    required
                  />
                  <Dropdown
                    name="points"
                    value={reviewForm.points}
                    label={content.edit_modal.points_label}
                    onChange={(event) => handleChange(event, 'points')}
                    className="w-full pointer-events-none text-slate-200"
                    options={formatDropdown(content.points)}
                  />
                  <h4 className="font-bold mb-2">{content.edit_modal.point_header}</h4>
                  <p>{content.edit_modal.point_description}</p>
                </div>
              </div>
              <div className="flex justify-center mt-12">
                <Button
                  buttonStyle="primary"
                  className="!w-full md:!w-48 save-btn"
                  onClick={handleUpdate}
                  disabled={updateLoading}
                  buttonAttrs={{ disabled: updateLoading }}
                >{content.edit_modal.update_cta}
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
      </div>
    </div>
  );
};
