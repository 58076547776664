export type ApiRequestStatus = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface ApiRequestState<TResponse> {
  status: ApiRequestStatus;
  errorMessage?: string;
  response?: TResponse;
}

export const apiReset = (): ApiRequestState<any> => ({
  status: 'idle',
  errorMessage: undefined,
  response: undefined,
});

export const apiSuccess = <TResponse>(currentState: ApiRequestState<TResponse>, response: TResponse): ApiRequestState<TResponse> => ({
  ...currentState,
  status: 'succeeded',
  errorMessage: undefined,
  response
});

export const apiFailed = <TResponse>(currentState: ApiRequestState<TResponse>, errorMessage: string): ApiRequestState<TResponse> => ({
  ...currentState,
  status: 'failed',
  errorMessage,
  response: undefined,
});
