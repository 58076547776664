/* eslint-disable max-len */
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import Form from '../../../components/Form/Form';

interface IHelocManualReview {
    progress: any;
}

const HelocManualReview = (props: IHelocManualReview) => (
  <Form title="Your application is in manual review" progress={props.progress}>
    <div className="manualReview-info min-h-[300px]">
      <FontIcon className="text-8xl" iconName="doc-long" />
      <p className="mt-8 text-center">Thank you for submitting your application! Your application is now under review by our team. You can expect to hear back from us over the next few business days.</p>
    </div>
  </Form>
);

export default HelocManualReview;
