import Loader from '@guaranteed-rate/react-components/dist/Loader';

function PageLoader() {
  return (
    <div className="min-h-[420px] flex items-center justify-center">
      <Loader color="#D13239" className="loader-medium" />
    </div>
  );
}

export default PageLoader;
