/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import { useCallback, useEffect, useState } from 'react';
import Form from '../../../components/Form/Form';
import { MASKS } from '../../../config/util/masks';
import { IBasicInfoData, IPropertyResponse } from '../../../config/util/interfaces';
import { formatRichText, getDateAfterOrBeforeYear, getDOBErrorText, loadDate } from '../../../config/util/common';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { HelocAccountPage } from '../HelocAccountPage/HelocAccountPage';
import CalendarInput from '../../../components/CalendarInput/CalendarInput';

interface ISSNProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  basicInfoData: IBasicInfoData;
  setGlobalLoading: any;
  propertyInfoData: IPropertyResponse;
  progress?: ProgressProps;
  isLite?: boolean;
  appId: string;
}

export const HelocSSNPage = (props: ISSNProps) => {
  const [helocForm, setHelocForm] = useState({ birthday: '', ssn: '', lastFourSSN: '' });
  const [trySubmit, setTrySubmit] = useState(false);
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const [showSSN, setShowSSN] = useState(false);
  const [showAccountScreen, setShowAccountScreen] = useState(false);
  const [showCreditScreen, setShowCreditScreen] = useState(false);
  const [creditChecked, setCreditChecked] = useState(false);

  // used to save the identifier and birthdate to the orchestrator and move to next screen
  const handleSubmit = async () => {
    setTrySubmit(true);
    if (canSubmit()) {
      window.scrollTo(0, 0);
      if (props.isLite) {
        setShowCreditScreen(true);
      } else {
        setShowAccountScreen(true);
      }
    }
  };

  const handleCreditSubmit = () => {
    setShowAccountScreen(true);
  };

  const handleCreditBack = () => {
    setShowCreditScreen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setHelocForm({ ...helocForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  const handleBack = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      ${props.isLite ? 'lite: true' : ''}
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };

  // verifies the users age
  const isValidDate = useCallback(() => !getDOBErrorText(helocForm.birthday), [helocForm.birthday]);
  const validSSNDataCaptured = () => (props.isLite ? helocForm.ssn.length === 9 : helocForm.lastFourSSN.length === 4);

  const canSubmit = () => validSSNDataCaptured() && helocForm.birthday.length > 0 && isValidDate();

  return (
    <div>
      { showAccountScreen
        ? (
          <HelocAccountPage
            handleSubmit={props.handleSubmit}
            content={props.content.account}
            pageName={props.pageName}
            basicInfoData={props.basicInfoData}
            setGlobalLoading={props.setGlobalLoading}
            propertyInfoData={props.propertyInfoData}
            form={helocForm}
            progress={props.progress}
            isLite={props.isLite}
            setShowAccount={setShowAccountScreen}
            appId={props.appId}
          />
        )
        : showCreditScreen
          ? (
            <Form title={props.content.credit.header} progress={props.progress}>
              <div className="min-h-[300px]">
                <h4 className="mb-8">{props.content.credit.description}</h4>
                <div>
                  <div className="flex flex-row mb-4">
                    <div>
                      <input
                        type="checkbox"
                        className="checkbox"
                        checked={creditChecked}
                        onChange={() => setCreditChecked(!creditChecked)}
                      />
                    </div>
                    <h4>
                      <div className="text-sm text-left">
                        {
                          formatRichText('credit', props.content.credit.checkbox_content, props.content.credit)
                        }
                      </div>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-12">
                <Button
                  buttonStyle="primary"
                  className="!w-full md:!w-48 nextButton"
                  onClick={handleCreditSubmit}
                  buttonAttrs={{ disabled: !creditChecked || loading }}
                  loading={loading}
                >Confirm
                </Button>
              </div>
              <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                <Button
                  buttonStyle="quaternary"
                  iconPos="left"
                  iconName="chevron-left-large"
                  onClick={handleCreditBack}
                  buttonAttrs={{ disabled: loading }}
                >Back
                </Button>
              </div>
            </Form>
          )
          : (
            <Form title={props.content.header} progress={props.progress}>
              <div className="min-h-[300px]">
                {props.isLite ? (
                  <h4 className="mb-4">{props.content.description}</h4>
                ) : (
                  <h4 className="mb-4">{props.content.heloc_description}</h4>
                )}
                <div className="md:grid md:grid-cols-2 md:space-x-4">
                  <div onClick={(event: any) => event.target.className.indexOf('eye') !== -1 && setShowSSN(!showSSN)}>
                    {props.isLite ? (
                      <TextInput
                        name="ssn"
                        value={helocForm.ssn}
                        label={props.content.ssn_field_label}
                        mask={showSSN ? MASKS.SSN : MASKS.SSN_PW}
                        type="password"
                        onChange={(input) => handleChange(input, 'ssn')}
                        hasError={trySubmit && helocForm.ssn.length < 9}
                        helperText={trySubmit && helocForm.ssn.length < 9 ? 'Please enter a valid ssn' : ''}
                      />
                    ) : (
                      <TextInput
                        name="lastFourSSN"
                        value={helocForm.lastFourSSN}
                        label={props.content.last_four_digit_ssn_label}
                        type="password"
                        onChange={(input) => handleChange(input, 'lastFourSSN')}
                        hasError={trySubmit && helocForm.lastFourSSN.length < 4}
                        helperText={trySubmit && helocForm.lastFourSSN.length < 4 ? 'Please enter a valid 4 digit ssn' : ''}
                        maxLength={4}
                      />
                    )}
                  </div>
                  <div>
                    <CalendarInput
                      label={props.content.birthday_label}
                      className="w-full birthdayInput"
                      name="birthday"
                      form={helocForm}
                      setForm={setHelocForm}
                      hasError={trySubmit && !isValidDate()}
                      helperText={getDOBErrorText(helocForm.birthday)}
                      id="date"
                      required={true}
                    />
                  </div>
                </div>
                <p className="text-xs">{props.content.disclaimer}</p>
              </div>
              <div className="flex justify-center mt-12">
                <Button
                  buttonStyle="primary"
                  className="!w-full md:!w-64 confirmButton"
                  onClick={handleSubmit}
                  buttonAttrs={{ disabled: loading }}
                  loading={loading}
                >I agree and continue
                </Button>
              </div>
              <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                <Button
                  buttonStyle="quaternary"
                  iconPos="left"
                  iconName="chevron-left-large"
                  onClick={handleBack}
                  buttonAttrs={{ disabled: loading }}
                >Back
                </Button>
              </div>
              <div className="text-center pt-12 ">
                <p>{props.content.income_description}</p>
                <p>{props.content.other_income_description}</p>
              </div>
            </Form>
          )}
    </div>
  );
};
