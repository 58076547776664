import { ReactNode } from 'react';
import Img from '@guaranteed-rate/react-components/dist/Img';

interface ICardProps {
  children: ReactNode;
  title?: string;
  titlecolor?: string;
  titleimage?: string;
  titleimageAlt?: string;
  titleimageoffset?: number;
  className?: string;
  [x: string]: any;
}

const Card = (props: ICardProps) => {
  const { children, ...rest } = props;
  return (
    <div {...rest} className={`rounded-xl border-slate-300 border-2 w-full ${props.className ? props.className : ''}`}>
      {props.title && (
        <div>
          {props.titleimage && (
            <div className="flex justify-end mr-[5%]">
              <Img
                src={props.titleimage}
                alt={props.titleImageAlt || ''}
                width={216}
                height={124}
                className="absolute -mt-[22px]"
              />
            </div>
          )}
          <div className={`${props.titlecolor} h-24 rounded-t-xl`}>
            <h4 className="text-3xl font-bold text-white py-7 px-7">{props.title}</h4>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
