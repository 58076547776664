import Button from '@guaranteed-rate/react-components/dist/Button';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import React, { useState } from 'react';

interface IAlertProps {
  front: boolean;
  fileId: string;
  imageSrc: string;
  className?: string;
}

const UploadButton = (props: IAlertProps) => (
  <div className={props.className}>
    <label htmlFor={props.fileId}>
      <div className="border-gray-300 border-2 rounded-lg p-4 text-center cursor-pointer flex justify-center flex-col w-[240px] relative">
        <div>
          { (props.imageSrc !== '' && props.imageSrc.includes('image'))
            ? <img src={props.imageSrc} alt="uploaded document" />
            : (props.front || !props.imageSrc.includes('image')) ? (
              <div className="flex w-32 h-16 border-gray-300 border-4 rounded-lg m-8">
                <FontIcon className="ml-2 mt-2 text-4xl text-gray-300" iconName="user" />
                <div>
                  <span className="ml-4 mt-4 flex w-12 border-gray-300 border-2" />
                  <span className="ml-4 mt-2 flex w-12 border-gray-300 border-2" />
                </div>
              </div>
            ) : (
              <div className="flex w-32 h-16 border-gray-300 border-4 rounded-lg m-8">
                <span className="ml-2 mt-2 w-[1px] h-5 flex border-gray-300 border-2" />
                <span className="ml-1 mt-2 w-[1px] h-5 flex border-gray-300 border-2" />
                <span className="ml-3 mt-2 w-[1px] h-5 flex border-gray-300 border-2" />
                <span className="ml-1 mt-2 w-[1px] h-5 flex border-gray-300 border-2" />
                <span className="ml-1 mt-2 w-[1px] h-5 flex border-gray-300 border-2" />
                <span className="ml-3 mt-2 w-[1px] h-5 flex border-gray-300 border-2" />
                <span className="ml-3 mt-2 w-[1px] h-5 flex border-gray-300 border-2" />
                <span className="ml-1 mt-2 w-[1px] h-5 flex border-gray-300 border-2" />
                <span className="ml-1 mt-2 w-[1px] h-5 flex border-gray-300 border-2" />
                <span className="ml-1 mt-2 w-[1px] h-5 flex border-gray-300 border-2" />
                <span className="ml-1 mt-2 w-[1px] h-5 flex border-gray-300 border-2" />
              </div>
            )}
        </div>
        <h4 className="tertiary font-bold">Browse files or take a photo</h4>
        <p>{props.front ? '(Front)' : '(Back)'}</p>
      </div>
    </label>
  </div>
);

export default UploadButton;
