/* eslint-disable max-len */
import Img from '@guaranteed-rate/react-components/dist/Img';

function OwningError(props: any) {
  const content = props.pageContent;
  return (
    <div>
      <div className="flex flex-col justify-center text-center">
        <div className="flex justify-center mt-36 mb-4 w-full">
          <Img
            src="/images/ErrorWarning.png"
            alt="errorwarning"
            width={112}
            height={0}
          />
        </div>
        <h2 className="font-bold">Error Message</h2>
        <h4>Unable to process request at this time. Please try again later.</h4>
      </div>
    </div>
  );
}

export default OwningError;
