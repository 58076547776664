import Button from '@guaranteed-rate/react-components/dist/Button';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import { useState } from 'react';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { IAdditionalInfo } from '../../../config/util/interfaces';

interface IQuestionProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: Array<any>;
  pageName: string;
  progress?: ProgressProps;
  additionalInfo: IAdditionalInfo;
  setAdditionalInfo: any;
  isLite?: boolean;
}

interface IAdditionalInfoForm {
  gender: string;
  ethnicity: string;
  race: string;
}

export const HelocAdditionalQuestions = (props: IQuestionProps) => {
  const [loading, setLoading] = useState(false);
  const [helocForm, setHelocForm] = useState({
    gender: '',
    ethnicity: '',
    race: ''
  } as IAdditionalInfoForm);
  const [other, setOther] = useState('');

  // used to navigate between the gender, ethnicity and race questions
  const getStepByTitle = (title: string) => {
    let stepIndex = 0;
    props.content.map((content: any, index) => (content.title === title ? stepIndex = index : undefined));
    return stepIndex;
  };

  // start at the gender screen
  const [step, setStep] = useState(getStepByTitle('Gender_Page'));

  // renders the options for the screen based on what page you are on
  const options = props.content[step].radio_button.map((item: any) => ({
    name: 'options',
    label: item.option.label,
    value: item.option.value,
    active: false
  }));

  // save the value to the correct field based on what page you are on.
  // navigate to the next page based on the value selected.
  const handleSelect = async (value: string) => {
    if (value.length > 0) {
      switch (props.content[step].title) {
      case 'Gender_Page':
        props.setAdditionalInfo({ ...props.additionalInfo, gender: value });
        setHelocForm({ ...helocForm, gender: value });
        setStep(getStepByTitle('Hispanic_Page'));
        break;
      case 'Hispanic_Page':
        if (value === 'HISPANIC') {
          props.setAdditionalInfo({ ...props.additionalInfo, ethnicity: value });
          setStep(getStepByTitle('Hispanic_Detail_Page'));
        } else {
          props.setAdditionalInfo({ ...props.additionalInfo, ethnicity: value });
          setHelocForm({ ...helocForm, ethnicity: value });
          setStep(getStepByTitle('Race_Page'));
        }
        break;
      case 'Hispanic_Detail_Page':
        props.setAdditionalInfo({ ...props.additionalInfo, ethnicityDetail: value });
        setHelocForm({ ...helocForm, ethnicity: value });
        setStep(getStepByTitle('Race_Page'));
        break;
      case 'Race_Page':
        props.setAdditionalInfo({ ...props.additionalInfo, race: value });
        if (value === 'HAWAIIAN_ISLANDER') {
          setStep(getStepByTitle('Islander_Page'));
        } else if (value === 'ASIAN') {
          setStep(getStepByTitle('Asian_Page'));
        } else {
          setHelocForm({ ...helocForm, race: value });
          handleSubmit(value);
        }
        break;
      case 'Asian_Page':
        props.setAdditionalInfo({ ...props.additionalInfo, asianDetail: value });
        setHelocForm({ ...helocForm, race: value });
        handleSubmit(value);
        break;
      case 'Islander_Page':
        props.setAdditionalInfo({ ...props.additionalInfo, islanderDetail: value });
        setHelocForm({ ...helocForm, race: value });
        handleSubmit(value);
        break;
      default:
        break;
      }
      setOther('');
    }
  };

  // since the data is stored in a global additional info object, we just call this to move to the next page
  const handleSubmit = async (race: string) => {
    const pageData = `{
      page: "${props.pageName}"
      ${props.isLite ? 'lite: true' : ''}
    }`;
    await props.handleSubmit(pageData, false);
  };

  // go to previous screen
  const handleBack = async () => {
    const pageData = `{ 
      page: "${props.pageName}"
      ${props.isLite ? 'lite: true' : ''}
      back: true
    }`;
    await props.handleSubmit(pageData, true);
  };

  return (
    <Form title={props.content[step].header} progress={props.progress}>
      { loading
        ? (
          <div className="min-h-[420px] flex items-center justify-center">
            <Loader color="#D13239" className="loader-medium" />
          </div>
        )
        : (
          <div>
            <div className="min-h-[300px]">
              <h4>{props.content[step].prompt}</h4>
              <h4 className="font-bold mt-4 mb-4">Select one of the following:</h4>
              <div className="mb-12">
                <div className="flex justify-center w-full">
                  <div className="w-full max-w-md">
                    <RadioButtons className="radioButton" radios={options} onChange={(event) => handleSelect(event.target.value)} />
                  </div>
                </div>
              </div>
              {props.content[step].input_label.length > 0
          && (
            <div>
              <h4 className="font-bold -mt-12 mb-4">Or</h4>
              <TextInput
                name="other"
                value={other}
                label={props.content[step].input_label}
                onChange={(event) => setOther(event.target.value)}
              />
              <div className="flex justify-center mt-4">
                <Button
                  name="nextButton"
                  buttonStyle="primary"
                  className="!w-full md:!w-48 nextButton"
                  onClick={() => handleSelect(other)}
                  buttonAttrs={{ disabled: other.length === 0 }}
                >Next
                </Button>
              </div>
            </div>
          )}
            </div>
            <div className="flex justify-center mt-8 -ml-8 mb-4 md:block md:mb-8 md:ml-0">
              <Button buttonStyle="quaternary" iconPos="left" iconName="chevron-left-large" onClick={handleBack}>Back</Button>
            </div>
            <p className="text-sm">{props.content[step].disclaimer}</p>
          </div>
        )}
    </Form>
  );
};
