const CompanyAvatar = ({ src, alt }: { src: string; alt?: string }) => (
  <div className="relative overflow-hidden border bg-white rounded-full w-[4.5rem] h-[4.5rem]">
    <img
      className="absolute left-1/2 top-1/2 h-auto max-w-[75%] -translate-x-1/2 -translate-y-1/2 border-none transition-opacity duration-300"
      src={src}
      alt={alt || 'Company Avatar'}
      width="72"
      height="72"
    />
  </div>
);

export default CompanyAvatar;
