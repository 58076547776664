/* eslint-disable max-len */
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import { v4 as uuidv4 } from 'uuid';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { IBasicInfoData, IPropertyResponse } from '../../../config/util/interfaces';
import CheckCircle from '../../../components/CheckCircle/CheckCircle';
import { MOCK_EMAIL_SUFFIX } from '../../../config/content/constants';
import { formatRichText, formatDate, loadDate } from '../../../config/util/common';

interface IAccountProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  setGlobalLoading: any;
  basicInfoData: IBasicInfoData;
  propertyInfoData: IPropertyResponse;
  form: any;
  setShowAccount: any;
  isLite?: boolean;
  appId: string;
  progress?: ProgressProps;
}
// account-exists, no-record, loan-exists
/*
  if(account-exists){
    updateBasicInfo
    redirect to login (with redirect back)
  }
  else {
    proceed with password screen
    updateBasicInfo
    on subimt -> call registerBorrower()
    on response from registerBorrower() -> redirect to myAccountRegistrationUrl()
    on response from myAccountRegistrationUrl() -> call update borrower in orchestrator
  }
*/

interface IStates {
  length: 'neutral' | 'success' | 'error';
  upper: 'neutral' | 'success' | 'error';
  lower: 'neutral' | 'success' | 'error';
  number: 'neutral' | 'success' | 'error';
  special: 'neutral' | 'success' | 'error';
  name: 'neutral'| 'success' | 'error';
  username: 'neutral'| 'success' | 'error';
}

const newStates = () => ({
  length: 'neutral',
  upper: 'neutral',
  lower: 'neutral',
  number: 'neutral',
  special: 'neutral',
  name: 'neutral',
  username: 'neutral',
}as IStates);

export const HelocAccountPage = (props: IAccountProps) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);
  const [passwordStates, setPasswordStates] = useState(newStates());
  const [nameValid, setNameValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [fetchedBorrower, setFetchedBorrower] = useState(false);
  const [borrowerStatus, setBorrowerStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);

  const emailParts = props.basicInfoData.emailId.slice(0, props.basicInfoData.emailId.lastIndexOf('.')).split('@');

  const handleSubmit = async (borrowerId: string) => {
    setTrySubmit(true);
    props.setGlobalLoading(true);
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      personalData: { 
        email: "${props.basicInfoData.emailId}"
        birthday: "${formatDate(loadDate(props.form.birthday))}"
        identifier: "${props.form.ssn}"
        borrowerId: "${borrowerId || ''}"
        lastFourSSN: ${props.form.lastFourSSN && props.form.lastFourSSN.length > 0 ? `"${props.form.lastFourSSN}"` : null}
      }
      ${props.isLite ? 'lite: true' : ''}
    }`;
    if (Cookies.get('authToken') && !props.isLite && !isTestEmail()) {
      Cookies.remove('authToken');
    }
    await props.handleSubmit(pageData, false);
    setLoading(false);
    props.setGlobalLoading(false);
  };

  // used to go back to previous screen
  const handleBack = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };

  const handleGoToSSNScreen = () => {
    props.setShowAccount(false);
  };

  const isTestEmail = () => ((window as any).env !== 'prod' && props.basicInfoData?.emailId?.endsWith(MOCK_EMAIL_SUFFIX));

  const searchBorrower = async (email: string) => {
    setFetchedBorrower(true);
    setLoading(true);
    // fetch the loan officer data based on the loId
    const query = {
      query:
        `{
          searchBorrower(user: { emailAddress:"${email}"}) {
            status
          }
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(query)
    });
    try {
      const data = await resp.json();
      if (data?.data?.searchBorrower) {
        setBorrowerStatus(data.data.searchBorrower.status);
        if (data.data.searchBorrower.status === 'account-exists') {
          await handleSubmit(props.basicInfoData.borrowerEntityId);
          setLoading(false);
        }
        setLoading(false);
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        navigate(`/heloc/error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
      }
    } catch (e) {
      console.error(e);
      setFetchedBorrower(false);
      setLoading(false);
      const urlParams = new URLSearchParams(window.location.search);
      navigate(`/heloc/error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
      return e;
    }
  };

  const registerBorrower = async () => {
    if (isValid() && termsChecked && nameValid && emailValid) {
      setLoading(true);
      if (Cookies.get('authToken')) {
        Cookies.remove('authToken');
      }
      if (Cookies.get('JSESSIONID')) {
        Cookies.remove('JSESSIONID');
      }
      const mutation = {
        query:
        `mutation {
          registerHelocBorrower(applicationId:"${props.appId}", user: { 
            firstName: "${props.basicInfoData.name.first}"
            middleName: ""
            lastName: "${props.basicInfoData.name.last}"
            emailAddress: "${props.basicInfoData.emailId}"
            password: "${password}"
            ssn: "${props.form.ssn}"
            lastFourSSN: ${props.form.lastFourSSN && props.form.lastFourSSN.length > 0 ? `"${props.form.lastFourSSN}"` : null}
            dateOfBirth: "${formatDate(new Date(props.form.birthday))}"
            phoneNumber: "${props.basicInfoData.phoneNumber}"
            address: {
              street: ["${props.propertyInfoData.address.street[0]}"${props.propertyInfoData.address.street.length > 1 ? `"${props.propertyInfoData.address.street[1]}"` : ''}]
              city: "${props.propertyInfoData.address.city}"
              state: "${props.propertyInfoData.address.region}"
              zip: "${props.propertyInfoData.address.postalCode}"
            }
            applicationType: ${props.isLite ? 'HELOC_LITE' : 'HELOC'}
          }) {
            myAccountRegistrationURL
            borrowerId
            token
            status
            verified
          }
        }`
      };
      const uuid = uuidv4();
      const url = '/gateway/graphql';
      try {
        const resp = await fetch(url, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
            'X-Request-ID': uuid,
          },
          body: JSON.stringify(mutation)
        });
        const data = await resp.json();
        if (data?.data?.registerHelocBorrower) {
          window.scrollTo(0, 0);
          if (props.isLite || isTestEmail()) {
            Cookies.set('authToken', data.data.registerHelocBorrower.token);
          }
          await handleSubmit(data.data.registerHelocBorrower.borrowerId);
          setLoading(false);
        } else {
          setLoading(false);
          const urlParams = new URLSearchParams(window.location.search);
          navigate(`/heloc/error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
        const urlParams = new URLSearchParams(window.location.search);
        navigate(`/heloc/error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
        return e;
      }
    }
  };

  // each time the password changes, verify each requirement
  const updatePassword = (basicInfoData: IBasicInfoData, password: string) => {
    setPassword(password);
    const passwordStates = newStates();
    const len = password.length > 7;
    let upper = false;
    let lower = false;
    let symbol = false;
    let number = false;
    password.split('').map((c) => {
      if (!isNaN(parseInt(c))) {
        // checks that a number is present in the string
        number = true;
        passwordStates.number = 'success';
      } else if (!/^[a-zA-Z0-9]+$/.test(c)) {
        // checks that a symbol is present in the string
        symbol = true;
        passwordStates.special = 'success';
      } else if (c === c.toUpperCase()) {
        // checks that an upper case letter is present in the string
        upper = true;
        passwordStates.upper = 'success';
      } else if (c === c.toLowerCase()) {
        // checks that a lower case letter is present in the string
        lower = true;
        passwordStates.lower = 'success';
      }
    });

    if (password && !password.includes(props.basicInfoData.name.first) && !password.includes(props.basicInfoData.name.last)) {
      passwordStates.name = 'success';
    }

    if (props.basicInfoData.emailId.length > 3) {
      const name: string = props?.basicInfoData?.emailId || '';

      const threeParts: Array<string> = name.match(/.{3}/g) || [];
      const partsOfThreeLetters = threeParts.concat(name.slice(1).match(/.{3}/g) ?? [], name.slice(2).match(/.{3}/g) ?? []);
      if (partsOfThreeLetters && new RegExp(partsOfThreeLetters.join('|'), 'i').test(password)) {
        passwordStates.username = 'error';
      } else if (password?.trim()) {
        passwordStates.username = 'success';
      }
    }

    // verifies that the password is at least 8 characters long
    if (len) {
      passwordStates.length = 'success';
    }

    // if the state was previously success or error, don't set the state to neutral anymore
    Object.keys(passwordStates).map((key: string) =>
      passwordStates[key as keyof IStates] = passwordStates[key as keyof IStates] === 'neutral'
        ? 'error'
        : passwordStates[key as keyof IStates]);

    // verify that no parts of the email are used in the password
    let email = false;
    emailParts.map((part: string) => email = email || password.toLowerCase().indexOf(part.toLowerCase().trim()) !== -1);
    setEmailValid(!email);
    setPasswordStates(passwordStates);

    // verify that the users first or last name is not used in the password
    setNameValid(!(password.toLowerCase().indexOf(basicInfoData.name.first.toLowerCase().trim()) !== -1
    || password.toLowerCase().indexOf(basicInfoData.name.last.toLowerCase().trim()) !== -1));
  };

  // returns whether or not the password states are successful (number, symbol, upper, lower)
  const isValid = () => {
    let valid = true;
    Object.keys(passwordStates).map((key: string) => {
      valid = passwordStates[key as keyof IStates] === 'success' ? valid : false;
    });
    return valid;
  };

  // checks if the password is valid for all checks
  const hasError = () => trySubmit && (!isValid() || !emailValid || !nameValid);

  if (!fetchedBorrower) {
    searchBorrower(props.basicInfoData.emailId);
  }

  return (
    <Form title={props.content.header} progress={props.progress}>
      {
        borrowerStatus !== '' && borrowerStatus !== 'account-exists'

          ? (
            <div className="min-h-[420px]">
              <p className="pb-4">{props.content.description}</p>
              <TextInput
                name="email"
                value={props.basicInfoData.emailId}
                label="Email"
                disabled={true}
              />
              <TextInput
                name="password"
                value={password}
                label={props.content.password_label}
                type="password"
                onChange={(event) => updatePassword(props.basicInfoData, event.target.value)}
                hasError={hasError()}
                helperText={hasError()
                  ? !emailValid
                    ? 'Passwords must not contain part of the email.'
                    : !nameValid
                      ? 'Passwords must not contain your name.'
                      : ''
                  : ''}
                required={true}
              />
              <h4>Passwords must have the following: </h4>
              <div className="mb-4 mt-4 space-y-1">
                <div className="flex space-x-1"><CheckCircle status={passwordStates.length} /><span>8 Character Minimum</span></div>
                <div className="flex space-x-1"><CheckCircle status={passwordStates.upper} /><span>1 uppercase letter</span></div>
                <div className="flex space-x-1"><CheckCircle status={passwordStates.lower} /><span>1 lowercase letter</span></div>
                <div className="flex space-x-1"><CheckCircle status={passwordStates.number} /><span>1 number</span></div>
                <div className="flex space-x-1">
                  <CheckCircle status={passwordStates.special} /><span>1 special character (!@#$%&+-)</span>
                </div>
                <div className="flex space-x-1">
                  <CheckCircle status={passwordStates.name} /><span>should not contain first or last name</span>
                </div>
                <div className="flex space-x-1">
                  <CheckCircle status={passwordStates.username} /><span>should not contain part of email</span>
                </div>
              </div>
              <p className="mb-4">{props.content.password_subtext}</p>
              <div className="flex flex-row mb-4">
                <div>
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={termsChecked}
                    onChange={() => setTermsChecked(!termsChecked)}
                  />
                </div>
                <p className="text-left">{formatRichText('account', props.content.terms_label, props.content)}</p>
              </div>
              <p className="text-sm">{props.content.disclaimer}</p>
              <div className="flex justify-center mt-12">
                <Button
                  buttonStyle="primary"
                  className="!w-full md:!w-48 confirmButton"
                  onClick={registerBorrower}
                  loading={loading}
                  buttonAttrs={{ disabled: !isValid() || !termsChecked || loading }}
                >Confirm
                </Button>
              </div>
              <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                <Button
                  buttonStyle="quaternary"
                  iconPos="left"
                  iconName="chevron-left-large"
                  onClick={handleGoToSSNScreen}
                  buttonAttrs={{ disabled: loading }}
                >Back
                </Button>
              </div>
            </div>
          )
          : (
            <div className="min-h-[420px]">
              <div className="flex items-center justify-center">
                <Loader color="#D13239" className="loader-medium" />
              </div>
            </div>
          )
      }

    </Form>
  );
};
