import { useState } from 'react';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Table from '@guaranteed-rate/react-components/dist/Table';
import RadioButtons, { useRadio } from '@guaranteed-rate/react-components/dist/RadioButtons';
import Form from '../../../components/Form/Form';
import Alert from '../../../components/Alert/Alert';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { IPricingData, IPropertyResponse } from '../../../config/util/interfaces';
import { localeOptions } from '../../../config/util/common';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const RangeSlider = require('react-range-slider-input').default;

interface ILoanSelectedProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  progress?: ProgressProps;
  propertyData: IPropertyResponse;
  pricingData: IPricingData;
}

export const HelocLoanSelectedPage = (props: ILoanSelectedProps) => {
  const [loading, setLoading] = useState(false);
  const loanAmount = props.propertyData.requestedLoanAmount.toLocaleString('en-US', localeOptions);
  const monthlyPayment = props.pricingData?.selected?.term?.estimatedMonthlyPayment.toLocaleString('en-US', localeOptions) || '';
  const handleSubmit = async () => {
    setLoading(true);
    const pageData = `{ 
        page: "${props.pageName}"
      }`;
    await props.handleSubmit(pageData, false);
    setLoading(false);
  };

  const handleBack = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };

  return (
    <Form title={props.content.header} progress={props.progress}>
      <p>{props.content.description}</p>
      <h2 className="font-bold mt-4 mb-4">{props.content.details.header}</h2>
      <h4>{props.content.details.amount_label}: <span className="font-bold">{loanAmount}</span></h4>
      <h4>{props.content.details.term_label}: <span className="font-bold">{props.pricingData?.selected?.term?.term || ''}</span></h4>
      <h4>{props.content.details.monthly_payment_label}: <span className="font-bold">{monthlyPayment}</span></h4>
      <h4>{props.content.details.apr_label}: <span className="font-bold">{`${props.pricingData?.selected?.term?.apr || ''}%`}</span></h4>
      <div className="line mt-4 mb-4" />
      <h2 className="font-bold">{props.content.closing_conditions_header}</h2>
      { props.content.sections.map((section: any, index: number) => (
        <div key={`section${index}`} className="mt-4">
          <h2 className="font-bold text-xl">{section.section.header}</h2>
          <p>{section.section.description}</p>
        </div>
      ))}
      <div className="flex justify-center mt-12">
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-48 launchDisclosures"
          onClick={handleSubmit}
          loading={loading}
          buttonAttrs={{ disabled: loading }}
        >Next
        </Button>
      </div>
      <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
        <Button
          buttonStyle="quaternary"
          iconPos="left"
          iconName="chevron-left-large"
          onClick={handleBack}
          buttonAttrs={{ disabled: loading }}
        >Back
        </Button>
      </div>
    </Form>
  );
};
