export interface IHelocPageResponse {
  page: string;
  id: string;
  disablePrequalify: boolean;
  applicationDate: string;
  pageContentJson: Array<string>;
  encompassId: string;
  employmentData: IIncomeEmploymentData;
  mortgageData: IMortgageData;
  pricingData: IPricingData;
  propertyData: IPropertyResponse;
  basicInfoData: IBasicInfoData;
  incomeData: IIncomeData;
  depositInfo: IBankInfo;
  debitInfo: IBankInfo;
  accounts: IAccountInfo;
  tcpaConfig: ITcpaData;
  closingResponse: IClosingResponse;
}

export interface ILoanOfficer {
  displayName: string;
  employeeId: string;
}

export interface IClosingResponse {
  iframeUrl: string;
  platformUrl: string;
  credential: string;
}

export interface IIncomeData {
  incomeSource: string;
  annualIncome: number;
  otherIncome: number;
  employerName?: string;
  employmentStartDate?: string;
}
export interface IProperty {
  street: Array<string>;
  unitNumber: string;
  city: string;
  region: string;
  postalCode: string;
}

export interface IEmployment {
    employmentType: string;
    employerName: string;
    employerPhone: string;
    jobTitle: string;
    employerAddress: IProperty;
    startDate: string;
    endDate: string;
    annualPay: number;
    monthlyPay: number;
    annualCommission: number;
    annualOverTime: number;
    annualBonus: number;
    currentEmploymentIndicator: boolean;
}

export interface IEmploymentIncome {
  incomeSource: string;
  currentEmployment: boolean;
  employerName: string;
  startDate: string;
  endDate: string;
  annualPay: number;
  ownershipInterestGreaterThanOrEqualTo25Percent: null | boolean;
}

export interface IOtherIncome {
  incomeType: string;
  monthlyPay: number;
}

export interface IIncomeEmploymentData {
  employments: Array<IEmployment>;
  otherIncomes: Array<IOtherIncome>;
}

export interface IError {
  message: string;
  details: string;
  requestId: string;
}

export interface IErrorObject {
  status: string;
  errros: Array<IError>;
}

export interface IErrorData {
  error: IErrorObject;
  page: string;
  pageData: string;
}

export interface IBasicInfoData {
  id: string;
  name: IName;
  suffix: string;
  emailId: string;
  phoneNumber: string;
  dateOfBirth: string;
  identifier: string;
  maritalStatus: string;
  gender: string;
  ethnicity: string;
  ethnicityDetail: string;
  race: string;
  asianDetail: string;
  islanderDetail: string;
  citizenStatus: string;
  borrowerEntityId: string;
  residenceStartDate: string;
  residenceAddress: IAddressResponse;
  isActiveOrExMilitary: boolean;
}

export interface ITcpaData {
  accountCode: string;
  campaignId: string;
}

export interface IName {
  first: string;
  middle: string;
  last: string;
  suffix: string;
}

export interface IPropertyResponse {
  purpose: string;
  propertyType: string;
  structureType: string;
  address: IAddressResponse;
  annualTaxes: number;
  annualInsurance: number;
  annualHoa: number;
  estimatedValue: number;
  requestedLoanAmount: number;
  ownershipType: string;
  trustName: string;
  otherReason: string;
}

export interface IAddressResponse {
  street: Array<string>;
  unitNumber: string;
  city: string;
  region: string;
  postalCode: string;
}

export interface IAddressForm {
  address: string;
  unitNumber: string;
  city: string;
  state: string;
  zip: string;
}

export interface IMortgageData {
  mortgages: Array<IMortgage>;
  address: string;
}

export interface IMortgage {
  lienType: string;
  lender: string;
  date: string;
  amount: number;
  id: string;
  monthlyPayment: string;
  isManualEntry: boolean;
  borrowerSelected: boolean;
}

export interface ITerm {
  term: string;
  apr: number;
  estimatedMonthlyPayment: number;
  pricingTermId: string;
  loanAmount: number;
}
export interface IAccountInfo {
  number: string;
  name: string;
  bankType: string;
  institutionName: string;
  accountid: string;
}
export interface IBankInfo {
  id: string;
  name: string;
  routingNumber: string;
  finicityId: string;
}
export interface IOrigination {
  terms: Array<ITerm>;
  fee: string;
}

export interface IPricingData {
  options: Array<IOrigination>;
  maximumAmount: number;
  loanAmount: number;
  selected: ISelectedPricing;
  autoPay: boolean;
  creditUnion: boolean;
}

export interface ISelectedPricing {
  term: ITerm;
  fee: string;
}

export interface IPersonalForm {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  suffix: string;
  birthday: string;
  ssn: string;
  phoneNumber: string;
}

export interface ILoData {
  avatarUrl: string;
  email: string;
  id: string;
  name: string;
  phone: string;
  subTitle: string;
  title: string;
}
export interface ISupportNavLoData extends ILoData {
  heading: string;
  description: string;
}

export interface IAdditionalInfo {
  maritalStatus: string;
  ownershipType: string;
  trustName: string; // optional
  gender: string;
  ethnicity: string;
  ethnicityDetail: string; // optional
  race: string;
  asianDetail: string; // optional
  islanderDetail: string; // optional
  citizenStatus: string;
  primaryResidence: boolean;
  otherOwnership: boolean;
  borrowingMoney: boolean;
  otherMortgage: boolean;
  newCredit: boolean;
  newLien: boolean;
  otherDebt: boolean;
  outstandingJudgment: boolean;
  delinquentDefault: boolean;
  lawsuit: boolean;
  conveyedTitle: boolean;
  shortSale: boolean;
  foreclosure: boolean;
  bankruptcy: boolean;
  language: string;
}

export interface IAffordabilityDebtData {
  autoLoan: string;
  personalLoan: string;
  creditCardLoan: string;
  studentLoan: string;
  otherLoan: string;
  isMonthly: boolean;
}

export interface IAffordabilityIncomeData {
  base: string;
  bonus: string;
  overTime: string;
  otherIncome: string;
  isMonthly: boolean;
}

export interface IAffordabilityData {
  id?: string;
  creditScore: string;
  isMilitary: boolean;
  cash: string;
  equity: string;
  city: string;
  zip: string;
  isHomeOwner: boolean;
  occupancyType: string;
  propertyType: string;
  affordableDebtData: IAffordabilityDebtData;
  affordableIncomeData: IAffordabilityIncomeData;
  formattedAddress: string;
  unitNumber: string;
  state: string;
  county: string;
  points?: string;
}

export interface IAffordabilityProgressList {
  title: string;
  currentStep: number;
  steps: any;
}

export const PRICING_TYPES = {
  finalOffer: 'FINAL',
  prequalify: 'QUALIFY'
};

export const iconMap = {
  welcome: 'doc-remove',
  property: 'home',
  annual: 'home',
  occupancy: 'home',
  purpose: 'home',
  personal: 'user',
  income: 'dollar-coin',
  ssnPage: 'calendar-remove',
  liens: 'money-receipt',
  mortgage: 'money-receipt',
  prequalify: 'new-loan',
  marital: 'people-pair',
  partnership: 'people-pair',
  officer: 'people-pair',
  additionalQuestions: 'people-pair',
  finalQuestions: 'people-pair',
  confirmation: 'folder-remove',
  verify_identity: 'identification',
  identity_result: 'identification',
  verify_income: 'dollar-bill',
  income_result: 'dollar-bill',
  deposit: 'bank',
  debit: 'bank',
  finalOffer: 'new-loan',
  loanSelected: 'doc-remove',
  documents: 'doc-remove',
  error: 'close-small'
};

export interface IAffordableDebtInfo {
  autoLoan: string;
  creditCardLoan: string;
  isMonthly: boolean;
  otherLoan: string;
  personalLoan: string;
  studentLoan: string;
}

export interface IAffordableIncomeInfo {
  base: string;
  bonus: string;
  isMonthly: boolean;
  otherIncome: string;
  overTime: string;
}

export interface IAffordabilityInputResponse {
  affordableDebtInfo: IAffordableDebtInfo;
  affordableIncomeInfo: IAffordableIncomeInfo;
  cash: string;
  creditScore: string;
  equity: string;
  isHomeOwner: boolean;
  isMilitary: boolean;
  occupancyType: string;
  propertyType: string;
  city: string;
  zip: string;
  points: string;
}

export interface IConfirmIdentityForm {
  ssn: string;
  birthday: string;
}

export interface IPersonalDetailForm {
  firstName: string;
  lastName: string;
  dependentsCount: number;
  maritalStatus: string;
  propertyUse: string;
}

export interface IDeclarationInfo {
  primaryResidence: boolean;
  otherOwnership: boolean;
  priorPropertyUsage?: string;
  priorPropertyTitle?: string;
  borrowingMoney: boolean;
  otherMortgage: boolean;
  newCredit: boolean;
  newLien: boolean;
  otherDebt: boolean;
  outstandingJudgment: boolean;
  delinquentDefault: boolean;
  lawsuit: boolean;
  conveyedTitle: boolean;
  shortSale: boolean;
  foreclosure: boolean;
  bankruptcy: boolean;
  citizenshipStatus: string;
  bankruptcyType: string;
  undisclosedBorrowedAmount?: number;
  payAlimony?: boolean | null;
  alimony?: number | null;
  payChildSupport?: boolean | null;
  childSupport?: number | null;
  otherLiabilities?: Array<any>;
}

interface IPersonalData {
  name: string;
  maritalStatus: string;
  dependentsCount: number;
  propertyUse: string;
}

interface IEmploymentData {
  employerName: string;
  annualSalary: number;
}

export interface IOtherIncomeData {
  incomeType: string;
  amount: number;
}

export interface IConfirmAssetsData {
  borrowerOnly: boolean;
  assets: Array<IConfirmAssets>;
}

export interface IConfirmAssets {
  accountType: string;
  financialInstitution: string;
  balance: string;
  owner: string;
}

interface IDemographicData {
  sex: string;
  ethnicity: string;
  race: string;
}

export interface IPreferenceData {
  language: string;
}
export interface IBorrowerInfo {
  id: string;
  borrower: any;
  coborrower: any;
  employment: any;
  otherIncomeSources: any;
  prequalCreditReportIndicator: boolean;
  creditReportReferenceIdentifier: string;
  propertyUsageType: string;
}

export interface IOtherIncomeForm {
  incomeType: string;
  monthlyAmount: string;
  owner: string;
  isFormOpen: boolean;
}

export interface IConfirmAssetsForm {
  accountType: string;
  financialInstitution: string;
  accountNumber?: string;
  amount: string;
  owner: string;
  isFormOpen: boolean;
}

export type BorrowerType = 'Borrower' | 'CoBorrower';

export type EmploymentOwnershipInterestType =
'LessThan25Percent' // I have an ownership share of less 25%
 | 'GreaterThanOrEqualTo25Percent' // I have an ownership share of 25% or more

export type EmploymentUnitType =
'Apartment'
 | 'Basement'
 | 'Building'
 | 'Condo'
 | 'Department'
 | 'Floor'
 | 'Front'
 | 'Hangar'
 | 'Key'
 | 'Lobby'
 | 'Lot'
 | 'Lower'
 | 'Office'
 | 'Penthouse'
 | 'Pier'
 | 'Rear'
 | 'Room'
 | 'Side'
 | 'Space'
 | 'Stop'
 | 'Suite'
 | 'Trailer'
 | 'Unit'
 | 'Upper'

export interface IEmploymentInfo {
  addressCity?: string;
  addressPostalCode?: string;
  addressState?: string;
  addressStreetLine1?: string;
  altId?: string;
  attention?: string;
  badgeOrEmployeeID?: string;
  basePayAmount?: number;
  bonusAmount?: number;
  businessName?: string;
  businessOwnedPercent?: number;
  businessPhone?: string;
  clothingAllowance?: number;
  commissionsAmount?: number;
  country?: string;
  countryCode?: string;
  currentEmploymentIndicator?: boolean;
  doesNotApply?: boolean;
  email?: string;
  employerComments?: string;
  employerName?: string;
  employmentMonthlyIncomeAmount?: number;
  employmentStartDate?: Date | string;
  endDate?: Date | string;
  fax?: string;
  foreignAddressIndicator?: boolean;
  foreignIncome?: boolean;
  id?: string;
  individualEmployer?: boolean;
  jobTermMonths?: number;
  militaryCombatPay?: number;
  militaryEmployer?: boolean;
  militaryEntitlement?: number;
  militaryFlightPay?: number;
  militaryHazardPay?: number;
  militaryOverseasPay?: number;
  militaryPropPay?: number;
  monthlyIncomeAmount?: number;
  noLinkToDocTrackIndicator?: boolean;
  otherAllowanceDescription?: string;
  otherAmount?: number;
  overtimeAmount?: number;
  owner?: BorrowerType;
  ownershipInterestType?: EmploymentOwnershipInterestType;
  phoneNumber?: string;
  positionDescription?: string;
  printAttachmentIndicator?: boolean;
  printUserJobTitleIndicator?: boolean;
  printUserNameIndicator?: boolean;
  quartersAllowance?: number;
  rationsAllowance?: number;
  seasonalIncome?: boolean;
  selfEmployedIndicator?: boolean;
  specialEmployerRelationshipIndicator?: boolean;
  /**
   * @deprecated not displayed in Encompass, use employmentStartDate instead
   */
  startDate?: Date | string;
  timeInLineOfWorkMonths?: number;
  timeInLineOfWorkYears?: number;
  timeOnJobTermMonths?: number;
  timeOnJobTermYears?: number;
  title?: string;
  titleFax?: string;
  titlePhone?: string;
  uRLA2020StreetAddress?: string;
  unitNumber?: string;
  unitType?: EmploymentUnitType;
  variableHousingAllowance?: number;

  employmentCopiedFromOldLoan?: boolean;
}

export interface IEmploymentForm {
  employmentType: string;
  employerName: string;
  jobTitle: string;
  city: string;
  cityInput: string;
  state: string;
  isCurrentJob: boolean;
  employmentStartDate: string;
  endDate?: string | null;
  annualBasePay: string;
  annualCommission: string;
  annualOverTime: string;
  annualBonus: string;
  monthlyBasePay?: string;
  monthlyCommission?: string;
  monthlyOverTime?: string;
  monthlyBonus?: string;
  owner: BorrowerType;
  isFormOpen: boolean;
  employmentCopiedFromOldLoan: boolean;

  /*
    if this employment form edits an existing employment,
    store the existing employment's data here.
    even if data is not featured on the form, the original employment data
    must be sent back to the server in order to avoid losing that data
  */
  originalEmploymentData: IEmploymentInfo;
}

export interface IDiscountPoints {
  cost: string;
  raw: string;
  closest: string;
}

export interface IMortgageRates {
  apr: string;
  armFixedPeriodMonths?: string;
  calculatedMonthlyPayment: string;
  discountDollarAmount: string;
  discountPoints: IDiscountPoints;
  fees?: string;
  label: string;
  lastUpdated: string;
  loanType: string;
  margin?: string;
  monthlyMi: string;
  pmtPrincipalAndInterest: string;
  priority: string;
  productId: string;
  rate: string;
  rebate: string;
  totalClosingCosts: string;
  totalPayment: string;
}

export interface IIOEOrderDetails {
  orderId: string;
  product: string;
  identifier: string;
}
export interface IBorrowerCreditScore {
  creditVendor: string;
  creditScore?: number; // if missing, indicates an error running credit
  responseCreditPullType: string;
  creditPullSuccessTimestamp: string;
  coBorrower: boolean;
  name: string;
}

export interface ILeadInput {
  submittedBy?: string;
  loanPurpose?: string;
  estimatedHousePrice: number;
  downPayment: number;
  loanAmount: number;
  cashOutAmount: number;
  creditProfile: string;
  adTrack?: string;
  tcpaAuditId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  propertyType: string;
  isMilitary: boolean;
  postalCode: string;
  city: string;
  state: string;
  county: string;
}

export interface IContentStackFile {
  publish_details: {
    environment: string;
    time: Date;
    locale: string;
    user: string;
  };
  created_at: Date;
  ACL: any;
  title: string;
  created_by: string;
  file_size: string;
  url: string;
  tags: Array<any>;
  uid: string;
  filename: string;
  parent_uid: null;
  updated_at: Date;
  content_type: string;
  is_dir: boolean;
  updated_by: string;
  _version: number;
}

export interface IThreatMetrixData {
  trackingId: string;
  threatMetrixOrgId: string;
  threatMetrixProfilingDomain: string;
}
