/* eslint-disable max-len */
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { IAdditionalInfo, IErrorData, IErrorObject, ILoData, ILoanOfficer, ISupportNavLoData, IThreatMetrixData } from '../../../config/util/interfaces';
import { fetchLoanOfficers, setLoCookie } from '../../../config/util/common';
import Header from '../../../components/Header/Header';
import LoanOfficerBanner from '../../../components/SupportNavigation/LoanOfficerBanner';
import { getSupportNavigationContent, defaultNavigationContent } from '../../../config/content/supportNavigation';
import Footer from '../../../components/Footer/Footer';
import { footerContent } from '../../../config/content/footer';
import { HelocPropertyPage } from '../HelocPropertyPage/HelocPropertyPage';
import { HelocPersonalPage } from '../HelocPersonalPage/HelocPersonalPage';
import { HelocSSNPage } from '../HelocSSNPage/HelocSSNPage';
import { HelocErrorPage } from '../HelocErrorPage/HelocErrorPage';
import { HelocAdditionalOptionPage } from '../HelocAdditionalQuestions/HelocAdditionalOptionPage';
import { HelocOwnershipPage } from '../HelocAdditionalQuestions/HelocOwnershipPage';
import { HelocAdditionalQuestions } from '../HelocAdditionalQuestions/HelocAdditionalQuestions';
import { HelocFinalQuestions } from '../HelocFinalQuestions/HelocFinalQuestions';
import { HelocManualUpload } from '../HelocVerifyPage/HelocManualUpload';
import { helocGraphQLRequest } from '../../../config/content/constants';
import { HelocLoanInfo } from './HelocLoanInfo';
import { HelocUpdateProperty } from '../HelocPersonalPage/HelocUpdateProperty';
import { HelocIncomeEmployment } from '../HelocIncomePage/HelocIncomeEmployment';
import { HelocCompletePage } from '../HelocCompletePage/HelocCompletePage';

const helocPathMap = {
  dev: '/heloc/lite/',
  qa: '/heloc/lite/',
  stage: '/',
  prod: '/',
};

export const HelocLite = () => {
  const navigate = useNavigate();
  const [globalLoading, setGlobalLoading] = useState(false);
  const [content, setContent] = useState([] as Array<string>);
  const [supportNavContent, setSupportNavContent] = useState<ISupportNavLoData>();
  const [page, setPage] = useState('');
  const [pageData, setPageData] = useState('' as any);
  const [params] = useSearchParams();
  const [applicationId, setApplicationId] = useState(Cookies.get('cesId') || params.get('id') || undefined);
  const [loData, setLoData] = useState(null as unknown as ILoData);
  const [fetchLoData, setFetchLoData] = useState(false);
  const [fetchPageData, setFetchPageData] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(newAdditionalInfo());
  const [threatMetrix, setThreatMetrix] = useState('');
  const [loanOfficers, setLoanOfficers] = useState([] as Array<ILoanOfficer>);
  const [helocPath, setHelocPath] = useState(helocPathMap[(window as any).env as keyof typeof helocPathMap] ?? '/heloc/lite/');
  const { heloc_support_navigation } = defaultNavigationContent;

  // this sets the loan officer data for the application
  const setLo = async (loId: string) => {
    setLoCookie(loId);
    setFetchLoData(true);

    // fetch the loan officer data based on the loId
    const mutation = {
      query:
        `{
          fetchLoanOfficer(employeeId: "${loId}") {
            name
            id
            title
            subTitle
            email
            phone
            avatarUrl
          }
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.fetchLoanOfficer) {
        const newLoData = { ...data.data.fetchLoanOfficer, avatarUrl: heloc_support_navigation.avatarUrl, name: heloc_support_navigation.name, subTitle: heloc_support_navigation.subTitle, title: heloc_support_navigation.title };
        setLoData(newLoData);
      }
    } catch (e) {
      console.error(e);
      setFetchLoData(false);
      return e;
    }
  };

  /**
   * @param pageData the data to submit to gateway
   * @param back used for navigating backwards
   * @param timeout how many times the request has been repeated due to a timeout
   * @returns the page to navigate to and the data for that page
   */
  const submitPage: any = async (requestData: string, back: boolean, timeout: number) => {
    setFetchPageData(true);
    // capture the url params so we can attach them to navigate
    const urlParams = new URLSearchParams(window.location.search);

    // pass in the requestData in the graphQL request.
    // here we select which data we want to recieve from graphQL
    const mutation = {
      query:
        `mutation {
          updateHelocData(data: ${requestData}, id: "${applicationId || ''}", borrowerId: "${pageData?.basicInfoData?.borrowerEntityId || ''}", sub: "${pageData?.basicInfoData?.emailId || ''}") ${helocGraphQLRequest}
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      setFetchPageData(false);
      if (data?.data?.updateHelocData) {
        window.scrollTo(0, 0);
        if (data.data.updateHelocData.pageContentJson !== '' && data.data.updateHelocData.pageContentJson) {
          const contentData = [] as Array<string>;
          data.data.updateHelocData.pageContentJson.map((entry: string) => contentData.push(JSON.parse(entry)));
          setContent(contentData);
        }
        if (!applicationId && data.data.updateHelocData.id) {
          setApplicationId(data.data.updateHelocData.id);
          Cookies.set('cesId', data.data.updateHelocData.id, { expires: 2 });
        }
        setPage(data.data.updateHelocData.page);
        setPageData(data?.data?.updateHelocData);
        if (data?.data?.updateHelocData.status === 'DECLINED') {
          urlParams.set('code', '10014');
          navigate(`${helocPath}error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
          return '';
        }
        if (data?.data?.updateHelocData.status === 'EXPIRED') {
          urlParams.set('code', '10016');
          navigate(`${helocPath}error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
          Cookies.remove('cesId');
          return '';
        }
        if (data.data.updateHelocData.additionalData) {
          const propertyData = data.data.updateHelocData.propertyData;
          const basicData = data.data.updateHelocData.basicInfoData;
          data.data.updateHelocData.additionalData = {
            ...data.data.updateHelocData.additionalData,
            ownershipType: propertyData.ownershipType,
            trustName: propertyData.trustName,
            maritalStatus: basicData.maritalStatus,
            gender: basicData.gender,
            ethnicity: basicData.ethnicity,
            ethnicityDetail: basicData.ethnicityDetail,
            race: basicData.race,
            asianDetail: basicData.asianDetail,
            islanderDetail: basicData.islanderDetail,
            citizenStatus: basicData.citizenStatus
          };
          setAdditionalInfo(data.data.updateHelocData.additionalData);
        }
        navigate(`${helocPath}${data.data.updateHelocData.page}/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
        setGlobalLoading(false);
        return data?.data?.updateHelocData;
      } if (data.errors[0].message.indexOf('timeout') !== -1) {
        const pageData = `{ page: "${page}"
                            lite: true
                        ${back ? 'back: true' : ''}}`;
        const newData = await submitPage(pageData, back, timeout);
        return newData;
      }
      // if there is an error, we want to show the error page.
      if (data.errors[0].message.indexOf('Exception from downstream FSP service. ') !== -1) {
        // fetch the error code form the error message
        const errorData = {
          error: JSON.parse(data.errors[0].message.substring(data.errors[0].message.indexOf('.') + 2)) as IErrorObject,
          page,
          pageData: requestData
        } as IErrorData;
        setPageData(errorData);
        setFetchPageData(false);
        setGlobalLoading(false);
        setPage('error');
        window.scrollTo(0, 0);
        navigate(`${helocPath}error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
        return data;
      }

      // load the generic error handler for the page
      window.scrollTo(0, 0);
      setFetchPageData(false);
      setGlobalLoading(false);
      setPage('error');
      setPageData({
        error: {} as IErrorObject,
        page,
        pageData: requestData
      });
      navigate(`${helocPath}error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
      return data;
    } catch (e) {
      // if there is a timeout, then try again until 3 total tries
      if (resp.status === 504 && timeout < 3) {
        const tryAgainData = await submitPage(pageData, back, timeout + 1);
        return tryAgainData;
      }

      // load the generic error handler for the page
      window.scrollTo(0, 0);
      setFetchPageData(false);
      setGlobalLoading(false);
      setPage('error');
      setPageData({
        error: {} as IErrorObject,
        page,
        pageData: requestData
      });
      navigate(`${helocPath}error/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`);
      return e;
    }
  };

  const handleApplyAgain = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    Cookies.remove('cesId');
    urlParams.delete('code');
    window.location.search = urlParams.toString();
    setApplicationId(undefined);
    await handlePageSubmit(`{
      page: "loanInfo"
      lite: true
    }`, false);
  };

  const handlePageSubmit = async (pageData: string, back: boolean) => submitPage(pageData, back, 0);
  if (page === '') {
    if (!fetchPageData) {
      setGlobalLoading(true);
      const urlParams = new URLSearchParams(window.location.search);
      const loId = urlParams.get('loId') || urlParams.get('emp-id') || Cookies.get('loId');
      if (loId) {
        setLo(loId);
      } else {
        fetchLoanOfficers(setFetchLoData, setLoanOfficers);
      }
      handlePageSubmit(`{
        page: ""
        lite: true
        }`, false);
    }
  }

  // handle back button
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', (event) => {
      window.history.pushState(null, document.title, window.location.href);
    });
  });

  async function setSupportNavigationContent() {
    const supportNavResp = await getSupportNavigationContent('heloc_support_navigation');
    setSupportNavContent(supportNavResp);
  }

  if (!supportNavContent) {
    setSupportNavigationContent();
  }

  const threatMetrixProps: IThreatMetrixData = {
    trackingId: pageData?.trackingId,
    threatMetrixOrgId: pageData?.threatMetrixOrgId,
    threatMetrixProfilingDomain: pageData?.threatMetrixOrgId,
  }

  // Route the application based on the page returned from gateway
  return (
    <div>
      <div className="p-4 md:p-8 md:flex md:justify-center">
        <div className="md:max-w-7xl">
          <Header authData={null} fetchUserData={true} isLite={true} />
          <div className="md:flex md:flow-row md:shrink md:justify-center">
            <div className="md:w-full md:max-w-2xl">
              {globalLoading ? (
                <div className="min-h-[420px]">
                  <div className="flex items-center justify-center">
                    <Loader color="#D13239" className="loader-medium" />
                  </div>
                </div>
              ) : page === '' ? <div />
                : (
                  <div>
                    <Routes>
                      <Route path="/" element={(<HelocLoanInfo handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} loanOfficers={loanOfficers} setLo={setLo} />)} />
                      <Route path="/loanInfo/*" element={(<HelocLoanInfo handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} loanOfficers={loanOfficers} setLo={setLo} />)} />
                      <Route path="/officer/*" element={(<HelocLoanInfo handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} loanOfficers={loanOfficers} setLo={setLo} />)} />
                      <Route path="/personal/*" element={(<HelocPersonalPage handleSubmit={handlePageSubmit} isDisaster={false} content={content[0]} pageName="personal" tcpaData={pageData?.tcpaConfig} basicInfoData={pageData?.basicInfoData} propertyData={pageData?.propertyData} isLite={true} threatMetrixData={threatMetrixProps} threatMetrix={threatMetrix} setThreatMetrix={setThreatMetrix} />)} />
                      <Route path="/annual/*" element={(<HelocPropertyPage handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} setLo={setLo} isLite={true} />)} />
                      <Route path="/occupancy/*" element={(<HelocPropertyPage handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} setLo={setLo} isLite={true} />)} />
                      <Route path="/propertyType/*" element={(<HelocPropertyPage handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} setLo={setLo} isLite={true} />)} />
                      <Route path="/purpose/*" element={(<HelocPropertyPage handleSubmit={handlePageSubmit} content={content[0]} pageName={page} propertyData={pageData?.propertyData} setLo={setLo} isLite={true} />)} />
                      <Route path="/updateProperty/*" element={(<HelocUpdateProperty handleSubmit={handlePageSubmit} content={content[0]} basicInfoData={pageData?.basicInfoData} propertyData={pageData?.propertyData} pageName="updateProperty" />)} />
                      <Route path="/ssnPage/*" element={(<HelocSSNPage handleSubmit={handlePageSubmit} setGlobalLoading={setGlobalLoading} appId={applicationId || ''} content={content[0]} pageName="ssnPage" basicInfoData={pageData?.basicInfoData} propertyInfoData={pageData?.propertyData} isLite={true} />)} />
                      <Route path="/employment/*" element={(<HelocIncomeEmployment handleSubmit={handlePageSubmit} content={content[0]} pageName="employment" employmentData={pageData?.employmentData} />)} />
                      <Route path="/marital/*" element={(<HelocAdditionalOptionPage isLite={true} handleSubmit={handlePageSubmit} content={content[0]} pageName="marital" fieldName="maritalStatus" additionalInfo={additionalInfo} setAdditionalInfo={setAdditionalInfo} />)} />
                      <Route path="/language/*" element={(<HelocAdditionalOptionPage isLite={true} handleSubmit={handlePageSubmit} content={content[0]} pageName="language" fieldName="language" additionalInfo={additionalInfo} setAdditionalInfo={setAdditionalInfo} />)} />
                      <Route path="/partnership/*" element={(<HelocOwnershipPage handleSubmit={handlePageSubmit} content={content[0]} pageName="partnership" additionalInfo={additionalInfo} setAdditionalInfo={setAdditionalInfo} isLite={true} />)} />
                      <Route path="/additionalQuestions/*" element={(<HelocAdditionalQuestions handleSubmit={handlePageSubmit} content={content} pageName="additionalQuestions" additionalInfo={additionalInfo} setAdditionalInfo={setAdditionalInfo} isLite={true} />)} />
                      <Route path="/finalQuestions/*" element={(<HelocFinalQuestions handleSubmit={handlePageSubmit} content={content[0]} pageName="finalQuestions" additionalInfo={additionalInfo} setAdditionalInfo={setAdditionalInfo} isLite={true} />)} />
                      <Route path="/verify_identity/*" element={(<HelocManualUpload handleSubmit={handlePageSubmit} content={content[0]} pageName="verify_identity" isLite={true} applicationId={applicationId || ''} />)} />
                      <Route path="/complete/*" element={(<HelocCompletePage content={content[0]} pageName="complete" basicInfoData={pageData?.basicInfoData} />)} />
                      <Route path="/error/*" element={(<HelocErrorPage handleSubmit={handlePageSubmit} handleApplyAgain={handleApplyAgain} errorData={pageData as IErrorData} isLite={true} />)} />
                    </Routes>
                  </div>
                )}
            </div>
          </div>
          <div className="mt-16">
            {supportNavContent && <LoanOfficerBanner supportNavLoData={loData ? { ...loData, heading: supportNavContent.heading, description: supportNavContent.description } : supportNavContent} />}
          </div>
          <Footer data={footerContent} />
        </div>

      </div>
    </div>
  );
};

// default values used for additional info screen
const newAdditionalInfo = () => ({
  maritalStatus: '',
  ownershipType: '',
  trustName: '', // optional
  gender: '',
  ethnicity: '',
  ethnicityDetail: '', // optional
  race: '',
  asianDetail: '', // optional
  islanderDetail: '', // optional
  citizenStatus: '',
} as unknown as IAdditionalInfo);
