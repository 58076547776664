import { IProgressBar } from './IProgressBar';

const ProgressBar = (props: IProgressBar) => {
  const { progress } = props;
  const currentStep = progress.currentStep + 1;
  const percent = (currentStep / progress.steps.length) * 100;
  const stepMsg = `Step ${currentStep} of ${progress.steps.length}`;
  return (
    <div>
      <div className="bg-slate-300 h-0.5 -mt-0.5 xl:w-full" />
      <div className="flex justify-between py-4">
        <h4 className="font-bold">{progress.title}</h4>
        <h4 className="font-bold xl:block ">{stepMsg}</h4>
      </div>
      <div className="xl:m-0 xl:w-full">
        <div className="w-full bg-slate-300 h-0.5" />
        <div style={{ width: `${percent}%` }} className="progress h-0.5 -mt-0.5" />
      </div>
    </div>
  );
};

export default ProgressBar;
