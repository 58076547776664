import clsx from 'clsx';
import { IRateAppDownloadCtaProps } from './types';
import { RateAppDownloadCtaDesktopLayout } from './RateAppDownloadCtaDesktopLayout';
import { RateAppDownloadCtaMobileLayout } from './RateAppDownloadCtaMobileLayout';

export const RateAppDownloadCta = ({
  className,
  innerClassName,
  header,
  description,
  screenshotUrl,
  qrCodeUrl,
  appleAppStoreUrl,
  googlePlayStoreUrl,
}: IRateAppDownloadCtaProps) => (
  <div className={clsx(className, '@container')}>
    <RateAppDownloadCtaDesktopLayout
      className={clsx(innerClassName, 'hidden @[38.75rem]:block')}
      header={header}
      description={description}
      screenshotUrl={screenshotUrl}
      qrCodeUrl={qrCodeUrl}
      appleAppStoreUrl={appleAppStoreUrl}
      googlePlayStoreUrl={googlePlayStoreUrl}
    />
    <RateAppDownloadCtaMobileLayout
      className={clsx(innerClassName, 'block @[38.75rem]:hidden')}
      header={header}
      description={description}
      screenshotUrl={screenshotUrl}
      qrCodeUrl={qrCodeUrl}
      appleAppStoreUrl={appleAppStoreUrl}
      googlePlayStoreUrl={googlePlayStoreUrl}
    />
  </div>
);
