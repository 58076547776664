/* eslint-disable max-len */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import Button from '@guaranteed-rate/react-components/dist/Button';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Modal from '@guaranteed-rate/react-components/dist/Modal';
import Banner from '../../components/Banner/Banner';
import { fetchFspPageContent, getCode, getTimeDiff, redirectToMyALogin, setCustomErrorBasedOnResponse } from '../../config/util/common';
import { IPreferenceData } from '../../config/util/interfaces';
import { PreviousButton } from '../../components/PreviousButton/PreviousButton';
import RateAlertLogo from '../../components/RateAlertLogo/RateAlertLogo';
import { RateAlertWizardProgress } from '../../components/WizardProgress/RateAlertWizardProgress';
import { generateLink, getNextPanelBasedOnState } from './prefiCommon';
import { log } from '../../config/util/logger';
import { REDIRECT_TO_LOGIN_ERROR_CODE } from '../../config/content/constants';

const getLanguagePreferenceData = async (applicationId: string, isCoBorrower: boolean) => {
  const mutation = {
    query:
      `query {
        getLanguagePreferenceData(applicationId: "${applicationId}", isCoBorrower: ${isCoBorrower}) {
          language
          otherLanguageDescription
      }
    }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Query "getLanguagePreferenceData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.getLanguagePreferenceData) {
      log({ message: `Query "getLanguagePreferenceData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.getLanguagePreferenceData };
    }
    log({ message: `Query "getLanguagePreferenceData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Query "getLanguagePreferenceData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

const updateLanguagePreferenceData = async (applicationId: string, isCoBorrower: boolean, languagePreferenceRequest: any) => {
  const mutation = {
    query:
      `mutation {
        updateLanguagePreferenceData(applicationId: "${applicationId}", isCoBorrower: ${isCoBorrower}, languagePreferenceRequest: ${languagePreferenceRequest}) {
          applicationType
            application {
                id
                tenant
                applicationType
                createdAt
                updatedAt
                state
                currentState
                currentDynamicState
                previousState
                previousDynamicState
            }
    }
  }`
  };
  const uuid = uuidv4();
  const url = '/gateway/graphql';
  log({ message: `Mutation "updateLanguagePreferenceData" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
  const startTime = performance.now();
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
      'X-Request-ID': uuid,
      Authorization: Cookies.get('matc') ?? '',
    },
    body: JSON.stringify(mutation)
  });
  try {
    const data = await resp.json();
    const duration = getTimeDiff(startTime, performance.now());
    if (data?.data?.updateLanguagePreferenceData) {
      log({ message: `Mutation "updateLanguagePreferenceData" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
      return { content: data?.data?.updateLanguagePreferenceData };
    }
    log({ message: `Mutation "updateLanguagePreferenceData" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
    return { content: 'error', errors: data?.errors[0]?.message };
  } catch (e) {
    log({ message: `Mutation "updateLanguagePreferenceData" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
    console.error(e);
    return { content: 'error' };
  }
};

function PrefiLanguagePreference(props: any) {
  const navigate = useNavigate();
  const [languagePreferenceInfo, setLanguagePreferenceInfo] = useState<IPreferenceData>();
  const [languagePreferenceInfoFetched, setLanguagePreferenceInfoFetched] = useState(false);
  const [content, setContent] = useState(null as any);
  const [contentFetched, setContentFetched] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [trySubmit, setTrySubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(null as any);
  const [isOtherLang, setIsOtherLang] = useState(false);
  const [isOtherLangError, setIsOtherLangError] = useState(false);
  const [otherLanguageValue, setOtherLanguageValue] = useState(null as any);
  const [langPref, updateLangPref] = useState(null as any);
  const urlParams = new URLSearchParams(window.location.search);

  if (!content && !contentFetched && !error) {
    fetchFspPageContent('"prefi_language_preference"').then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiLanguagePreference: fetchFspPageContent(): Error fetching page content. ${JSON.stringify({ page: 'prefi_language_preference' })}`, level: 'error' });
          setError(true);
          setErrorMessage('Error fetching page content. Please Try again...');
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
          let languagePreference = data.content?.language_preference ? data.content?.language_preference?.map((item: any) => ({
            label: item.option.label,
            value: item.option.value,
            selected: false
          })) : [];
          updateLangPref(languagePreference);
        }
      }
    );
  }

  if (content && props.applicationId && !languagePreferenceInfo && !languagePreferenceInfoFetched && !error) {
    getLanguagePreferenceData(props.applicationId, props.isCoborrowerPage).then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiLanguagePreference: getLanguagePreferenceData(): isCoborrowerPage ${props.isCoborrowerPage}: Setting custom error based on response ${JSON.stringify({ data })}`, context: { applicationId: props.applicationId }, level: 'error' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          setLanguagePreferenceInfoFetched(false);
          if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
            redirectToMyALogin();
          }
        } else {
          setError(false);
          setLanguagePreferenceInfoFetched(true);
          setLanguagePreferenceInfo(data.content);
          setOtherLanguageValue(data.content.otherLanguageDescription ? data.content.otherLanguageDescription : '');
          handleLanguagePreference((data.content.language && (data.content.language !== 'Not Provided')) ? (`${data.content.language}Indicator`) : 'EnglishIndicator');
        }
      }
    );
  }

  const handleLanguagePreference = (selectedLanguage: any) => {
    setSelectedLang(selectedLanguage);
    if (selectedLanguage === 'OtherIndicator') {
      setIsOtherLang(true);
    } else {
      setIsOtherLang(false);
      setIsOtherLangError(false);
      setOtherLanguageValue(null);
    }
    updateLangPref((currentLangPref: any) => {
      let newObj = [...currentLangPref];
      newObj.forEach((lang: any) => {
        if (lang.value === selectedLanguage) {
          lang.selected = true;
        } else {
          lang.selected = false;
        }
      });
      return newObj;
    });
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleInfo = () => {
    setModalOpen(true);
  };

  const handleUpdateLanguagePreferenceData = (languagePreferenceRequest: any) => {
    updateLanguagePreferenceData(props.applicationId, props.isCoborrowerPage, languagePreferenceRequest).then(
      (data: any) => {
        setLoading(false);
        if (data.content === 'error') {
          log({ message: `PrefiLanguagePreference: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: updateLanguagePreferenceData(): Setting custom error based on response ${JSON.stringify({ data })}`, level: 'info' });
          setError(true);
          setErrorMessage(setCustomErrorBasedOnResponse(data, props.errors.errorTypes));
          if (getCode(data.errors) === REDIRECT_TO_LOGIN_ERROR_CODE) {
            redirectToMyALogin();
          }
        } else {
          setError(false);
          if (data.content?.application) {
            const nextPanel = getNextPanelBasedOnState(data);
            log({ message: `PrefiLanguagePreference: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: updateLanguagePreferenceData(): Success ${JSON.stringify({ nextPanel })}`, level: 'info' });
            navigate(generateLink(`/${nextPanel}${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`));
            window.scrollTo(0, 0);
          }
        }
      }
    );
  };

  const handleContinue = async () => {
    log({ message: `PrefiLanguagePreference: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: Handling continue`, level: 'info' });
    setTrySubmit(true);
    if (!isOtherLangError) {
      let languagePreferenceRequest = {};
      if (isOtherLang) {
        if (otherLanguageValue) {
          log({ message: `PrefiLanguagePreference: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: Client validation passed`, level: 'info' });
          setLoading(true);
          languagePreferenceRequest = `{
            languagePreference: "${selectedLang}",
            languageCodeOtherDescription: "${otherLanguageValue}",
          }`;
          handleUpdateLanguagePreferenceData(languagePreferenceRequest);
        } else {
          log({ message: `PrefiLanguagePreference: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: Other language error`, level: 'info' });
          setIsOtherLangError(true);
        }
      } else {
        log({ message: `PrefiLanguagePreference: handleContinue(): isCoborrowerPage ${props.isCoborrowerPage}: Client validation passed`, level: 'info' });
        setLoading(true);
        languagePreferenceRequest = `{
          languagePreference: "${selectedLang}",
        }`;
        handleUpdateLanguagePreferenceData(languagePreferenceRequest);
      }
    }
  };

  return (
    <div>
      {
        (content && languagePreferenceInfo)
          ? (
            <div>
              <div className="flex justify-center w-full">
                <div className="max-w-2xl w-full px-1">
                  <RateAlertWizardProgress className="lg:hidden px-4 mb-4" />
                  <div className="flex items-center justify-center">
                    <div className="px-4 w-full">
                      {
                        error
                          ? (
                            <Banner className="border-2 mt-4 mb-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
                          )
                          : ''
                      }
                      <RateAlertLogo alt={content?.header} />
                      <RateAlertWizardProgress className="hidden lg:block mt-6" />
                      <div className="mt-4">{content?.description}</div>
                      <div className="text-xl font-black mt-6">{props.isCoborrowerPage ? content?.coborrower_header : content?.header}</div>
                      <div className="w-full mt-4">
                        {
                          langPref?.map((language: any, index: number) => (
                            <div key={`langPref${index}`} className="py-1">
                              <input
                                type="radio"
                                className="checkbox"
                                checked={language.selected}
                                readOnly={false}
                                onChange={() => handleLanguagePreference(language.value)}
                              />
                              <span className="absolute leading-tight">{language.label}</span>
                            </div>
                          ))
                        }
                        {
                          isOtherLang
                            ? (
                              <TextInput
                                name="otherLanguageValue"
                                label={content?.form?.other_language_label}
                                className="w-full"
                                value={otherLanguageValue}
                                hasError={isOtherLangError}
                                helperText={isOtherLangError ? 'required field' : ''}
                                onChange={(input) => { setOtherLanguageValue(input.target.value); setIsOtherLangError(!input.target.value); }}
                              />
                            )
                            : ''
                        }
                      </div>
                      <div className="text-sm mt-4">
                        <span>{content?.form?.info}</span>
                        <span role="button" onClick={handleInfo} tabIndex={0}>
                          <FontIcon iconName="information-circle" className="absolute !leading-normal ml-0.5" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 mt-2 mb-8 w-full flex justify-between">
                    <div className="flex justify-center mt-12">
                      <PreviousButton isDynamic={true} applicationId={props.applicationId} />
                    </div>
                    <div className="items-center justify-center">
                      <div className="px-4 mt-2 mb-8 w-full">
                        <div className="flex justify-center mt-12">
                          <Button
                            buttonStyle="primary"
                            className="!w-full sm:!w-24 md:!w-48 confirmButton"
                            onClick={handleContinue}
                            buttonAttrs={{ disabled: loading || (isOtherLang && !otherLanguageValue) }}
                            loading={loading}
                          >{content?.form?.button_label}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal title="" isOpen={isModalOpen} toggleModal={toggleModal} clickOffToClose={true} className="info-modal">
                <div>
                  {
                    content?.info_modal?.split('\n').map((line: string, index: number) => (
                      <div key={`info_${index}`}>
                        <div className="text-left text-sm mb-4" key={`info${index}`} dangerouslySetInnerHTML={{ __html: line }} />
                      </div>
                    ))
                  }
                </div>
              </Modal>
            </div>
          ) : (
            <div className="px-4 w-full">
              {
                error
                  ? (
                    <Banner className="border-2 mt-4 mb-4" text={errorMessage} title="Error" type="error" icon="warning-triangle" isMultiline={true} errorContent={props.errors.errorContent} />
                  )
                  : ''
              }
            </div>
          )
      }
    </div>
  );
}

export default PrefiLanguagePreference;
