import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import { fetchPageContent } from '../../../config/util/common';
import OwningHeader from '../../../components/Header/OwningHeader';
import Footer from '../../../components/Footer/Footer';

export const OwningPreScreen = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState(null) as any;
  const [footerContent, setFooterContent] = useState(null) as any;
  const [error, setError] = useState(false);
  const [contentFetched, setContentFetched] = useState(false);
  const [params] = useSearchParams();
  const [applicationId, setApplicationId] = useState(params.get('id') || undefined);
  const [tcpaConfig, setTcpaConfig] = useState(null) as any;
  const [productType, setProductType] = useState('Purchase');
  const [redirectUrl, setRedirectUrl] = useState('');

  window.onpopstate = (e) => {
    navigate(0);
  };

  if (!content && !contentFetched && !error) {
    fetchPageContent('"owning_prescreen_form"').then(
      (data: any) => {
        if (data.content === 'error') {
          setError(true);
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
          setTcpaConfig(data.tcpaConfig);
        }
      }
    );
  }

  if (!footerContent) {
    fetchPageContent('"owning_footer"').then(
      (data: any) => {
        if (data.content === 'error') {
          setError(true);
        } else {
          setError(false);
          setFooterContent(data.content);
        }
      }
    );
  }

  const handleFormSubmit = async (mutation: string, setLoading: any) => {
    setLoading(true);
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.checkSameDayEligibility?.eligibilityCheck) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.append('id', data.data.checkSameDayEligibility.id);
        setApplicationId(data.data.checkSameDayEligibility.id);
        setLoading(false);
        if (data.data.checkSameDayEligibility.eligibilityCheck.foundEligible) {
          (window as any).digitalData.pageInfo.isEligible = true;
          setRedirectUrl(data.data.checkSameDayEligibility.eligibilityCheck.dmxAppUrl);
          navigate(`/pre-screen/qualified/?${urlSearchParams}`);
        } else {
          (window as any).digitalData.pageInfo.isEligible = false;
          setRedirectUrl(data.data.checkSameDayEligibility.eligibilityCheck.nonSameDayDmxAppUrl);
          navigate(`/pre-screen/notEligible/?${urlSearchParams}`);
        }
        window.scrollTo(0, 0);
      } else {
        console.error(data?.errors[0].message);
        setLoading(false);
        setError(true);
        navigate('/pre-screen/error');
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(true);
      navigate('/pre-screen/error');
    }
    return true;
  };

  const handleGriSubmit = async (mutation: string, setLoading: any) => {
    setLoading(true);
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.acceptNonSameDayLead?.eligibilityCheck) {
        const adTrk = Cookies.get('AdTrk');
        window.location.href = `${data.data.acceptNonSameDayLead.eligibilityCheck.nonSameDayDmxAppUrl}${adTrk && `&AdTrk=${adTrk}`}`;
      } else {
        console.error(data?.errors[0].message);
        setLoading(false);
        setError(true);
        navigate('/pre-screen/error');
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(true);
      navigate('/pre-screen/error');
    }
    return true;
  };

  useEffect(() => {
    if (window.location.pathname === '/pre-screen' || window.location.pathname === '/pre-screen/') {
      navigate('/pre-screen/form');
    }
  }, [window.location.pathname]);

  return content && (
    <>
      <div className="owning-pre-screen">
        <div className="w-full">
          <OwningHeader />
          <Loader />
          <Footer />
        </div>
      </div>
    </>
  );
};
