/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import Form from '../../../components/Form/Form';

interface IHelocManualReview {
    progress: any;
    pageName: string;
    handleSubmit: (pageData: any, back: boolean) => Promise<any>;
    content: any;
}

const HelocReviewPage = (props: IHelocManualReview) => {
  const [apiCount, setApiCount] = useState(1);

  useEffect(() => {
    handleSubmit();
    setApiNextApiCall(30000);
  }, [apiCount]);

  const setApiNextApiCall = (timeout: number) => {
    setTimeout(() => setApiCount(apiCount + 1), timeout);
  };

  const handleSubmit = async () => {
    const pageData = `{ 
      page: "${props.pageName}"
    }`;
    await props.handleSubmit(pageData, true);
  };

  return (
    <Form title="Thank you for your application" progress={props.progress}>
      <div className="min-h-[300px]">
        <p>Thank you for submitting your application! Your application is now under review by our team. You can expect to hear back from us over the next few business days.</p>
      </div>
    </Form>
  );
};

export default HelocReviewPage;
