export const states = [
  { value: 'ALABAMA', displayName: 'Alabama' },
  { value: 'ALASKA', displayName: 'Alaska' },
  { value: 'ARIZONA', displayName: 'Arizona' },
  { value: 'ARKANSAS', displayName: 'Arkansas' },
  { value: 'CALIFORNIA', displayName: 'California' },
  { value: 'COLORADO', displayName: 'Colorado' },
  { value: 'CONNECTICUT', displayName: 'Connecticut' },
  { value: 'DELAWARE', displayName: 'Delaware' },
  { value: 'DISTRICT_OF_COLUMBIA', displayName: 'District Of Columbia' },
  { value: 'FLORIDA', displayName: 'Florida' },
  { value: 'GEORGIA', displayName: 'Georgia' },
  { value: 'HAWAII', displayName: 'Hawaii' },
  { value: 'IDAHO', displayName: 'Idaho' },
  { value: 'ILLINOIS', displayName: 'Illinois' },
  { value: 'INDIANA', displayName: 'Indiana' },
  { value: 'IOWA', displayName: 'Iowa' },
  { value: 'KANSAS', displayName: 'Kansas' },
  { value: 'KENTUCKY', displayName: 'Kentucky' },
  { value: 'LOUISIANA', displayName: 'Louisiana' },
  { value: 'MAINE', displayName: 'Maine' },
  { value: 'MARYLAND', displayName: 'Maryland' },
  { value: 'MASSACHUSETTS', displayName: 'Massachusetts' },
  { value: 'MICHIGAN', displayName: 'Michigan' },
  { value: 'MINNESOTA', displayName: 'Minnesota' },
  { value: 'MISSISSIPPI', displayName: 'Mississippi' },
  { value: 'MISSOURI', displayName: 'Missouri' },
  { value: 'MONTANA', displayName: 'Montana' },
  { value: 'NEBRASKA', displayName: 'Nebraska' },
  { value: 'NEVADA', displayName: 'Nevada' },
  { value: 'NEW_HAMPSHIRE', displayName: 'New Hampshire' },
  { value: 'NEW_JERSEY', displayName: 'New Jersey' },
  { value: 'NEW_MEXICO', displayName: 'New Mexico' },
  { value: 'NEW_YORK', displayName: 'New York' },
  { value: 'NORTH_CAROLINA', displayName: 'North Carolina' },
  { value: 'NORTH_DAKOTA', displayName: 'North Dakota' },
  { value: 'OHIO', displayName: 'Ohio' },
  { value: 'OKLAHOMA', displayName: 'Oklahoma' },
  { value: 'OREGON', displayName: 'Oregon' },
  { value: 'PENNSYLVANIA', displayName: 'Pennsylvania' },
  { value: 'RHODE_ISLAND', displayName: 'Rhode Island' },
  { value: 'SOUTH_CAROLINA', displayName: 'South Carolina' },
  { value: 'SOUTH_DAKOTA', displayName: 'South Dakota' },
  { value: 'TENNESSEE', displayName: 'Tennessee' },
  { value: 'TEXAS', displayName: 'Texas' },
  { value: 'UTAH', displayName: 'Utah' },
  { value: 'VERMONT', displayName: 'Vermont' },
  { value: 'VIRGINIA', displayName: 'Virginia' },
  { value: 'WASHINGTON', displayName: 'Washington' },
  { value: 'WEST_VIRGINIA', displayName: 'West Virginia' },
  { value: 'WISCONSIN', displayName: 'Wisconsin' },
  { value: 'WYOMING', displayName: 'Wyoming' }
];

export const helocLiteStates = [
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'DC', name: 'District of Columbia' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'MD', name: 'Maryland' },
  { value: 'ME', name: 'Maine' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'UT', name: 'Utah' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
];

export const mortgageTypes = [
  { value: 'mortgage', displayName: 'Mortgage' },
  { value: 'heloc', displayName: 'HELOC' },
];

export const yesNo = [
  {
    value: 'true',
    displayName: 'Yes'
  },
  {
    value: 'false',
    displayName: 'No'
  }
];
export const citizenStatus = [
  {
    value: 'US_CITIZEN',
    displayName: 'US Citizen'
  },
  {
    value: 'PERMANENT_ALIEN',
    displayName: 'Permanent Resident Alien'
  },
  {
    value: 'NON_PERMANENT_ALIEN',
    displayName: 'Non-Permanent Resident Alien'
  },
];
