import Button from '@guaranteed-rate/react-components/dist/Button';
import Img from '@guaranteed-rate/react-components/dist/Img';
import { useState } from 'react';
import { renderParagraph } from '../../config/util/common';

function OwningGri(props: any) {
  const content = props.pageContent;
  const [loading, setLoading] = useState(false);
  const handleGriSubmit = async () => {
    setLoading(true);
    const mutation = {
      query:
        `mutation {
        acceptNonSameDayLead(id: "${props.applicationId}") {
          id
          pageContentJson
          eligibilityCheck {
            id
            nonSameDayDmxGuid
            nonSameDayDmxAppUrl
          }
        }
      }`
    };
    props.handleSubmit(mutation, setLoading);
    return true;
  };

  return (
    <div>
      <div className="flex justify-center w-full">
        <div className="w-full">
          <div className="flex justify-center mt-16">
            <h1 className="font-bold justify-center">{content.gri.header}</h1>
          </div>
          <div className="flex justify-center mt-8">
            <h4 className="font-bold text-xl justify-center">{content.gri.sub_header}</h4>
          </div>
          <div className="flex justify-center mt-8">
            <ul>
              {content?.eligibility_criteria.map((bullet: any) => (
                <li key={bullet.bullet._metadata.uid} className="mb-2">
                  <Img
                    src="/images/check.svg"
                    alt="check"
                    width={15}
                    height={0}
                    className="inline mr-4"
                  />{bullet.bullet.text}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex justify-center mt-8">
            {renderParagraph(content.gri.description, 'mt-4 text-center max-w-md px-7')}
          </div>
          <div className="flex justify-center mt-12">
            <Button
              buttonStyle="primary"
              onClick={handleGriSubmit}
              loading={loading}
              buttonAttrs={{ disabled: loading }}
            >{content.gri.cta_label}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OwningGri;
