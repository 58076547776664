import { Dispatch, SetStateAction, useState } from 'react';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import { useNavigate } from 'react-router-dom';
import { MASKS } from '../../../config/util/masks';
import { formatRichText, isPhoneValid, validateEmail } from '../../../config/util/common';
import { ILeadInput } from '../../../config/util/interfaces';
import { affordabilityPathMap } from '../AffordabilityPage';

interface ISaveProgressProps {
  leadInput: ILeadInput;
  setLeadInput: Dispatch<SetStateAction<ILeadInput>>;
  handleDMXSubmit: (leadInput: ILeadInput) => Promise<any>;
  content: any;
  isApplyLoading: boolean;
}

interface ISaveProgressForm {
  first: string;
  last: string;
  email: string;
  phone: string;
}

const loadExistingData = (leadInput: ILeadInput) => ({
  first: leadInput?.firstName || '',
  last: leadInput?.lastName || '',
  email: leadInput?.email || '',
  phone: leadInput?.phone || '',
} as ISaveProgressForm);

export const AffordabilitySaveProgressPage = (props: ISaveProgressProps) => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const [saveProgressForm, setSaveProgressForm] = useState(loadExistingData(props.leadInput));
  const [trySubmit, setTrySubmit] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setSaveProgressForm({ ...saveProgressForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    setTrySubmit(true);
    const keys = Object.keys(saveProgressForm);
    if (keys.every((key) => saveProgressForm[key as keyof ISaveProgressForm].length > 0)
    && (saveProgressForm.phone.length === 10 && isPhoneValid(saveProgressForm.phone)) && validateEmail(saveProgressForm.email)) {
      const leadInput = {
        ...props.leadInput,
        firstName: saveProgressForm.first,
        lastName: saveProgressForm.last,
        phone: saveProgressForm.phone,
        email: saveProgressForm.email,
      };
      props.setLeadInput(leadInput);
      props.handleDMXSubmit(leadInput);
    }
  };

  const handleBack = async () => {
    navigate(`${affordabilityPathMap[(window as any).env as keyof typeof affordabilityPathMap]
      ?? '/home-affordability/'}review/?${urlParams.size > 0 ? urlParams.toString() : ''}`);
  };
  const hasError = (name: string) => trySubmit && saveProgressForm[name as keyof ISaveProgressForm].length <= 0;

  return (
    <div className="md:flex justify-center">
      <div className="md:max-w-2xl md:grow">
        <h2 className="text-[32px] md:text-[48px] font-extrabold pt-4 md:pt-8 md:leading-[4rem]">{props.content.header}</h2>
        <div className="min-h-[400px] mb-8">
          <h4 className="mb-4 md:mb-8 mt-4 md:mt-0">{props.content.description}</h4>
          <div className="md:flex justify-center w-full md:space-x-4">
            <TextInput
              name="first"
              className="grow"
              value={saveProgressForm.first}
              label={props.content.first_name_label}
              onChange={(input) => handleChange(input, 'first')}
              hasError={hasError('first')}
              helperText={hasError('first') ? 'required' : undefined}
              required={true}
            />
            <TextInput
              name="last"
              className="grow"
              value={saveProgressForm.last}
              label={props.content.last_name_label}
              onChange={(input) => handleChange(input, 'last')}
              hasError={hasError('last')}
              helperText={hasError('last') ? 'required' : undefined}
              required={true}
            />
          </div>
          <div className="md:flex justify-center w-full  md:space-x-4">
            <TextInput
              name="phone"
              className="grow"
              value={saveProgressForm.phone}
              label={props.content.phone_number_label}
              onChange={(input) => handleChange(input, 'phone')}
              hasError={trySubmit && (hasError('phone') || !isPhoneValid(saveProgressForm.phone))}
              helperText={(trySubmit && saveProgressForm.phone.length < 10) ? 'required'
                : (saveProgressForm.phone.length === 10 && !isPhoneValid(saveProgressForm.phone))
                  ? props.content.invalid_phone_no_error_label : undefined}
              required={true}
              mask={MASKS.PHONE}
            />
            <TextInput
              name="email"
              className="grow"
              value={saveProgressForm.email}
              label={props.content.email_address_label}
              onChange={handleChange}
              hasError={trySubmit && !validateEmail(saveProgressForm.email)}
              helperText={trySubmit && !validateEmail(saveProgressForm.email) ? 'required' : undefined}
              required={true}
            />
          </div>
          <div className="whitespace-pre-line">
            {formatRichText('disclaimer', props?.content?.disclaimer, props?.content)}
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <Button
            buttonStyle="primary"
            className="!w-full md:!w-48 continue-btn"
            onClick={handleSubmit}
            loading={props.isApplyLoading}
            disabled={props.isApplyLoading}
            buttonAttrs={{ disabled: props.isApplyLoading }}
          >Continue
          </Button>
        </div>
        <div className="flex -ml-8 md:ml-0 md:block justify-center mt-6 md:-mt-11">
          <Button
            className="back-btn"
            buttonStyle="quaternary"
            iconPos="left"
            iconName="chevron-left-large"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        <div className="text-center pt-12 ">
          <p>{props.content.saveProgress_description}</p>
          <p>{props.content.other_saveProgress_description}</p>
        </div>
      </div>
    </div>
  );
};
