/* eslint-disable max-len */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { useState } from 'react';
import Button from '@guaranteed-rate/react-components/dist/Button';
import CreditScore from '@guaranteed-rate/react-components/dist/CreditScore';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import Countdown from 'react-countdown';
import { fetchFspPageContent, getEncompassIdData, formatRichText, loadDate, percentageCalculator, renderParagraph, timeRenderer, getTimeDiff, getCode, redirectToMyALogin } from '../../config/util/common';
import Banner from '../../components/Banner/Banner';
import { IBorrowerCreditScore } from '../../config/util/interfaces';
import PageLoader from '../../components/PageLoader/PageLoader';
import { config } from '../../config/content/config';
import { RateAppDownloadCta } from '../../components/RateAppDownloadCta/RateAppDownloadCta';
import { usePrefiContext } from './PrefiContext';
import RateAlertLogo from '../../components/RateAlertLogo/RateAlertLogo';
import { RateAlertWizardProgress } from '../../components/WizardProgress/RateAlertWizardProgress';
import { log } from '../../config/util/logger';
import { calculatePrefiCreditStatus } from './util/credit';
import { REDIRECT_TO_LOGIN_ERROR_CODE } from '../../config/content/constants';

function PrefiSoftCredit(props: any) {
  const [content, setContent] = useState(null as any);
  const [contentFetched, setContentFetched] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null as any);
  const [creditReport, setCreditReport] = useState({} as IBorrowerCreditScore);
  const [creditReportOfCoBorrower, setCreditReportOfCoBorrower] = useState<IBorrowerCreditScore | undefined>(undefined);
  const [creditReportFetchAttempted, setCreditReportFetchAttempted] = useState(false);
  const [creditReportFetchLoading, setCreditReportFetchLoading] = useState(false);

  const { prefiCommonContent } = usePrefiContext();

  if (!content && !contentFetched && !error) {
    fetchFspPageContent('"prefi_soft_credit"').then(
      (data: any) => {
        if (data.content === 'error') {
          log({ message: `PrefiSoftCredit: fetchFspPageContent(): Error fetching page content. ${JSON.stringify({ page: 'prefi_soft_credit' })}`, level: 'error' });
          setError(true);
          setErrorMessage('Error fetching page content. Please try again.');
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
        }
      }
    );
  }

  const handleTaskBtn = async () => {
    setError(false);
    getEncompassIdData(props?.applicationId).then(
      (data) => {
        const encompassId = data.content?.encompassId;
        log({
          message: `PrefiSoftCredit: handleTaskBtn(): Redirecting to MyAccount ${JSON.stringify(
            { encompassId }
          )}`,
          context: { applicationId: props?.applicationId },
          level: 'info',
        });
        window.location.href = encompassId
          ? `${(config.myAccountUrl as any)[(window as any).env]}/loan/${encompassId}`
          : `${(config.myAccountUrl as any)[(window as any).env]}/okta/login`;
      }
    );
    window.scrollTo(0, 0);
  };

  const setCreditReportDynamically = (data: any, indexValue: number, isCoBorrower: boolean) => {
    const creditDate = loadDate(data.data.getBorrowerCreditScore[indexValue].creditPullSuccessTimestamp);
    if (isCoBorrower) {
      setCreditReportOfCoBorrower({ ...data?.data?.getBorrowerCreditScore[indexValue], creditPullSuccessTimestamp: creditDate.toLocaleDateString() });
    } else {
      setCreditReport({ ...data?.data?.getBorrowerCreditScore[indexValue], creditPullSuccessTimestamp: creditDate.toLocaleDateString() });
    }
    document.documentElement.style.setProperty('--percentage', percentageCalculator(900, data?.data?.getBorrowerCreditScore[indexValue]?.creditScore));
    document.documentElement.style.setProperty('--fill', '#1780A3');
  };

  const getCreditScore = async (applicationId: string) => {
    setCreditReportFetchAttempted(true);
    const mutation = {
      query:
        `query {
        getBorrowerCreditScore(applicationId: "${applicationId}") {
          creditVendor
          creditScore
          responseCreditPullType
          creditPullSuccessTimestamp
          coBorrower
          name
      }
    }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    log({ message: `Query "getBorrowerCreditScore" ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'info', requestId: uuid });
    setCreditReportFetchLoading(true);
    const startTime = performance.now();
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId}`,
        'X-Request-ID': uuid,
        Authorization: Cookies.get('matc') ?? '',
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      const duration = getTimeDiff(startTime, performance.now());
      if (data?.data?.getBorrowerCreditScore) {
        const indexOfBorrowerCreditScoreObject = data.data.getBorrowerCreditScore.indexOf(data.data.getBorrowerCreditScore.find((i: any) => i.coBorrower === false));
        setCreditReportDynamically(data, indexOfBorrowerCreditScoreObject, false);
        const indexOfCoBorrowerCreditScoreObject = data.data.getBorrowerCreditScore.indexOf(data.data.getBorrowerCreditScore.find((i: any) => i.coBorrower === true));
        if (indexOfCoBorrowerCreditScoreObject >= 0) {
          setCreditReportDynamically(data, indexOfCoBorrowerCreditScoreObject, true);
        }
        log({ message: `Query "getBorrowerCreditScore" was successful ${JSON.stringify({ applicationId, duration })}`, context: { applicationId }, level: 'info', requestId: uuid });
        return;
      }
      if (getCode(data?.errors[0]?.message) === REDIRECT_TO_LOGIN_ERROR_CODE) {
        redirectToMyALogin();
      }
      log({ message: `Query "getBorrowerCreditScore" failed with errors ${JSON.stringify({ applicationId, duration, errors: data?.errors[0]?.message })}`, context: { applicationId }, level: 'error', requestId: uuid });
      return { content: 'error', errors: data?.errors[0]?.message };
    } catch (e) {
      log({ message: `Query "getBorrowerCreditScore" failed due to exception ${JSON.stringify({ applicationId })}`, context: { applicationId }, level: 'error', requestId: uuid });
      console.error(e);
      return { content: 'error' };
    } finally {
      setCreditReportFetchLoading(false);
    }
  };

  if (!creditReportFetchAttempted) {
    getCreditScore(props?.applicationId);
  }

  const creditStatus = calculatePrefiCreditStatus({
    creditReportFetchLoading,
    creditReport,
    creditReportOfCoBorrower
  });

  return (
    <>
      <div>
        {(content)
          ? (
            <div className="flex justify-center w-full">
              <div className="max-w-2xl w-full px-1">
                <div className="flex flex-col items-stretch justify-center">
                  <RateAlertWizardProgress className="lg:hidden px-4 mb-4" subheaderOverride={creditStatus === 'goodCredit' ? 'Success' : undefined} />
                  <div className="px-4 w-full">
                    {
                      error
                        ? (
                          <Banner className="border-2 mt-4 mb-4" text="" title={errorMessage} type="error" icon="warning-triangle" />
                        )
                        : ''
                    }
                    <RateAlertLogo alt={content?.header} />
                    <RateAlertWizardProgress className="hidden lg:block mt-6 mb-4" subheaderOverride={creditStatus === 'goodCredit' ? 'Success' : undefined} />
                    {creditStatus === 'poorCredit' && (
                      <div>
                        <div className="mt-4 bold-link-container">{formatRichText('rateAppLink', content.low_credit_error_message, content)}
                        </div>
                      </div>
                    )}
                    {creditStatus === 'goodCredit' && (
                      <div>
                        <div className="mt-4">{content?.description}</div>
                        <div className="mt-4">{content?.soft_credit_description}</div>
                      </div>
                    )}
                  </div>
                </div>
                {
                  (creditStatus === 'goodCredit' || creditStatus === 'poorCredit') && (
                    <div>
                      <div>
                        <div className="text-sm font-bold flex items-center justify-center mt-8">{creditReport?.name}</div>
                        <div className="flex items-center justify-center">
                          <CreditScore creditScore={creditReport.creditScore || 0} captureDate={creditReport.creditPullSuccessTimestamp} />
                        </div>
                      </div>
                      {
                        creditReportOfCoBorrower && (
                          <div>
                            <div className="text-sm font-bold flex items-center justify-center mt-8">{creditReportOfCoBorrower.name}</div>
                            <div className="flex items-center justify-center">
                              <CreditScore creditScore={creditReportOfCoBorrower.creditScore || 0} captureDate={creditReportOfCoBorrower.creditPullSuccessTimestamp} />
                            </div>
                          </div>
                        )
                      }
                      {creditStatus === 'goodCredit' ? (
                        <div>
                          <div className="flex justify-center mt-12">
                            <h4 className="font-bold"><FontIcon iconName="clock-pending" className="mr-1 !leading-8 text-lg" />
                              {`${content?.redirect_text}`}<Countdown date={Date.now() + parseInt(content?.redirect_time) * 1000} renderer={timeRenderer} onComplete={handleTaskBtn} />
                            </h4>
                          </div>
                          <div className="px-4 mt-2 mb-8 w-full">
                            <div className="flex justify-center mt-12">
                              <Button
                                buttonStyle="primary"
                                className="!w-full md:!w-48 confirmButton"
                                onClick={handleTaskBtn}
                              >{content?.task_button_label}
                              </Button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <RateAppDownloadCta
                          className="m-4"
                          header={prefiCommonContent?.rate_app_banner_download_header}
                          description={formatRichText('rateAppDownloadCtaDescription', prefiCommonContent?.rate_app_banner_download_description || '', content)}
                          screenshotUrl={prefiCommonContent?.rate_app_banner_screenshot?.url}
                          qrCodeUrl={prefiCommonContent?.rate_app_banner_qr_code.url}
                        />
                      )}
                    </div>
                  )
                }
                {creditStatus === 'loading' && (
                  <div className="px-4 w-full">
                    <PageLoader />
                    <div className="flex items-center justify-center -mt-24 font-bold">{content?.loader_message}</div>
                  </div>
                )}
                {creditStatus === 'error' && (
                  <div>
                    <div className="px-4 mt-4 mb-8 bold-link-container">{renderParagraph(content?.technical_error_message)}
                    </div>
                    <RateAppDownloadCta
                      header={prefiCommonContent?.rate_app_banner_download_header}
                      description={formatRichText('rateAppDownloadCtaDescription', prefiCommonContent?.rate_app_banner_download_description || '', content)}
                      screenshotUrl={prefiCommonContent?.rate_app_banner_screenshot?.url}
                      qrCodeUrl={prefiCommonContent?.rate_app_banner_qr_code.url}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : ''}
      </div>
    </>
  );
}

export default PrefiSoftCredit;
