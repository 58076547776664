/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import Form from '../../../components/Form/Form';
import { useScript } from '../../../config/hooks/useScript';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import { IIOEOrderDetails } from '../../../config/util/interfaces';
import { objectToGraphQl } from '../../../config/util/common';

interface IHelocBankVerificationProps {
  handleSuccess: (result: any) => void;
  handleError: (error: any) => void;
  handleBack: () => void;
  content: any;
  progress?: ProgressProps;
  applicationId: string;
  isAdditional?: boolean;
}

export const HelocBankVerification = (props: IHelocBankVerificationProps) => {
  const [loading, setLoading] = useState(false);
  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [orderIdDetails, setOrderIdDetails] = useState<IIOEOrderDetails>();
  const onScriptLoad = () => {
    if (!sdkLoaded) {
      setSdkLoaded(true);
      createIOEOrder();
      setLoading(false);
    }
  };

  const launchFinicity = (orderDetails: IIOEOrderDetails) => {
    (window as any).finicityConnect.launch(orderDetails?.identifier, {
      selector: '#connect-container',
      overlay: 'rgba(255,255,255, 0)',
      success: async (event: any) => {
        submitIOEOrder(orderDetails.orderId);
      },
      cancel: (event: any) => {
        // set error true
        console.log('The user cancelled the iframe', event);
      },
      error: (error: any) => {
        // set error true
        props.handleError(error);
        console.error('Some runtime error was generated during insideConnect ', error);
      },
      loaded: () => {
        console.log('This gets called only once after the iframe has finished loading ');
      },
      route: (event: any) => {
        console.log('This is called as the user navigates through Connect ', event);
      },
      user: (event: any) => {
        console.log('This is called as the user interacts with Connect ', event);
      }
    });
  };

  useScript('https://connect2.finicity.com/assets/sdk/finicity-connect.min.js', false, onScriptLoad);

  const handleBack = async () => {
    props.handleBack();
  };

  const createIOEOrder = async () => {
    setLoading(true);
    const mutation = {
      query:
        `mutation {
          createIOEOrder(
            id: "${props.applicationId}",
            data: {
              product: "voai",
            }
            isAdditional: ${props.isAdditional ? 'true' : 'false'}
          ) {
            orderId
            product
            identifier
          }
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.createIOEOrder) {
        setOrderIdDetails(data.data.createIOEOrder);
        launchFinicity(data.data.createIOEOrder);
      } else {
        props.handleError(data?.errors[0]);
        setLoading(false);
      }
      console.log(data);
    } catch (e) {
      props.handleError(e);
      setLoading(false);
    }
  };

  const submitIOEOrder = async (orderId: string) => {
    setLoading(true);
    const mutation = {
      query:
        `mutation {
          submitIOEOrder(
            id: "${props.applicationId}",
            data: {
              product: "voai",
              orderId: "${orderId}",
            } 
            isAdditional: ${props.isAdditional ? 'true' : 'false'}
          ) {
            id
            page
            accounts {
              number
              name
              bankType
              institutionName
              accountid
            }
            verificationData {
              incomeAndEmploymentOrderStatus {
                voi
                voieInstant
                voiePayroll
              }
            }
          }
        }`
    };
    const uuid = uuidv4();
    const url = '/gateway/graphql';
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-GR-FSP-TENANT-ID': `${(window as any).tenantId.toLowerCase()}`,
        'X-Request-ID': uuid,
      },
      body: JSON.stringify(mutation)
    });
    try {
      const data = await resp.json();
      if (data?.data?.submitIOEOrder) {
        props.handleSuccess(data?.data?.submitIOEOrder);
      } else {
        props.handleError(data?.errors[0]);
      }
      console.log(data);
    } catch (e) {
      props.handleError(e);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {
        !sdkLoaded || loading
          ? (
            <div className="min-h-[420px] flex items-center justify-center">
              <Loader color="#D13239" className="loader-medium" />
            </div>
          )
          : (
            <div>
              <div className="min-h-[420px]">
                <div id="connect-container" className="w-full h-[720px]" />
              </div>
              <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                <Button buttonStyle="quaternary" iconPos="left" iconName="chevron-left-large" onClick={handleBack}>Back</Button>
              </div>
            </div>
          )
      }
    </div>
  );
};
