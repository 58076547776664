import Img from '@guaranteed-rate/react-components/dist/Img';
import ProductLabel from '@guaranteed-rate/react-components/dist/ProductLabel';
import clsx from 'clsx';
import { IRateAppDownloadCtaProps } from './types';
import {
  DEFAULT_RATE_APP_DOWNLOAD_CTA_HEADER,
  DEFAULT_RATE_APP_DOWNLOAD_CTA_DESCRIPTION,
  DEFAULT_RATE_APP_SCREENSHOT_URL,
  DEFAULT_RATE_APP_APPLE_APP_STORE_URL,
  DEFAULT_RATE_APP_PLAY_STORE_URL,
} from './constants';

export const RateAppDownloadCtaMobileLayout = ({
  className,
  header = DEFAULT_RATE_APP_DOWNLOAD_CTA_HEADER,
  description = DEFAULT_RATE_APP_DOWNLOAD_CTA_DESCRIPTION,
  screenshotUrl = DEFAULT_RATE_APP_SCREENSHOT_URL,
  appleAppStoreUrl = DEFAULT_RATE_APP_APPLE_APP_STORE_URL,
  googlePlayStoreUrl = DEFAULT_RATE_APP_PLAY_STORE_URL,
}: IRateAppDownloadCtaProps) => (
  <div
    className={clsx(className, 'app-banner-gradient-background pt-6 pl-6 pr-6 rounded-xl')}
  >
    <ProductLabel text="Rate App" />
    <h2>{header}</h2>
    <p>{description}</p>
    <div className="flex items-center justify-center">
      <a
        className="mr-4"
        href={appleAppStoreUrl}
        target="_blank"
        rel="noreferrer"
      >
        <Img
          src="/images/app-badge-apple-app-store-en.svg"
          alt="Rate App Apple App Store"
          width={132}
          height={44}
        />
      </a>
      <a
        className="mr-4"
        href={googlePlayStoreUrl}
        target="_blank"
        rel="noreferrer"
      >
        <Img
          src="/images/app-badge-google-play-en.png"
          alt="Rate App Play Store"
          width={146}
          height={44}
        />
      </a>
    </div>
    <div className="flex justify-center items-center mt-4">
      <img
        src={screenshotUrl}
        alt="Rate App screenshot"
        className="max-w-60"
      />
    </div>
  </div>
);
