import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Error404Page } from '../Error/Error404Page';
import { PayrollTest } from './Payroll/PayrollTest';
import { BankAccountTest } from './BackAccount/BankAccountTest';

export const TestPage = () => {
  const navigate = useNavigate();

  // Route the application based on the page returned from gateway
  return (
    <div>
      <Routes>
        <Route path="/bankAccount/*" element={(<BankAccountTest />)} />
        <Route path="/payroll/*" element={(<PayrollTest />)} />
        <Route path="/*" element={(<Error404Page />)} />
      </Routes>
    </div>
  );
};
