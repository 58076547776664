import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import ToolTip from '@guaranteed-rate/react-components/dist/ToolTip';
import { ReactNode } from 'react';

interface IScaleProps {
  percent: number;
  tooltip: string;
}

const AffordabilityScale = (props: IScaleProps) => {
  const { percent, tooltip } = props;
  return (
    <div className="flex">
      <div className="w-[50%] mr-1">
        <label className="text-xs">Affordable</label>
        { percent > 50 && (
          <div className="text-2xl -mt-2 -mb-3 flex justify-end -mr-2 ml-3" style={{ width: `${100 - ((percent - 50.0) / 50) * 100}%` }}>
            <FontIcon iconName="sort-descending" />
          </div>
        ) }
        <div className={`green scale ${percent <= 50 ? 'mt-3' : 'mt-2'}`} />
      </div>
      <div className="w-[25%] ml-1 mr-1">
        <label className="text-xs">Stretch</label>
        { percent <= 50 && percent > 25 && (
          <div className="text-2xl -mt-2 -mb-3 flex justify-end -mr-2 ml-3" style={{ width: `${100 - ((percent - 25.0) / 25) * 100}%` }}>
            <FontIcon iconName="sort-descending" />
          </div>
        ) }
        <div className={`yellow scale ${(percent <= 25 || percent > 50) ? 'mt-3' : 'mt-2'}`} />
      </div>
      <div className="w-[25%] ml-1">
        <label className="text-xs">Aggressive</label>
        { percent <= 25 && (
          <div className="text-2xl -mt-2 -mb-3 flex justify-end -mr-2 ml-3" style={{ width: `${100 - ((percent) / 25) * 100}%` }}>
            <FontIcon iconName="sort-descending" />
          </div>
        ) }
        <div className={`red scale ${percent > 15 ? 'mt-3' : 'mt-2'}`} />
      </div>
      <ToolTip iconName="information-circle" content={tooltip} offset="right" />
    </div>
  );
};

export default AffordabilityScale;
