import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import Card from '../Card/Card';

interface IDocumentUploadProps {
  label: string;
  name: string;
  uploadedFile: any;
  isMultiUpload: boolean;
  uploadError: string;
  loading: boolean;
  onFileChangeCallback: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteFileCallback: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
}

const DocumentUpload = (props: IDocumentUploadProps) => (
  <div className={`${props.className} min-h-[170px]`}>
    <h3 className="font-bold mb-4">{props.label}</h3>
    <Card className="mb-6">
      <div>
        <input
          type="file"
          name={props.name}
          id={props.name}
          className="hidden"
          multiple={props.isMultiUpload}
          onChange={props.onFileChangeCallback}
        />
        <label htmlFor={props.name}>
          <div className="text-center p-4 cursor-pointer flex justify-center items-center gap-[4px]">
            <FontIcon
              iconName="cloud-upload"
              className="tertiary text-xl !leading-5"
            />
            <span className="font-bold tertiary">Browse Files</span> or
            <span className="font-bold tertiary">take a photo</span>
          </div>
        </label>
      </div>
    </Card>
    {props.uploadedFile.name && (
      <div className="mb-6">
        <div
          className="flex justify-between gap-4"
          key={`${props.uploadedFile.name}`}
        >
          <span className="font-bold tertiary">{props.uploadedFile.name}</span>
          <button onClick={props.onDeleteFileCallback}>
            {props.loading ? (
              <Loader className="paystub loading" />
            ) : (
              <FontIcon
                iconName={!props.uploadError ? 'trash' : 'redo'}
                className="paystub text-xl !leading-5"
              />
            )}
          </button>
        </div>
        {props.uploadError && (
          <span className="paystub error">{props.uploadError}</span>
        )}
      </div>
    )}
  </div>
);

export default DocumentUpload;
