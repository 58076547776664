import Button from '@guaranteed-rate/react-components/dist/Button';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import { ReactNode } from 'react';
import AffordabilityScale from '../AffordabilityScale/AffordabilityScale';

interface ITableProps {
  header: string;
  labels: Array<string>;
  values: Array<string>;
  showDetails: boolean;
  setShowDetails?: (value: boolean) => void;
  percent?: number;
  tooltip?: string;
}

const ReviewTable = (props: ITableProps) => {
  const { percent, labels, values, setShowDetails, header, showDetails, tooltip } = props;
  return (
    <div className="w-full border-2 border-neutral-200 p-6 rounded-md">
      <div className="flex justify-between">
        <h4 className="font-bold pb-4">{header}</h4>
        {
          setShowDetails && (
            <div className="md:hidden">
              {
                showDetails ? (
                  <Button
                    buttonStyle="tertiary"
                    iconPos="left"
                    iconName=""
                    onClick={(event: any) => setShowDetails(false)}
                    buttonSize="small"
                  ><FontIcon fontSize="16px" iconName="chevron-up-small" className="font-bold !leading-6" /> Hide details
                  </Button>
                ) : (
                  <Button
                    buttonStyle="tertiary"
                    iconPos="left"
                    iconName=""
                    onClick={(event: any) => setShowDetails(true)}
                    buttonSize="small"
                  ><FontIcon fontSize="16px" iconName="chevron-down-small" className="font-bold !leading-6" /> Show details
                  </Button>
                )
              }
            </div>
          )
        }
      </div>
      <div className="hidden md:flex mb-4">
        {
          labels.slice(0, Math.min(labels.length, 3)).map((label: string, index: number) => (
            <div className="w-[30%] mr-4" key={`${label}${index}`}>
              <label>{label}</label>
              <p className="font-bold">{values[index]}</p>
            </div>
          ))
        }
      </div>
      {
        labels.length > 3 && (
          <div className="hidden md:flex mb-4">
            {
              labels.slice(3, Math.min(labels.length, 6)).map((label: string, index: number) => (
                <div className="w-[30%] mr-4" key={`${label}${index}`}>
                  <label>{label}</label>
                  <p className="font-bold">{values[index + 3]}</p>
                </div>
              ))
            }
          </div>
        )
      }
      {
        showDetails && (
          <div>
            <div className="flex md:hidden mb-4">
              {
                labels.slice(0, Math.min(labels.length, 2)).map((label: string, index: number) => (
                  <div className="w-[50%] mr-4 flex flex-col justify-end" key={`${label}${index}`}>
                    <label>{label}</label>
                    <p className="font-bold">{values[index]}</p>
                  </div>
                ))
              }
            </div>
            {
              labels.length > 2 && (
                <div className="flex md:hidden mb-4">
                  {
                    labels.slice(2, Math.min(labels.length, 4)).map((label: string, index: number) => (
                      <div className="w-[50%] mr-4 flex flex-col justify-end" key={`${label}${index}`}>
                        <label>{label}</label>
                        <p className="font-bold">{values[index + 2]}</p>
                      </div>
                    ))
                  }
                </div>
              )
            }
            {
              labels.length > 4 && (
                <div className="flex md:hidden mb-4">
                  {
                    labels.slice(4, Math.min(labels.length, 6)).map((label: string, index: number) => (
                      <div className="w-[50%] mr-4 flex flex-col justify-end" key={`${label}${index}`}>
                        <label>{label}</label>
                        <p className="font-bold">{values[index + 4]}</p>
                      </div>
                    ))
                  }
                </div>
              )
            }
          </div>
        )
      }
      { percent && tooltip && <AffordabilityScale percent={percent} tooltip={tooltip} /> }
    </div>
  );
};

export default ReviewTable;
