import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import { ProgressProps } from './IProgressList';
import './styles.css';

const HorizontalProgressList = (props: { data: ProgressProps }) => (
  <div className="font-bold">
    <div>
      <div className="flex justify-between -mt-2">
        {props.data?.steps?.map((step, i) => (
          <div className="text-center grow" id={`step${i}`} key={`progressStep${i}`}>
            {
              step.complete
                ? <FontIcon iconName="check-tick" className="bg-white px-2 text-[#53A8A0]" />
                : step.active
                  ? (
                    <div className="bg-white px-2 inline-block">
                      <div className="rounded-full bg-black w-[8px] h-[8px] border-2 border-black inline-block mb-1" />
                    </div>
                  )
                  : (
                    <div className="bg-white px-2 inline-block">
                      <div className="rounded-full bg-white w-[8px] h-[8px] border-2 border-black inline-block mb-1" />
                    </div>
                  )
            }

            { i < props.data.steps.length - 1
            && <div className={`border h-[1px] w-full -mt-4 ml-[50%] ${step.complete && 'border-[#53A8A0]'}`} /> }
            <div className={`${i < props.data.steps.length - 1 ? 'mt-4' : ''} font-normal`}>
              <div className={step.active ? 'active-step' : ''}>{step.title}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default HorizontalProgressList;
