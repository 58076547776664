import { fetchFspPageContent } from '../util/common';

type Page = keyof typeof defaultNavigationContent

export const getSupportNavigationContent = async (page: Page) => {
  const data: any = await fetchFspPageContent(`"${page}"`);
  if (data?.content === 'error' || !(data?.content)) {
    return defaultNavigationContent[page];
  }
  const { avatar_url, email, application_title, phone_number, sub_title, title, content_id, heading, description } = data.content;
  return {
    avatarUrl: avatar_url,
    email,
    id: content_id,
    name: title,
    phone: phone_number,
    subTitle: sub_title,
    title: application_title,
    heading,
    description,
  };
};

export const defaultNavigationContent = {
  affordability_support_navigation: {
    avatarUrl: '/images/mobile-logo.png',
    email: 'direct.quote@rate.com',
    id: '00000',
    name: 'Mortgage Expert',
    phone: '(833) 539-1346',
    subTitle: '',
    title: 'Guaranteed Rate',
    heading: 'You have questions?',
    description: "We'll have the answers.",
  },
  heloc_support_navigation: {
    avatarUrl: '/images/mobile-logo.png',
    email: 'support@myrateservicing.com',
    id: '00000',
    name: 'Home Equity Specialist',
    phone: '8666812264',
    subTitle: '',
    title: 'Guaranteed Rate',
    heading: 'Need to connect?',
    description: "I'm always available",
  }
};
