/* eslint-disable max-len */
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import DatePicker from 'react-datepicker';
import { dateDiffInYears, fetchPageContent } from '../../config/util/common';
import GoogleMapAddress from '../../components/GoogleMapAddress/GoogleMapAddress';
import { IAddressResponse } from '../../config/util/interfaces';

interface IPersonalizedRatesForm {
    birthday: Date;
    softCredit: string;
}

const newForm = () => ({
  birthday: '',
  softCredit: 'false'
});

const buildLocation = (props: any) => {
  if (props.propertyData != null) {
    // eslint-disable-next-line max-len
    return `${props.propertyData.address.street[0]}${props.propertyData.address.street.length > 1 ? ` ${props.propertyData.address.street[1]}` : ''} ${props.propertyData.address.city} ${props.propertyData.address.region} ${props.propertyData.address.postalCode}`;
  }
  return '';
};

function OwningPersonalizedRates(props: any) {
  const navigate = useNavigate();
  props.updateHeaderType('one');
  const today = new Date();
  const min18 = new Date(`${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear() - 18}`);
  const [personalizedRatesForm, setPersonalizedRatesForm] = useState(newForm());
  const [birthdayDate, setBirthdayDate] = useState<Date | null>();
  const [currentAddress, setCurrentAddress] = useState(buildLocation(props));
  const [currentAddressObj, setCurrentAddressObj] = useState({} as IAddressResponse);
  const [softCredit, setSoftCredit] = useState(false);
  const [content, setContent] = useState(null) as any;
  const [contentFetched, setContentFetched] = useState(false);
  const [error, setError] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);

  if (!content && !contentFetched && !error) {
    fetchPageContent('"owning_personalized_rates"').then(
      (data: any) => {
        if (data.content === 'error') {
          setError(true);
          setContentFetched(false);
        } else {
          setError(false);
          setContentFetched(true);
          setContent(data.content);
        }
      }
    );
  }

  console.log(content);

  const isValidDate = () => !birthdayDate || dateDiffInYears(birthdayDate, today) >= 18;

  const onBirthDayChange = (date: any) => {
    setPersonalizedRatesForm({ ...personalizedRatesForm, birthday: date.toISOString().substring(0, 10) });
    setBirthdayDate(date);
  };

  const onAddressChange = (address: any) => {
    setCurrentAddress(address.formattedAddress);
    setCurrentAddressObj(address);
  };

  const onSoftCreditChange = () => {
    const value = softCredit ? 'false' : 'true';
    setPersonalizedRatesForm({ ...personalizedRatesForm, softCredit: value });
    setSoftCredit(!softCredit);
  };

  const validateKey = (key: string) => personalizedRatesForm[key as keyof IPersonalizedRatesForm].length > 0;

  const hasError = (name: string) => {
    const isError: any = trySubmit && !validateKey(name);
    if (isError) {
      window.scrollTo(0, 0);
    }
    return isError;
  };

  const handleGetPersonalizedRates = async () => {
    setTrySubmit(true);
    const keys = Object.keys(personalizedRatesForm);
    console.log(keys.every((key) => validateKey(key)));
    if (isValidDate() && keys.every((key) => validateKey(key)) && currentAddress.length > 0) {
      navigate('/mortgage/sweet');
    }
  };

  return (
    <>
      <div className="flex justify-center w-full">
        <div className="max-w-2xl w-full px-1">
          <div className="flex items-center justify-center">
            <div className="px-4 w-full">
              <h2 className="font-bold mt-8">{content?.header}</h2>
              <div className="mt-4">{content?.description}</div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="px-4 w-full mt-8">
              <div className="md:grid">
                <DatePicker
                  selected={birthdayDate}
                  className="w-full"
                  onChange={onBirthDayChange}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  maxDate={min18}
                  dropdownMode="select"
                  customInput={(
                    <TextInput
                      name="birthday"
                      value={personalizedRatesForm.birthday}
                      label={content?.form?.birthday_label}
                      className="w-full"
                      hasError={hasError('birthday')}
                      helperText={hasError('birthday') ? 'required field' : (!isValidDate() ? 'You must be at least 18 years old' : '')}
                    />
                  )}
                />
              </div>
              <div className="grid">
                <GoogleMapAddress
                  onChange={onAddressChange}
                  value={currentAddress}
                  addressObj={currentAddressObj}
                  label={content?.form?.current_address_label}
                  type="address"
                  hasError={hasError('currentAddress')}
                  hasUnitError={trySubmit && !currentAddressObj.unitNumber}
                />
              </div>
              <div className="flex flex-row mb-4">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={softCredit}
                  onChange={onSoftCreditChange}
                />
                <h4>{content?.form?.checkbox_label}</h4>
              </div>
              <div className="disclaimer text-left text-xs mb-8">
                {content?.form?.disclaimer}
              </div>
              <div className="font-bold flex">
                <div className="-mt-1">
                  <FontIcon iconName="shield-check" className="mr-2 !leading-8 text-lg" />
                </div>
                <div>
                  <span>{content?.form?.info}</span>
                </div>
              </div>
              <Button buttonStyle="primary" className="!w-full mt-8" onClick={handleGetPersonalizedRates}>{content?.form?.submit_button}</Button>
              <div className="mt-4 underline text-center">{content?.enquire_question}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OwningPersonalizedRates;
