import Button from '@guaranteed-rate/react-components/dist/Button';
import { useEffect, useState } from 'react';
import Loader from '@guaranteed-rate/react-components/dist/Loader';
import RadioButtons from '@guaranteed-rate/react-components/dist/RadioButtons';
import Form from '../../../components/Form/Form';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import GoogleMapAddress from '../../../components/GoogleMapAddress/GoogleMapAddress';
import { getDateErrorText, loadDate, loadScriptFunction } from '../../../config/util/common';
import { config } from '../../../config/content/config';
import { IBasicInfoData, IProperty, IPropertyResponse } from '../../../config/util/interfaces';
import { buildLocation, buildLocationFromProperty } from '../HelocPropertyPage/HelocPropertyPage';
import CalendarInput from '../../../components/CalendarInput/CalendarInput';

interface IPersonalProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  progress?: ProgressProps;
  basicInfoData: IBasicInfoData;
  propertyData: IPropertyResponse;
}

interface IAddressForm {
  address: string;
  unitNumber: string;
  city: string;
  state: string;
  zip: string;
}

// load the existing form with the previously entered data, if available
const loadExistingData = (property: IProperty) =>
  (property?.city != null
    ? ({
      address: property.street[0],
      unitNumber: property.street.length > 1 ? property.street[1] : '',
      city: property.city,
      state: property.region,
      zip: property.postalCode,
    })
    : ({
      address: '',
      unitNumber: '',
      city: '',
      state: '',
      zip: '',
    } as IAddressForm));

export const HelocUpdateProperty = (props: IPersonalProps) => {
  const [loading, setLoading] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [fetchGoogleApi, setFetchGoogleApi] = useState(false);
  const [propertyForm, setPropertyForm] = useState(loadExistingData(props.propertyData?.address));
  const [personalForm, setPersonalForm] = useState(loadExistingData(props.basicInfoData?.residenceAddress));
  const [propertyAddress, setPropertyAddress] = useState(props.propertyData?.address?.city
    ? buildLocationFromProperty(props.propertyData.address) : '');
  const [personalAddress, setPeronalAddress] = useState(props.basicInfoData?.residenceAddress?.city
    ? buildLocationFromProperty(props.basicInfoData.residenceAddress) : '');
  const [sameAddress, setSameAddress] = useState(propertyAddress?.length > 0
    ? propertyAddress === personalAddress ? 'YES' : 'NO' : '');
  const [startDate, setStartDate] = useState<Date | null>(props.basicInfoData.residenceStartDate
    ? loadDate(props.basicInfoData.residenceStartDate) : null);
  const [start, setStart] = useState(props.basicInfoData.residenceStartDate || '');
  const [trySubmit, setTrySubmit] = useState(false);
  const options = [
    {
      name: 'Yes',
      label: 'Yes',
      value: 'YES',
      active: false
    }, {
      name: 'Yes',
      label: 'No',
      value: 'NO',
      active: false
    }
  ];

  if (!fetchGoogleApi && !apiLoaded) {
    setFetchGoogleApi(true);
    loadScriptFunction(config.googleMapsApiURL, () => {
      setApiLoaded(true);
    });
  }

  // Used to pass the personal data to the orchestrator
  const handleSubmit = async () => {
    setTrySubmit(true);
    if (!getDateErrorText(start || '') && personalForm.address.length > 0 && propertyForm.address.length > 0) {
      setLoading(true);
      const pageData = `{ 
        page: "${props.pageName}"
        lite: true
        personalData: {
          address: "${personalForm.address}"
          apt: "${personalForm.unitNumber}"
          city: "${personalForm.city}"
          state: "${personalForm.state}"
          zip: "${personalForm.zip}"
          residenceStartDate: "${start}"
        }
        propertyData: {
          address: "${propertyForm.address}"
          apt: "${propertyForm.unitNumber}"
          city: "${propertyForm.city}"
          state: "${propertyForm.state}"
          zip: "${propertyForm.zip}"
        }
      }`;
      await props.handleSubmit(pageData, false);
      setLoading(false);
    }
  };

  // Used to go back to previous screen (currently property screens)
  const handleBack = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}" 
      lite: true
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };
  const onAddressChange = (address: any, form: IAddressForm, setAddress: any, setAddressObj: any) => {
    Object.keys(form).map((key: string) => {
      form[key as keyof IAddressForm] = address[key];
    });
    setAddressObj(form);
    setAddress(address.formattedAddress);
  };

  const onSameAddress = (event: any) => {
    const same = event.target.value;
    let form = {} as IAddressForm;
    if (same === 'YES') {
      Object.keys(propertyForm).map((key: string) => {
        form[key as keyof IAddressForm] = propertyForm[key as keyof IAddressForm];
      });
      setPersonalForm(form);
      setPeronalAddress(propertyAddress);
    }
    setSameAddress(same);
  };

  useEffect(() => {
    if (sameAddress === 'YES') {
      let form = {} as IAddressForm;
      Object.keys(propertyForm).map((key: string) => {
        form[key as keyof IAddressForm] = propertyForm[key as keyof IAddressForm];
      });
      setPersonalForm(form);
      setPeronalAddress(propertyAddress);
    }
  }, [propertyForm, propertyAddress]);

  const getErrorText: string = trySubmit ? getDateErrorText(start || '') : '';

  return (
    <Form title={props.content.header} progress={props.progress}>
      {
        !apiLoaded ? (
          <div className="min-h-[420px] flex items-center justify-center">
            <Loader color="#D13239" className="loader-medium" />
          </div>
        )
          : (
            <div>
              <div className="min-h-[300px]">
                <GoogleMapAddress
                  name="propertyAddress"
                  onChange={(address) => onAddressChange(address, propertyForm, setPropertyAddress, setPropertyForm)}
                  value={propertyAddress}
                  addressObj={propertyForm}
                  label={props.content.subject_property_label}
                  hasError={trySubmit && propertyForm.address.length === 0}
                  helperText={trySubmit && propertyForm.address.length === 0 ? 'Please select a valid address from the list' : ''}
                  required={true}
                  type="address"
                />
                <div>
                  <h4 className="mb-4 font-bold mt-4">{props.content.same_label}</h4>
                  <RadioButtons
                    className="radioButton radioFlex grow"
                    radios={options}
                    onChange={onSameAddress}
                    isChecked={sameAddress}
                  />
                </div>
                {
                  sameAddress === 'NO' && (
                    <GoogleMapAddress
                      name="personalAddress"
                      onChange={(address) => onAddressChange(address, personalForm, setPeronalAddress, setPersonalForm)}
                      value={personalAddress}
                      addressObj={personalForm}
                      label={props.content.current_address_label}
                      hasError={trySubmit && personalForm.address.length === 0}
                      helperText={trySubmit && personalForm.address.length === 0 ? 'Please select a valid address from the list' : ''}
                      required={true}
                      type="address"
                    />
                  )
                }
                {
                  sameAddress !== '' && (
                    <CalendarInput
                      label={props.content.start_date_label}
                      name="startInput"
                      setForm={(form: any) => setStart(form.startInput)}
                      form={{ startInput: start }}
                      className="birthdayInput"
                      hasError={getErrorText}
                      helperText={getErrorText}
                    />
                  )
                }

              </div>
              <div className="flex justify-center mt-12">
                <Button
                  buttonStyle="primary"
                  className="!w-full md:!w-64 nextButton"
                  onClick={handleSubmit}
                  loading={loading}
                  buttonAttrs={{ disabled: loading }}
                >Next
                </Button>
              </div>
              <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
                <Button
                  buttonStyle="quaternary"
                  iconPos="left"
                  iconName="chevron-left-large"
                  onClick={handleBack}
                  buttonAttrs={{ disabled: loading }}
                >Back
                </Button>
              </div>
            </div>
          )
      }

    </Form>
  );
};
