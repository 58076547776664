import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import { useState } from 'react';
import Form from '../../../components/Form/Form';
import { MASKS } from '../../../config/util/masks';
import { IIncomeData } from '../../../config/util/interfaces';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';

interface IIncomeProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  pageName: string;
  incomeData: IIncomeData;
  progress?: ProgressProps;
}

interface IIncomeForm {
  income: string;
  incomeType: string;
  otherIncome: string;
}

const loadExistingData = (props: IIncomeProps) => {
  if (props.incomeData != null) {
    return {
      income: props?.incomeData?.annualIncome.toString(),
      incomeType: props?.incomeData?.incomeSource,
      otherIncome: props?.incomeData?.otherIncome.toString(),
    } as IIncomeForm;
  }
  return {
    income: '',
    incomeType: '',
    otherIncome: '',
  } as IIncomeForm;
};

export const HelocIncomePage = (props: IIncomeProps) => {
  const [helocForm, setHelocForm] = useState(loadExistingData(props));
  const [loading, setLoading] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);

  // Load the income types from content stack
  const incomeTypes = props.content.income_type_dropdown.map((option: any) =>
    ({ value: option.label.value, displayName: option.label.label }));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setHelocForm({ ...helocForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  // submits the income data to the orchestrator and moves to the next state
  const handleSubmit = async () => {
    setTrySubmit(true);

    // verify that all fields have been completed
    if (helocForm.incomeType?.trim() && helocForm.income?.trim()) {
      setLoading(true);
      const pageData = `{ 
        page: "${props.pageName}"
        incomeData: { 
          income: "${helocForm.income}"
          incomeType: "${helocForm.incomeType}"
          otherIncome: "${helocForm.otherIncome?.trim() || '0'}"
        }
      }`;

      // save the non sensitive data to the window for adobe
      (window as any).digitalData = {
        pageInfo: {
          incomeData: {
            income: helocForm.income,
            incomeType: helocForm.incomeType,
            otherIncome: helocForm.otherIncome
          }
        }
      };
      await props.handleSubmit(pageData, false);
      setLoading(false);
    }
  };

  // go to previous screen
  const handleBack = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };

  const hasError = (name: string) => trySubmit && helocForm[name as keyof IIncomeForm].length <= 0;

  return (
    <Form title={props.content.header} progress={props.progress}>
      <div>
        <h4 className="pb-4">{props.content.sub_header}</h4>
        <Dropdown
          name="incomeType"
          value={helocForm.incomeType}
          label={props.content.income_type_label}
          onChange={(event) => handleChange(event, 'incomeType')}
          className="w-full incomeTypeDropdown"
          options={incomeTypes}
          hasError={hasError('incomeType')}
          helperText={hasError('incomeType') ? 'required' : undefined}
          required={true}
        />
        <div className="md:grid md:grid-cols-2 md:space-x-4">
          <TextInput
            name="income"
            value={helocForm.income}
            label={props.content.income_label}
            onChange={(input) => handleChange(input, 'income')}
            mask={MASKS.CURRENCY}
            hasError={hasError('income')}
            helperText={hasError('income') ? 'required' : props.content.income_label_helper_text}
          />
          <TextInput
            name="otherIncome"
            value={helocForm.otherIncome}
            mask={MASKS.CURRENCY}
            label={props.content.other_income}
            onChange={(input) => handleChange(input, 'otherIncome')}
          />
        </div>
      </div>
      <div className="flex justify-center mt-12">
        <Button
          buttonStyle="primary"
          className="!w-full md:!w-48 nextButton"
          onClick={handleSubmit}
          loading={loading}
          buttonAttrs={{ disabled: loading }}
        >Next
        </Button>
      </div>
      <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
        <Button
          buttonStyle="quaternary"
          iconPos="left"
          iconName="chevron-left-large"
          onClick={handleBack}
          buttonAttrs={{ disabled: loading }}
        >Back
        </Button>
      </div>
      <div className="text-center pt-12 ">
        <p>{props.content.income_description}</p>
        <p>{props.content.other_income_description}</p>
      </div>
    </Form>
  );
};
