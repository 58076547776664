/* eslint-disable max-len */
import React, { useState } from 'react';
import Button from '@guaranteed-rate/react-components/dist/Button';
import Dropdown from '@guaranteed-rate/react-components/dist/Dropdown';
import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import TextInput from '@guaranteed-rate/react-components/dist/TextInput';
import { mortgageTypes } from '../../../config/content/dropdown';
import { MASKS } from '../../../config/util/masks';
import Form from '../../../components/Form/Form';
import Alert from '../../../components/Alert/Alert';
import { IMortgage, IMortgageData } from '../../../config/util/interfaces';
import { ProgressProps } from '../../../components/ProgressList/IProgressList';
import CustomTable from '../../../components/CustomTable/CustomTable';
import CalendarInput from '../../../components/CalendarInput/CalendarInput';
import { LienProgressBar } from '../HelocPropertyPage/LienProgressBar';

interface IMortgageProps {
  handleSubmit: (pageData: any, back: boolean) => Promise<any>;
  content: any;
  mortgageData: IMortgageData;
  pageName: string;
  progress?: ProgressProps;
  isLite?: boolean;
}

interface IMortgageForm {
  type: string;
  lender: string;
  date: string;
  currentBalance: string;
  monthlyPayment: string;
}

const sortedMortgages = (data: Array<IMortgage>) => (
  !data ? [] : data?.sort((a, b) => {
    if (!a.isManualEntry && b.isManualEntry) {
      return -1;
    } if (a.isManualEntry && !b.isManualEntry) {
      return 1;
    }
    return 0;
  })
);

export const HelocMortgagePage = (props: IMortgageProps) => {
  const [mortgages, setMortgages] = useState(sortedMortgages(props?.mortgageData?.mortgages) || []);

  const columns = [
    { key: 'lender', title: props.content.lender_column_label },
    { key: 'originationDate', title: props.content.origination_column_label },
    { key: 'action', title: '', isClickable: true, }
  ];

  const [loading, setLoading] = useState(false);
  const [mortgageSubmissionLoader, setMortgageSubmissionLoader] = useState(false);
  const [tryAdd, setTryAdd] = useState(false);
  const [addMortgage, setAddMortgage] = useState(false);
  const [mortgageForm, setMortgageForm] = useState({
    type: '',
    lender: '',
    date: '',
    currentBalance: '',
    monthlyPayment: '',
  } as IMortgageForm);
  const [noOutStandingMortgages, setNoOutStandingMortgages] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const { address } = props.mortgageData;
  const header = `${props.content.header} ${address?.split(',')?.[0] || ''}?`;
  const onNoOutStandingMortgagesChange = () => {
    const value = noOutStandingMortgages ? 'false' : 'true';
    setNoOutStandingMortgages(!noOutStandingMortgages);
    setCanSubmit(!noOutStandingMortgages);
  };

  const handleRowEdit = (rowIndex: number) => {
    setAddMortgage(true);
    setSelectedRowIndex(rowIndex);
    setMortgageForm({
      type: mortgages[rowIndex]?.lienType.toLowerCase(),
      lender: mortgages[rowIndex]?.lender,
      date: mortgages[rowIndex]?.date,
      currentBalance: String(mortgages[rowIndex]?.amount),
      monthlyPayment: String(mortgages[rowIndex]?.monthlyPayment),
    });
  };

  const handleRowDelete = () => {
    const updatedMortgages = mortgages.filter((_, index) => index !== selectedRowIndex);
    const updatedRows = rows.filter((_, index) => index !== selectedRowIndex);
    setRows(updatedRows);
    setMortgages(updatedMortgages);
    handleAddCancel();
  };

  const getRow = (lender: string, date: string, isManual: boolean) => ({
    lender,
    originationDate: date,
    action: isManual ? (
      <Button
        className="manualMortgage-edit-btn"
        buttonStyle="tertiary"
        buttonSize="small"
      >
        Edit
      </Button>
    ) : '',
  });

  const [rows, setRows] = useState(mortgages.map((mortgage: IMortgage) => getRow(mortgage.lender, mortgage.date, mortgage.isManualEntry)));

  const printMortgage = (mortgage: IMortgage) => `{ 
      type: "${mortgage.lienType}"
      lender: "${mortgage.lender}"
      date: "${mortgage.date}"                            
      currentBalance: "${mortgage.amount}"
      monthlyPayment: "${mortgage.monthlyPayment}"
    }`;

  const getMortgages = () => {
    let data = '{ mortgages: [';
    mortgages.map((mortgage: IMortgage) => {
      if (mortgage.isManualEntry && mortgage.borrowerSelected) {
        data += printMortgage(mortgage);
      }
    });
    data += '] }';
    return data;
  };

  const getMortgageIds = () => {
    let mortgageIds = '[';
    mortgages.map((mortgage: IMortgage) => {
      mortgageIds += `"${mortgage.id}",`;
    });
    mortgageIds = `${mortgageIds.length > 1 ? mortgageIds.substring(0, mortgageIds.length - 1) : mortgageIds}]`;
    return mortgageIds;
  };

  const checkSubmit = () => mortgages.length > 0 || mortgages.some((mortgage: IMortgage) => mortgage.borrowerSelected) || noOutStandingMortgages;

  const [canSubmit, setCanSubmit] = useState(checkSubmit());

  const handleSubmit = async () => {
    if (canSubmit) {
      setMortgageSubmissionLoader(true);
      const pageData = `{
        page: "${props.pageName}"
        ${props.isLite ? 'lite: true' : ''}
        lienIds: ${getMortgageIds()}
        mortgageData: ${getMortgages()}
      }`;

      (window as any).digitalData = {
        pageInfo: {
          mortgageData: {
            lienCount: mortgages.length,
            manualCount: mortgages.filter((x) => x.isManualEntry)
          }
        }
      };
      try {
        await props.handleSubmit(pageData, false);
      } catch (error) {
        console.log(error)
      } finally {
        setMortgageSubmissionLoader(false);
      }
    }
  };

  const handleAddButton = () => {
    setAddMortgage(true);
  };

  const handleAddCancel = () => {
    setMortgageForm({
      type: '',
      lender: '',
      date: '',
      currentBalance: '',
      monthlyPayment: '',
    });
    setAddMortgage(false);
    setSelectedRowIndex(-1);
  };

  const handleAddSubmit = () => {
    setTryAdd(true);
    const keys = Object.keys(mortgageForm);
    if (keys.every((key) => mortgageForm[key as keyof IMortgageForm].length > 0)) {
      if (selectedRowIndex !== -1) {
        const tempMortages = [...mortgages];
        const tempRows = [...rows];
        tempMortages[selectedRowIndex] = ({
          lienType: mortgageForm.type,
          lender: mortgageForm.lender,
          date: mortgageForm.date,
          amount: parseFloat(mortgageForm.currentBalance),
          monthlyPayment: mortgageForm.monthlyPayment,
          isManualEntry: true,
          borrowerSelected: true
        } as IMortgage);
        tempRows[selectedRowIndex] = getRow(mortgageForm.lender, mortgageForm.date, true);
        setMortgages(tempMortages);
        setRows(tempRows);
      } else {
        setMortgages((prevState) => [...prevState, {
          lienType: mortgageForm.type,
          lender: mortgageForm.lender,
          date: mortgageForm.date,
          amount: parseFloat(mortgageForm.currentBalance),
          monthlyPayment: mortgageForm.monthlyPayment,
          isManualEntry: true,
          borrowerSelected: true
        } as IMortgage]);
        setRows((prevState) => [...prevState, getRow(mortgageForm.lender, mortgageForm.date, true)]);
      }
      handleAddCancel();
      setCanSubmit(checkSubmit());
      setTryAdd(false);
      setSelectedRowIndex(-1);
    }
  };

  const handleBack = async () => {
    setLoading(true);
    const pageData = `{ 
      page: "${props.pageName}"
      ${props.isLite ? 'lite: true' : ''}
      back: true
    }`;
    await props.handleSubmit(pageData, true);
    setLoading(false);
  };

  const hasError = (name: string) => tryAdd && mortgageForm[name as keyof IMortgageForm].length <= 0;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, name = '') => {
    setMortgageForm({ ...mortgageForm, [name !== '' ? name : event.target.name]: event.target.value });
  };

  return (
    <Form title={mortgageSubmissionLoader ? props.content.loader.loader_heading : header} progress={props.progress}>
      <div className="min-h-[300px]">
        { mortgageSubmissionLoader && (
          <div className="min-h-[420px] text-center flex flex-col items-center justify-center">
            <LienProgressBar content={props.content.loader} mortgageSubmissionLoader={mortgageSubmissionLoader} />
          </div>
        )}
        {
          !mortgageSubmissionLoader
          && (
            <>
              <p>{props.content.description}</p>
              {
                rows.length > 0 ? (
                  <CustomTable className="mt-2" rows={rows} columns={columns} onCellClick={handleRowEdit} />
                )
                  : (
                    <div className="mt-8">
                      {props.content.warning_message_line_1} <span className="font-bold">{address || ''}</span>. {props.content.warning_message_line_2}
                      <div>
                        <span>{props.content.mortgage_status_label}</span>
                        <div className="flex flex-row mb-4 mt-4">
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={noOutStandingMortgages}
                            onChange={onNoOutStandingMortgagesChange}
                          />
                          <h4>{props.content.mortgage_status_declaration}</h4>
                        </div>
                      </div>
                    </div>
                  )
              }
              {addMortgage
                ? (
                  <div>
                    <h4 className="font-bold text-xl mt-4 mb-4">{props.content.add_lien_label}</h4>
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                      <Dropdown
                        name="type"
                        value={mortgageForm.type}
                        label={props.content.mortgage_type_label}
                        onChange={(event) => handleChange(event, 'type')}
                        className="w-full mortgageType"
                        options={mortgageTypes}
                        hasError={hasError('type')}
                        helperText={hasError('type') ? 'required' : undefined}
                        required={true}
                      />
                      <CalendarInput
                        label={props.content.origination_column_label}
                        name="date"
                        setForm={setMortgageForm}
                        form={mortgageForm}
                        hasError={hasError('date')}
                        className="originationDate"
                        required={true}
                        helperText="required"
                      />
                    </div>
                    <TextInput
                      name="lender"
                      value={mortgageForm.lender}
                      label={props.content.lender_column_label}
                      onChange={handleChange}
                      hasError={hasError('lender')}
                      helperText={hasError('lender') ? 'required' : undefined}
                      required={true}
                    />
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                      <TextInput
                        name="currentBalance"
                        value={mortgageForm.currentBalance}
                        label={props.content.current_balance_label}
                        onChange={(input) => handleChange(input, 'currentBalance')}
                        mask={MASKS.CURRENCY}
                        hasError={hasError('currentBalance')}
                        helperText={hasError('currentBalance') ? 'required' : undefined}
                        required={true}
                      />
                      <TextInput
                        name="monthlyPayment"
                        value={mortgageForm.monthlyPayment}
                        label={props.content.monthly_payment_label}
                        onChange={(input) => handleChange(input, 'monthlyPayment')}
                        mask={MASKS.CURRENCY}
                        hasError={hasError('monthlyPayment')}
                        helperText={hasError('monthlyPayment') ? 'required' : undefined}
                        required={true}
                      />
                    </div>
                    <div className={`flex ${(rows.length > 0 && selectedRowIndex !== -1) ? 'justify-between' : 'justify-end'}`}>
                      {(rows.length > 0 && selectedRowIndex !== -1) && (
                        <div className="delete-mortgage">
                          <Button
                            buttonStyle="tertiary"
                            iconName="trash"
                            className="!w-full min-h-14 !text-2xl delete-mortgage-btn"
                            onClick={handleRowDelete}
                          />
                        </div>
                      )}
                      <div className="flex justify-center md:justify-end mb-16">
                        <Button
                          buttonStyle="secondary"
                          className="!w-full md:!w-48 mr-4"
                          onClick={handleAddCancel}
                          loading={loading}
                        >{props.content.cancel_button}
                        </Button>
                        <Button
                          buttonStyle="primary"
                          className="!w-full md:!w-48 saveButton"
                          onClick={handleAddSubmit}
                          loading={loading}
                        >{props.content.save_button}
                        </Button>
                      </div>
                    </div>
                  </div>
                )
                : (
                  <Button className="font-bold pt-4 !border-0 addMortgageButton" buttonStyle="tertiary" onClick={handleAddButton}>
                    <FontIcon iconName="plus-circle" className="text-xl mr-2 !leading-loose" />
                    <span className="text-base">{props.content.add_lien_label}</span>
                  </Button>
                )}
            </>
          )
        }
      </div>
      {!addMortgage && !mortgageSubmissionLoader
      && (
        <div>
          <div className="flex justify-center mt-12">
            <Button
              buttonStyle="primary"
              className="!w-full md:!w-48 nextButton"
              onClick={handleSubmit}
              buttonAttrs={{ disabled: !canSubmit || loading }}
              loading={loading}
            >{props.content.next_button}
            </Button>
          </div>
          <div className="flex justify-center mt-6 -ml-8 md:-mt-11 md:ml-0 md:block">
            <Button
              buttonStyle="quaternary"
              iconPos="left"
              iconName="chevron-left-large"
              onClick={handleBack}
              buttonAttrs={{ disabled: loading }}
            >{props.content.back_button}
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};
