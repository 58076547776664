import FontIcon from '@guaranteed-rate/react-components/dist/FontIcon';
import { useEffect, useState } from 'react';

interface IProgressBarLoaderProps {
  title: string;
  timer: number;
  loaderColorClass?: string;
  onCompleted: () => void;
  icon: string;
}

export default function ProgressBarLoader(props: IProgressBarLoaderProps) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const timer = (props.timer * 1000) / 100;
    let timeOut;
    if (width === 100) {
      clearTimeout(timeOut);
      props.onCompleted();
    } else {
      timeOut = setTimeout(() => {
        setWidth(Math.min(width + 1, 100));
      }, timer);
    }
  }, [width]);

  const className: string = props.loaderColorClass || 'bg-blue-600';

  return (
    <>
      <div className="w-full flex">
        <div className="w-full bg-gray-200 rounded-full h-6 dark:bg-gray-700 mb-2">
          <div className={`${className} h-6 rounded-full`} style={{ width: `${width}%` }} />
        </div>
        <FontIcon className="text-gray-800 text-6xl ml-4 -mt-4" iconName={props.icon} />
      </div>
      {width !== 100 && (
        <div className="text-center">
          <p className="text-2xl font-semibold">{props.title}</p>
        </div>
      )}
    </>

  );
}
